import { Component, EventEmitter, Input } from "@angular/core";
import { NgOptimizedImage } from "@angular/common";
import { Router } from "@angular/router";

@Component({
  selector: 'app-add-another-dialog',
  standalone: true,
  imports: [
    NgOptimizedImage
  ],
  templateUrl: './add-another-dialog.component.html',
  styleUrl: './add-another-dialog.component.scss'
})
export class AddAnotherDialogComponent {
  constructor(private router: Router) {
  }

  @Input() featureIconPath = ""
  @Input() title = ""
  @Input() titleDescription = ""
  @Input() primaryBtnText = ""
  @Input() primaryBtnUrl = ""
  @Input() secondaryBtnText = "Do it Later"
  @Input() secondaryBtnUrl = ""
  @Input() primaryBtnClickEmitter = new EventEmitter<any>();

  handlePrimaryBtnClick() {
    if(this.primaryBtnUrl === ""){
      this.primaryBtnClickEmitter.emit();
      return;
    }
    this.router.navigateByUrl(this.primaryBtnUrl);
  }

  handleSecondaryBtnClick(){
    if(this.secondaryBtnUrl === "") return;
    this.router.navigateByUrl(this.secondaryBtnUrl);
  }
}
