import {
  Component,
  ElementRef,
  Input,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { SvgIconComponent } from '../../../svg-icon/svg-icon.component';
import { BackButtonComponent } from '../../../back-button/back-button.component';
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControlOptions,
  FormArray,
  ValidatorFn,
  AbstractControl,
  FormControl,
} from '@angular/forms';
import { User } from '../../../../models/user.interface';
import { ApiService } from '../../../../services/api.service';
import { LoadingService } from '../../../../services/loading.service';
import { ToasterService } from '../../../../services/toaster.service';
import { SharedModule } from '../../../../modules/shared/shared.module';
import { atLeastOneCheckboxCheckedValidator } from '../../../../validators/atleastOneCheckboxCheked';
import { ActivatedRoute, Router } from '@angular/router';
import { Customer } from '../../../../models/customer-master.interface';
import { AutocompleteDropdownComponent } from '../../../autocomplete-dropdown/autocomplete-dropdown.component';
import { ToggleComponent } from '../../../common/toggle/toggle.component';

import {
  MatExpansionModule,
  MatExpansionPanel,
} from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';
import { HorizontalTabsComponent } from '../../../horizontal-tabs/horizontal-tabs.component';

@Component({
  selector: 'app-manage-customer-revamp',
  standalone: true,
  imports: [
    SharedModule,
    SvgIconComponent,
    BackButtonComponent,
    AutocompleteDropdownComponent,
    MatExpansionModule,
    MatTabsModule,
    HorizontalTabsComponent,
    ToggleComponent,
  ],
  templateUrl: './manage-customer-revamp.component.html',
  styleUrl: './manage-customer-revamp.component.scss',
})
export class ManageCustomerRevampComponent {
  @Input() customerEditingEnabled: boolean = false;
  @Input() customerId!: string;
  @Input() customerObj!: Customer;

  @ViewChildren('formField') formFields!: QueryList<ElementRef>;
  @ViewChildren(MatExpansionPanel) expansionPanels!: QueryList<MatExpansionPanel>;

  readOnlyMode: boolean = true;
  customerForm: FormGroup;
  customerTypes = [
    { name: 'Freight Forwarder', value: 'FREIGHT_FORWARDER', checked: false },
    { name: 'Consignee', value: 'CONSIGNEE', checked: false },
    { name: 'Line Agent', value: 'LINE_AGENT', checked: false },
    { name: 'Line', value: 'LINE', checked: false },
    { name: 'CHA', value: 'CHA', checked: false },
    { name: 'Transporter', value: 'TRANSPORTER', checked: false },
    { name: 'Shipper', value: 'SHIPPER', checked: false },
    { name: 'Group Company', value: 'GROUP_COMPANY', checked: false },
    { name: 'Other CFS', value: 'OTHER_CFS', checked: false },
  ];
  selectedTab: string = 'BasicDetails'; // Default tab

  tabList = [
    { id: 'BasicDetails', label: 'Basic Details' },
    { id: 'ContactInformation', label: 'Contact Information' },
    { id: 'BankDetails', label: 'Bank Details' },
  ];
  customerDataRes?: Customer;


  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private toasterService: ToasterService,
    private loadingService: LoadingService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.customerForm = this.fb.group({
      code: ['', Validators.required],
      parentCustomerId: [''],
      name: ['', Validators.required],
      panNumber: ['', Validators.required],
      sisterCustomerId: [''],
      nominated: [false],
      customerType: this.fb.array(
        this.customerTypes.map((ct) => new FormControl(ct.checked)),
        atLeastOneCheckboxCheckedValidator()
      ),
      contactInformation: this.fb.array([this.createContactFormGroup()]),
      responsibleMExecUsedId: [''],
      serviceTaxNo: [''],
      tdsExemptedFlag: [false],
      tdsExemptionNo: [{ value: '', disabled: true }],
      tdsPercentage: [0],
      incomeTaxNo: [''],
      creditNoOfDays: [0],
      creditAmount: [0],
      economicFreeZoneFlag: [false],
      efzCertificateNo: [{ value: '', disabled: true }],
      tanNumber: [''],
      importerExporterCode: [''],
      pdaCreditAmount: [{ value: 0, disabled: true }],
      active: [true],
      pdaMaintain: [false],
      // createdBy: [''],
      // modifiedBy: [''],
      // createdDate: [''],
      // modifiedDate: [''],
    });
  }

  ngOnInit() {
    this.customerId = this.route.snapshot.paramMap.get('customerId') || '';
    if (this.customerId !== '') {
      if (this.route.snapshot.url[0].path === 'edit') {
        this.customerEditingEnabled = true;
        this.readOnlyMode = false;
      } else {
        this.customerEditingEnabled = false;
        this.readOnlyMode = true;
        this.customerForm.disable();
      }
      this.loadingService.show();

      this.api.getCustomer(this.customerId).subscribe({
        next: (res: any) => {
          this.customerDataRes = res.data;
          const customerData = this.customerDataRes;
          console.log(customerData);
          this.customerForm.patchValue({
            ...customerData,
            parentCustomerId: customerData?.parentCustomerName,
            sisterCustomerId: customerData?.sisterCustomerName,
          });
          this.hydrateForm();
          this.loadingService.hide();
        },
        error: (err) => {
          console.log(err);
          this.loadingService.hide();
        },
      });
    } else {
      this.readOnlyMode = false;
      this.customerEditingEnabled = false;
      this.hydrateForm();
    }
  }

  selectTab(tabId: string) {
    this.selectedTab = tabId;
  }

  hydrateForm() {
    this.customerForm
      .get('tdsExemptedFlag')
      ?.valueChanges.subscribe((checked) => {
        const inputControl = this.customerForm.get('tdsExemptionNo');
        if (checked === 'true') {
          inputControl?.enable();
        } else {
          this.customerForm.get('tdsExemptionNo')?.reset();
          inputControl?.disable();
        }
      });

    this.customerForm
      .get('economicFreeZoneFlag')
      ?.valueChanges.subscribe((checked) => {
        const inputControl = this.customerForm.get('efzCertificateNo');
        if (checked === 'true') {
          inputControl?.enable();
        } else {
          this.customerForm.get('efzCertificateNo')?.reset();
          inputControl?.disable();
        }
      });

    this.customerForm.get('pdaMaintain')?.valueChanges.subscribe((checked) => {
      const inputControl = this.customerForm.get('pdaCreditAmount');
      if (checked === 'true') {
        inputControl?.enable();
      } else {
        this.customerForm.get('pdaCreditAmount')?.setValue(0);
        inputControl?.disable();
      }
    });
  }

  // Method to create a new contact form group
  createContactFormGroup(): FormGroup {
    return this.fb.group({
      address: [''],
      billingAddress: [''],
      mobileNumber1: [''],
      mobileNumber2: [''],
      faxNumber: [''],
      email: [''],
      pinCode: [''],
      cityId: [''],
      stateId: [''],
      countryId: [''],
      communicationPreference: ['mobile'],
      boardLine: [''],
      directLine: [''],
      gstNumber: [''],
      gstValidTill: [''],
      defaultContact: [false],
      panNumber: [''],
      sez: [false],
      lut: [false],
    });
  }

  // Getters for contact information and additional addresses form arrays
  get contactInformation(): FormArray {
    return this.customerForm.get('contactInformation') as FormArray;
  }

  get additionalAddresses(): FormArray {
    return this.customerForm.get('additionalAddresses') as FormArray;
  }

  get customerTypeArray(): FormArray {
    return this.customerForm.get('customerType') as FormArray;
  }

  // Scroll to the first invalid form control
  // scrollToFirstInvalidControl() {
  //   const firstInvalidControl = this.formFields.find((element) => {
  //     return !element.nativeElement.validity?.valid;
  //   });

  //   if (firstInvalidControl) {
  //     firstInvalidControl.nativeElement.scrollIntoView({
  //       behavior: 'smooth',
  //       block: 'center',
  //     });
  //     // firstInvalidControl.nativeElement.focus(); // Optionally, focus the invalid element
  //   }
  // }

  getTabContainingElement(element: HTMLElement): string | null {
    const tabMappings: { [key: string]: string } = {
      'basic-details-section': 'BasicDetails',
      'contact-information-section': 'ContactInformation',
      'billing-information-section': 'BillingInformation',
      'bank-details-section': 'BankDetails',
      'vendor-information-section': 'VendorInformation',
    };

    console.log('Checking invalid field inside:', element);

    for (const sectionId in tabMappings) {
      const sectionElement = element.closest(`#${sectionId}`);
      console.log(`Checking section: #${sectionId}`, sectionElement);

      if (sectionElement) {
        console.log(`Invalid field found inside: ${sectionId}`);
        return tabMappings[sectionId];
      }
    }

    console.warn('No matching tab found for invalid field');
    return null;
  }

  scrollToFirstInvalidControl() {
    setTimeout(() => {
      // Get all invalid form controls
      const invalidControls = this.formFields.filter((element) => {
        return !element.nativeElement.validity?.valid;
      });

      if (invalidControls.length > 0) {
        const firstInvalidControl = invalidControls[0];
        const invalidControlElement = firstInvalidControl.nativeElement;

        // Find the tab that contains the invalid field
        const invalidTab = this.getTabContainingElement(invalidControlElement);

        if (invalidTab && this.selectedTab !== invalidTab) {
          this.selectedTab = invalidTab; // Switch to the correct tab

          setTimeout(() => {
            this.scrollToInvalidField(firstInvalidControl.nativeElement);
          }, 500);
        } else {
          // If already on the correct tab, scroll immediately
          this.scrollToInvalidField(firstInvalidControl.nativeElement);
        }
      }
    }, 100);
  }

  scrollToInvalidField(element: HTMLElement) {
    // Expand the accordion if the invalid field is inside it
    this.expansionPanels.forEach((panel) => {
      if (panel._body.nativeElement.contains(element)) {
        panel.expanded = true;
      }
    });

    // Smooth scroll to the invalid field
    setTimeout(() => {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      element.focus();
    }, 300);
  }

  onCheckboxChange(e: any, i: number) {
    console.log('onCheckboxChange');
    this.customerTypeArray.controls[i].setValue(e.target.checked);
  }

  // Method to add a new contact form group to the array
  addContact() {
    this.contactInformation.push(this.createContactFormGroup());
  }

  // Method to remove a contact form group from the array
  removeContact(index: number) {
    this.contactInformation.removeAt(index);
  }

  // Method to add a new address
  addAddress() {
    this.additionalAddresses.push(
      this.fb.group({
        extraAddress: [''],
      })
    );
  }

  // Method to remove an address
  removeAddress(index: number) {
    this.additionalAddresses.removeAt(index);
  }

  async submitCustomerForm() {
    console.log('submitCustomerForm');
    console.log(this.customerForm.value);
    this.customerForm.markAllAsTouched();
    if (this.customerForm.valid) {
      console.log('valid form');
      const selectedCustomerTypes = this.customerForm.value.customerType
        .map((checked: boolean, i: number) =>
          checked ? this.customerTypes[i].value : null
        )
        .filter((type: any) => type !== null);
      console.log(selectedCustomerTypes);

      const customerData: Customer = {
        ...this.customerForm.value,
        customerType: selectedCustomerTypes,
      };

      if (this.customerEditingEnabled) {
        this.updateCustomer(customerData, this.customerId);
      } else {
        this.addCustomer(customerData);
      }
    } else {
      console.log('invalid form');
      this.scrollToFirstInvalidControl(); //scroll having issue
    }
  }

  addCustomer(customerData: Customer) {
    this.loadingService.show();
    console.log(customerData);
    this.api.addCustomer(customerData).subscribe({
      next: (res) => {
        console.log(res);
        this.toasterService.success(
          `Customer ${customerData.name} has been added !`
        );
        this.loadingService.hide();
        this.goBack();
      },
      error: (err) => {
        this.toasterService.error(err.error.errorDesc);
        console.log(err);
        this.loadingService.hide();
      },
    });
  }

  updateCustomer(customerData: Customer, customerId: string) {
    this.loadingService.show();
    customerData = {
      ...customerData,
      parentCustomerId:
        this.customerDataRes?.parentCustomerId || customerData.parentCustomerId,
      sisterCustomerId:
        this.customerDataRes?.sisterCustomerId || customerData.sisterCustomerId,
    };
    this.api.updateCustomer(customerData, customerId).subscribe({
      next: (res) => {
        console.log(res);
        this.toasterService.success(
          `Customer ${customerData.name} has been updated !`
        );
        this.loadingService.hide();
        this.goBack();
      },
      error: (err) => {
        this.toasterService.error(err.error.errorDesc);
        console.log(err);
        this.loadingService.hide();
      },
    });
  }

  onNominatedChange(active: boolean) {
    this.customerForm.get('Nominated')?.setValue(active);
  }

  onStatusChange(active: boolean) {
    this.customerForm.get('active')?.setValue(active);
  }

  onTdsExemptedFlagChange(active: boolean) {
    this.customerForm.get('tdsExemptedFlag')?.setValue(active);
  }

  onPdaMaintainChange(active: boolean) {
    this.customerForm.get('pdaMaintain')?.setValue(active);
  }

  onEconomicFreeZoneFlag(active: boolean) {
    this.customerForm.get('economicFreeZoneFlag')?.setValue(active);
  }

  onDefaultContactChange(active: boolean, i: number) {
    console.log('onDefaultContact', active, i);
    this.contactInformation.controls.forEach((contact, index) => {
      contact.get('defaultContact')?.setValue(index === i ? active : false);
      console.log('defaultContact', active);
    });
  }
  onSezChange(active: boolean, i: number) {
    console.log('onSez', active, i);
    this.contactInformation.controls.forEach((contact, index) => {
      contact.get('sez')?.setValue(index === i ? active : false);
      console.log('sez', active);
    });
  }

  onLutChange(active: boolean, i: number) {
    console.log('onLut', active, i);
    this.contactInformation.controls.forEach((contact, index) => {
      contact.get('lut')?.setValue(index === i ? active : false);
      console.log('lut', active);
    });
  }

  goBack() {
    this.router.navigateByUrl('/manage-masters/customers');
  }

  checkIfPanNumberIsAllowed(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const panNumberValue = inputElement.value;
    this.checkPanNumberValidity(panNumberValue)
  }

  private checkPanNumberValidity(panNumber: string) {
    this.api.checkPanNumberValidity(panNumber).subscribe({
      next: (res: any)=>{
        if(!res.data){
          this.toasterService.error("Customer with this PAN Number already exists");
        }
      },
      error: (error: any)=>{
        this.toasterService.error(error.error.errorDesc);
      }
    })
  }
}
