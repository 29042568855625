{
    "filterFields": [ 
        {
            "name": "name",
            "displayLabel": "Name",
            "dataType": "picklist",
            "hasLookup": false,
            "searchConfig": {

            },
            "defaultValue": "any",
            "readonly": false,
            "deletable": true
        },
        {
            "name": "cha",
            "displayLabel": "CHA",
            "dataType": "string",
            "hasLookup": true,
            "searchConfig": {
                "apiUrl": "v1/locations/list",
                "selectFields": ["id", "name", "panNumber"],
                "whereFields": ["name", "panNumber"],
                "limit": 10,
                "orderBy": { "name": "asc" },
                "filterByKey": "name",
                "bindLabel": "name",
                "bindValue": "id",
                "responseBindValue": "toLocationId",
                "responseBindLabel": "toLocationName",
                "displayColumns": [
                  { "key": "name", "header": "Name" },
                  { "key": "panNumber", "header": "PAN Number" }
                ]
              },
            "defaultValue": "any",
            "readonly": false,
            "deletable": true
        }
    ]
}