import { Component, ComponentRef, ViewChild, ViewContainerRef } from '@angular/core';
import { TosTableComponent } from '../../../tos-table/tos-table.component';
import { MultiSelectComponent } from "../../../select/multi-select.component";
import { Cycle, CycleType } from '../../../../constants/app-constants';
import { TableListRequest } from '../../../../models/table-list-request.interface';
import { PaginatedListDataResponse } from '../../../../models/paginated-list-data-response';
import { SharedModule } from '../../../../modules/shared/shared.module';
import { FilterCondition, Where } from '../../../../models/user-list-request.interface';
import ImportReleasedHoldConfig from '../config/importReleasedHoldConfig.json'
import { TableMeta } from '../../../../models/table-meta.interface';
import { AdvancedFilterComponentV2 } from '../../../advanced-filter/advanced-filter-v2.component';
import { ModalComponent } from '../../../modal/modal.component';
import { FieldInfo } from '../../../../models/editable-table-header.interface';
import { OperationService } from '../../../../services/operations.service';
import { SvgIconComponent } from '../../../svg-icon/svg-icon.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ReleaseHoldDialogComponent } from '../release-hold-dialog/release-hold-dialog.component';
import { HoldSource } from '../../../../constants/operations-contsants';

@Component({
  selector: 'app-released-holds',
  standalone: true,
  imports: [
    SharedModule, 
    TosTableComponent, 
    MultiSelectComponent,
    SvgIconComponent
  ],
  templateUrl: './released-holds.component.html',
  styleUrl: './released-holds.component.scss'
})
export class ReleasedHoldsComponent {

  cycleOptions = Object.values(CycleType);

  tableLoaded: boolean = false;
  selectedCycle!: Cycle
  tableMeta!: TableMeta;
  filterFields!: FieldInfo[];
  totalTablePages = 0;
  currentTablePage = 1;
  currentPage: number = 0;
  rowsPerPage: number = 15;
  rowsPerPageOptions: number[] = [];
  focusSearch: boolean = false;
  searchTerm: string = "";
  previousTerm: string = "";
  selectedItems: any[] = [];
  sortColumn: string = "modifiedDate";
  sortDirection: "asc" | "desc" = "desc";
  allTableRecordsSelected: boolean = false;
  filterData!: Where;
  rawFilterData!: Where;
  searchFilterData!: Where | null;
  tableListData: PaginatedListDataResponse = {
    totalCount: 0,
    page: 0,
    limit: 0,
    records: []
  };
  tableListRequest: TableListRequest = {
    selectFields: [
      "id",
      "containerId.containerNo",
      "cargoId.igmItemNo",
      "cargoId.accountHolder.name as accountHolder",
      "cargoId.chaId.name as cha",
      "holdCategory",
      "holdIssuedBy",
      "holdSubCategory",
      "holdDocumentNo",
      "holdDate",
      "releaseDocumentNo",
      "releaseDate",
      "holdRemarks",
      "releaseRemarks",
      "modifiedDate"
    ],
    orderBy: {
      [this.sortColumn]: this.sortDirection
    },
    includeTotal: true,
    limit: this.rowsPerPage,
    page: this.currentPage
  };

  defaultFilters: Where = {
    filterConditions: [
    {
      name: "processCycle",
      alias: "processCycle",
      operator: "EQ",
      value: ["IMPORT"]
    },
    {
      name: "releaseDate",
      alias: "releaseDate",
      operator: "IS_NOT_NULL",
      value: []
    }
  ],
  expression: "processCycle AND releaseDate"
}

  // table menu
  openMenuIndex: number | null = null;
  menuOptions = [
    { label: "Release", value: "release" },
    { label: "Release Document", value: "releaseDocument" }
  ];

  @ViewChild('processCycleDropdown', { static: false }) processCycleDropdown!: MultiSelectComponent;


  constructor(private viewContainerRef: ViewContainerRef,
              private api: OperationService,
              private route: ActivatedRoute,
              private router: Router) {
      
  }

  
  ngOnInit() {
    this.tableMeta = ImportReleasedHoldConfig.tableConfig;
    this.filterFields = ImportReleasedHoldConfig.filterConfig.filterFields;
    this.selectedCycle = Object.values(this.cycleOptions).find(cycle => cycle.key === history.state?.processCycle) ?? CycleType.IMPORT;
    this.listHoldRecords();
  }

  ngAfterViewInit() {
    this.processCycleDropdown.toggleSelection(this.selectedCycle);
  }

  onCycleChange(selectedCycleOption: Cycle): void {
    console.log(selectedCycleOption);
    this.selectedCycle = selectedCycleOption;
  }

  handleTableSearch(event: KeyboardEvent) {
    const searchTerm = this.searchTerm.trim();

    if (searchTerm.length >= 3) {
      this.searchFilterData = {
        filterConditions: [
          {
            name: "containerId.containerNo",
            alias: "containerNoStartsWith",
            operator: "CONTAINS",
            value: [searchTerm]
          },
          {
            name: "cargoId.igmItemNo",
            alias: "cargoNoStartsWith",
            operator: "CONTAINS",
            value: [searchTerm]
          }
        ],
        expression: "containerNoStartsWith OR cargoNoStartsWith"
      };
      this.listHoldRecords();
    }
    if (searchTerm.length === 0 && this.previousTerm.length !== 0) {
      this.searchFilterData = null;
      this.listHoldRecords();
    }

    this.previousTerm = searchTerm;
  }

  filter() {
      const modalRef = this.viewContainerRef.createComponent(ModalComponent);
      modalRef.instance.title = "Advanced Filter";
      const filterDialogRef = modalRef.instance.loadComponent(
        AdvancedFilterComponentV2
      );
      filterDialogRef.setInput("filterFields", this.filterFields);
      if (this.tableListRequest.where) {
        filterDialogRef.setInput("appliedFilters", this.rawFilterData);
      }
      modalRef.instance.close.subscribe((res: any) => {
        this.rawFilterData = res?.rawFilterData;
        if (res && res.resetAll) {
          delete this.tableListRequest.where;
          this.listHoldRecords();
        } else if (res && res.refresh) {
          if (
            res.filterData.expression &&
            res.filterData.filterConditions.length > 0
          ) {
            this.filterData = {
              filterConditions: res.filterData.filterConditions,
              expression: res.filterData.expression
            };
          } else {
            console.error("Invalid filter !");
            delete this.tableListRequest.where;
          }
          this.listHoldRecords();
        }
        modalRef.destroy();
      });
    }



  listHoldRecords() {
    this.mergeFilters();
    this.api.listHoldRecords(this.tableListRequest).subscribe({
      next: (res: any) => {
        this.tableListData = res.data;
      },
      error: (err) => {
        console.error(err);
      }
    });
  }

  mergeFilters() {
    delete this.tableListRequest.where;
    this.tableListRequest.where = JSON.parse(JSON.stringify(this.defaultFilters));
    
    if (!this.searchFilterData && !this.filterData) return;

    if (!this.tableListRequest.where) {
      this.tableListRequest.where = {filterConditions: [], expression: ''};
    }
  
    if (this.filterData?.filterConditions?.length) {
      this.filterData.filterConditions.forEach((filter: any) => {
        this.tableListRequest.where?.filterConditions?.push(filter);
      });
    }
  
    if (this.searchFilterData?.filterConditions?.length) {
      this.searchFilterData.filterConditions.forEach((filter: any) => {
        this.tableListRequest.where?.filterConditions?.push(filter);
      });
    }
  
    // Merge expressions with proper checks
    const expressions: string[] = [];
    if (this.defaultFilters?.expression) {
      expressions.push(`(${this.defaultFilters.expression})`);
    }
    if (this.filterData?.expression) {
      expressions.push(`(${this.filterData.expression})`);
    }
    if (this.searchFilterData?.expression) {
      expressions.push(`(${this.searchFilterData.expression})`);
    }
  
    // Safely assign the joined expression
    this.tableListRequest.where.expression = expressions.join(' AND ');
  } 
  
  
}
