import { NgFor, NgIf } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from "@angular/core";
import { SvgIconComponent } from "../svg-icon/svg-icon.component";

@Component({
  selector: 'app-vertical-tabs',
  standalone: true,
  imports: [NgFor,NgIf, SvgIconComponent],
  templateUrl: './vertical-tabs.component.html',
  styleUrl: './vertical-tabs.component.scss'
})
export class VerticalTabsComponent {
  @Input() tabs: any[] = [];
  @Output() tabSelected = new EventEmitter<string>();

  constructor(private cdr: ChangeDetectorRef) {}

  selectedTab: string = "";

  ngAfterViewInit() {
    if (this.tabs?.length > 0) {
      this.selectTab(this.tabs[0].id);
      this.cdr.detectChanges();
    }
  }

  selectTab(sectionId: string) {
    // console.log("Container List", this.containerList)
    this.selectedTab = sectionId;
    this.tabSelected.emit(sectionId);
  }
}
