import { Component, OnInit } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar'; // Import MatProgressBarModule
import { CommonModule } from '@angular/common'; // For ngIf
import { ProgressLoaderService } from '../../services/progress-loader.service';

@Component({
  selector: 'app-progress-loader',
  standalone: true,
  imports: [MatProgressBarModule, CommonModule], // Import required modules
  template: `
    <div class="app-progress-loader">
      <mat-progress-bar
        *ngIf="progress !== null"
        [value]="progress"
        mode="indeterminate"
      >
      </mat-progress-bar>
    </div>
  `,
  styles: [
    `
      .app-progress-loader {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 100;
      }
    `,
  ],
})
export class ProgressLoaderComponent implements OnInit {
  progress: number | null = null;

  constructor(private loaderService: ProgressLoaderService) {}

  ngOnInit(): void {
    // Subscribe to progress changes from the service
    this.loaderService.progress$.subscribe((value) => {
      this.progress = value;
    });
  }
}
