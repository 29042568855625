import { Component, WritableSignal } from "@angular/core";
import { TosTableComponent } from "../../tos-table/tos-table.component";
import { ActivatedRoute, NavigationExtras, Router, RouterOutlet } from "@angular/router";
import { MultiSelectComponent } from "../../select/multi-select.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import {  MatSelectModule, MatSelectTrigger } from "@angular/material/select";
import { MatChipsModule, MatChip, MatChipListbox } from "@angular/material/chips";
import { FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { NgForOf, NgIf } from "@angular/common";
import { MatInput } from "@angular/material/input";
import { TableMeta } from "../../../models/table-meta.interface";
import TariffListingTableConfig from '../config/TariffListingTableConfig.json'
import { TableListRequest } from "../../../models/table-list-request.interface";
import { PaginatedListDataResponse } from "../../../models/paginated-list-data-response";
import { FinanceService } from "../../../services/finance.service";
import { LoadingService } from "../../../services/loading.service";
import { ToasterService } from "../../../services/toaster.service";
import tableMeta from "../../admin/user-management/config/userManagementTableConfig.json";
import { StatsHorizontalComponent } from "../../stats-horizontal/stats-horizontal.component";
import { Stats } from "../../../models/stats.interface";
import { Charge } from "../../../models/charge.interface";


@Component({
  selector: 'app-tariff',
  standalone: true,
  imports: [
    TosTableComponent,
    RouterOutlet,
    MultiSelectComponent,
    MatFormFieldModule,
    MatSelectModule,
    MatChipsModule,
    ReactiveFormsModule,
    MatIconModule,
    NgForOf,
    MatSelectTrigger,
    MatInput,
    FormsModule,
    NgIf,
    StatsHorizontalComponent
  ],
  templateUrl: './tariff.component.html',
  styleUrls: ['./tariff.component.scss']
})
export class TariffComponent {
  chargeId!: string;
  charge!: Charge;
  tableMeta!: TableMeta
  menuOptions = [
    { label: "Edit", value: "edit" },
    { label: "View", value: "view" },
    { label: "Delete", value: "delete" },
  ];
  chargeStats: Stats[] = [];
  tableListRequest: TableListRequest = {
    selectFields: [
      "id",
      "chargeId",
      "description",
      "fromDate",
      "toDate",
      "criteria",
      "priority",
      "modifiedBy.name as modifiedBy",
      "modifiedAt"
    ],
    includeTotal: true,
    orderBy: {
      ['modifiedAt']: "desc"
    },
    limit: 15,
    page: 0
  };
  tableListData: PaginatedListDataResponse = {
    totalCount: 0,
    page: 0,
    limit: 0,
    records: []
  };
  selectedItems: any[] = []

  constructor(private router: Router,
              private route: ActivatedRoute,
              private tariffService:FinanceService,
              private loadingService: LoadingService,
              private toasterService: ToasterService) {}

  ngOnInit(){
    this.chargeId = this.route.snapshot.paramMap.get('chargeId') || '';
    if(!this.chargeId){
      console.log("No charge id found");
      return;
    }
    this.loadingService.show()
    this.tableMeta = TariffListingTableConfig
    this.getChargeInfoFromNavigationExtras();
    this.createChargeStats();
    this.addChargeIdFilterToTableListRequest();
    this.getTableList();
  }

  private getChargeInfoFromNavigationExtras() {
    this.charge = history.state?.charge
    console.log(this.charge)
  }

  private addChargeIdFilterToTableListRequest() {
    this.tableListRequest.where = {
      filterConditions: [{
        name: "chargeId",
        alias: "chargeIdEQ",
        operator: "EQ",
        value: [`${this.chargeId}`]
      },
        {
          name: "deletedAt",
          alias: "deletedAtNULL",
          operator: "IS_NULL",
          value: []
        }
      ],
      expression: "chargeIdEQ AND deletedAtNULL"
    };
  }

  getTableList(){

    this.tariffService.listTariffs(this.tableListRequest).subscribe({
      next: (response: any) => {
        this.tableListData = response.data;
        this.loadingService.hide()
      },
      error: (error: any) => {
        this.loadingService.hide()
        this.toasterService.error(error.error.message)
      }
    })
  }

  addNewTariff() {
    const navigationExtras: NavigationExtras = {
      state: { charge: this.charge },
      relativeTo: this.route
    };
    this.router.navigate(['add-new'], navigationExtras);
  }

  goBack() {
    this.router.navigateByUrl("/manage-finance/charges");
  }

  handleOptionSelected(event: any) {
    const { record: tariff, actionName } = event;
    const navigationExtras: NavigationExtras = {
      state: { charge: this.charge },
      relativeTo: this.route
    };
    if (actionName === 'edit') {
      this.router.navigate([`edit/${tariff.id}`], navigationExtras);
    } else if (actionName === 'view') {
      this.router.navigate([`view/${tariff.id}`], navigationExtras);
    } else if(actionName === 'delete'){
      this.deleteTariff(tariff.id)
    }
  }

  private createChargeStats() {
    this.chargeStats = [
      {
        displayLabel: "Charge Code",
        value:  this.charge?.code
      },
      {
        displayLabel: "Charge Name",
        value: this.charge?.name
      },
      {
        displayLabel: "Status",
        value: this.charge?.active ? "Active" : "Inactive",
        iconUrl: "/assets/icons/circle_icon.svg"
      }
    ]
  }

  private deleteTariff(id: string) {
    this.tariffService.deleteTariff(id).subscribe({
      next: (response: any) => {
        this.toasterService.success(response.data)
        this.getTableList()
      },
      error: (error: any) => {
        this.toasterService.error(error.error.message)
      }
    })
  }
}
