{
    "formName": "Create New Charge",
    "formRows": [
      {
        "formGroups": [
          {
            "style": { "gridColumn": "span 3" },
            "control": {
              "label": "code",
              "displayLabel": "Charge Code",
              "disabled": false,
              "placeHolder": "Ex:12345",
              "fieldInfo": { "dataType": "text" },
              "validators": [{ "type": "required", "message": "Charge Code is required" }]
            }
          },
          {
            "style": { "gridColumn": "span 3" },
            "control": {
              "label": "name",
              "displayLabel": "Charge Name",
              "disabled": false,
              "placeHolder": "Ex:12345",
              "fieldInfo": { "dataType": "text" },
              "validators": [{ "type": "required", "message": "Charge Name is required" }]
            }
          }
        ]
      },
      {
        "formGroups": [
          {
            "style": { "gridColumn": "span 3" },
            "control": {
              "label": "printName",
              "displayLabel": "Print Name",
              "disabled": false,
              "placeHolder": "Ex:12345",
              "fieldInfo": { "dataType": "text" },
              "validators": [{ "type": "required", "message": "Print Name is required" }]
            }
          },
          {
            "style": { "gridColumn": "span 3" },
            "control": {
              "label": "gstPercentage",
              "displayLabel": "GST Percentage",
              "disabled": false,
              "placeHolder": "Ex:12345",
              "fieldInfo": { "dataType": "text" }
            }
          }
        ]
      },
      {
        "formGroups": [
          {
            "style": { "gridColumn": "span 3" },
            "control": {
              "label": "hsnOrSacCode",
              "displayLabel": "HSN/SAC Code",
              "disabled": false,
              "placeHolder": "Ex:12345",
              "fieldInfo": { "dataType": "text" },
              "validators": [{ "type": "required", "message": "HSN/SAC Code is required" }]
            }
          },
          {
            "style": {
              "gridColumn": "span 3"
            },
            "control": {
              "label": "storageType",
              "displayLabel": "Storage",
              "placeHolder": "Enter Storage Type",
              "disabled": false,
              "fieldInfo": {
                "dataType": "boolean"
              }
            }
          }
        ]
      },
      {
        "formGroups": [
          {
            "style": { "gridColumn": "span 3" },
            "control": {
              "label": "active",
              "displayLabel": "Active",
              "disabled": false,
              "defaultValue": true,
              "fieldInfo": { "dataType": "boolean" }
            }
          }
        ]
      },
      {
        "formGroups": [
          {
            "style": { "gridColumn": "span 6" },
            "control": {
              "label": "description",
              "displayLabel": "Description",
              "disabled": false,
              "placeHolder": "Enter a description...",
              "fieldInfo": { "dataType": "textarea" }
            }
          }
        ]
      }
    ]
  }
