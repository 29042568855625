import { Component, ViewContainerRef } from "@angular/core";
import { User } from "../../../models/user.interface";
import { StatsData, StatsResponse } from "../../../models/stats-response.interface";
import { PaginatedListDataResponse } from "../../../models/paginated-list-data-response";
import { TableListRequest } from "../../../models/table-list-request.interface";
import { ApiService } from "../../../services/api.service";
import { ToasterService } from "../../../services/toaster.service";
import { ActivatedRoute, Router } from "@angular/router";
import { LoadingService } from "../../../services/loading.service";
import { ModalComponent } from "../../modal/modal.component";
import { AdvancedFilterComponent } from "../../advanced-filter/advanced-filter.component";
import { forkJoin } from "rxjs";
import { ConfirmDialogComponent } from "../../confirm-dialog/confirm-dialog.component";
import { ServiceMaster } from "../../../models/service-master.interface";
import { DatePipe, NgClass, NgForOf, NgIf, UpperCasePipe } from "@angular/common";
import { HighlightPipe } from "../../../pipes/highlight.pipe";
import { NoDataComponent } from "../../no-data/no-data.component";
import { NoDataFoundComponent } from "../../no-data-found/no-data-found.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SvgIconComponent } from "../../svg-icon/svg-icon.component";
import { TableMenuComponent } from "../../table-menu/table-menu.component";
import { TooltipDirective } from "../../../directives/tooltip.directive";

@Component({
  selector: 'app-service-master',
  standalone: true,
  imports: [
    DatePipe,
    HighlightPipe,
    NgForOf,
    NgIf,
    NoDataComponent,
    NoDataFoundComponent,
    ReactiveFormsModule,
    SvgIconComponent,
    TableMenuComponent,
    FormsModule,
    NgClass,
    TooltipDirective,
    UpperCasePipe
  ],
  templateUrl: './service-master.component.html',
  styleUrl: './service-master.component.scss'
})
export class ServiceMasterComponent {
  //table
  tableLoaded: boolean = false;
  totalTablePages = 0;
  currentTablePage = 1;
  currentPage: number = 0;
  rowsPerPage: number = 10;
  rowsPerPageOptions: number[] = [];
  focusSearch: boolean = false;
  searchTerm: string = '';
  previousTerm: string = '';
  selectedItems: User[] = [];
  // deSelectedItems: User[] = [];
  sortColumn: string = 'modifiedAt';
  sortDirection: 'asc' | 'desc' = 'desc';
  allTableRecordsSelected: boolean = false;
  tableStats: StatsData = {
    total: 0,
    active: 0,
    inactive: 0,
  };
  tableListData: PaginatedListDataResponse = {
    totalCount: 0,
    page: 0,
    limit: 0,
    records: [],
  };
  tableListRequest: TableListRequest = {
    selectFields: [
      'id',
      'active',
      'name',
      'code',
      'type',
      'chargeId',
      'serviceType',
      'modifiedAt',
      'modifiedBy.name as modifiedBy',
    ],
    orderBy: {
      [this.sortColumn]: this.sortDirection,
    },
    limit: this.rowsPerPage,
    page: this.currentPage,
  };

  // table menu
  openMenuIndex: number | null = null;
  menuOptions = [
    { label: 'Edit', value: 'edit' },
    { label: 'Mark as active', value: 'active' },
    { label: 'Mark as inactive', value: 'inactive' },
    { label: 'View', value: 'view' },
  ];

  // filter
  filterFields = [
    {
      displayName: 'Service Name',
      key: 'name',
    },
    {
      displayName: 'Service Code',
      key: 'code',
    },
  ];
  isFilterApplied: boolean = false;

  constructor(
    private api: ApiService,
    private toasterService: ToasterService,
    private viewContainerRef: ViewContainerRef,
    private router: Router,
    private route: ActivatedRoute,
    private loadingService: LoadingService
  ) { }

  ngOnInit() {
    this.tableLoaded = false;
    this.fetchAllData();
  }

  // pagination helper methods
  patchTableListWithSerialNumbers() {
    this.tableListData.records?.forEach((record: User, index: number) => {
      record.slNo = (this.currentTablePage - 1) * this.rowsPerPage + index + 1;
    });
  }

  calculateTotalPages() {
    if (this.tableListRequest.where) {
      this.totalTablePages = Math.ceil(
        this.tableListData.totalCount / this.rowsPerPage
      );
    } else {
      this.totalTablePages = Math.ceil(
        this.tableStats.total / this.rowsPerPage
      );
    }
  }

  generateRowsPerPageOptions(totalRecords: number): void {
    const baseOptions = [10];
    const dynamicOptions = [];

    if (totalRecords > 10 && totalRecords <= 25) {
      dynamicOptions.push(25);
    }

    if (totalRecords > 25 && totalRecords <= 50) {
      dynamicOptions.push(25, 50);
    }

    // If totalRecords is between 1 and 100, add 100 to the options
    if (totalRecords > 50 && totalRecords <= 100) {
      dynamicOptions.push(50, 100);
    }

    // If totalRecords is between 101 and 1000, add 150, 250, 500, 1000
    if (totalRecords > 100 && totalRecords <= 1000) {
      dynamicOptions.push(100, 150, 250, 500, 1000);
    }

    // If totalRecords is greater than 1000, add multiples of 500
    if (totalRecords > 1000) {
      dynamicOptions.push(100);
      let nextOption = 150;

      // Add options starting from 150, 250, 500, 1000
      while (nextOption <= 1000) {
        dynamicOptions.push(nextOption);
        nextOption += nextOption === 150 ? 100 : 250; // add 100, then 250 up to 1000
      }

      // After 1000, add multiples of 500
      nextOption = 1500;
      while (nextOption <= totalRecords) {
        dynamicOptions.push(nextOption);
        nextOption += 500;
      }
    }

    this.rowsPerPageOptions = [...new Set([...baseOptions, ...dynamicOptions])];
  }

  updateRowsPerPage(newRowsPerPage: number) {
    this.rowsPerPage = newRowsPerPage;
    this.tableListRequest.limit = newRowsPerPage;
    this.tableListRequest.page = 0;
    this.currentTablePage = 1; // Reset to first page
    this.calculateTotalPages();
    this.getTableList();
  }

  goToPage(page: number) {
    this.currentTablePage = page;
    this.tableListRequest.page = this.currentTablePage - 1;
    this.getTableList();
  }

  nextPage() {
    if (this.currentTablePage < this.totalTablePages) {
      this.currentTablePage++;
      this.tableListRequest.page = this.currentTablePage - 1;
      this.getTableList();
    }
  }

  previousPage() {
    if (this.currentTablePage > 1) {
      this.currentTablePage--;
      this.tableListRequest.page = this.currentTablePage - 1;
      this.getTableList();
    }
  }

  sortData(column: string) {
    if (this.sortColumn === column) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortColumn = column;
      this.sortDirection = 'asc';
    }
    this.tableListRequest.orderBy = {
      [this.sortColumn]: this.sortDirection,
    };
    this.refreshTableList();
  }

  filter() {
    // console.log(this.customerMasterListRequest);
    const modalRef = this.viewContainerRef.createComponent(ModalComponent);
    modalRef.instance.title = 'Advanced Filter';
    const filterDialogRef = modalRef.instance.loadComponent(
      AdvancedFilterComponent
    );

    filterDialogRef.setInput('filterFields', this.filterFields);

    if (!this.searchTerm && this.tableListRequest.where) {
      filterDialogRef.setInput('appliedFilters', this.tableListRequest.where);
    }

    modalRef.instance.close.subscribe((res: any) => {
      this.clearSelection();
      if (res && res.resetAll) {
        delete this.tableListRequest.where;
        this.isFilterApplied = false;
        this.refreshTableList();
      } else if (res && res.refresh) {
        if (
          res.filterData.expression &&
          res.filterData.filterConditions.length > 0
        ) {
          this.tableListRequest.where = {
            filterConditions: res.filterData.filterConditions,
            expression: res.filterData.expression,
          };
          this.isFilterApplied = true;
        } else {
          console.error('Invalid filter !');
          delete this.tableListRequest.where;
          this.isFilterApplied = false;
        }
        this.refreshTableList();
      }
      modalRef.destroy();
    });
  }

  // single / multi selection helper methods
  isSelected(item: User): boolean {
    if (this.allTableRecordsSelected) {
      return true;
    } else {
      const recordIdx = this.selectedItems.findIndex((x) => x.id === item.id);
      if (recordIdx !== -1) {
        return true;
      } else {
        return false;
      }
    }
  }

  isAllRecordsOfCurrentPageSelected(): boolean {
    return (
      this.allTableRecordsSelected ||
      (this.tableListData.records?.length > 0 &&
        this.tableListData.records?.every((item: User) =>
          this.isSelected(item)
        ))
    );
  }

  selectAllTableRecords() {
    this.allTableRecordsSelected = true;
    this.selectedItems = [];
  }

  clearSelection() {
    // console.warn('clearing both selectedItems & selectedItems !');
    this.selectedItems = [];
    // this.deSelectedItems = [];
  }

  toggleAllRecordsOfCurrentPageSelections(isChecked: boolean) {
    if (isChecked) {
      this.tableListData.records?.forEach((item) => {
        if (!this.isSelected(item)) {
          this.selectedItems.push(item);
        }
      });
    } else {
      this.tableListData.records?.forEach((item) => {
        this.selectedItems = this.selectedItems.filter((i) => i.id !== item.id);
      });
    }
  }

  toggleSelection(item: User) {
    if (!this.isSelected(item)) {
      this.selectedItems.push(item);
    } else {
      this.selectedItems = this.selectedItems.filter((i) => i.id !== item.id);
    }
  }

  // fetch table data and stats
  fetchAllData(): void {
    this.loadingService.show();
    const getTableStatsApiCall = this.api.getServiceMasterStats();
    const getTableListApiCall = this.api.getServiceMasterList(
      this.tableListRequest
    );
    forkJoin([getTableStatsApiCall, getTableListApiCall]).subscribe({
      next: ([tableStats, tableList]) => {
        this.tableStats = tableStats.data;
        console.log(tableStats.data)
        this.tableListData = tableList.data;
        this.patchTableListWithSerialNumbers();
        this.calculateTotalPages();
        this.generateRowsPerPageOptions(tableList.data.totalCount);
        this.tableLoaded = true;
        this.loadingService.hide();
      },
      error: (err) => {
        console.error(err);
        // this.toasterService.error(err.message);
        this.loadingService.hide();
      },
    });
  }

  handleTableSearch(event: KeyboardEvent) {
    const searchTerm = this.searchTerm.trim();
    if (searchTerm.length > 0) {
      this.tableListRequest.where = {
        filterConditions: [
          {
            name: 'name',
            alias: 'A',
            operator: 'CONTAINS',
            value: [searchTerm],
          },
        ],
        expression: 'A',
      };
      // Search when Enter is pressed
      if (event.key === 'Enter') {
        this.searchTableList();
      }
    }
    // Call reset only when the search bar is cleared (avoid on multiple backspaces)
    if (searchTerm.length === 0 && this.previousTerm.length !== 0) {
      delete this.tableListRequest.where;
      this.searchTableList();
    }
    this.previousTerm = searchTerm;
  }

  tableSearch() {
    const searchTerm = this.searchTerm.trim();
    if (searchTerm.length > 0) {
      this.tableListRequest.where = {
        filterConditions: [
          {
            name: 'name',
            alias: 'A',
            operator: 'CONTAINS',
            value: [this.searchTerm],
          },
        ],
        expression: 'A',
      };
      this.searchTableList();
    }
  }

  handleMenuToggle(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? null : index;
  }

  handleOptionSelected(serviceMaster: ServiceMaster, actionName: string) {
    console.log(serviceMaster);
    const serviceId = serviceMaster.id;
    if (actionName === 'edit') {
      this.router.navigate([`edit/${serviceId}`], { relativeTo: this.route });
    } else if (actionName === 'view') {
      this.router.navigate([`view/${serviceId}`], { relativeTo: this.route });
    } else {
      // need to confirm before executing the action
      let actionTitle = 'Confirmation';
      let message = 'Are you sure ?';
      let successMsg = 'Done !';
      let flag = false;
      switch (actionName) {
        case 'active':
        case 'inactive':
          if (actionName === 'active') {
            flag = true;
          }
          actionTitle = `${flag ? 'Activate' : 'Inactivate'} Service`;
          message = `Are you sure to ${flag ? 'activate' : 'inactivate'} ${serviceMaster.name
          } ?`;
          successMsg = `Successfully ${flag ? 'activated' : 'inactived'} ${serviceMaster.name
          } !`;
          break;

        default:
          console.error('unknown actionName');
          break;
      }
      const modalRef = this.viewContainerRef.createComponent(ModalComponent);
      modalRef.instance.title = actionTitle;
      const confirmDialogRef = modalRef.instance.loadComponent(
        ConfirmDialogComponent
      );
      confirmDialogRef.instance.message = message;
      confirmDialogRef.instance.confirm.subscribe(() => {
        this.menuAction(actionName, successMsg, flag, serviceId!);
        modalRef.destroy();
      });
      confirmDialogRef.instance.cancel.subscribe(() => {
        modalRef.destroy();
      });
      modalRef.instance.close.subscribe(() => modalRef.destroy());
    }
  }

  menuAction(
    actionName: string,
    successMsg: string,
    flag: boolean,
    userId: string
  ) {
    switch (actionName) {
      case 'active':
      case 'inactive':
        this.api.setServiceActiveStatus(flag, [userId]).subscribe({
          next: () => {
            this.toasterService.success(successMsg);
            this.refreshTableListWithStats();
          },
          error: (err) => {
            console.error(err);
            this.toasterService.error(err.error.errorDesc || 'Unknown Error !');
          },
        });
        break;
      default:
        console.error('unknown actionName');
        break;
    }
  }

  bulkActionConfirmation(actionName: string) {
    let actionTitle = 'Confirmation';
    let message = 'Are you sure ?';
    let successMsg = 'Done !';
    const selectedUserCount = this.selectedItems.length;
    switch (actionName) {
      case 'setActive':
        actionTitle = `Activate ${this.selectedItems.length > 1 ? 'Services' : 'Service'
        } Confirmation`;
        message = `Are you sure to activate ${this.selectedItems.length > 1
          ? ` the selected ${selectedUserCount} Service?`
          : `${this.selectedItems[0].name}'s Service?`
        }`;
        successMsg = `Successfully activated ${this.selectedItems.length > 1
          ? `${selectedUserCount} Services`
          : `${this.selectedItems[0].name}`
        }`;
        break;
      case 'setInActive':
        actionTitle = `Inactivate ${this.selectedItems.length > 1 ? 'Service' : 'BaServicesnks'
        } Confirmation`;
        message = `Are you sure to inactivate ${this.selectedItems.length > 1
          ? ` the selected ${selectedUserCount} Services?`
          : `${this.selectedItems[0].name}'s account?`
        }`;
        successMsg = `Successfully inactivated ${this.selectedItems.length > 1
          ? `${selectedUserCount} Services`
          : `${this.selectedItems[0].name}`
        }`;
        break;
      default:
        console.error('unknown actionName');
        break;
    }
    const modalRef = this.viewContainerRef.createComponent(ModalComponent);
    modalRef.instance.title = actionTitle;
    const confirmDialogRef = modalRef.instance.loadComponent(
      ConfirmDialogComponent
    );
    confirmDialogRef.instance.message = message;
    confirmDialogRef.instance.confirm.subscribe(() => {
      this.bulkAction(actionName, successMsg);
      modalRef.destroy();
    });
    confirmDialogRef.instance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.instance.close.subscribe(() => modalRef.destroy());
  }

  bulkAction(actionName: string, successMsg: string) {
    const selectedUserIds = this.selectedItems.map((user: User) => {
      return user.id!;
    });
    switch (actionName) {
      case 'setActive':
        this.api.setServiceActiveStatus(true, selectedUserIds).subscribe({
          next: () => {
            this.clearSelection();
            this.toasterService.success(successMsg);

            this.refreshTableListWithStats();
          },
          error: (err) => {
            console.error(err);
            this.toasterService.error(err.error.errorDesc || 'Unknown Error !');
          },
        });
        break;
      case 'setInActive':
        this.api.setServiceActiveStatus(false, selectedUserIds).subscribe({
          next: () => {
            this.clearSelection();
            this.toasterService.success(successMsg);
            this.refreshTableListWithStats();
          },
          error: (err) => {
            console.error(err);
            this.toasterService.error(err.error.errorDesc || 'Unknown Error !');
          },
        });
        break;
      default:
        console.error('unknown actionName');
        break;
    }
  }

  getTableStats() {
    this.api.getServiceMasterStats().subscribe({
      next: (res: StatsResponse) => {
        this.tableStats = res.data;
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  getTableList() {
    this.api.getServiceMasterList(this.tableListRequest).subscribe({
      next: (res) => {
        this.tableListData = res.data;
        this.patchTableListWithSerialNumbers();
        this.calculateTotalPages();
        this.generateRowsPerPageOptions(res.data.totalCount);
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  searchTableList() {
    this.api.getServiceMasterList(this.tableListRequest).subscribe({
      next: (res) => {
        this.currentTablePage = 1; // Reset to first page
        this.currentPage = 0;
        this.tableListData = res.data;
        this.patchTableListWithSerialNumbers();
        this.calculateTotalPages();
        this.generateRowsPerPageOptions(res.data.totalCount);
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  clearSearchQuery() {
    this.searchTerm = '';
  }

  refreshTableList() {
    this.getTableList();
  }

  refreshTableListWithStats() {
    this.getTableList();
    this.getTableStats();
  }

  addNewRecord() {
    this.router.navigate(['add-new'], { relativeTo: this.route });
  }

  getDisplayedPages() {
    const total = this.totalTablePages;
    const current = this.currentTablePage;
    const pages: Array<number | string> = [];
    const windowSize = 3; // Numbers to show on each side

    // If total pages is less than or equal to windowSize * 2, show all pages
    if (total <= windowSize * 2) {
      for (let i = 1; i <= total; i++) {
        pages.push(i);
      }
      return pages;
    }

    // Calculate start of left window based on current page
    let leftStart = Math.max(1, current - windowSize + 1);

    // Calculate start of right window
    let rightStart = Math.max(total - windowSize + 1, leftStart + windowSize + 1);

    // Add left window numbers
    for (let i = leftStart; i < leftStart + windowSize && i <= total; i++) {
      pages.push(i);
    }

    // Add ellipsis if there's a gap
    if (rightStart - leftStart > windowSize) {
      pages.push('...');
    }

    // Add right window numbers
    for (let i = rightStart; i <= total; i++) {
      pages.push(i);
    }

    return pages;
  }
}
