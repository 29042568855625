<div
  class="container full_page_container login_system_bkg_cover centered_flex_container"
>
  @if (!verificationEmailSent) {
    <div class="login_card_component forgot_password_form">
      <div class="heading text_align_center">
        <div class="feature_icon">
          <img src="assets/icons/key.svg">
        </div>
        <div class="text_and_supporting_text">
          <div class="header">Forgot password?</div>
          <div class="sub_header">No worries, we’ll send you reset instructions.</div>
        </div>
      </div>
      <form [formGroup]="forgotPasswordLoginForm" (ngSubmit)="submitEmail()">


        <label for="username" class="required_field_label">Username</label>
        <input
          id="username"
          name="username"
          formControlName="username"
          type="text"
          placeholder="Enter your username"
          required
          autocomplete="username"
          [ngClass]="{
          form_error:
            forgotPasswordLoginForm.get('username')?.invalid &&
            forgotPasswordLoginForm.get('username')?.touched &&
            forgotPasswordLoginForm.get('username')?.errors
        }"
        />
        <div
          class="validation_error"
          *ngIf="
          forgotPasswordLoginForm.get('username')?.invalid &&
          forgotPasswordLoginForm.get('username')?.touched
        "
        >
          <small
            *ngIf="forgotPasswordLoginForm.get('username')?.errors?.['required']"
          >Username is required</small
          >
        </div>
        <button type="submit" class="primary_button">Proceed</button>


      </form>
      <div class="back_btn" [routerLink]="'/login'">
        <img src="assets/icons/back_button.svg">Back to log in
      </div>
    </div>
  } @else {
    <div class="login_card_component email_sent_form">
      <img
        class="success_email_banner"
        src="/assets/images/forgot_password_mail_sent.png"
        alt="Email Sent"
      />
      <div class="success_msg">
        {{ successMsg }}
      </div>
      <div class="hint_msg">
        You can close this browser window now and continue the reset password
        process from mail.
      </div>
    </div>
  }
</div>
