import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SharedModule } from '../../modules/shared/shared.module';

@Component({
  selector: 'app-input',
  standalone: true,
    imports: [SharedModule],
  templateUrl: './input.component.html',
  styleUrl: './input.component.scss',
})
export class InputComponent implements OnInit {
  @Input() label: string = '';
  @Input() type: string = 'text';
  @Input() id: string = '';
  @Input() name: string = '';
  @Input() placeholder: string = '';
  @Input() control: FormControl = new FormControl();

  errorMessages: string[] = [];

  ngOnInit() {
    this.updateErrorMessages();
    this.control.statusChanges.subscribe(() => this.updateErrorMessages());
  }

  showErrors(): boolean {
    return this.control.invalid && (this.control.dirty || this.control.touched);
  }

  updateErrorMessages() {
    this.errorMessages = [];
    if (this.control.errors) {
      for (const key in this.control.errors) {
        if (this.control.errors.hasOwnProperty(key)) {
          const errorValue = this.control.errors[key];
          switch (key) {
            case 'required':
              this.errorMessages.push('This field is required.');
              break;
            case 'minlength':
              this.errorMessages.push(
                `Minimum length is ${errorValue.requiredLength} characters.`
              );
              break;
            case 'maxlength':
              this.errorMessages.push(
                `Maximum length is ${errorValue.requiredLength} characters.`
              );
              break;
            case 'email':
              this.errorMessages.push('Invalid email address.');
              break;
            // Add more custom error messages here
            default:
              this.errorMessages.push('Invalid field.');
              break;
          }
        }
      }
    }
  }

  onBlur() {
    this.control.markAsTouched();
  }
}
