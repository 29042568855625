<div class="container">
  <div class="header">
    <div class="header-top">
      <!-- if back arrow icon is not required put header_with_back_button css -->
      <div class="header-left">
        <!-- <app-back-button></app-back-button> -->
        <div class="title-container">
          <span class="title">
            @if (readOnlyMode) { View } @else if(customerEditingEnabled) { Edit
            } @else { Add } Customer
          </span>
          <!-- <span class="subtitle">
            @if (readOnlyMode) { View } @else if(customerEditingEnabled) { Edit
            } @else { Fill } Customer Information to Begin Freight
            Management</span
          > -->
        </div>
      </div>
      <div class="header-right">
        <ng-container *ngIf="readOnlyMode">
          <button type="button" class="secondary_button" (click)="goBack()">
            Back
          </button>
        </ng-container>
        <ng-container *ngIf="!readOnlyMode">
          <button type="button" class="secondary_button" (click)="goBack()">
            Cancel
          </button>
          <button
            type="button"
            class="primary_button"
            (click)="submitCustomerForm()"
          >
            @if(customerEditingEnabled) { Update } @else { Save }
          </button>
        </ng-container>
      </div>
    </div>

    <app-horizontal-tabs
      [tabs]="tabList"
      [selectedTab]="selectedTab"
      (tabSelected)="selectTab($event)"
    ></app-horizontal-tabs>
    <!-- Tab Sections -->
    <!-- <div *ngIf="selectedTab === 'BasicDetails'" class="section">
      <h3>BasicDetails Content</h3>
      <p>Put your content here for BasicDetails.</p>
    </div> -->

    <!-- <div *ngIf="selectedTab === 'ContactInformation'" class="section">
      <h3>ContactInformation Content</h3>
      <p>Put your content here for ContactInformation.</p>
    </div> -->

    <div
      id="billing-information-section"
      [hidden]="selectedTab !== 'BillingInformation'"
      class="section"
    >
      <h3>BillingInformation Content</h3>
      <p>Put your content here for BillingInformation.</p>
    </div>

    <div
      id="bank-details-section"
      [hidden]="selectedTab !== 'BankDetails'"
      class="section"
    >
      <h3>BankDetails Content</h3>
      <p>Put your content here for BankDetails.</p>
    </div>

    <div
      id="vendor-information-section"
      [hidden]="selectedTab !== 'VendorInformation'"
      class="section"
    >
      <h3>VendorInformation Content</h3>
      <p>Put your content here for VendorInformation.</p>
    </div>

    <div class="container-content">
      <form [formGroup]="customerForm">
        <div
          id="basic-details-section"
          [hidden]="selectedTab !== 'BasicDetails'"
          class=""
        >
          <div class="section">
            <mat-accordion>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div class="panel-header-content">
                      <span class="acc-title">Tax & Legal Identification</span>
                      <span class="acc-subtitle"
                        >Essential identification numbers for businesses</span
                      >
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="grid-container grid-3">
                  <div class="form-group one-col">
                    <label for="panNumber" class="required_field_label" #formField
                      >Pan Number</label
                    >
                    <input
                      id="panNumber"
                      name="panNumber"
                      formControlName="panNumber"
                      type="text"
                      placeholder="Enter Pan Number"
                      (focusout)="checkIfPanNumberIsAllowed($event)"
                      required
                      [ngClass]="{
                        form_error:
                          customerForm.get('panNumber')?.invalid &&
                          customerForm.get('panNumber')?.touched &&
                          customerForm.get('panNumber')?.errors
                      }"
                    />
                    <div
                      class="form_validation_error .form_error"
                      [ngClass]="{
                        form_error:
                          customerForm.get('panNumber')?.invalid &&
                          customerForm.get('panNumber')?.touched
                      }"
                    >
                      <small
                        *ngIf="customerForm.get('panNumber')?.errors?.['required']"
                        >Pan Number is required</small
                      >
                    </div>
                  </div>
                  <div class="empty-two"></div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
          <div class="section">
            <mat-accordion>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div class="panel-header-content">
                      <span class="acc-title">Basic Information</span>
                      <span class="acc-subtitle"
                        >Fill in customer details to proceed</span
                      >
                    </div>

                    <!-- <div class="head">Basic Information</div> -->
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="fields">
                  <div class="form-group">
                    <div class="label required_field_label" #formField>
                      Customer Type
                    </div>
                    <div
                      class="form-row checkbox-label checkbox-area"
                      formArrayName="customerType"
                    >
                      <label
                        *ngFor="
                          let customerType of customerTypes;
                          let i = index
                        "
                      >
                        <input
                          type="checkbox"
                          [formControlName]="i"
                          (change)="onCheckboxChange($event, i)"
                        />
                        {{ customerType.name }}
                        <!-- <app-toggle
                          [toggleId]="'customerType-' + i"
                          [label]="customerType.name"
                          [isActive]="customerForm.get('customerType')?.checked"
                          [formControlName]="i"
                          (toggleChange)="onCheckboxChange($event, i)"
                          [disable]="readOnlyMode"
                        ></app-toggle> -->
                      </label>
                    </div>
                    <div
                      class="form_validation_error"
                      [ngClass]="{
                        form_error:
                          customerForm.get('customerType')?.invalid &&
                          customerForm.get('customerType')?.touched &&
                          customerForm.get('customerType')?.errors
                      }"
                      style="margin-bottom: 10px"
                    >
                      <small
                        *ngIf="
                          customerForm
                            .get('customerType')
                            ?.hasError('atLeastOneCheckboxChecked')
                        "
                        >At least one customer type must be selected</small
                      >
                    </div>
                  </div>
                  <div class="grid-container grid-3">
                    <div class="form-group one-col">
                      <label for="name" class="required_field_label" #formField
                        >Customer Name</label
                      >
                      <input
                        id="name"
                        name="name"
                        formControlName="name"
                        type="text"
                        placeholder="Enter name"
                        required
                        autocomplete="name"
                        [ngClass]="{
                          form_error:
                            customerForm.get('name')?.invalid &&
                            customerForm.get('name')?.touched &&
                            customerForm.get('name')?.errors
                        }"
                      />
                      <div
                        class="form_validation_error .form_error"
                        [ngClass]="{
                          form_error:
                            customerForm.get('name')?.invalid &&
                            customerForm.get('name')?.touched
                        }"
                      >
                        <small
                          *ngIf="customerForm.get('name')?.errors?.['required']"
                          >Name is required</small
                        >
                      </div>
                    </div>
                    <div class="empty-two"></div>

                    <div class="form-group one-col">
                      <label for="code" class="required_field_label" #formField
                        >Customer Code</label
                      >
                      <input
                        id="code"
                        name="code"
                        formControlName="code"
                        type="text"
                        placeholder="Enter code"
                        required
                        autocomplete="code"
                        [ngClass]="{
                          form_error:
                            customerForm.get('code')?.invalid &&
                            customerForm.get('code')?.touched &&
                            customerForm.get('code')?.errors
                        }"
                      />
                      <div
                        class="form_validation_error"
                        [ngClass]="{
                          form_error:
                            customerForm.get('code')?.invalid &&
                            customerForm.get('code')?.touched
                        }"
                      >
                        <small
                          *ngIf="customerForm.get('code')?.errors?.['required']"
                          >Code is required</small
                        >
                      </div>
                    </div>
                    <div class="empty-two"></div>

                    <div class="form-group one-col">
                      <label for="parentCustomerId">Parent Customer</label>
                      <app-autocomplete-dropdown
                        id="parentCustomerId"
                        name="parentCustomerId"
                        formControlName="parentCustomerId"
                        [placeholder]="'Enter Parent Customer'"
                        [apiUrl]="'v1/customers/list'"
                        [selectFields]="['id', 'name', 'code']"
                        [whereFields]="['name', 'code']"
                        [limit]="10"
                        [orderBy]="{ name: 'asc' }"
                        [filterByKey]="'name'"
                        [bindLabel]="'name'"
                        [bindValue]="'id'"
                        [skipIds]="[customerId]"
                        [displayColumns]="[
                          { key: 'name', header: 'Name' },
                          { key: 'code', header: 'Code' }
                        ]"
                        [disable]="customerForm.disabled"
                        (suggestionSelected)="
                          customerDataRes &&
                            (customerDataRes.parentCustomerId = $event.id)
                        "
                      >
                      </app-autocomplete-dropdown>
                    </div>

                    <div class="form-group one-col">
                      <label for="sisterCustomerId">Sister Company</label>
                      <app-autocomplete-dropdown
                        id="sisterCustomerId"
                        name="sisterCustomerId"
                        formControlName="sisterCustomerId"
                        [placeholder]="'Enter Sister Company'"
                        [apiUrl]="'v1/customers/list'"
                        [selectFields]="['id', 'name', 'code']"
                        [whereFields]="['name', 'code']"
                        [limit]="10"
                        [orderBy]="{ name: 'asc' }"
                        [filterByKey]="'name'"
                        [bindLabel]="'name'"
                        [bindValue]="'id'"
                        [skipIds]="[customerId]"
                        [displayColumns]="[
                          { key: 'name', header: 'Name' },
                          { key: 'code', header: 'Code' }
                        ]"
                        [disable]="customerForm.disabled"
                        (suggestionSelected)="
                          customerDataRes &&
                            (customerDataRes.sisterCustomerId = $event.id)
                        "
                      >
                      </app-autocomplete-dropdown>
                    </div>
                    <div class="empty-one"></div>

                    <!-- <div class="form-group">
                      <label for="nominated">Nominated</label>
                      <select id="nominated" formControlName="nominated">
                        <option [value]="true">Yes</option>
                        <option [value]="false">No</option>
                      </select>
                    </div> -->

                    <div class="form-group one-col">
                      <app-toggle
                        formControlName="nominated"
                        [toggleId]="'nominated'"
                        [label]="'Nominated'"
                        [isActive]="customerForm.get('Nominated')?.value"
                        (toggleChange)="onNominatedChange($event)"
                        [disable]="readOnlyMode"
                      ></app-toggle>
                    </div>

                    <!-- <div class="form-group">
                      <label> Status </label>
                      <select id="active" formControlName="active">
                        <option [value]="true">Active</option>
                        <option [value]="false">Inactive</option>
                      </select>
                    </div> -->

                    <div class="form-group one-col">
                      <app-toggle
                        formControlName="active"
                        [toggleId]="'active'"
                        [label]="'Status'"
                        [isActive]="customerForm.get('active')?.value"
                        (toggleChange)="onStatusChange($event)"
                        [disable]="readOnlyMode"
                      ></app-toggle>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>

          <div class="section">
            <mat-accordion>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div class="panel-header-content">
                      <span class="acc-title">Other Details</span>
                      <span class="acc-subtitle"
                        >Comprehensive other details</span
                      >
                    </div>
                    <!-- <div class="head">
                      <div class="tab">Other Details</div>
                    </div> -->
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="fields">
                  <div class="grid-container">
                    <div class="form-group">
                      <label for="responsibleMExecUsedId"
                        >Responsible M Exec-User</label
                      >
                      <input
                        id="responsibleMExecUsedId"
                        name="responsibleMExecUsedId"
                        formControlName="responsibleMExecUsedId"
                        type="text"
                        placeholder="Enter Responsible M Exec-User"
                      />
                    </div>

                    <div class="form-group">
                      <label for="serviceTaxNo">Service Tax No.</label>
                      <input
                        id="serviceTaxNo"
                        name="serviceTaxNo"
                        formControlName="serviceTaxNo"
                        type="text"
                        placeholder="Enter Service Tax Number"
                      />
                    </div>

                    <div class="form-group">
                      <label for="incomeTaxNo">Income Tax No.</label>
                      <input
                        id="incomeTaxNo"
                        name="incomeTaxNo"
                        formControlName="incomeTaxNo"
                        type="text"
                        placeholder="Enter Income Tax Number"
                      />
                    </div>

                    <!-- <div class="form-group">
                      <label> TDS Exempted Flag </label>
                      <select
                        id="tdsExemptedFlag"
                        formControlName="tdsExemptedFlag"
                      >
                        <option [value]="true">Yes</option>
                        <option [value]="false">No</option>
                      </select>
                    </div> -->

                    <div class="form-group">
                      <app-toggle
                        formControlName="tdsExemptedFlag"
                        [toggleId]="'tdsExemptedFlag'"
                        [label]="'TDS Exempted Flag'"
                        [isActive]="customerForm.get('tdsExemptedFlag')?.value"
                        (toggleChange)="onTdsExemptedFlagChange($event)"
                        [disable]="readOnlyMode"
                      ></app-toggle>
                    </div>

                    <div class="form-group">
                      <label for="tdsExemptionNo">TDS Exemption No.</label>
                      <input
                        id="tdsExemptionNo"
                        name="tdsExemptionNo"
                        formControlName="tdsExemptionNo"
                        type="text"
                        placeholder="Enter TDS Exemption Number"
                      />
                    </div>

                    <div class="form-group">
                      <label for="tdsPercentage">TDS Percentage</label>
                      <input
                        id="tdsPercentage"
                        name="tdsPercentage"
                        formControlName="tdsPercentage"
                        type="text"
                        placeholder="Enter TDS Percentage"
                      />
                    </div>

                    <div class="form-group">
                      <label for="creditNoOfDays">Credit No of Days</label>
                      <input
                        id="creditNoOfDays"
                        name="creditNoOfDays"
                        formControlName="creditNoOfDays"
                        type="number"
                        placeholder="Enter Credit No of Days"
                        min="0"
                      />
                    </div>

                    <div class="form-group">
                      <label for="creditAmount">Credit Amount</label>
                      <input
                        id="creditAmount"
                        name="creditAmount"
                        formControlName="creditAmount"
                        type="number"
                        placeholder="Enter Credit Amount"
                        min="0"
                      />
                    </div>

                    <div class="form-group">
                      <label for="tanNumber">TAN Number</label>
                      <input
                        id="tanNumber"
                        name="tanNumber"
                        formControlName="tanNumber"
                        type="text"
                        placeholder="Enter TAN Number"
                      />
                    </div>

                    <div class="form-group">
                      <label for="importerExporterCode"
                        >Importer/Exporter Code</label
                      >
                      <input
                        id="importerExporterCode"
                        name="importerExporterCode"
                        formControlName="importerExporterCode"
                        type="text"
                        placeholder="Enter Importer/Exporter Code"
                      />
                    </div>

                    <!-- <div class="form-group">
                      <label> Is PDA Maintain </label>
                      <select id="pdaMaintain" formControlName="pdaMaintain">
                        <option [value]="true">Yes</option>
                        <option [value]="false">No</option>
                      </select>
                    </div> -->

                    <div class="form-group">
                      <app-toggle
                        formControlName="pdaMaintain"
                        [toggleId]="'pdaMaintain'"
                        [label]="'Is PDA Maintain'"
                        [isActive]="customerForm.get('pdaMaintain')?.value"
                        (toggleChange)="onPdaMaintainChange($event)"
                        [disable]="readOnlyMode"
                      ></app-toggle>
                    </div>

                    <div class="form-group">
                      <label for="pdaCreditAmount">PDA Credit Amount</label>
                      <input
                        id="pdaCreditAmount"
                        name="pdaCreditAmount"
                        formControlName="pdaCreditAmount"
                        type="number"
                        placeholder="Enter Amount"
                        min="0"
                      />
                    </div>

                    <!-- <div class="form-group">
                      <label> Economic Free Zone </label>
                      <select
                        id="economicFreeZoneFlag"
                        formControlName="economicFreeZoneFlag"
                      >
                        <option [value]="true">Yes</option>
                        <option [value]="false">No</option>
                      </select>
                    </div> -->

                    <div class="form-group">
                      <app-toggle
                        formControlName="economicFreeZoneFlag"
                        [toggleId]="'economicFreeZoneFlag'"
                        [label]="'Economic Free Zone'"
                        [isActive]="
                          customerForm.get('economicFreeZoneFlag')?.value
                        "
                        (toggleChange)="onEconomicFreeZoneFlag($event)"
                        [disable]="readOnlyMode"
                      ></app-toggle>
                    </div>

                    <div class="form-group">
                      <label for="efzCertificateNo">EFZ Certificate No.</label>
                      <input
                        id="efzCertificateNo"
                        name="efzCertificateNo"
                        formControlName="efzCertificateNo"
                        type="text"
                        placeholder="Enter EFZ Certificate Number"
                      />
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>

        <div
          id="contact-information-section"
          [hidden]="selectedTab !== 'ContactInformation'"
          class=""
        >
          <div class="section">
            <mat-accordion>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div class="panel-header-content">
                      <span class="acc-title">Contact</span>
                      <span class="acc-subtitle">Contact details</span>
                    </div>
                    <!-- <div class="head tabs">
                      <div class="tab">Contact 1</div>
                      <div class="tab">Contact 2</div>
                    </div> -->
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="fields">
                  <div formArrayName="contactInformation">
                    <div
                      *ngFor="
                        let contact of contactInformation.controls;
                        let i = index
                      "
                      [formGroupName]="i"
                    >
                      <div class="form-group text-area-form">
                        <label for="address">Full Address</label>
                        <textarea
                          id="address"
                          name="address"
                          formControlName="address"
                          type="text"
                          placeholder="Enter full address"
                          rows="4"
                        ></textarea>
                      </div>

                      <div class="form-group text-area-form">
                        <label for="billingAddress">Billing Address</label>
                        <textarea
                          id="billingAddress"
                          name="billingAddress"
                          formControlName="billingAddress"
                          type="text"
                          placeholder="Enter Billing Address"
                          rows="4"
                        ></textarea>
                      </div>

                      <div class="grid-container">
                        <div class="form-group">
                          <label for="cityId">City</label>
                          <input
                            id="cityId"
                            name="cityId"
                            formControlName="cityId"
                            type="text"
                            placeholder="Enter City"
                          />
                        </div>

                        <div class="form-group">
                          <label for="countryId">Country</label>
                          <input
                            id="countryId"
                            name="countryId"
                            formControlName="countryId"
                            type="text"
                            placeholder="Enter Country"
                          />
                        </div>

                        <div class="form-group">
                          <label for="stateId">State</label>
                          <input
                            id="stateId"
                            name="stateId"
                            formControlName="stateId"
                            type="text"
                            placeholder="Enter State"
                          />
                        </div>

                        <div class="form-group">
                          <label for="pinCode">Postal Code</label>
                          <input
                            id="pinCode"
                            name="pinCode"
                            formControlName="pinCode"
                            type="text"
                            placeholder="Enter Postal Code"
                          />
                        </div>

                        <div class="form-group">
                          <label for="email">Email</label>
                          <input
                            id="email"
                            name="email"
                            formControlName="email"
                            type="text"
                            placeholder="Enter Email"
                          />
                        </div>

                        <div class="form-group">
                          <label for="mobileNumber1">Mobile Numer</label>
                          <input
                            id="mobileNumber1"
                            name="mobileNumber1"
                            formControlName="mobileNumber1"
                            type="text"
                            placeholder="Enter Mobile Number"
                          />
                        </div>

                        <div class="form-group">
                          <label for="mobileNumber2"
                            >Alternative Mobile Numer</label
                          >
                          <input
                            id="mobileNumber2"
                            name="mobileNumber2"
                            formControlName="mobileNumber2"
                            type="text"
                            placeholder="Enter Alternative Mobile Number"
                          />
                        </div>

                        <!-- <div class="form-group">
                          <label for="defaultContact">Default Contact</label>
                          <select
                            id="defaultContact"
                            formControlName="defaultContact"
                          >
                            <option [value]="true">Yes</option>
                            <option [value]="false">No</option>
                          </select>
                        </div> -->

                        <div class="form-group">
                          <app-toggle
                            formControlName="defaultContact"
                            [toggleId]="'defaultContact'"
                            [label]="'Default Contact'"
                            [isActive]="contact.get('defaultContact')?.value"
                            (toggleChange)="onDefaultContactChange($event, i)"
                            [disable]="readOnlyMode"
                          ></app-toggle>
                        </div>

                        <div class="form-group">
                          <label for="communicationPreference"
                            >Communication Preference</label
                          >
                          <select
                            id="communicationPreference"
                            formControlName="communicationPreference"
                            value="mobile"
                          >
                            <option value="mobile">Mobile Phone</option>
                            <option value="email">Email</option>
                          </select>
                        </div>
                        <div class="form-group">
                          <label for="panNumber">PAN Number</label>
                          <input
                            name="panNumber"
                            formControlName="panNumber"
                            type="text"
                            placeholder="Enter PAN Number"
                          />
                        </div>

                        <div class="form-group">
                          <label for="directLine">Direct Line</label>
                          <input
                            id="directLine"
                            name="directLine"
                            formControlName="directLine"
                            type="text"
                            placeholder="Enter Direct Line"
                          />
                        </div>

                        <div class="form-group">
                          <label for="boardLine">Board Line</label>
                          <input
                            id="boardLine"
                            name="boardLine"
                            formControlName="boardLine"
                            type="text"
                            placeholder="Enter Board Line"
                          />
                        </div>

                        <div class="form-group">
                          <label for="faxNumber">Fax</label>
                          <input
                            id="faxNumber"
                            name="faxNumber"
                            formControlName="faxNumber"
                            type="text"
                            placeholder="Enter Fax Number"
                          />
                        </div>

                        <div class="form-group">
                          <label for="gstNumber">GST No.</label>
                          <input
                            id="gstNumber"
                            name="gstNumber"
                            formControlName="gstNumber"
                            type="text"
                            placeholder="Enter GST Number"
                          />
                        </div>

                        <div class="form-group">
                          <label for="gstValidTill">GST Valid Till</label>
                          <input
                            id="gstValidTill"
                            name="gstValidTill"
                            formControlName="gstValidTill"
                            placeholder="GST Validity"
                            type="datetime-local"
                          />
                        </div>
                        <!--
                        <div class="form-group">
                          <label for="sez">Sez</label>
                          <select id="sez" formControlName="sez">
                            <option [value]="true">Yes</option>
                            <option [value]="false">No</option>
                          </select>
                        </div> -->

                        <div class="form-group">
                          <app-toggle
                            formControlName="sez"
                            [toggleId]="'sez'"
                            [label]="'Sez'"
                            [isActive]="contact.get('sez')?.value"
                            (toggleChange)="onSezChange($event, i)"
                            [disable]="readOnlyMode"
                          ></app-toggle>
                        </div>

                        <!-- <div class="form-group">
                          <label for="lut">LUT</label>
                          <select id="lut" formControlName="lut">
                            <option [value]="true">Yes</option>
                            <option [value]="false">No</option>
                          </select>
                        </div> -->

                        <div class="form-group">
                          <app-toggle
                            formControlName="lut"
                            [toggleId]="'lut'"
                            [label]="'LUT'"
                            [isActive]="contact.get('lut')?.value"
                            (toggleChange)="onLutChange($event, i)"
                            [disable]="readOnlyMode"
                          ></app-toggle>
                        </div>
                      </div>

                      <!-- <h4>Contact {{ i + 1 }}</h4> -->

                      <!-- <label for="address">Address</label>
                <input id="address" type="text" formControlName="address" />

                <label for="mobileNumber1">Mobile Number 1</label>
                <input
                  id="mobileNumber1"
                  type="text"
                  formControlName="mobileNumber1"
                /> -->

                      <!-- Other contact fields can be added here similarly -->

                      <!-- <button type="button" (click)="removeContact(i)">
                  Remove Contact
                </button> -->
                    </div>
                  </div>

                  <!-- <button type="button" (click)="addContact()">Add Contact</button> -->
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
      </form>
    </div>

    <!-- <div class="footer_actions">
    <ng-container *ngIf="readOnlyMode">
      <button type="button" class="secondary_button" (click)="goBack()">
        Back
      </button>
    </ng-container>
    <ng-container *ngIf="!readOnlyMode">
      <button type="button" class="secondary_button" (click)="goBack()">
        Cancel
      </button>
      <button
        type="button"
        class="primary_button"
        (click)="submitCustomerForm()"
      >
        @if(customerEditingEnabled){ Update }@else { Save }
      </button>
    </ng-container>
  </div> -->
  </div>
</div>
