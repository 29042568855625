<div class="container">
  <div class="header">Transporter Master</div>

  <div class="container-content">
    <div class="tos-table-template">
      <!-- <app-suggestion-dropdown
        [placeholder]="'Search Customers'"
        [apiUrl]="'v1/customers/list'"
        [selectFields]="['id', 'name']"
        [limit]="10"
        [orderBy]="{ name: 'asc' }"
        [filterByKey]="'name'"
        [bindLabel]="'name'"
        [bindValue]="'id'"
        (suggestionSelected)="onSuggestionSelected($event)"
      ></app-suggestion-dropdown> -->

      <!-- <app-autocomplete-dropdown
        [placeholder]="'Search Customers'"
        [apiUrl]="'v1/customers/list'"
        [selectFields]="['id', 'name', 'code']"
        [limit]="10"
        [orderBy]="{ name: 'asc' }"
        [filterByKey]="'name'"
        [bindLabel]="'name'"
        [bindValue]="'id'"
        (suggestionSelected)="onSuggestionSelected($event)"
      >
      </app-autocomplete-dropdown> -->

      <!-- <app-autocomplete-dropdown
        [placeholder]="'Search Banks'"
        [apiUrl]="'v1/customers/list'"
        [selectFields]="['id', 'name']"
        [limit]="10"
        [orderBy]="{ name: 'asc' }"
        [filterByKey]="'name'"
        [bindLabel]="'name'"
        [bindValue]="'id'"
        (suggestionSelected)="onSuggestionSelected($event)"
      >
      </app-autocomplete-dropdown> -->

      <div class="table-stats">
        <div class="left">
          <div class="total-users">
            Total Transporters: {{ tableStats.total }}
          </div>
          <div class="active-users">
            Active: {{ tableStats.active }}
          </div>
          <div class="inactive-users">
            Inactive: {{ tableStats.inactive }}
          </div>
        </div>
        <div class="right">
          <div
            class="search-component-container"
            [class.search-input-focus]="focusSearch"
          >
            <input
              type="text"
              name="search_users"
              id="search_users"
              [(ngModel)]="searchTerm"
              placeholder="Search by name or code"
              (focus)="focusSearch = true"
              (focusout)="focusSearch = false"
              (keyup.enter)="tableSearch()"
              (keyup)="handleTableSearch($event)"
            />
            <button (click)="tableSearch()">
              <app-svg-icon
                [path]="'assets/icons/search_icon.svg'"
              ></app-svg-icon>
            </button>
          </div>
          <button
            class="secondary_button"
            [ngClass]="{
              filter_is_applied:
                !searchTerm && tableListRequest.where ? true : false
            }"
            (click)="filter()"
          >
            <app-svg-icon [path]="'assets/icons/filter.svg'"></app-svg-icon>
            Filter
          </button>
          <button class="primary_button" (click)="addNewRecord()">
            <app-svg-icon [path]="'assets/icons/add_icon.svg'"></app-svg-icon>
            Add New
          </button>
        </div>
      </div>

      <div class="table-container">
        <ng-container *ngIf="tableLoaded && tableStats.total === 0">
          <app-no-data
            [msg]="
              'No transporters are available in the system. Please click on Add New Transporter button to create one.'
            "
          ></app-no-data>
        </ng-container>
        <ng-container
          *ngIf="
            tableLoaded &&
            tableStats.total > 0 &&
            tableListData.records?.length > 0
          "
        >
          <table>
            <thead>
              <tr>
                <th class="cb_th select-all-cb">
                  <input
                    type="checkbox"
                    id="select_all_users"
                    [checked]="isAllRecordsOfCurrentPageSelected()"
                    (change)="
                      toggleAllRecordsOfCurrentPageSelections(
                        $event.target.checked
                      )
                    "
                  />
                </th>
                <th class="sl_no_th">Sl No.</th>
                <th
                  class="name_th sortable"
                  [ngClass]="{ sorted: sortColumn === 'name' }"
                  (click)="sortData('name')"
                >
                  <div class="icon_cover">
                    Transporter Name
                    <app-svg-icon
                      [path]="'assets/icons/sort_icon.svg'"
                    ></app-svg-icon>
                  </div>
                </th>
                <th
                  class="code_th sortable"
                  [ngClass]="{ sorted: sortColumn === 'code' }"
                  (click)="sortData('code')"
                >
                  <div class="icon_cover">
                    Transporter Code
                    <app-svg-icon
                      [path]="'assets/icons/sort_icon.svg'"
                    ></app-svg-icon>
                  </div>
                </th>

                <th class="customerType_th">Customer Type</th>

                <th
                  class="status_th sortable"
                  [ngClass]="{ sorted: sortColumn === 'active' }"
                  (click)="sortData('active')"
                >
                  <div class="icon_cover">
                    Status
                    <app-svg-icon
                      [path]="'assets/icons/sort_icon.svg'"
                    ></app-svg-icon>
                  </div>
                </th>

                <!-- <th
                    class="created_date_th sortable"
                    [ngClass]="{ sorted: sortColumn === 'createdAt' }"
                    (click)="sortData('createdAt')"
                  >
                    <div class="icon_cover">
                      Created date
                      <app-svg-icon
                        [path]="'assets/icons/sort_icon.svg'"
                      ></app-svg-icon>
                    </div>
                  </th> -->

                <th
                  class="modified_date_th sortable"
                  [ngClass]="{ sorted: sortColumn === 'modifiedAt' }"
                  (click)="sortData('modifiedAt')"
                >
                  <div class="icon_cover">
                    Modified date
                    <app-svg-icon
                      [path]="'assets/icons/sort_icon.svg'"
                    ></app-svg-icon>
                  </div>
                </th>

                <!-- <th
                    class="createdBy_th sortable"
                    [ngClass]="{ sorted: sortColumn === 'createdBy' }"
                    (click)="sortData('createdBy')"
                  >
                    <div class="icon_cover">
                      Created By
                      <app-svg-icon
                        [path]="'assets/icons/sort_icon.svg'"
                      ></app-svg-icon>
                    </div>
                  </th> -->
                <th
                  class="modifiedBy_th sortable"
                  [ngClass]="{ sorted: sortColumn === 'modifiedBy' }"
                  (click)="sortData('modifiedBy')"
                >
                  <div class="icon_cover">
                    Modified By
                    <app-svg-icon
                      [path]="'assets/icons/sort_icon.svg'"
                    ></app-svg-icon>
                  </div>
                </th>

                <th class="table_menu_th">
                  <!-- <div class="icon_cover plus_icon">
                        <app-svg-icon
                          [path]="'assets/icons/plus_icon.svg'"
                        ></app-svg-icon>
                      </div> -->
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let record of tableListData.records; let i = index"
                [ngClass]="isSelected(record) ? 'selected_row' : ''"
              >
                <td class="cb_td row-select-cb">
                  <input
                    type="checkbox"
                    [id]="'user_sl_no_' + i + 1"
                    [checked]="isSelected(record)"
                    (change)="toggleSelection(record)"
                  />
                </td>
                <td class="sl_no_td">
                  {{ record.slNo }}
                </td>
                <td class="name_td" [title]="record.name">
                  <span
                    [innerHTML]="record.name | highlight : searchTerm"
                  ></span>
                </td>
                <td class="code_td" [title]="record.code">
                  <span
                    [innerHTML]="record.code | highlight : searchTerm"
                  ></span>
                </td>

                <td class="customerType_td">
                  @if (record.customerType && record.customerType.length > 0) {
                  <div class="role_container">
                    <div class="first_role">{{ record.customerType[0] }}</div>
                    <div
                      class="role_count"
                      [appTooltip]="record.customerType.slice(1)"
                      [keyToPick]="'name'"
                      position="bottom"
                      *ngIf="record.customerType.length > 1"
                    >
                      + {{ record.customerType.length - 1 }}
                    </div>
                  </div>
                  }@else { N/A }
                </td>
                <td class="status_td">
                  <div
                    class="icon_cover"
                    [ngClass]="record.active ? 'active' : 'inactive'"
                  >
                    <app-svg-icon
                      [path]="'assets/icons/circle_icon.svg'"
                    ></app-svg-icon>
                    {{ record.active ? "Active" : "Inactive" }}
                  </div>
                </td>
                <!-- <td class="created_date_td">
                    {{ record.createdAt | date : "dd/MM/yy hh:mm a" }}
                  </td> -->
                <td class="modified_date_td">
                  {{ record.modifiedAt | date : "dd/MM/yy hh:mm a" }}
                </td>
                <!-- <td class="createdBy_td">
                    {{ record.createdBy || "N/A" }}
                  </td> -->
                <td class="modifiedBy_td">
                  {{ record.modifiedBy || "N/A" }}
                </td>
                <td class="table_menu_td">
                  <div class="menu_component">
                    <app-table-menu
                      [options]="menuOptions"
                      [isOpen]="openMenuIndex === i"
                      (toggle)="handleMenuToggle(i)"
                      (optionSelected)="handleOptionSelected(record, $event)"
                      [tableRowData]="record"
                    >
                    </app-table-menu>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </ng-container>
        <ng-container
          *ngIf="
            tableLoaded &&
            tableStats.total > 0 &&
            tableListData.records?.length === 0
          "
        >
          <app-no-data-found [msg]="'No results found !'"></app-no-data-found>
        </ng-container>
      </div>
      <div class="action_bar">
        <div class="bulk_actions">
          <ng-container
            *ngIf="selectedItems.length > 0 || allTableRecordsSelected"
          >
            <div class="selection_count">
              <div class="count">
                {{
                  allTableRecordsSelected
                    ? tableStats.total
                    : selectedItems.length
                }}
                selected
              </div>
            </div>
            <div class="actions">
              <button
                class="secondary_button tick"
                (click)="bulkActionConfirmation('setActive')"
              >
                <app-svg-icon [path]="'assets/icons/tick.svg'"></app-svg-icon>
                Active
              </button>
              <button
                class="secondary_button close"
                (click)="bulkActionConfirmation('setInActive')"
              >
                <app-svg-icon [path]="'assets/icons/close.svg'"></app-svg-icon>
                Inactive
              </button>
            </div>
          </ng-container>
        </div>
        <div class="pagination">
          <span class="info">
            Showing
            {{
              currentTablePage === 1
                ? 1
                : currentTablePage * rowsPerPage + 1 - rowsPerPage
            }}
            to
            {{
              currentTablePage * rowsPerPage > tableListData.totalCount
                ? tableListData.totalCount
                : currentTablePage * rowsPerPage
            }}
            of {{ tableListData.totalCount }} customers
          </span>
          <div class="buttons">
            <button
              class="previous_btn"
              (click)="previousPage()"
              [disabled]="currentTablePage === 1"
              title="Previous Page"
            >
              <app-svg-icon
                [path]="'assets/icons/left-arrow.svg'"
              ></app-svg-icon>
            </button>
            <ng-container *ngIf="totalTablePages">
              <button
                *ngFor="
                  let page of [].constructor(totalTablePages);
                  let i = index
                "
                (click)="goToPage(i + 1)"
                [class.active]="currentTablePage === i + 1"
              >
                {{ i + 1 }}
              </button>
            </ng-container>

            <button
              class="next_btn"
              (click)="nextPage()"
              [disabled]="currentTablePage === totalTablePages"
              title="Next Page"
            >
              <app-svg-icon
                [path]="'assets/icons/right-arrow.svg'"
              ></app-svg-icon>
            </button>
          </div>
          <span class="info"> Per page:</span>
          <select
            id="rowsPerPage"
            class="row-options"
            #rowOptions
            (change)="updateRowsPerPage(rowOptions.value)"
          >
            <option
              class="option"
              *ngFor="let option of rowsPerPageOptions"
              [value]="option"
            >
              {{ option }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
</div>
