<div class="equipment-container">
    <form [formGroup]="equipmentForm" class="manage-equipment-form">
        <div class="form-row">
            <div class="form-group">
                <label class="required_field_label" for="name">Equipment Name</label>
                <input id="name" formControlName="name" [placeholder]="'Enter Equipment Name'" [ngClass]="{form_error : equipmentForm.get('name')?.invalid && equipmentForm.get('name')?.touched}"/>
                <div class="form_validation_error" [ngClass]="{'form_error' : equipmentForm.get('name')?.invalid && equipmentForm.get('name')?.touched}">
                  <small *ngIf="equipmentForm.get('name')?.errors?.['required']">Equipment Name is required</small>
                </div>
            </div>
        </div>
        
        <div class="form-row">
            <div class="form-group" style="grid-column: span 2">
                <label for="description" class="form-label">Description</label>
                <textarea [ngStyle]="" class="form-text-area" id="description" placeholder="Enter description" formControlName="description"></textarea>
            </div>
        </div>
    </form>

    <div class="footer">
        <div class="divider"></div>
        <div class="content">
          <div class="action_btn">
            <button class="secondary_button" (click)="close()">Cancel</button>
            <button (click)="onSubmit()" class="primary_button">Save</button>
          </div>
        </div>
    </div>
</div>