import { Component, ElementRef, QueryList, ViewChildren } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSelectModule } from "@angular/material/select";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject, takeUntil } from "rxjs";
import { SharedModule } from "../../../../modules/shared/shared.module";
import { ApiService } from "../../../../services/api.service";
import { LoadingService } from "../../../../services/loading.service";
import { ToasterService } from "../../../../services/toaster.service";
import { BackButtonComponent } from "../../../back-button/back-button.component";
import { SvgIconComponent } from "../../../svg-icon/svg-icon.component";
import { Bank } from "../../../../models/bank-master.interface";

@Component({
  selector: "app-manage-bank-master",
  standalone: true,
  imports: [SharedModule, SvgIconComponent, BackButtonComponent, MatSelectModule],
  templateUrl: "./manage-bank-master.component.html",
  styleUrl: "./manage-bank-master.component.scss"
})
export class ManageBankMasterComponent {
  readOnlyMode = true;
  masterEditingEnabled = false;
  masterForm: FormGroup;
  masterId: string = "";

  @ViewChildren("formField") formFields!: QueryList<ElementRef>;
  private destroy$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private toasterService: ToasterService,
    private loadingService: LoadingService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.masterForm = this.initForm();
  }

  ngOnInit(): void {
    this.masterId = this.route.snapshot.paramMap.get("bankId") || "";
    console.log(this.masterId);
    this.setupComponentMode();
    if (this.masterId) {
      this.loadMasterData();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initForm(): FormGroup {
    return this.fb.group({
      name: ["", Validators.required],
      code: ["", Validators.required],
      ifscCode: ["", Validators.required],
      branch: [""]
    });
  }

  private setupComponentMode(): void {
    if (this.masterId) {
      const path = this.route.snapshot.url[0].path;
      this.masterEditingEnabled = path === "edit";
      this.readOnlyMode = !this.masterEditingEnabled;
      if (this.readOnlyMode) {
        this.masterForm.disable();
      }
    } else {
      this.readOnlyMode = false;
      this.masterEditingEnabled = false;
    }
  }

  private loadMasterData(): void {
    this.loadingService.show();
    this.api.getBankMaster(this.masterId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: any) => {
          const bankData: Bank = res.data;
          this.patchFormWithMasterData(bankData);
          this.loadingService.hide();
        },
        error: (err) => {
          console.error("Error loading country data:", err);
          this.toasterService.error("Failed to load country data");
          this.loadingService.hide();
        }
      });
  }

  private patchFormWithMasterData(bank: Bank): void {
    this.masterForm.patchValue(bank);
  }

  submitMasterForm(): void {
    if (this.masterForm.valid) {
      const formValue = this.masterForm.value;
      const masterData: Bank = formValue;
      masterData.active = true; //always create new master resource with active=true

      this.loadingService.show();
      const action$ = this.masterEditingEnabled
        ? this.api.updateBankMaster(masterData, this.masterId)
        : this.api.addBankMaster(masterData);

      action$.pipe(takeUntil(this.destroy$)).subscribe({
        next: () => {
          const message = this.masterEditingEnabled
            ? `Bank ${masterData.name} has been updated!`
            : `Bank ${masterData.name} has been added!`;
          this.toasterService.success(message);
          this.loadingService.hide();
          this.goBack();
        },
        error: (err) => {
          this.toasterService.error(err.error.errorDesc || "An error occurred");
          console.error("Error submitting form:", err);
          this.loadingService.hide();
        }
      });
    } else {
      this.markFormGroupTouched(this.masterForm);
      this.scrollToFirstInvalidControl();
    }
  }

  private markFormGroupTouched(formGroup: FormGroup | FormArray): void {
    Object.values(formGroup.controls).forEach(control => {
      if (control instanceof FormGroup || control instanceof FormArray) {
        this.markFormGroupTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }

  scrollToFirstInvalidControl(): void {
    const firstInvalidControl = this.formFields.find((element) => {
      const nativeElement = element.nativeElement as HTMLElement;
      return nativeElement.classList.contains("ng-invalid");
    });

    if (firstInvalidControl) {
      firstInvalidControl.nativeElement.scrollIntoView({
        behavior: "smooth",
        block: "center"
      });
      (firstInvalidControl.nativeElement as HTMLElement).focus();
    }
  }

  goBack(): void {
    this.router.navigateByUrl("/manage-masters/banks");
  }

  getBankDetailsByIfscCode(): void {
    const ifscCode = this.masterForm.get("ifscCode")?.value;

    if (!ifscCode)
      return;

    const bank: Bank = { ifscCode } as Bank;

    this.api.getBankDetailsByIfscCode(bank).subscribe({
      next: (bankData: Bank) => {
        this.patchFormWithMasterData(bankData);
      },
      error: (err: any) => {
        console.error("Error fetching bank details:", err);
        this.toasterService.error(err?.error?.errorDesc);
      }
    });
  }

}
