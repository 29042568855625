<div class="container">
    
    <div class="page_header">
        <div class="left">
            <div class="close_icon_wrapper" (click)="cancel()" >
                <app-svg-icon class="close_icon" [path]="'/assets/icons/close_icon.svg'"></app-svg-icon>
            </div>
            <div class="title">Create Hold</div>
        </div>
        <div class="right">
            <button class="btn secondary_button" (click)="cancel()">Cancel</button>
            <button class="btn primary_button" (click)="submit()">Release</button>
        </div>
    </div>

    <div class="content">
        <form [formGroup]="releaseForm">
            <div class="hold_form">
                <div class="form-item">
                    <label for="holdCategory" class="required_field_label">Hold Type</label>
                    <app-multi-select
                    id="holdCategory"
                    formControlName="holdCategory"
                    [options]="holdTypeOptions"
                    [displayKey]="'label'"
                    [valueKey]="'key'"
                    [isMultiSelect]="false"
                    [placeholder]="'Select Issuer'"
                    ></app-multi-select>
                    <div class="form_validation_error" [ngClass]="{form_error : releaseForm.get('holdCategory')?.invalid && releaseForm.get('holdCategory')?.touched}">
                        <small *ngIf="releaseForm.get('holdCategory')?.errors?.['required']">Hold Type is required</small>
                    </div>
                </div>
                <div class="form-item">
                    <label for="holdIssuedBy" class="required_field_label">Hold Issued By</label>
                    <app-multi-select
                    id="holdIssuedBy"
                    formControlName="holdIssuedBy"
                    [options]="holdSourceOptions"
                    [displayKey]="'label'"
                    [valueKey]="'key'"
                    [isMultiSelect]="false"
                    [placeholder]="'Select Issuer'"
                    ></app-multi-select>
                    <div class="form_validation_error" [ngClass]="{form_error : releaseForm.get('holdIssuedBy')?.invalid && releaseForm.get('holdIssuedBy')?.touched}">
                        <small *ngIf="releaseForm.get('holdIssuedBy')?.errors?.['required']">Please select the mode</small>
                    </div>
                </div>
                <div class="form-item"  *ngIf="releaseForm.get('holdCategory')?.value === 'INTERNAL' ">
                    <label for="holdSubCategory" class="required_field_label">Hold Reason</label>
                    <app-multi-select
                        id="holdSubCategory"
                        formControlName="holdSubCategory"
                        [options]="holdReasonOptions"
                        [displayKey]="'label'"
                        [valueKey]="'key'"
                        [isMultiSelect]="false"
                        [placeholder]="'Select Hold Reason'"
                        (selectionChange)="onHoldSourceChange($event)"
                    ></app-multi-select>
                    <div class="form_validation_error" [ngClass]="{form_error : releaseForm.get('holdSubCategory')?.invalid && releaseForm.get('holdSubCategory')?.touched}">
                        <small *ngIf="releaseForm.get('holdSubCategory')?.errors?.['required']">Hold Reason is required</small>
                    </div>
                </div>
                
                <div class="form-item" *ngIf="releaseForm.get('holdCategory')?.value === 'CUSTOMS'">
                    <label for="holdDocumentNo" class="required_field_label">Hold Document No</label>
                    <input id="holdDocumentNo" formControlName="holdDocumentNo" placeholder="Enter Document No" readonly/>
                    <div class="form_validation_error" [ngClass]="{form_error : releaseForm.get('holdDocumentNo')?.invalid && releaseForm.get('holdDocumentNo')?.touched}">
                        <small *ngIf="releaseForm.get('holdDocumentNo')?.errors?.['required']">Hold Document is required</small>
                    </div>
                </div>

                <div class="form-item" *ngIf="releaseForm.get('holdCategory')?.value === 'CUSTOMS'">
                    <label for="releaseDocumentNo" class="required_field_label">Release Document No</label>
                    <input id="releaseDocumentNo" formControlName="releaseDocumentNo" placeholder="Enter Release Document No" />
                    <div class="form_validation_error" [ngClass]="{form_error : releaseForm.get('holdDocumentNo')?.invalid && releaseForm.get('holdDocumentNo')?.touched}">
                        <small *ngIf="releaseForm.get('holdDocumentNo')?.errors?.['required']">Hold Document is required</small>
                    </div>
                </div>
                <div class="form-item">
                    <label for="releaseDate" class="required_field_label">Release Date</label>
                    <input id="releaseDate" formControlName="releaseDate" type="datetime-local" />
                    <div class="form_validation_error" [ngClass]="{form_error : releaseForm.get('releaseDate')?.invalid && releaseForm.get('releaseDate')?.touched}">
                        <small *ngIf="releaseForm.get('releaseDate')?.errors?.['required']">Hold Date is required</small>
                    </div>
                </div>
            </div>
        </form>

        <div class="hold-item-selection-container">
            <div class="search_bar_action_btns">
                <div class="search_bar">
                    <div class="search_container">
                        <input
                            type="text"
                            name="search_table"
                            id="search_table"
                            [(ngModel)]="searchTerm"
                            placeholder="Search by Container or Item No"
                            (focus)="focusSearch = true"
                            (focusout)="focusSearch = false"
                            (keyup)="handleTableSearch($event)"
                        >
                            <img src="assets/icons/search_icon.svg" class="search_icon">
                    </div>
                </div>
    
                <div class="action_btn">
                    <button class="secondary_button" *ngIf="selectedItems.length > 0 
                    && !allRecordsSelected && tableListData.totalCount != tableListData.records.length" (click)="selectAll()">
                        Select all {{tableListData.totalCount}} records
                    </button>
                    <button class="secondary_button" *ngIf="allRecordsSelected" (click)="clearSelection()">
                        Clear Selection
                    </button>
                    <div class="selection-message" *ngIf="selectedItems.length > 0">
                        @if (allRecordsSelected || tableListData.totalCount == selectedItems.length) {
                            Selected: {{tableListData.totalCount}} of {{tableListData.totalCount}} 
                        } @else  {
                            Selected: {{selectedItems.length}} of {{tableListData.totalCount}} 
                        }
                    </div>
                </div>
            </div>
    
            <div class="table-container">
                <app-tos-table
                [showTableStats]="false"
                [tableMeta]="tableMeta"
                [(dataListRequest)]="tableListRequest"
                [dataListResponse]="tableListData"
                [(selectedItems)]="selectedItems"
                [showMenuOptions]="false"
                (tosTableAction)="listItemsOnHold()"
                ></app-tos-table>
            </div>
        </div>
    </div>
</div>