import { Component, EventEmitter, Input, Output, ViewContainerRef } from "@angular/core";
import { SidebarExpandedComponent } from "../../sidebar/sidebar-expanded/sidebar-expanded.component";
import { Menu } from "../../../models/sidebar-menu.interface";
import { LocalStorageService } from "../../../services/local-storage.service";
import { NgClass } from "@angular/common";
import { ModalComponent } from "../../modal/modal.component";
import { ConfirmDialogComponent } from "../../confirm-dialog/confirm-dialog.component";
import { AuthService } from "../../../services/auth.service";
import { SidebarOptionsComponent } from "../sidebar-options/sidebar-options.component";

@Component({
  selector: "app-sidebar-new-collapsed",
  standalone: true,
  imports: [
    SidebarExpandedComponent,
    NgClass,
    SidebarOptionsComponent
  ],
  templateUrl: "./sidebar-new-collapsed.component.html",
  styleUrl: "./sidebar-new-collapsed.component.scss"
})
export class SidebarNewCollapsedComponent {
  @Input() isExpanded: boolean = false;
  @Input() menuItems: Menu[] = [];
  @Input() activeMenuParent!: Menu;

  @Output() menuChildResources: EventEmitter<Menu> = new EventEmitter<Menu>();
  @Output() isSearchingEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() collapseSidebarEvent = new EventEmitter<void>();

  clickedMenuId: string | null = null;
  isSearching: boolean = false;
  showOptions: boolean = false;


  handleClickOnNavItem(menu: Menu) {
    if(this.clickedMenuId === menu.id) {
      this.collapseSidebarEvent.emit()
      return
    }


    this.menuChildResources.emit(menu);
    this.clickedMenuId = menu.id;
  }

  isParentActive(id: string) {
    return this.activeMenuParent?.id === id;
  }

  isClicked(id: string) {
    if (!this.isExpanded) {
      this.clickedMenuId = null;
      return false;
    }
    return this.clickedMenuId === id && this.clickedMenuId !== this.activeMenuParent.id;
  }

  toggleSearch() {
    this.isSearching = !this.isSearching;
    this.isSearchingEvent.emit(this.isSearching);
  }

  showSideNavOptions() {
    this.showOptions = !this.showOptions;
  }

  closeOptions() {
    this.showOptions = false
  }
}
