<div class="container">
    <form [formGroup]="releaseForm">
        <div class="release-form">
            <div class="form-item">
                <label for="holdIssuedBy" class="required_field_label">Hold Issued By</label>
                <app-multi-select
                    id="holdIssuedBy"
                    formControlName="holdIssuedBy"
                    [options]="holdSourceOptions"
                    [displayKey]="'label'"
                    [valueKey]="'key'"
                    [isMultiSelect]="false"
                    [placeholder]="'Select Issuer'"
                    (selectionChange)="onHoldSourceChange($event)"
                ></app-multi-select>
                <div class="form_validation_error" [ngClass]="{form_error : releaseForm.get('holdIssuedBy')?.invalid && releaseForm.get('holdIssuedBy')?.touched}">
                    <small *ngIf="releaseForm.get('holdIssuedBy')?.errors?.['required']">Please select the mode</small>
                </div>
            </div>
            <div class="form-item">
                <label for="holdCategory" class="required_field_label">Hold Type</label>
                <app-multi-select
                    id="holdCategory"
                    formControlName="holdCategory"
                    [options]="holdTypeOptions"
                    [displayKey]="'label'"
                    [valueKey]="'key'"
                    [isMultiSelect]="false"
                    [placeholder]="'Select Hold Type'"
                ></app-multi-select>
                <div class="form_validation_error" [ngClass]="{form_error : releaseForm.get('holdCategory')?.invalid && releaseForm.get('holdCategory')?.touched}">
                    <small *ngIf="releaseForm.get('holdCategory')?.errors?.['required']">Hold Type is required</small>
                </div>
            </div>
            <div class="form-item"  *ngIf="isInternalHold()">
                <label for="holdSubCategory" class="required_field_label">Hold Reason</label>
                <app-multi-select
                    id="holdSubCategory"
                    formControlName="holdSubCategory"
                    [options]="holdReasonOptions"
                    [displayKey]="'label'"
                    [valueKey]="'key'"
                    [isMultiSelect]="false"
                    [placeholder]="'Select Hold Reason'"
                ></app-multi-select>
                <div class="form_validation_error" [ngClass]="{form_error : releaseForm.get('holdSubCategory')?.invalid && releaseForm.get('holdSubCategory')?.touched}">
                    <small *ngIf="releaseForm.get('holdSubCategory')?.errors?.['required']">Hold Reason is required</small>
                </div>
            </div>
            <div class="form-item" *ngIf="isCustomsHold()">
                <label for="holdDocumentNo" class="required_field_label">Hold Document No</label>
                <input id="holdDocumentNo" formControlName="holdDocumentNo" placeholder="Enter Hold Document No" [readOnly]="isHoldDocumentReadOnly" />
                <div class="form_validation_error" [ngClass]="{form_error : releaseForm.get('holdDocumentNo')?.invalid && releaseForm.get('holdDocumentNo')?.touched}">
                    <small *ngIf="releaseForm.get('holdDocumentNo')?.errors?.['required']">Hold Document is required</small>
                </div>
            </div>
            <div class="form-item" *ngIf="isCustomsHold()">
                <label for="releaseDocumentNo" class="required_field_label">Release Document No</label>
                <input id="releaseDocumentNo" formControlName="releaseDocumentNo" placeholder="Enter Release Document No" />
                <div class="form_validation_error" [ngClass]="{form_error : releaseForm.get('releaseDocumentNo')?.invalid && releaseForm.get('releaseDocumentNo')?.touched}">
                    <small *ngIf="releaseForm.get('releaseDocumentNo')?.errors?.['required']">Release Document No is required</small>
                </div>
            </div>
            <div class="form-item">
                <label for="releaseDate" class="required_field_label">Release Date</label>
                <input id="releaseDate" formControlName="releaseDate" type="datetime-local" />
                <div class="form_validation_error" [ngClass]="{form_error : releaseForm.get('releaseDate')?.invalid && releaseForm.get('releaseDate')?.touched}">
                    <small *ngIf="releaseForm.get('releaseDate')?.errors?.['required']">Release Date is required</small>
                </div>
            </div>
        </div>
    </form>
    <div class="dialog_actions">
        <button type="button" class="secondary_button" (click)="closeDialog()">
          Cancel
        </button>
        <button type="submit" class="primary_button" (click)="releaseHold()">Release</button>
      </div>
</div>