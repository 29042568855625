import { Component, ElementRef, QueryList, ViewChildren } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { Subject, takeUntil } from "rxjs";
import { ApiService } from "../../../../services/api.service";
import { ToasterService } from "../../../../services/toaster.service";
import { LoadingService } from "../../../../services/loading.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Bank } from "../../../../models/bank-master.interface";
import { BackButtonComponent } from "../../../back-button/back-button.component";
import { NgIf } from "@angular/common";
import { SharedModule } from "../../../../modules/shared/shared.module";
import { AutocompleteDropdownComponent } from "../../../autocomplete-dropdown/autocomplete-dropdown.component";
import { StateMaster } from "../../../../models/state-master.interface";

@Component({
  selector: 'app-manage-state-master',
  standalone: true,
  imports: [
    BackButtonComponent,
    NgIf,
    ReactiveFormsModule,
    SharedModule,
    AutocompleteDropdownComponent
  ],
  templateUrl: './manage-state-master.component.html',
  styleUrl: './manage-state-master.component.scss'
})
export class ManageStateMasterComponent {
  readOnlyMode = true;
  masterEditingEnabled = false;
  masterForm: FormGroup;
  masterId: string = '';
  public masterDataResponse?: StateMaster

  @ViewChildren('formField') formFields!: QueryList<ElementRef>;
  private destroy$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private toasterService: ToasterService,
    private loadingService: LoadingService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.masterForm = this.initForm();
  }

  ngOnInit(): void {
    this.masterId = this.route.snapshot.paramMap.get('stateId') || '';
    console.log(this.masterId)
    this.setupComponentMode();
    if (this.masterId) {
      this.loadMasterData();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initForm(): FormGroup {
    return this.fb.group({
      name: ['', Validators.required],
      code: ['', Validators.required],
      countryId: ['',Validators.required]
    });
  }

  private setupComponentMode(): void {
    if (this.masterId) {
      const path = this.route.snapshot.url[0].path;
      this.masterEditingEnabled = path === 'edit';
      this.readOnlyMode = !this.masterEditingEnabled;
      if (this.readOnlyMode) {
        this.masterForm.disable();
      }
    } else {
      this.readOnlyMode = false;
      this.masterEditingEnabled = false;
    }
  }

  private loadMasterData(): void {
    this.loadingService.show();
    this.api.getStateMaster(this.masterId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: any) => {
          const stateMasterData: StateMaster = res.data;
          this.masterDataResponse = stateMasterData;
          this.patchFormWithMasterData(stateMasterData);
          this.loadingService.hide();
        },
        error: (err) => {
          console.error('Error loading country data:', err);
          this.toasterService.error('Failed to load country data');
          this.loadingService.hide();
        },
      });
  }

  private patchFormWithMasterData(stateMaster: StateMaster): void {
    this.masterForm.patchValue({
      ...stateMaster,
      countryId: stateMaster.countryName,
    });
  }

  submitMasterForm(): void {
    if (this.masterForm.valid) {
      const formValue = this.masterForm.value;
      const masterData: StateMaster = formValue;
      masterData.countryId = this.masterDataResponse?.countryId
      masterData.active = true; //always create new master resource with active=true

      this.loadingService.show();
      const action$ = this.masterEditingEnabled
        ? this.api.updateStateMaster(masterData, this.masterId)
        : this.api.addStateMaster(masterData);

      action$.pipe(takeUntil(this.destroy$)).subscribe({
        next: () => {
          const message = this.masterEditingEnabled
            ? `State ${masterData.name} has been updated!`
            : `State ${masterData.name} has been added!`;
          this.toasterService.success(message);
          this.loadingService.hide();
          this.goBack();
        },
        error: (err) => {
          this.toasterService.error(err.error.errorDesc || 'An error occurred');
          console.error('Error submitting form:', err);
          this.loadingService.hide();
        },
      });
    } else {
      this.markFormGroupTouched(this.masterForm);
      this.scrollToFirstInvalidControl();
    }
  }

  private markFormGroupTouched(formGroup: FormGroup | FormArray): void {
    Object.values(formGroup.controls).forEach(control => {
      if (control instanceof FormGroup || control instanceof FormArray) {
        this.markFormGroupTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }

  scrollToFirstInvalidControl(): void {
    const firstInvalidControl = this.formFields.find((element) => {
      const nativeElement = element.nativeElement as HTMLElement;
      return nativeElement.classList.contains('ng-invalid');
    });

    if (firstInvalidControl) {
      firstInvalidControl.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      (firstInvalidControl.nativeElement as HTMLElement).focus();
    }
  }

  goBack(): void {
    this.router.navigateByUrl('/manage-masters/states');
  }
}
