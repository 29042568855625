import { Component, ElementRef, Input, QueryList, ViewChildren } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Customer } from '../../../../models/customer-master.interface';
import { SharedModule } from '../../../../modules/shared/shared.module';
import { ApiService } from '../../../../services/api.service';
import { LoadingService } from '../../../../services/loading.service';
import { ToasterService } from '../../../../services/toaster.service';
import { atLeastOneCheckboxCheckedValidator } from '../../../../validators/atleastOneCheckboxCheked';
import { BackButtonComponent } from '../../../back-button/back-button.component';
import { SvgIconComponent } from '../../../svg-icon/svg-icon.component';
import { Package } from '../../../../models/package-master.interface';

@Component({
  selector: 'app-manage-package',
  standalone: true,
  imports: [SharedModule, SvgIconComponent, BackButtonComponent],
  templateUrl: './manage-package.component.html',
  styleUrl: './manage-package.component.scss'
})
export class ManagePackageComponent {
  @Input() masterEditingEnabled: boolean = false;
  @Input() masterId!: string;
  @Input() masterObj!: Package;
  readOnlyMode: boolean = true;

  masterForm: FormGroup;

  // Query all form control elements with #formField
  @ViewChildren('formField') formFields!: QueryList<ElementRef>;

  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private toasterService: ToasterService,
    private loadingService: LoadingService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.masterForm = this.fb.group({
      code: ['', Validators.required],
      description: [''],
      name: ['', Validators.required],
    });
  }

  ngOnInit() {
    this.masterId = this.route.snapshot.paramMap.get('packageId') || '';
    if (this.masterId !== '') {
      if (this.route.snapshot.url[0].path === 'edit') {
        this.masterEditingEnabled = true;
        this.readOnlyMode = false;
      } else {
        this.masterEditingEnabled = false;
        this.readOnlyMode = true;
        this.masterForm.disable();
      }
      this.loadingService.show();
      this.api.getPackage(this.masterId).subscribe({
        next: (res: any) => {
          const customerData: Customer = res.data;
          this.masterForm.patchValue(customerData);
          this.loadingService.hide();
        },
        error: (err) => {
          console.log(err);
          this.loadingService.hide();
        },
      });
    } else {
      this.readOnlyMode = false;
      this.masterEditingEnabled = false;
    }
  }

  // Scroll to the first invalid form control
  scrollToFirstInvalidControl() {
    const firstInvalidControl = this.formFields.find((element) => {
      return !element.nativeElement.validity?.valid;
    });

    if (firstInvalidControl) {
      firstInvalidControl.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      // firstInvalidControl.nativeElement.focus(); // Optionally, focus the invalid element
    }
  }

  async submitCustomerForm() {
    console.log(this.masterForm.value);
    this.masterForm.markAllAsTouched();
    if (this.masterForm.valid) {
      console.log('valid form');

      const masterData: Package = {
        ...this.masterForm.value,
      };

      if (this.masterEditingEnabled) {
        this.updateMaster(masterData, this.masterId);
      } else {
        this.addMaster(masterData);
      }
    } else {
      console.log('invalid form');
      this.scrollToFirstInvalidControl();
    }
  }

  addMaster(masterData: Package) {
    this.loadingService.show();
    console.log(masterData);
    this.api.addPackage(masterData).subscribe({
      next: (res) => {
        console.log(res);
        this.toasterService.success(
          `Package ${masterData.name} has been added !`
        );
        this.loadingService.hide();
        this.goBack();
      },
      error: (err) => {
        this.toasterService.error(err.error.errorDesc);
        console.log(err);
        this.loadingService.hide();
      },
    });
  }

  updateMaster(masterData: Package, masterId: string) {
    this.loadingService.show();
    console.log(masterData);
    this.api.updatePackage(masterData, masterId).subscribe({
      next: (res) => {
        console.log(res);
        this.toasterService.success(
          `Package ${masterData.name} has been updated !`
        );
        this.loadingService.hide();
        this.goBack();
      },
      error: (err) => {
        this.toasterService.error(err.error.errorDesc);
        console.log(err);
        this.loadingService.hide();
      },
    });
  }

  goBack() {
    this.router.navigateByUrl('/manage-masters/packages');
  }
}
