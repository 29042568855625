import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { GateInRequest } from "../models/gate-in-request.interface";
import { TableListRequest } from "../models/table-list-request.interface";
import { Tally } from "../models/tally.interface";
import { InventoryType } from "../constants/gate-ops-constants";
import { Observable } from "rxjs";
import { JobOrderDetails } from "../models/job-order-details.interface";

const operationsEndPoint = "v1/operations";

const cargoItemsEndPoint = "v1/import-cargo-items";
const trailerInventoryEndPoint = "v1/inventory/trailers";
const containerInventoryEndPoint = "v1/containers";
const igmContainersEndPoint = "v1/igm-containers";
const jobOrdersEndpoint = "v1/job-orders"
const tallyEndPoint = "v1/tally"
const jobOrderInventoryEndPoint = "v1/job-order-inventories"
const holdItemsEndPoint = "v1/hold-items";

@Injectable({
  providedIn: "root"  // Ensures the service is available app-wide
})
export class OperationService {

  private baseUrl: string = environment.apiBaseUrl;

  constructor(private http: HttpClient) {
  }

  submitGateInRequest(gateInRequest: GateInRequest) {
    return this.http.post(
      `${this.baseUrl}/${operationsEndPoint}/gate-in`,
      gateInRequest
    );
  }

  submitGateOutRequest(gateOutRequest: any) {
    return this.http.post(
      `${this.baseUrl}/${operationsEndPoint}/gate-out`,
      gateOutRequest
    );
  }

  fetchTrailerById(trailerId: string, includeContainers: boolean, includeItems: boolean) {
    return this.http.get(`${this.baseUrl}/${trailerInventoryEndPoint}/${trailerId}?includeContainers=${includeContainers}&includeItems=${includeItems}`);
  }

  fetchContainerById(containerId: string, withItems: boolean, includeHoldDetails: boolean = true) {
    return this.http.get(`${this.baseUrl}/${containerInventoryEndPoint}/${containerId}?withItems=${withItems}&includeHoldDetails=${includeHoldDetails}`);
  }

  fetchRecentData() {
    return this.http.get(`${this.baseUrl}/${igmContainersEndPoint}/fetch-recent-data`);
  }

    getData(apirUrl: string) {
      return this.http.get(`${this.baseUrl}/${apirUrl}`);
    }

  submitItemFilingForm(cargoItem: any) {
    return this.http.post(
      `${this.baseUrl}/${cargoItemsEndPoint}`,
      cargoItem
    );
  }

  updateItemFilingForm(cargoItemId: string, cargoItem: any) {
    return this.http.put(
      `${this.baseUrl}/${cargoItemsEndPoint}/${cargoItemId}`,
      cargoItem
    );
  }

  getImportCargoById(importCargoId: string, withContainers: boolean = false, includeHoldDetails: boolean = true) {
    return this.http.get(`${this.baseUrl}/${cargoItemsEndPoint}/${importCargoId}?withContainers=${withContainers}&includeHoldDetails=${includeHoldDetails}`);
  }

  getImportCargoList(tableListRequest: TableListRequest) {
    return this.http.post(`${this.baseUrl}/${cargoItemsEndPoint}/list`, tableListRequest);
  }

  getIgmDeskList(tableListRequest: TableListRequest) {
    return this.http.post(`${this.baseUrl}/${containerInventoryEndPoint}/list`, tableListRequest);
  }

  getJobOrdersList(tableListRequest: TableListRequest) {
    return this.http.post(`${this.baseUrl}/${jobOrdersEndpoint}/list`, tableListRequest);
  }

  saveJobOrder(jobOrderRequest: any) {
    return this.http.post(
      `${this.baseUrl}/${jobOrdersEndpoint}`,
      jobOrderRequest
    );
  }

  updateJobOrder(jobOrderId: string, jobOrderRequest: any) {
    return this.http.put(
      `${this.baseUrl}/${jobOrdersEndpoint}/${jobOrderId}`,
      jobOrderRequest
    );
  }

  getJobOrderById(jobOrderId: string, includeItems: boolean = false, includeVendors: boolean = false,
    includeContainers: boolean = false, includeTruckTrailerDetails: boolean = false
  ) {
    return this.http.get(`${this.baseUrl}/${jobOrdersEndpoint }/${jobOrderId}?includeItems=${includeItems}&includeVendors=${includeVendors}&includeContainers=${includeContainers}&includeTruckTrailerDetails=${includeTruckTrailerDetails}`);
  }

  fetchJobOrdersByInventoryIdOrNo(inventoryIdOrNo: string, byInventoryNo: boolean = false, forGatePass: boolean = false) {
    return this.http.get(`${this.baseUrl}/${jobOrdersEndpoint }/find-by-inventory/${inventoryIdOrNo}?byInventoryNo=${byInventoryNo}&forGatePass=${forGatePass}`);
  }

  saveTally(tallyData: Tally) {
    return this.http.post(
      `${this.baseUrl}/${tallyEndPoint}`,
      tallyData
    );
  }

  getTruckTrailerInfoByJobOrderNo(jobOrderNo: string, inventoryType: InventoryType) {
    return this.http.get(`${this.baseUrl}/${jobOrderInventoryEndPoint}/trailer-truck?jobOrderNo=${jobOrderNo}&inventoryType=${inventoryType}`);
  }

  getJobOrderDetails(jobOrderIdOrNumber: any,includeInventories: boolean=false,includeVendors: boolean=false,includeContainers: boolean=false,includeTruckTrailerDetails: boolean=false) {
    return this.http.get(
      `${this.baseUrl}/${jobOrdersEndpoint}/job-order-details?jobOrderIdOrNumber=${jobOrderIdOrNumber}&includeItems=${includeInventories}&includeVendors=${includeVendors}&includeContainers=${includeContainers}&includeTruckTrailerDetails=${includeTruckTrailerDetails}`,
    );
  }

  getTallyDetailsById(examinationTallyId: String) {
    return this.http.get(`${this.baseUrl}/${tallyEndPoint}/${examinationTallyId}`);
  }

  listTalliesByJobOrder(jobOrderNo: string) {
    return this.http.get(`${this.baseUrl}/${tallyEndPoint}/list-by-job-order?jobOrderNo=${jobOrderNo}`);
  }

  listTally(tableListRequest: TableListRequest) {
    return this.http.post(`${this.baseUrl}/${tallyEndPoint}/list`, tableListRequest);
  }

  listHoldRecords(tableListRequest: TableListRequest) {
    return this.http.post(`${this.baseUrl}/${holdItemsEndPoint}/list`, tableListRequest);
  }

  listItemsForHold(tableListRequest: TableListRequest, cycle: string) {
    return this.http.post(`${this.baseUrl}/${holdItemsEndPoint}/list/items-for-hold?processCycle=${cycle}`, tableListRequest);
  }

  submitHoldRequest(holdRequest: any) {
    return this.http.post(`${this.baseUrl}/${holdItemsEndPoint}`, holdRequest);
  }

  submitHoldReleaseRequest(holdReleaseRequest: any) {
    return this.http.post(`${this.baseUrl}/${holdItemsEndPoint}/release`, holdReleaseRequest);
  }
}

