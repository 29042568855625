{
  "formName": "Create New Tariff",
  "formRows": [
    {
      "formGroups": [
        {
          "style": {
            "gridColumn": "span 2"
          },
          "control": {
            "label": "fromTime",
            "displayLabel": "From",
            "disabled": false,
            "placeHolder": "Enter From Time",
            "fieldInfo": {
              "dataType": "datetime-local"
            },
            "validators": [
              {
                "type": "required",
                "message": "From Time is required"
              }
            ]
          }
        },
        {
          "style": {
            "gridColumn": "span 2"
          },
          "control": {
            "label": "toTime",
            "displayLabel": "Valid Till",
            "disabled": false,
            "placeHolder": "Enter Valid Till",
            "fieldInfo": {
              "dataType": "datetime-local"
            },
            "validators": [
              {
                "type": "required",
                "message": "Valid Till is required"
              }
            ]
          }
        }
      ]
    },
    {
      "formGroups": [
        {
          "style": {
            "gridColumn": "span 2"
          },
          "control": {
            "label": "priority",
            "displayLabel": "Priority",
            "disabled": false,
            "defaultValue": 1,
            "placeHolder": "Enter Priority",
            "fieldInfo": {
              "dataType": "number"
            },
            "validators": [
              {
                "type": "required",
                "message": "Priority is required"
              }
            ]
          }
        }
      ]
    },
    {
      "formGroups": [
        {
          "style": {
            "gridColumn": "span 4"
          },
          "control": {
            "label": "description",
            "displayLabel": "Description",
            "placeHolder": "Enter Description",
            "disabled": false,
            "fieldInfo": {
              "dataType": "textarea"
            }
          }
        }
      ]
    }
  ]
}
