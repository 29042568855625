<div class="tabs">
  @for (ruleSet of ruleSets; track $index) {
    <div class="tab" [class.active_tab]="selectedRuleSetIndex === $index" (click)="selectedRuleSetIndex = $index"
         @tabAnimation>
      <div class="description">
        <span class="tab_description" [class.active_tab]="selectedRuleSetIndex === $index">{{ ruleSet.description }}</span>
        <div class="close-tab" (click)="deleteRuleSet($index, $event)">
          <app-svg-icon [path]="'assets/icons/delete_icon.svg'"></app-svg-icon>
        </div>
      </div>
    </div>
    @if($index !== ruleSets.length - 1) {
    <div class="add-tab">
      <app-svg-icon [path]="'assets/icons/plus_icon.svg'"></app-svg-icon>
    </div>
    }
  }
  <div class="add_new_tab_btn" (click)="addNewRuleSet()">
    New Rule Set
  </div>
</div>


<div class="page_container">
  @for (ruleSet of ruleSets; track $index) {
    <div class="rules-matrix" *ngIf="selectedRuleSetIndex === $index" (mouseenter)="showAddRowColBtns()"
         (mouseleave)="hideAddRowColBtns()">

      @if (descriptionEditable) {
        <div class="description_text_area">
          <textarea #descriptionTextArea placeholder="Enter Description" [value]="ruleSet.description"
                    (focusout)="hideDescriptionArea(ruleSet)" (keydown.enter)="hideDescriptionArea(ruleSet)"></textarea>
        </div>
      } @else {
        <div class="description_text" (click)="showDescriptionTextArea()">{{ ruleSet.description }}</div>
      }
      <div class="table_with_button">
        <div class="table-container">
          <table class="matrix-table">
            <thead>
            <tr>
              <th class="common_header">Rules</th>

              <!-- Column headers with rules -->
              <th class="col-header" *ngFor="let col of ruleSet.cols; let colIndex = index" @blinkAnimation>
                <div class="column_header_letter" [ngClass]="{
                  'highlighted': highlightHeaders.colIndex === colIndex,
                }"
                     #ColumnHeaderLetter
                >
                  <div class="letter">
                    {{ getColumnLetter(colIndex) }}
                  </div>
                  <div
                    class="delete_btn"
                    (mouseenter)="selectAllCols(colIndex)"
                    (mouseleave)="unselectAllCols(colIndex)"
                    (click)="deleteColumns(ruleSet,colIndex)"
                  >
                    <app-svg-icon [path]="'assets/icons/delete_icon.svg'"></app-svg-icon>
                  </div>
                </div>
                <div class="header-content">
                  <div class="header_btns">
                    <div class="add_conditions_button"
                         (click)="addConditionsForSelectedRowOrColumn($index,colIndex,'column')">+ Add Rule
                    </div>

                  </div>
                  <div class="rules-container" *ngIf="col.rules.length > 0">
                    <!--                      <div class="rule-pill" *ngFor="let rule of row.rules">-->
                    <div *ngFor="let rule of col.rules.slice(0, 6); let i = index" class="rule-pill" (click)="addConditionsForSelectedRowOrColumn($index,colIndex,'column'
                    )">
                      {{ getDisplayTextForDeterminant(rule?.determinant) }}
                      {{ getDisplayTextForOperator(rule?.determinant, rule?.operator) }}
                      {{ getValuesToPrint(rule) }}<span *ngIf="rule.values.length > 3">...</span>
                    </div>
                    <div
                      class="rule_count"
                      [appTooltip]="col.rules.slice(6)"
                      [keyToPick]="'determinant'"
                      position="bottom"
                      *ngIf="col.rules?.length > 6"
                    >
                      + {{ col.rules?.length - 6 }}
                    </div>
                  </div>
                </div>
              </th>
            </tr>
            </thead>

            <tbody>
            <tr *ngFor="let row of ruleSet.rows; let rowIndex = index">
              <!-- Row headers with rules -->
              <td class="row-header" @blinkAnimation>

                <div class="header-content_row">
                  <div class="row_header_letter" [ngClass]="{
                  'highlighted': highlightHeaders.rowIndex === rowIndex,
                    }"
                  >
                    <div class="letter">
                      {{ rowIndex + 1 }}
                    </div>
                    <div (click)="deleteRows(ruleSet,rowIndex,$event)" class="delete_btn"
                         (mouseenter)="selectAllRows(rowIndex)" (mouseleave)="unselectAllRows(rowIndex)">
                      <app-svg-icon [path]="'assets/icons/delete_icon.svg'"></app-svg-icon>
                    </div>
                  </div>

                  <div class="header_btn_rules_container">
                    <div class="header_btns">
                      <div class="add_conditions_button"
                           (click)="addConditionsForSelectedRowOrColumn($index,rowIndex,'row')">+ Add Rule
                      </div>
                    </div>
                    <div class="rules-container" *ngIf="row.rules.length > 0"
                         (click)="addConditionsForSelectedRowOrColumn($index,rowIndex,'row')">
                      <div *ngFor="let rule of row.rules.slice(0, 6); let i = index" class="rule-pill">
                        {{ getDisplayTextForDeterminant(rule?.determinant) }}
                        {{ getDisplayTextForOperator(rule?.determinant, rule?.operator) }}
                        {{ getValuesToPrint(rule) }}<span *ngIf="rule.values.length > 3">...</span>
                      </div>
                      <div
                        class="rule_count"
                        [appTooltip]="row.rules.slice(6)"
                        [keyToPick]="'determinant'"
                        position="bottom"
                        *ngIf="row.rules?.length > 6"
                      >
                        + {{ row.rules?.length - 6 }}
                      </div>
                    </div>
                  </div>
                </div>
              </td>

              <!-- Tariff cells -->
              <td class="tariff-cell" *ngFor="let col of ruleSet.cols; let colIndex = index" @blinkAnimation
                  (mouseover)="highLightRowAndCol(colIndex,rowIndex)" (mouseleave)="highLightRowAndCol(-1,-1)">
                <div class="tariff-content">
                  <div class="tariff-expression">
                    <div class="label">Min Tariff</div>
                    <input type="text" [(ngModel)]="ruleSet.values[rowIndex][colIndex].tariffExpression"
                           placeholder="Enter Min Tariff">
                  </div>
                  <div class="tariff-expression">
                    <div class="label">Tariff</div>
                    <input type="text" [(ngModel)]="ruleSet.values[rowIndex][colIndex].minTariffExpression"
                           placeholder="Enter Tariff">
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

        @if (addButtonVisible) {
          <div class="add_col_btn" (click)="addColumns(ruleSet,$event)">
            <app-svg-icon [path]="'assets/icons/plus_icon.svg'"></app-svg-icon>
          </div>
        }
      </div>
      @if (addButtonVisible) {
        <div class="add_row_btn" (click)="addRows(ruleSet,$event)">
          <app-svg-icon [path]="'assets/icons/plus_icon.svg'"></app-svg-icon>
        </div>
      }

    </div>
  }


</div>
