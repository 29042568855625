import { Component, Input } from "@angular/core";
import { ColumnDto, StyleConfig, StyleConfigValue, TableDataType } from "../../../models/table-meta.interface";
import { DatePipe, NgClass, NgForOf, NgIf, NgStyle } from "@angular/common";
import { SvgIconComponent } from "../../svg-icon/svg-icon.component";
import { TooltipDirective } from "../../../directives/tooltip.directive";

@Component({
  selector: 'app-tos-table-td',
  standalone: true,
  imports: [
    DatePipe,
    NgClass,
    SvgIconComponent,
    NgIf,
    TooltipDirective,
    NgForOf,
    NgStyle
  ],
  templateUrl: './tos-table-td.component.html',
  styleUrl: './tos-table-td.component.scss'
})
export class TosTableTdComponent {
  styleConfigForField: StyleConfigValue[] = []

  @Input() record: any;
  @Input() column!: ColumnDto;
  @Input() styleConfig!: StyleConfig

  styleConfigPresentForColumn(fieldName: string): boolean {
    if(!fieldName) return false;

    const styleConfigPresent = this.styleConfig.columns.some((column) => column.fieldName === fieldName);
    if(!styleConfigPresent) return false;

    this.styleConfigForField = this.styleConfig.columns.find((column) => column.fieldName === fieldName)!.styleConfigs;
    return true
  }

  protected readonly TableDataType = TableDataType;
}
