import { Component, EventEmitter, Input, Output, ViewContainerRef } from "@angular/core";
import { DatePipe, NgClass, NgForOf, NgIf } from "@angular/common";
import { HighlightPipe } from "../../../../pipes/highlight.pipe";
import { NoDataComponent } from "../../../no-data/no-data.component";
import { NoDataFoundComponent } from "../../../no-data-found/no-data-found.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SvgIconComponent } from "../../../svg-icon/svg-icon.component";
import { TableMenuComponent } from "../../../table-menu/table-menu.component";
import { User } from "../../../../models/user.interface";
import { StatsData, StatsResponse } from "../../../../models/stats-response.interface";
import { PaginatedListDataResponse } from "../../../../models/paginated-list-data-response";
import { TableListRequest } from "../../../../models/table-list-request.interface";
import { FinanceService } from "../../../../services/finance.service";
import { ToasterService } from "../../../../services/toaster.service";
import { ActivatedRoute, Router } from "@angular/router";
import { LoadingService } from "../../../../services/loading.service";
import { ModalComponent } from "../../../modal/modal.component";
import { AdvancedFilterComponent } from "../../../advanced-filter/advanced-filter.component";
import { forkJoin } from "rxjs";
import { Charge } from "../../../../models/charge.interface";
import { ConfirmDialogComponent } from "../../../confirm-dialog/confirm-dialog.component";
import { RightPanelComponent } from "../../../right-panel/right-panel.component";
import { log } from "@angular-devkit/build-angular/src/builders/ssr-dev-server";
import { AutocompleteDropdownComponent } from "../../../autocomplete-dropdown/autocomplete-dropdown.component";
import { StaticTableComponent } from "../../../static-table/static-table.component";
import ChargeGroupMeta from "./config/charge-group-meta.json"
import { TableMeta } from "../../../../models/table-meta.interface";
import { ChargeGroup } from "../../../../models/charge-group";

@Component({
  selector: 'app-map-charge-group',
  standalone: true,
  imports: [
    DatePipe,
    HighlightPipe,
    NgForOf,
    NgIf,
    NoDataComponent,
    NoDataFoundComponent,
    ReactiveFormsModule,
    SvgIconComponent,
    TableMenuComponent,
    NgClass,
    FormsModule,
    AutocompleteDropdownComponent,
    StaticTableComponent
  ],
  templateUrl: './map-charge-group.component.html',
  styleUrl: './map-charge-group.component.scss'
})
export class MapChargeGroupComponent {
  tableLoaded: boolean = false;
  totalTablePages = 0;
  currentTablePage = 1;
  currentPage: number = 0;
  rowsPerPage: number = 10;
  rowsPerPageOptions: number[] = [];
  focusSearch: boolean = false;
  searchTerm: string = "";
  previousTerm: string = "";
  selectedItems: User[] = [];
  // deSelectedItems: User[] = [];
  sortColumn: string = "modifiedAt";
  sortDirection: "asc" | "desc" = "desc";
  allTableRecordsSelected: boolean = false;
  tableStats: StatsData = {
    total: 0,
    active: 0,
    inactive: 0
  };
  tableListData: PaginatedListDataResponse = {
    totalCount: 0,
    page: 0,
    limit: 0,
    records: []
  };
  tableListRequest: TableListRequest = {
    selectFields: [
      "id",
      "name",
      "description",
      "invoiceHeaderTitle",
      "operationalProcess",
      "active",
      "createdBy.name as createdBy",
      "modifiedBy.name as modifiedBy",
      "createdAt",
      "modifiedAt"
    ],
    orderBy: {
      [this.sortColumn]: this.sortDirection
    },
    limit: this.rowsPerPage,
    page: this.currentPage
  };

  // table menu
  openMenuIndex: number | null = null;
  menuOptions = [
    { label: "Edit", value: "edit" },
    { label: "Mark as active", value: "active" },
    { label: "Mark as inactive", value: "inactive" },
    { label: "View", value: "view" },
    { label: "Add Charge", value: "addCharge" }
  ];

  // filter
  filterFields = [
    {
      displayName: "Name",
      key: "name"
    },
    {
      displayName: "Operational Process",
      key: "operationalProcess"
    }
  ];
  isFilterApplied: boolean = false;
  searchAndAddText: string = "";
  disableAddBtn: boolean = true;
  tableMeta!: TableMeta
  selectedChargeGroup!: ChargeGroup;
  alreadyAddedChargeGroups: Set<string> = new Set<string>(); //used by autocomplete dropdown for tracking the already added charge groups
  deletedRecords: Set<string> = new Set<string>();

  @Input() chargeId!: string;

  @Output() selectedChargeGroupEmitter = new EventEmitter<any>();


  constructor(
    private tariffService: FinanceService,
    private toasterService: ToasterService,
    private viewContainerRef: ViewContainerRef,
    private router: Router,
    private route: ActivatedRoute,
    private loadingService: LoadingService
  ) {
  }

  ngOnInit() {
    this.tableLoaded = false;
    this.tableMeta = ChargeGroupMeta
    this.fetchAllData();
  }

  // pagination helper methods

  calculateTotalPages() {
    if (this.tableListRequest.where) {
      this.totalTablePages = Math.ceil(
        this.tableListData.totalCount / this.rowsPerPage
      );
    } else {
      this.totalTablePages = Math.ceil(
        this.tableStats.total / this.rowsPerPage
      );
    }
  }

  goToPage(page: number) {
    this.currentTablePage = page;
    this.tableListRequest.page = this.currentTablePage - 1;
    this.getTableList();
  }

  nextPage() {
    if (this.currentTablePage < this.totalTablePages) {
      this.currentTablePage++;
      this.tableListRequest.page = this.currentTablePage - 1;
      this.getTableList();
    }
  }

  previousPage() {
    if (this.currentTablePage > 1) {
      this.currentTablePage--;
      this.tableListRequest.page = this.currentTablePage - 1;
      this.getTableList();
    }
  }

  sortData(column: string) {
    if (this.sortColumn === column) {
      this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
    } else {
      this.sortColumn = column;
      this.sortDirection = "asc";
    }
    this.tableListRequest.orderBy = {
      [this.sortColumn]: this.sortDirection
    };
    this.refreshTableList();
  }

  // single / multi selection helper methods
  isSelected(item: User): boolean {
    if (this.allTableRecordsSelected) {
      return true;
    } else {
      const recordIdx = this.selectedItems.findIndex((x) => x.id === item.id);
      if (recordIdx !== -1) {
        return true;
      } else {
        return false;
      }
    }
  }

  isAllRecordsOfCurrentPageSelected(): boolean {
    return (
      this.allTableRecordsSelected ||
      (this.tableListData.records?.length > 0 &&
        this.tableListData.records?.every((item: User) =>
          this.isSelected(item)
        ))
    );
  }

  selectAllTableRecords() {
    this.allTableRecordsSelected = true;
    this.selectedItems = [];
  }

  clearSelection() {
    // console.warn('clearing both selectedItems & selectedItems !');
    this.selectedItems = [];
    // this.deSelectedItems = [];
  }

/*  toggleAllRecordsOfCurrentPageSelections(isChecked: boolean) {
    if (isChecked) {
      this.tableListData.records?.forEach((item) => {
        if (!this.isSelected(item)) {
          this.selectedItems.push(item);
        }
      });
    } else {
      this.tableListData.records?.forEach((item) => {
        this.selectedItems = this.selectedItems.filter((i) => i.id !== item.id);
      });
    }

    this.checkBoxToggleEmitter.emit(this.selectedItems);
  }

  toggleSelection(item: User) {
    if (!this.isSelected(item)) {
      this.selectedItems.push(item);
    } else {
      this.selectedItems = this.selectedItems.filter((i) => i.id !== item.id);
    }

    this.checkBoxToggleEmitter.emit(this.selectedItems);
  }*/

  // fetch table data and stats
  fetchAllData(): void {
    this.loadingService.show();
    this.tableListRequest.where = {
      filterConditions: [{
        name: "charges",
        alias: "charges_in",
        operator: "IN",
        value: [`${this.chargeId}`]
      }],
        expression: "charges_in"
    }

    this.tariffService.getChargeGroupList(this.tableListRequest).subscribe({
      next: (tableList: any) => {
        this.loadingService.hide();
        this.tableListData = tableList.data;
        this.alreadyAddedChargeGroups =  new Set(tableList.data.records.map((chargeGroup: any) => chargeGroup.id));
        // this.calculateTotalPages();
        this.tableLoaded = true;

      },
      error: (err) => {
        console.error(err);
        this.toasterService.error(err.error.message);
        this.loadingService.hide();
      }
    });
  }

  handleTableSearch(event: KeyboardEvent) {
    const searchTerm = this.searchTerm.trim();
    if (searchTerm.length > 0) {
      this.tableListRequest.where = {
        filterConditions: [
          {
            name: "charges",
            alias: "charges_in",
            operator: "IN",
            value: [`${this.chargeId}`]
          },
          {
            name: "name",
            alias: "A",
            operator: "CONTAINS",
            value: [searchTerm]
          },
        ],
        expression: "A and charges_in"
      };
      // Search when Enter is pressed
      if (event.key === "Enter") {
        this.searchTableList();
      }
    }
    // Call reset only when the search bar is cleared (avoid on multiple backspaces)
    if (searchTerm.length === 0 && this.previousTerm.length !== 0) {
      delete this.tableListRequest.where;
      this.tableListRequest.where = {
        filterConditions: [{
          name: "charges",
          alias: "charges_in",
          operator: "IN",
          value: [`${this.chargeId}`]
        }],
        expression: "charges_in"
      }
      this.searchTableList();
    }
    this.previousTerm = searchTerm;
  }

  tableSearch() {
    const searchTerm = this.searchTerm.trim();
    if (searchTerm.length > 0) {
      this.tableListRequest.where = {
        filterConditions: [
          {
            name: "name",
            alias: "A",
            operator: "CONTAINS",
            value: [this.searchTerm]
          }
        ],
        expression: "A"
      };
      this.searchTableList();
    }
  }

  handleMenuToggle(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? null : index;
  }

  handleOptionSelected(charge: Charge, actionName: string) {
    console.log(charge);
    const chargeId = charge.id;
    console.log("charge id is " + chargeId);
    if (actionName === "edit") {
      this.router.navigate([`edit/${chargeId}`], { relativeTo: this.route });
    } else if (actionName === "view") {
      this.router.navigate([`view/${chargeId}`], { relativeTo: this.route });
    } else if(actionName === 'addCharge'){
      this.loadAddChargeGroupRightPanel()
    } else {
      // need to confirm before executing the action
      let actionTitle = "Confirmation";
      let message = "Are you sure ?";
      let successMsg = "Done !";
      let flag = false;
      switch (actionName) {
        case "active":
        case "inactive":
          if (actionName === "active") {
            flag = true;
          }
          actionTitle = `${flag ? "Activate" : "Inactivate"} Charge`;
          message = `Are you sure to ${flag ? "activate" : "inactivate"} ${charge.name
          } ?`;
          successMsg = `Successfully ${flag ? "activated" : "inactived"} ${charge.name
          } !`;
          break;
        default:
          console.error("unknown actionName");
          break;
      }
      const modalRef = this.viewContainerRef.createComponent(ModalComponent);
      modalRef.instance.title = actionTitle;
      const confirmDialogRef = modalRef.instance.loadComponent(
        ConfirmDialogComponent
      );
      confirmDialogRef.instance.message = message;
      confirmDialogRef.instance.confirm.subscribe(() => {
        this.menuAction(actionName, successMsg, flag, chargeId!);
        modalRef.destroy();
      });
      confirmDialogRef.instance.cancel.subscribe(() => {
        modalRef.destroy();
      });
      modalRef.instance.close.subscribe(() => modalRef.destroy());
    }
  }

  menuAction(
    actionName: string,
    successMsg: string,
    flag: boolean,
    chargeId: string
  ) {
    switch (actionName) {
      case "active":
      case "inactive":
        this.tariffService.setChargeGroupActiveStatus(flag, [chargeId]).subscribe({
          next: () => {
            this.toasterService.success(successMsg);
            this.refreshTableListWithStats();
          },
          error: (err) => {
            console.error(err);
            this.toasterService.error(err.error.errorDesc || "Unknown Error !");
          }
        });
        break;
      default:
        console.error("unknown actionName");
        break;
    }
  }

  bulkActionConfirmation(actionName: string) {
    let actionTitle = "Confirmation";
    let message = "Are you sure ?";
    let successMsg = "Done !";
    const selectedUserCount = this.selectedItems.length;
    switch (actionName) {
      case "setActive":
        actionTitle = `Activate ${this.selectedItems.length > 1 ? "Charges" : "Charge"
        } Confirmation`;
        message = `Are you sure to activate ${this.selectedItems.length > 1
          ? ` the selected ${selectedUserCount} Charge?`
          : `${this.selectedItems[0].name}'s account?`
        }`;
        successMsg = `Successfully activated ${this.selectedItems.length > 1
          ? `${selectedUserCount} Charge Groups`
          : `${this.selectedItems[0].name}`
        }`;
        break;
      case "setInActive":
        actionTitle = `Inactivate ${this.selectedItems.length > 1 ? "Charge" : "Charges"
        } Confirmation`;
        message = `Are you sure to inactivate ${this.selectedItems.length > 1
          ? ` the selected ${selectedUserCount} charges?`
          : `${this.selectedItems[0].name}'s account?`
        }`;
        successMsg = `Successfully inactivated ${this.selectedItems.length > 1
          ? `${selectedUserCount} charges`
          : `${this.selectedItems[0].name}`
        }`;
        break;
      default:
        console.error("unknown actionName");
        break;
    }
    const modalRef = this.viewContainerRef.createComponent(ModalComponent);
    modalRef.instance.title = actionTitle;
    const confirmDialogRef = modalRef.instance.loadComponent(
      ConfirmDialogComponent
    );
    confirmDialogRef.instance.message = message;
    confirmDialogRef.instance.confirm.subscribe(() => {
      this.bulkAction(actionName, successMsg);
      modalRef.destroy();
    });
    confirmDialogRef.instance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.instance.close.subscribe(() => modalRef.destroy());
  }

  bulkAction(actionName: string, successMsg: string) {
    const selectedUserIds = this.selectedItems.map((user: User) => {
      return user.id!;
    });
    switch (actionName) {
      case "setActive":
        this.tariffService.setChargeGroupActiveStatus(true, selectedUserIds).subscribe({
          next: () => {
            this.clearSelection();
            this.toasterService.success(successMsg);

            this.refreshTableListWithStats();
          },
          error: (err) => {
            console.error(err);
            this.toasterService.error(err.error.errorDesc || "Unknown Error !");
          }
        });
        break;
      case "setInActive":
        this.tariffService.setChargeGroupActiveStatus(false, selectedUserIds).subscribe({
          next: () => {
            this.clearSelection();
            this.toasterService.success(successMsg);
            this.refreshTableListWithStats();
          },
          error: (err) => {
            console.error(err);
            this.toasterService.error(err.error.errorDesc || "Unknown Error !");
          }
        });
        break;
      default:
        console.error("unknown actionName");
        break;
    }
  }

  getTableStats() {
    this.tariffService.getChargeGroupStats().subscribe({
      next: (res: StatsResponse) => {
        this.tableStats = res.data;
      },
      error: (err) => {
        console.error(err);
      }
    });
  }

  getTableList() {
    this.tariffService.getChargeGroupList(this.tableListRequest).subscribe({
      next: (res: any) => {
        this.tableListData = res.data;
        // this.patchTableListWithSerialNumbers();
        this.calculateTotalPages();
        // this.generateRowsPerPageOptions(res.data.totalCount);
      },
      error: (err) => {
        console.error(err);
      }
    });
  }

  searchTableList() {
    this.tariffService.getChargeGroupList(this.tableListRequest).subscribe({
      next: (res: any) => {
        this.currentTablePage = 1; // Reset to first page
        this.currentPage = 0;
        this.tableListData = res.data;
        // this.patchTableListWithSerialNumbers();
        this.calculateTotalPages();
        // this.generateRowsPerPageOptions(res.data.totalCount);
      },
      error: (err) => {
        console.error(err);
      }
    });
  }

  clearSearchQuery() {
    this.searchTerm = "";
  }

  refreshTableList() {
    this.getTableList();
  }

  refreshTableListWithStats() {
    this.getTableList();
    this.getTableStats();
  }

  addNewRecord() {
    this.router.navigate(["add-new"], { relativeTo: this.route });
  }

  getDisplayedPages() {
    const total = this.totalTablePages;
    const current = this.currentTablePage;
    const pages: Array<number | string> = [];
    const windowSize = 3; // Numbers to show on each side

    // If total pages is less than or equal to windowSize * 2, show all pages
    if (total <= windowSize * 2) {
      for (let i = 1; i <= total; i++) {
        pages.push(i);
      }
      return pages;
    }

    // Calculate start of left window based on current page
    let leftStart = Math.max(1, current - windowSize + 1);

    // Calculate start of right window
    let rightStart = Math.max(total - windowSize + 1, leftStart + windowSize + 1);

    // Add left window numbers
    for (let i = leftStart; i < leftStart + windowSize && i <= total; i++) {
      pages.push(i);
    }

    // Add ellipsis if there's a gap
    if (rightStart - leftStart > windowSize) {
      pages.push("...");
    }

    // Add right window numbers
    for (let i = rightStart; i <= total; i++) {
      pages.push(i);
    }

    return pages;
  }

  updateRowsPerPage(newRowsPerPage: any) {
    this.rowsPerPage = newRowsPerPage;
    this.tableListRequest.limit = newRowsPerPage;
    this.tableListRequest.page = 0;
    this.currentTablePage = 1; // Reset to first page
    this.calculateTotalPages();
    this.getTableList();
  }

  private loadAddChargeGroupRightPanel() {
    const rightPanelComponentRef = this.viewContainerRef.createComponent(RightPanelComponent)
    rightPanelComponentRef.instance.title = "Add to Charge Group"
    rightPanelComponentRef.instance.loadComponent(NoDataComponent)

    rightPanelComponentRef.instance.close.subscribe(() => {rightPanelComponentRef.destroy()})
  }

  handleSuggestionSelected(selectedChargeGroup: any) {
    this.selectedChargeGroup = selectedChargeGroup
    this.disableAddBtn = false;
  }

  addNewChargesGroupsToCharge() {
    this.tableListData.records.push(this.selectedChargeGroup);
    this.alreadyAddedChargeGroups.add(this.selectedChargeGroup?.id);
    this.searchAndAddText = "";
    this.disableAddBtn = true;

    this.deletedRecords.delete(this.selectedChargeGroup.id);

    this.selectedChargeGroupEmitter.emit({
      chargeGroupsAdded: Array.from(this.alreadyAddedChargeGroups),
      chargeGroupsRemoved: Array.from(this.deletedRecords)
    });
  }

  handleDataDeletion(data: any) {
    this.alreadyAddedChargeGroups.delete(data?.id);
    this.deletedRecords.add(data?.id);

    this.selectedChargeGroupEmitter.emit({
      chargeGroupsAdded: Array.from(this.alreadyAddedChargeGroups),
      chargeGroupsRemoved: Array.from(this.deletedRecords)
    });
  }

  protected readonly Array = Array;

  handleBulkDelete() {
    this.tableListData.records = this.tableListData.records.filter((record: any) => !this.selectedItems.includes(record));

    this.selectedItems.forEach((item)=>{
      this.alreadyAddedChargeGroups.delete(item.id);
      this.deletedRecords.add(item.id);
    })

    this.selectedItems = []; //clear the selected item post deletion

    this.selectedChargeGroupEmitter.emit({
      chargeGroupsAdded: Array.from(this.alreadyAddedChargeGroups),
      chargeGroupsRemoved: Array.from(this.deletedRecords)
    });
  }
}
