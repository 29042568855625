import { Component, ViewContainerRef } from "@angular/core";
import { TableBoxComponent } from "../../../table-box/table-box.component";
import { ApiService } from "../../../../services/api.service";
import { CommonModule } from "@angular/common";
import { SvgIconComponent } from "../../../svg-icon/svg-icon.component";
import { AdvancedFilterComponent } from "../../../advanced-filter/advanced-filter.component";
import { ModalComponent } from "../../../modal/modal.component";
import { DropdownComponent } from "../../../dropdown/dropdown.component";
import { TooltipDirective } from "../../../../directives/tooltip.directive";
import { TableMenuComponent } from "../../../table-menu/table-menu.component";
import { FormsModule } from "@angular/forms";
import { User } from "../../../../models/user.interface";
import { ConfirmDialogComponent } from "../../../confirm-dialog/confirm-dialog.component";
import { ToasterService } from "../../../../services/toaster.service";
import {
  UserStatsData,
  UserStatsResponse
} from "../../../../models/user-stats-response.interface";
import { LoadingService } from "../../../../services/loading.service";
import { NoDataFoundComponent } from "../../../no-data-found/no-data-found.component";
import { NoDataComponent } from "../../../no-data/no-data.component";
import { HighlightPipe } from "../../../../pipes/highlight.pipe";
import { PaginatedListDataResponse } from "../../../../models/paginated-list-data-response";
import { TableListRequest } from "../../../../models/table-list-request.interface";
import { forkJoin } from "rxjs";
import { ResizableModule, ResizeEvent } from "angular-resizable-element";
import { RightPanelComponent } from "../../../right-panel/right-panel.component";
import { FileUploadDialogComponent } from "../../igm-desk/file-upload-dialog/file-upload-dialog.component";
import { LoginHistoryComponent } from "../../../login-history/login-history.component";
import { TosTableComponent } from "../../../tos-table/tos-table.component";
import { TableMeta } from "../../../../models/table-meta.interface";
import { StatsData, StatsResponse } from "../../../../models/stats-response.interface";
import { ActiveHoldsComponent } from "../active-holds/active-holds.component";
import { ReleasedHoldsComponent } from "../released-holds/released-holds.component";

@Component({
  selector: 'app-hold',
  standalone: true,
  imports: [ActiveHoldsComponent, ReleasedHoldsComponent],
  templateUrl: './hold.component.html',
  styleUrl: './hold.component.scss'
})
export class HoldComponent {

  selectedTab: string = 'active';

  constructor() {}

  selectTab(tabName: string) {
    this.selectedTab = tabName;
  }
}
