import { Component, Input } from "@angular/core";
import { Menu } from "../../../models/sidebar-menu.interface";
import { Router, RouterLink } from "@angular/router";

@Component({
  selector: 'app-sidebar-new-expanded',
  standalone: true,
  imports: [
    RouterLink
  ],
  templateUrl: './sidebar-new-expanded.component.html',
  styleUrl: './sidebar-new-expanded.component.scss'
})
export class SidebarNewExpandedComponent {
  constructor(private router: Router) {
  }

  @Input() selectedMenu!: Menu;
  @Input() selectingMenu!: Menu;
  @Input() childMenu!: Menu;

  ngOnInit(){
  }

  isActive(id: string) {
    return this.childMenu?.id === id;
  }

}
