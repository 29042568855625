import { Component, ElementRef, OnInit, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, AbstractControl, FormControl } from "@angular/forms";
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ApiService } from '../../../../services/api.service';
import { ToasterService } from '../../../../services/toaster.service';
import { LoadingService } from '../../../../services/loading.service';
import { Location, Person, ContactInformation } from '../../../../models/location-master.interface';
import { SharedModule } from '../../../../modules/shared/shared.module';
import { SvgIconComponent } from '../../../svg-icon/svg-icon.component';
import { BackButtonComponent } from '../../../back-button/back-button.component';
import { MatSelectModule } from '@angular/material/select';
import { atLeastOneCheckboxCheckedValidator } from "../../../../validators/atleastOneCheckboxCheked";

@Component({
  selector: 'app-manage-location',
  standalone: true,
  imports: [SharedModule, SvgIconComponent,BackButtonComponent,MatSelectModule],
  templateUrl: './manage-location.component.html',
  styleUrls: ['./manage-location.component.scss']
})
export class ManageLocationComponent implements OnInit, OnDestroy {
  readOnlyMode = true;
  masterEditingEnabled = false;
  masterForm: FormGroup;
  masterId: string = '';
  locationTypes = [
    { name: 'Port', value: 'PORT', checked: false },
    { name: 'Inland Container Depot', value: 'INLAND_CONTAINER_DEPOT', checked: false },
    { name: 'CFS', value: 'CFS', checked: false },
    { name: 'Empty Depot', value: 'EMPTY_DEPOT', checked: false },
    { name: 'Berthing Point', value: 'BERTHING_POINT', checked: false },
    { name: 'Port Terminal', value: 'PORT_TERMINAL', checked: false },
    { name: 'Repairing Yard', value: 'REPAIRING_YARD', checked: false },
  ];

  @ViewChildren('formField') formFields!: QueryList<ElementRef>;

  private destroy$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private toasterService: ToasterService,
    private loadingService: LoadingService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.masterForm = this.initForm();
  }

  ngOnInit(): void {
    this.masterId = this.route.snapshot.paramMap.get('locationId') || '';
    this.setupComponentMode();
    if (this.masterId) {
      this.loadLocationData();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initForm(): FormGroup {
    return this.fb.group({
      panNumber: ['',Validators.required],
      name: ['', Validators.required],
      locationType: this.fb.array(
        this.locationTypes.map((lt) => new FormControl(lt.checked)),
        atLeastOneCheckboxCheckedValidator()
      ),
      contactInformation: this.createContactFormGroup(),
      person: this.fb.array([this.createPersonFormGroup()]),
      distanceFromCFS: ['', [Validators.min(0)]],
    });
  }

  private setupComponentMode(): void {
    if (this.masterId) {
      const path = this.route.snapshot.url[0].path;
      this.masterEditingEnabled = path === 'edit';
      this.readOnlyMode = !this.masterEditingEnabled;
      if (this.readOnlyMode) {
        this.masterForm.disable();
      }
    } else {
      this.readOnlyMode = false;
      this.masterEditingEnabled = false;
    }
  }

  onCheckboxChange(e: any, i: number) {
    console.log('onCheckboxChange');
    this.locationTypeArray.controls[i].setValue(e.target.checked);
  }

  get locationTypeArray(): FormArray {
    return this.masterForm.get('locationType') as FormArray;
  }

  private loadLocationData(): void {
    this.loadingService.show();
    this.api.getLocation(this.masterId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: any) => {
          const locationData: Location = res.data;
          this.patchFormWithLocationData(locationData);
          this.loadingService.hide();
        },
        error: (err) => {
          console.error('Error loading location data:', err);
          this.toasterService.error('Failed to load location data');
          this.loadingService.hide();
        },
      });
  }

  private patchFormWithLocationData(locationData: Location): void {
    this.masterForm.patchValue(locationData);
    // this.patchArrayControl('contactInformation', locationData.contactInformation);
    // this.patchArrayControl('person', locationData.person);
  }

  private patchArrayControl(controlName: string, data: any[]): void {
    const control = this.masterForm.get(controlName) as FormArray;
    control.clear();
    data.forEach(item => control.push(this.createFormGroup(controlName, item)));
  }

  private createFormGroup(type: string, data?: any): FormGroup {
    return type === 'contactInformation' ? this.createContactFormGroup(data) : this.createPersonFormGroup(data);
  }

  createContactFormGroup(data?: ContactInformation): FormGroup {
    return this.fb.group({
      address: [data?.address || ''],
      billingAddress: [data?.billingAddress || ''],
      mobileNumber1: [data?.mobileNumber1 || ''],
      mobileNumber2: [data?.mobileNumber2 || ''],
      faxNumber: [data?.faxNumber || ''],
      email: [data?.email || ''],
      pinCode: [data?.pinCode || ''],
      cityId: [data?.cityId || ''],
      stateId: [data?.stateId || ''],
      countryId: [data?.countryId || ''],
      communicationPreference: [data?.communicationPreference || 'mobile'],
    });
  }

  createPersonFormGroup(data?: Person): FormGroup {
    return this.fb.group({
      name: [data?.name || ''],
      designation: [data?.designation || ''],
      email: [data?.contactInformation?.email || ''],
      mobileNumber1: [data?.contactInformation?.mobileNumber1 || ''],
      mobileNumber2: [data?.contactInformation?.mobileNumber2 || ''],
      communicationPreference: [data?.contactInformation?.communicationPreference || 'mobile'],
    });
  }

  get contactInformation(): FormArray {
    return this.masterForm.get('contactInformation') as FormArray;
  }

  get personInformation(): FormArray {
    return this.masterForm.get('person') as FormArray;
  }

  addContactInformation(): void {
    this.contactInformation.push(this.createContactFormGroup());
  }

  addPersonInformation(): void {
    this.personInformation.push(this.createPersonFormGroup());
  }

  removeContactInformation(index: number): void {
    this.contactInformation.removeAt(index);
  }

  removePersonInformation(index: number): void {
    this.personInformation.removeAt(index);
  }

  submitMasterForm(): void {
    if (this.masterForm.valid) {
      const formValue = this.masterForm.value;
      const masterData: Location = this.restructureFormData(formValue);

      this.loadingService.show();
      const action$ = this.masterEditingEnabled
        ? this.api.updateLocation(masterData, this.masterId)
        : this.api.addLocation(masterData);

      action$.pipe(takeUntil(this.destroy$)).subscribe({
        next: () => {
          const message = this.masterEditingEnabled
            ? `Location ${masterData.name} has been updated!`
            : `Location ${masterData.name} has been added!`;
          this.toasterService.success(message);
          this.loadingService.hide();
          this.goBack();
        },
        error: (err) => {
          this.toasterService.error(err.error.errorDesc || 'An error occurred');
          console.error('Error submitting form:', err);
          this.loadingService.hide();
        },
      });
    } else {
      this.markFormGroupTouched(this.masterForm);
      this.scrollToFirstInvalidControl();
    }
  }

  private restructureFormData(formValue: any): Location {
    const restructuredPersons: Person[] = formValue.person.map((personData: any) => ({
      name: personData.name,
      designation: personData.designation,
      contactInformation: {
        email: personData.email,
        mobileNumber1: personData.mobileNumber1,
        mobileNumber2: personData.mobileNumber2,
        communicationPreference: personData.communicationPreference
      } as ContactInformation
    }));
    const selectedLocationTypes = this.masterForm.value.locationType
      .map((checked: boolean, i: number) =>
        checked ? this.locationTypes[i].name : null
      )
      .filter((type: any) => type !== null);

    return {
      ...formValue,
      person: restructuredPersons,
      locationType: selectedLocationTypes
    };
  }

  private markFormGroupTouched(formGroup: FormGroup | FormArray): void {
    Object.values(formGroup.controls).forEach(control => {
      if (control instanceof FormGroup || control instanceof FormArray) {
        this.markFormGroupTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }

  scrollToFirstInvalidControl(): void {
    const firstInvalidControl = this.formFields.find((element) => {
      const nativeElement = element.nativeElement as HTMLElement;
      return nativeElement.classList.contains('ng-invalid');
    });

    if (firstInvalidControl) {
      firstInvalidControl.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      (firstInvalidControl.nativeElement as HTMLElement).focus();
    }
  }

  goBack(): void {
    this.router.navigateByUrl('/manage-masters/locations');
  }
}
