import { Routes } from "@angular/router";
import { PublicLayoutComponent } from "./layouts/public-layout/public-layout.component";
import { PrivateLayoutComponent } from "./layouts/private-layout/private-layout.component";
import { LoginComponent } from "./components/login/login.component";
import { ForgotPasswordComponent } from "./components/forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./components/reset-password/reset-password.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { PageNotFoundComponent } from "./components/page-not-found/page-not-found.component";
import { AuthGuard } from "./guards/auth.guard";
import { LoginGuard } from "./guards/login.guard";
import { ManageUsersComponent } from "./components/admin/manage-users/manage-users.component";
import { SupportComponent } from "./components/support/support.component";
import { SettingsComponent } from "./components/settings/settings.component";
import { UserProfileComponent } from "./components/user-profile/user-profile.component";
import { CustomerMasterComponent } from "./components/masters/customer-master/customer-master.component";
import {
  ManageCustomerComponent
} from "./components/masters/customer-master/manage-customer/manage-customer.component";
import { ManageCustomerRevampComponent } from "./components/masters/customer-master/manage-customer-revamp/manage-customer-revamp.component";
import { LocationMasterComponent } from "./components/masters/location-master/location-master.component";
import {
  ManageLocationComponent
} from "./components/masters/location-master/manage-location/manage-location.component";
import {
  CommodityCategoryMasterComponent
} from "./components/masters/commodity-category-master/commodity-category-master.component";
import {
  ManageCommodityCategoryComponent
} from "./components/masters/commodity-category-master/manage-commodity-category/manage-commodity-category.component";
import { CountyMasterComponent } from "./components/masters/county-master/county-master.component";
import {
  ManageCountryMasterComponent
} from "./components/masters/county-master/manage-country-master/manage-country-master.component";
import { BankMasterComponent } from "./components/masters/bank-master/bank-master.component";
import {
  ManageBankMasterComponent
} from "./components/masters/bank-master/manage-bank-master/manage-bank-master.component";
import { PackageMasterComponent } from "./components/masters/package-master/package-master.component";
import { ManagePackageComponent } from "./components/masters/package-master/manage-package/manage-package.component";
import { TrailerMasterComponent } from "./components/masters/trailer-master/trailer-master.component";
import {
  ManageTrailerMasterComponent
} from "./components/masters/trailer-master/manage-trailer-master/manage-trailer-master.component";
import { StateMasterComponent } from "./components/masters/state-master/state-master.component";
import {
  ManageStateMasterComponent
} from "./components/masters/state-master/manage-state-master/manage-state-master.component";
import { CityMasterComponent } from "./components/masters/city-master/city-master.component";
import { ManageCityComponent } from "./components/masters/city-master/manage-city/manage-city.component";
import { CommodityMasterComponent } from "./components/masters/commodity-master/commodity-master.component";
import {
  ManageCommodityComponent
} from "./components/masters/commodity-master/manage-commodity/manage-commodity.component";
import {
  VesselPortTrainDetailsMasterComponent
} from "./components/masters/vessel-port-train-details-master/vessel-port-train-details-master.component";
import {
  ManageVesselPortTrainDetailsComponent
} from "./components/masters/vessel-port-train-details-master/manage-vessel-port-train-details/manage-vessel-port-train-details.component";
import { VendorMasterComponent } from "./components/masters/vendor-master/vendor-master.component";
import { ManageVendorComponent } from "./components/masters/vendor-master/manage-vendor/manage-vendor.component";
import { ServiceMasterComponent } from "./components/masters/service-master/service-master.component";
import { EquipmentMasterComponent } from "./components/masters/equipment-master/equipment-master.component";
import { ManageServiceComponent } from "./components/masters/service-master/manage-service/manage-service.component";
import {
  ManageTransporterComponent
} from "./components/masters/transporter-master/manage-transporter/manage-transporter.component";
import { TransporterMasterComponent } from "./components/masters/transporter-master/transporter-master.component";
import { TrailerGateInComponent } from "./components/operations/gate-in/trailer/trailer-gate-in.component";
import { TruckGateInComponent } from "./components/operations/gate-in/truck/truck-gate-in.component";
import { ContainerGateInComponent } from "./components/operations/gate-in/container/container-gate-in.component";
import { IgmDeskComponent } from "./components/operations/igm-desk/igm-desk.component";
import { ItemFilingComponent } from "./components/operations/item-filing/item-filing.component";
import {
  ManageItemFilingComponent
} from "./components/operations/item-filing/manage-item-filing/manage-item-filing.component";
import { HoldComponent } from "./components/operations/hold/manage-holds/hold.component";
import { ChipInputComponent } from "./components/common/chip-input/chip-input.component";
import { DynamicFormPocComponent } from "./components/dynamic-form-poc/dynamic-form-poc.component";
import { JobOrderComponent } from "./components/operations/job-orders/listing/job-orders.component";
import { TallyComponent } from "./components/operations/tally/tally.component";
import { ManageTallyComponent } from "./components/operations/tally/manage-tally/manage-tally.component";
import { ChargeComponent } from "./components/finance/charge/charge.component";
import { ManageChargeComponent } from "./components/finance/charge/manage-charge/manage-charge.component";
import { ManageJoRendererComponent } from "./components/operations/job-orders/manage-job-orders/host/manage-jo-renderer.component";
import { TrailerGateOutComponent } from "./components/operations/gate-out/trailer/trailer-gate-out.component";
import { ContainerGateOutComponent } from "./components/operations/gate-out/container/container-gate-out.component";
import { TruckGateOutComponent } from "./components/operations/gate-out/truck/truck-gate-out.component";
import { ContainerListingComponent } from "./components/inventory/container/listing/container-listing.component";
import { VehicleListingComponent } from "./components/inventory/vehicle/listing/vehicle-listing.component";
import { ChargeGroupComponent } from "./components/finance/charge-group/charge-group.component";
import {
  ManageChargeGroupComponent
} from "./components/finance/charge-group/manage-charge-group/manage-charge-group.component";
import { CreateHoldComponent } from "./components/operations/hold/create-hold/create-hold.component";
import { ReleaseDocumentComponent } from "./components/operations/hold/release-document/release-document.component";
import { TariffComponent } from "./components/finance/tariff/tariff.component";
import {
  ManageTariffComponent
} from "./components/finance/tariff/manage-tariff/manage-tariff.component";
import { CustomerAgreementComponent } from "./components/finance/customer-agreement/customer-agreement.component";

export const routes: Routes = [
  { path: "", redirectTo: "/login", pathMatch: "full" },
  {
    path: "login",
    component: PublicLayoutComponent,
    children: [{ path: "", component: LoginComponent }],
    canActivate: [LoginGuard]
  },
  {
    path: "forgot-password",
    component: PublicLayoutComponent,
    children: [{ path: "", component: ForgotPasswordComponent }],
    canActivate: [LoginGuard]
  },
  {
    path: "reset-password/:token",
    component: PublicLayoutComponent,
    children: [{ path: "", component: ResetPasswordComponent }],
    canActivate: [LoginGuard]
  },
  {
    path: "dashboard",
    component: PrivateLayoutComponent,
    children: [{ path: "", component: DashboardComponent }],
    canActivate: [AuthGuard]
  },
  {
    path: "admin/manage-users",
    component: PrivateLayoutComponent,
    children: [{ path: "", component: ManageUsersComponent }],
    canActivate: [AuthGuard]
  },
  {
    path: "manage-masters/customers",
    component: PrivateLayoutComponent,
    children: [
      { path: "", component: CustomerMasterComponent },
      { path: "add-new", component: ManageCustomerRevampComponent,data: {showSideNav: false} },
      { path: "edit/:customerId", component: ManageCustomerRevampComponent,data: {showSideNav: false}},
      { path: "view/:customerId", component: ManageCustomerRevampComponent ,data: {showSideNav: false}}
    ],
    canActivate: [AuthGuard]
  },
  {
    path: "manage-masters/locations",
    component: PrivateLayoutComponent,
    children: [
      { path: "", component: LocationMasterComponent },
      { path: "add-new", component: ManageLocationComponent },
      { path: "edit/:locationId", component: ManageLocationComponent },
      { path: "view/:locationId", component: ManageLocationComponent }
    ],
    canActivate: [AuthGuard]
  },
  {
    path: "manage-masters/cities",
    component: PrivateLayoutComponent,
    children: [
      { path: "", component: CityMasterComponent },
      { path: "add-new", component: ManageCityComponent },
      { path: "edit/:cityId", component: ManageCityComponent },
      { path: "view/:cityId", component: ManageCityComponent }
    ],
    canActivate: [AuthGuard]
  },
  {
    path: "manage-masters/commodities",
    component: PrivateLayoutComponent,
    children: [
      { path: "", component: CommodityMasterComponent },
      { path: "add-new", component: ManageCommodityComponent },
      {
        path: "edit/:commodityId",
        component: ManageCommodityComponent
      },
      {
        path: "view/:commodityId",
        component: ManageCommodityComponent
      }
    ],
    canActivate: [AuthGuard]
  },
  {
    path: "manage-masters/commodity-categories",
    component: PrivateLayoutComponent,
    children: [
      { path: "", component: CommodityCategoryMasterComponent },
      { path: "add-new", component: ManageCommodityCategoryComponent },
      {
        path: "edit/:commodityCategoryId",
        component: ManageCommodityCategoryComponent
      },
      {
        path: "view/:commodityCategoryId",
        component: ManageCommodityCategoryComponent
      }
    ],
    canActivate: [AuthGuard]
  },
  {
    path: "manage-masters/countries",
    component: PrivateLayoutComponent,
    children: [
      { path: "", component: CountyMasterComponent },
      { path: "add-new", component: ManageCountryMasterComponent },
      { path: "edit/:countryId", component: ManageCountryMasterComponent },
      { path: "view/:countryId", component: ManageCountryMasterComponent }
    ],
    canActivate: [AuthGuard]
  },

  {
    path: "manage-finance/charges",
    component: PrivateLayoutComponent,
    children: [
      { path: "", component: ChargeComponent },
      { path: "add-new", component: ManageChargeComponent },
      { path: "edit/:chargeId", component: ManageChargeComponent },
      { path: "view/:chargeId", component: ManageChargeComponent },
    ],
    canActivate: [AuthGuard]
  },
  {
    path: "manage-finance/:chargeId/tariffs",
    component: PrivateLayoutComponent,
    children: [
      {path: "", component: TariffComponent, data: {showSideNav: false}},
      { path: "add-new", component: ManageTariffComponent,data: {showSideNav: false}},
      { path: "edit/:tariffId", component: ManageTariffComponent,data: {showSideNav: false} },
      { path: "view/:tariffId", component: ManageTariffComponent,data: {showSideNav: false} },
    ],
    canActivate: [AuthGuard]
  },
  {
    path: "manage-finance/charge-group",
    component: PrivateLayoutComponent,
    children: [
      { path: "", component: ChargeGroupComponent },
      { path: "add-new", component: ManageChargeGroupComponent },
      { path: "edit/:chargeGroupId", component: ManageChargeGroupComponent },
      { path: "view/:chargeGroupId", component: ManageChargeGroupComponent },
    ],
    canActivate: [AuthGuard]
  },
  {
    path: "manage-finance/customer-agreement",
    component: PrivateLayoutComponent,
    children: [
      { path: "", component: CustomerAgreementComponent }
    ],
    canActivate: [AuthGuard]
  },
  {
    path: "manage-masters/states",
    component: PrivateLayoutComponent,
    children: [
      { path: "", component: StateMasterComponent },
      { path: "add-new", component: ManageStateMasterComponent },
      { path: "edit/:stateId", component: ManageStateMasterComponent },
      { path: "view/:stateId", component: ManageStateMasterComponent }
    ],
    canActivate: [AuthGuard]
  },
  {
    path: "manage-masters/banks",
    component: PrivateLayoutComponent,
    children: [
      { path: "", component: BankMasterComponent },
      { path: "add-new", component: ManageBankMasterComponent },
      { path: "edit/:bankId", component: ManageBankMasterComponent },
      { path: "view/:bankId", component: ManageBankMasterComponent }
    ],
    canActivate: [AuthGuard]
  },
  {
    path: "manage-masters/packages",
    component: PrivateLayoutComponent,
    children: [
      { path: "", component: PackageMasterComponent },
      { path: "add-new", component: ManagePackageComponent },
      { path: "edit/:packageId", component: ManagePackageComponent },
      { path: "view/:packageId", component: ManagePackageComponent }
    ],
    canActivate: [AuthGuard]
  },
  {
    path: "manage-masters/trailers",
    component: PrivateLayoutComponent,
    children: [
      { path: "", component: TrailerMasterComponent },
      { path: "add-new", component: ManageTrailerMasterComponent },
      { path: "edit/:trailerId", component: ManageTrailerMasterComponent },
      { path: "view/:trailerId", component: ManageTrailerMasterComponent }
    ],
    canActivate: [AuthGuard]
  },
  {
    path: "manage-masters/vessel-port-train-details",
    component: PrivateLayoutComponent,
    children: [
      { path: "", component: VesselPortTrainDetailsMasterComponent },
      { path: "add-new", component: ManageVesselPortTrainDetailsComponent },
      { path: "edit/:vesselPortTrainDetailId", component: ManageVesselPortTrainDetailsComponent },
      { path: "view/:vesselPortTrainDetailId", component: ManageVesselPortTrainDetailsComponent }
    ],
    canActivate: [AuthGuard]
  },
  {
    path: "manage-masters/vendors",
    component: PrivateLayoutComponent,
    children: [
      { path: "", component: VendorMasterComponent },
      { path: "add-new", component: ManageVendorComponent },
      { path: "edit/:vendorId", component: ManageVendorComponent },
      { path: "view/:vendorId", component: ManageVendorComponent }
    ],
    canActivate: [AuthGuard]
  },
  {
    path: "manage-masters/services",
    component: PrivateLayoutComponent,
    children: [
      { path: "", component: ServiceMasterComponent },
      { path: "add-new", component: ManageServiceComponent },
      { path: "edit/:serviceId", component: ManageServiceComponent },
      { path: "view/:serviceId", component: ManageServiceComponent }
    ],
    canActivate: [AuthGuard]
  },
  {
    path: "manage-masters/equipments",
    component: PrivateLayoutComponent,
    children: [
      { path: "", component: EquipmentMasterComponent },
    ],
    canActivate: [AuthGuard]
  },
  {
    path: "manage-operations/trailer-gate-in",
    component: PrivateLayoutComponent,
    children: [
      { path: "", component: TrailerGateInComponent }
    ],
    canActivate: [AuthGuard]
  },
  {
    path: "manage-operations/trailer-gate-out",
    component: PrivateLayoutComponent,
    children: [
      { path: "", component: TrailerGateOutComponent }
    ],
    canActivate: [AuthGuard]
  },
  {
    path: "manage-operations/truck-gate-in",
    component: PrivateLayoutComponent,
    children: [
      { path: "", component: TruckGateInComponent }
    ],
    canActivate: [AuthGuard]
  },
  {
    path: "manage-operations/truck-gate-out",
    component: PrivateLayoutComponent,
    children: [
      { path: "", component: TruckGateOutComponent }
    ],
    canActivate: [AuthGuard]
  },
  {
    path: "manage-operations/container-gate-in",
    component: PrivateLayoutComponent,
    children: [
      { path: "", component: ContainerGateInComponent }
    ],
    canActivate: [AuthGuard]
  },
  {
    path: "manage-operations/container-gate-out",
    component: PrivateLayoutComponent,
    children: [
      { path: "", component: ContainerGateOutComponent }
    ],
    canActivate: [AuthGuard]
  },
  {
    path: "manage-operations/igm-desk",
    component: PrivateLayoutComponent,
    children: [
      { path: "", component: IgmDeskComponent }
    ],
    canActivate: []
  },
  {
    path: "manage-operations/item-filing",
    component: PrivateLayoutComponent,
    children: [
      { path: "add-new", component: ManageItemFilingComponent },
      { path: "edit/:item-filing-id", component: ManageItemFilingComponent },
      { path: "view/:item-filing-id", component: ManageItemFilingComponent },
      { path: "", component: ItemFilingComponent }
    ],
    canActivate: [AuthGuard]
  },
  {
    path: "manage-operations/import/tally",
    component: PrivateLayoutComponent,
    children: [
      { path: "", component: TallyComponent },
      { path: "add-new", component: ManageTallyComponent,data: {showSideNav: false}},
      { path: "edit/:tally-id", component: ManageTallyComponent,data: {showSideNav: false}},
      { path: "view/:tally-id", component: ManageTallyComponent,data: {showSideNav: false} },
    ],
    canActivate: []
  },
  {
      path: 'manage-operations/import/job-orders',
      component: PrivateLayoutComponent,
      children: [
        { path: '', component: JobOrderComponent },
        { path: 'create-new', component: ManageJoRendererComponent,data: {showSideNav: false}},
        { path: 'edit/:job-order-id', component: ManageJoRendererComponent,data: {showSideNav: false}},
        { path: 'view/:job-order-id', component: ManageJoRendererComponent,data: {showSideNav: false}},
      ],
      canActivate: [AuthGuard],
  },
  {
    path: 'manage-operations/hold',
    component: PrivateLayoutComponent,
    children: [
      { path: '', component: HoldComponent },
      { path: 'create-new', component: CreateHoldComponent},
      { path: 'release-hold', component: ReleaseDocumentComponent}
    ],
    canActivate: [AuthGuard],
  },
  {
    path: 'manage-inventory/containers',
    component: PrivateLayoutComponent,
    children: [
      { path: '', component: ContainerListingComponent },
      { path: 'edit/:container-id', component: ManageJoRendererComponent },
      { path: 'view/:container-id', component: ManageJoRendererComponent },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: 'manage-inventory/vehicles',
    component: PrivateLayoutComponent,
    children: [
      { path: '', component: VehicleListingComponent },
      { path: 'edit/:container-id', component: ManageJoRendererComponent },
      { path: 'view/:container-id', component: ManageJoRendererComponent },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: "test/side-panel",
    component: PrivateLayoutComponent,
    children: [
      { path: "", component: ChipInputComponent }
    ],
    canActivate: [AuthGuard]
  },
  {
    path: "test/dynamic-form",
    component: PrivateLayoutComponent,
    children: [
      { path: "", component: DynamicFormPocComponent }
    ],
    canActivate: [AuthGuard]
  },
  {
    path: "support",
    component: PrivateLayoutComponent,
    children: [{ path: "", component: SupportComponent }],
    canActivate: [AuthGuard]
  },
  {
    path: "settings",
    component: PrivateLayoutComponent,
    children: [{ path: "", component: SettingsComponent }],
    canActivate: [AuthGuard]
  },
  {
    path: "user-profile",
    component: PrivateLayoutComponent,
    children: [{ path: "", component: UserProfileComponent }],
    canActivate: [AuthGuard]
  },

  // Wildcard route for a 404 page
  { path: "**", component: PageNotFoundComponent }
];
