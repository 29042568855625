<div class="toaster" >
  <div
    *ngFor="let toast of toasts; let i = index"
    class="toast"
    [ngClass]="toast.type"
    [@toastAnimation]
  >
    <div class="circle_frame">
      <app-svg-icon
        *ngIf="toast.type === 'info'"
        [path]="'assets/icons/toast_info_icon.svg'"
      ></app-svg-icon>
      <app-svg-icon
        *ngIf="toast.type === 'error'"
        [path]="'assets/icons/toast_error_icon.svg'"
      ></app-svg-icon>
      <app-svg-icon
        *ngIf="toast.type === 'warning'"
        [path]="'assets/icons/toast_warning_icon.svg'"
      ></app-svg-icon>
      <app-svg-icon
        *ngIf="toast.type === 'success'"
        [path]="'assets/icons/toast_success_icon.svg'"
      ></app-svg-icon>
    </div>
    <div class="message">
      {{ toast.message || "Oops, something went wrong" }}
    </div>
    <!-- <button *ngIf="toast.action" (click)="onAction(toast)" class="action">
      {{ toast.action }}
    </button> -->
     <div (click)="dismiss(toast)" class="close"><app-svg-icon [path]="'assets/icons/close_icon.svg'"></app-svg-icon></div>
  </div>
</div>
