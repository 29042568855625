
<div class="create_hold_container">
    <div class="page_header">
        <div class="left">
            <div class="close_icon_wrapper" (click)="cancel()" >
                <app-svg-icon class="close_icon" [path]="'/assets/icons/close_icon.svg'"></app-svg-icon>
            </div>
            <div class="title">Create Hold</div>
        </div>
        <div class="right">
            <button class="btn secondary_button" (click)="cancel()">Cancel</button>
            <button class="btn primary_button" (click)="saveForm()">Save</button>
        </div>
    </div>

    <div class="content">
        <form [formGroup]="holdForm">
            <div class="hold_form">
                <div class="form-item">
                    <label for="holdIssuedBy" class="required_field_label">Hold Issued By</label>
                    <app-multi-select
                        id="holdIssuedBy"
                        formControlName="holdIssuedBy"
                        [options]="holdSourceOptions"
                        [displayKey]="'label'"
                        [valueKey]="'key'"
                        [isMultiSelect]="false"
                        [placeholder]="'Select Issuer'"
                        (selectionChange)="onHoldSourceChange($event)"
                    ></app-multi-select>
                    <div class="form_validation_error" [ngClass]="{form_error : holdForm.get('holdIssuedBy')?.invalid && holdForm.get('holdIssuedBy')?.touched}">
                        <small *ngIf="holdForm.get('holdIssuedBy')?.errors?.['required']">Issued By is required</small>
                    </div>
                </div>
                <div class="form-item">
                    <label for="holdCategory" class="required_field_label">Hold Type</label>
                    <app-multi-select
                        id="holdCategory"
                        formControlName="holdCategory"
                        [options]="holdTypeOptions"
                        [displayKey]="'label'"
                        [valueKey]="'key'"
                        [isMultiSelect]="false"
                        [placeholder]="'Select Hold Type'"
                        [disable]="true"
                        (selectionChange)="onHoldSourceChange($event)"
                    ></app-multi-select>
                    <div class="form_validation_error" [ngClass]="{form_error : holdForm.get('holdType')?.invalid && holdForm.get('holdType')?.touched}">
                        <small *ngIf="holdForm.get('holdType')?.errors?.['required']">Hold Type is required</small>
                    </div>
                </div>
                <div class="form-item"  *ngIf="isInternalHold()">
                    <label for="holdSubCategory" class="required_field_label">Hold Reason</label>
                    <app-multi-select
                        id="holdSubCategory"
                        formControlName="holdSubCategory"
                        [options]="holdReasonOptions"
                        [displayKey]="'label'"
                        [valueKey]="'key'"
                        [isMultiSelect]="false"
                        [placeholder]="'Select Hold Reason'"
                        (selectionChange)="onHoldSourceChange($event)"
                    ></app-multi-select>
                    <div class="form_validation_error" [ngClass]="{form_error : holdForm.get('holdSubCategory')?.invalid && holdForm.get('holdSubCategory')?.touched}">
                        <small *ngIf="holdForm.get('holdSubCategory')?.errors?.['required']">Hold Reason is required</small>
                    </div>
                </div>
                <div class="form-item" *ngIf="isCustomsHold()" >
                    <label for="holdDocumentNo" class="required_field_label">Hold Document No</label>
                    <input id="holdDocumentNo" formControlName="holdDocumentNo" placeholder="Enter Document No" />
                    <div class="form_validation_error" [ngClass]="{form_error : holdForm.get('holdDocumentNo')?.invalid && holdForm.get('holdDocumentNo')?.touched}">
                        <small *ngIf="holdForm.get('holdDocumentNo')?.errors?.['required']">Hold Document is required</small>
                    </div>
                </div>
                <div class="form-item">
                    <label for="holdDate" class="required_field_label">Hold Date</label>
                    <input id="holdDate" formControlName="holdDate" type="datetime-local" />
                    <div class="form_validation_error" [ngClass]="{form_error : holdForm.get('holdDate')?.invalid && holdForm.get('holdDate')?.touched}">
                        <small *ngIf="holdForm.get('holdDate')?.errors?.['required']">Hold Date is required</small>
                    </div>
                </div>
                <div class="form-item">
                    <label for="holdRemarks">Remarks</label>
                    <input id="holdRemarks" formControlName="holdRemarks" placeholder="Enter hold remarks"/>
                    <div class="form_validation_error" [ngClass]="{form_error : holdForm.get('holdRemarks')?.invalid && holdForm.get('holdRemarks')?.touched}">
                        <small *ngIf="holdForm.get('holdRemarks')?.errors?.['maxlength']">Max length of 250 characters exceeded.</small>
                    </div>
                </div>
            </div>
        </form>

        <div class="hold-item-selection-container">
            <div class="search_bar_action_btns">
                <div class="search_bar">
                    <div class="search_container">
                        <input
                            type="text"
                            name="search_table"
                            id="search_table"
                            [(ngModel)]="searchTerm"
                            placeholder="Search by Container No or Igm Item No"
                            (focus)="focusSearch = true"
                            (focusout)="focusSearch = false"
                            (keyup)="handleTableSearch($event)"
                        >
                            <img src="assets/icons/search_icon.svg" class="search_icon">
                    </div>
        
                    <div class="filter_btn">
                        <button (click)="filter()">
                        <img src="assets/icons/filter.svg">
                        Filters
                        </button>
                    </div>
                </div>

                <div class="action_btn">
                    <button class="secondary_button" *ngIf="selectedItems.length > 0 
                    && !allRecordsSelected && tableListData.totalCount != tableListData.records.length" (click)="selectAll()">
                        Select all {{tableListData.totalCount}} records
                    </button>
                    <button class="secondary_button" *ngIf="allRecordsSelected" (click)="clearSelection()">
                        Clear Selection
                    </button>
                    <div class="selection-message" *ngIf="selectedItems.length > 0">
                        @if (allRecordsSelected || tableListData.totalCount == selectedItems.length) {
                            Selected: {{tableListData.totalCount}} of {{tableListData.totalCount}} 
                        } @else  {
                            Selected: {{selectedItems.length}} of {{tableListData.totalCount}} 
                        }
                    </div>
                </div>
            </div>

            <div class="table-container">
                <app-tos-table
                [showTableStats]="false"
                [tableMeta]="tableMeta"
                [(dataListRequest)]="tableListRequest"
                [dataListResponse]="tableListData"
                [(selectedItems)]="selectedItems"
                [showMenuOptions]="false"
                (tosTableAction)="listItemsForHold()"
                ></app-tos-table>
            </div>
        </div>
    </div>
</div>