import { Component, EventEmitter, OnInit, Output, ViewChild, ViewContainerRef } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SharedModule } from "../../modules/shared/shared.module";
import { OtpInputComponent } from "../otp-input/otp-input.component";
import { ApiService } from "../../services/api.service";
import { LoginRequest } from "../../models/login-request.interface";
import { interval, Subscription } from "rxjs";
import { ToasterService } from "../../services/toaster.service";
import { Router } from "@angular/router";
import { LoadingService } from "../../services/loading.service";
import { AuthService } from "../../services/auth.service";
import { LocalStorageService } from "../../services/local-storage.service";
import { Menu } from "../../models/sidebar-menu.interface";
import { MenuService } from "../../services/menu.service";
import { StorageKeys } from "../../constants/app-constants";
import { ModalComponent } from "../modal/modal.component";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";

@Component({
  selector: "app-login",
  standalone: true,
  imports: [SharedModule, OtpInputComponent],
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit {
  emailLoginForm: FormGroup;
  phoneLoginForm: FormGroup;
  loginMethod: "email" | "phone" = "email";
  isOtpGenerated: boolean = false;
  otp: string = "";
  resendOtpBufferTime: number = 30; //in seconds
  countdown: number = 0;
  resendAllowed: boolean = false;
  loggedInUserId: string = "";
  countdownSubscription: Subscription = new Subscription();

  @ViewChild(OtpInputComponent) otpInputComponent!: OtpInputComponent;
  @Output() menuItemsEmitter = new EventEmitter<Menu[]>();

  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private toasterService: ToasterService,
    private router: Router,
    private loadingService: LoadingService,
    private authService: AuthService,
    private localStorageService: LocalStorageService,
    private menuService: MenuService,
    private viewContainerRef: ViewContainerRef
  ) {
    this.emailLoginForm = this.fb.group({
      username: ["", [Validators.required]],
      password: ["", [Validators.required]]
      // rememberMe: [''],
    });
    this.phoneLoginForm = this.fb.group({
      mobile: ["", [Validators.required, Validators.pattern("^[0-9]{10}$")]],
      otp: ["", [Validators.required, Validators.pattern("^[0-9]{6}$")]]
    });
    this.phoneLoginForm.get("mobile")?.valueChanges.subscribe(() => {
      this.isOtpGenerated = false;
    });
  }

  async ngOnInit() {
    const storedLoginMethod: any = await this.localStorageService.getItem(
      "loginMethod"
    );
    if (storedLoginMethod?.selected) {
      this.loginMethod = storedLoginMethod?.selected;
    } else {
      this.localStorageService.setItem("loginMethod", {
        selected: "email"
      });
    }
    // const lastLoggedInUser: any = await this.localStorageService.getItem(
    //   'lastLoggedInUser'
    // );
    // if (lastLoggedInUser) {
    //   this.emailLoginForm
    //     .get('email')
    //     ?.setValue(lastLoggedInUser.creds.username);
    //   this.emailLoginForm
    //     .get('password')
    //     ?.setValue(lastLoggedInUser.creds.password);
    //   this.emailLoginForm.get('rememberMe')?.setValue(true);
    // }
  }

  // Switches the login method
  switchLoginMethod(method: "email" | "phone"): void {
    this.loginMethod = method;
    this.localStorageService.setItem("loginMethod", {
      selected: this.loginMethod
    });
  }

  requestOtp() {
    if (this.phoneLoginForm.get("mobile")?.valid) {
      const mobileNumber = this.phoneLoginForm.get("mobile")?.value;
      this.loadingService.show();
      this.api.sendOtp(mobileNumber).subscribe({
        next: (res) => {
          this.toasterService.success(`${res.data}`);
          this.isOtpGenerated = true;
          this.startCountdown();
          setTimeout(() => {
            this.loadingService.hide();
          }, 1000);
        },
        error: (err) => {
          console.error(err);
          this.toasterService.error(err.error.errorDesc);
          setTimeout(() => {
            this.loadingService.hide();
          }, 1000);
        }
      });
    } else {
      this.phoneLoginForm.get("mobile")?.markAsTouched();
    }
  }

  resendOtp() {
    if (!this.resendAllowed) {
      return;
    } else {
      this.phoneLoginForm.get("otp")?.reset();
      this.otpInputComponent.otpForm.reset();
    }
    this.requestOtp();
  }

  formatTime(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${this.padZero(minutes)}:${this.padZero(remainingSeconds)}`;
  }

  padZero(value: number): string {
    return value < 10 ? "0" + value : value.toString();
  }

  startCountdown(): void {
    this.resendAllowed = false;
    this.countdown = this.resendOtpBufferTime;
    this.countdownSubscription = interval(1000).subscribe(() => {
      if (this.countdown > 0) {
        this.countdown--;
      } else {
        this.resendAllowed = true;
        this.countdownSubscription.unsubscribe();
      }
    });
  }

  // Handle OTP change
  onOtpChange(newOtp: string): void {
    this.phoneLoginForm.get("otp")?.setValue(newOtp);
  }

  async submitEmailLogin(killExistingSession: boolean= false) {
    this.emailLoginForm.markAllAsTouched();
    if (this.emailLoginForm.valid) {
      const loginRequest: LoginRequest = {
        username: this.emailLoginForm.get("username")?.value.toLowerCase(),
        password: this.emailLoginForm.get("password")?.value,
        killExistingSession: killExistingSession
      };
      this.loadingService.show();
      this.authService.login(loginRequest).subscribe({
        next: async (response) => {
          if(response && response.data != null && !response.data.success) {
            this.loadingService.hide();
            let message = "An active session is currently in progress. Would you like to terminate it and start a new session?"
            const modalRef = this.viewContainerRef.createComponent(ModalComponent);
            modalRef.instance.title = "Session Already Active";
            const confirmDialogRef = modalRef.instance.loadComponent(
              ConfirmDialogComponent
            );
            confirmDialogRef.instance.message = message;
            confirmDialogRef.instance.confirm.subscribe(() => {
              return this.submitEmailLogin(true);
            });
            confirmDialogRef.instance.cancel.subscribe(() => {
              modalRef.destroy();
            });
          } else {
            this.toasterService.success("Login successful!");
          try {
            await this.saveUserProfile();
            this.menuService.fetchAndStoreMenuItems(this.loggedInUserId);
          } catch (err) {
            console.error("Error saving user profile:", err);
          } finally {
            setTimeout(() => {
              this.loadingService.hide();
            }, 1000);
          }
          }
        },
        error: (err) => {
          console.error("Login failed", err);
          switch (err.status) {
            case 400:
              if (err.error.errorDesc.includes("Invalid email or mobile")) {
                this.toasterService.error("Incorrect email or password!");
              } else if (err.error.errorDesc.includes("Incorrect password")) {
                const remainingAttempts = parseInt(
                  err.error.data.remainingAttempts
                );
                if (remainingAttempts === 0) {
                  this.toasterService.error(
                    `Invalid login attempt. Your account has been locked as you have exceeded maximum login retries. Please contact the administrator for assistance.`
                  );
                } else {
                  this.toasterService.error(
                    `Invalid login attempt. You have ${remainingAttempts} ${
                      remainingAttempts === 1 ? "attempt" : "attempts"
                    } left !`
                  );
                }
              } else {
                this.toasterService.error(
                  err.error.errorDesc || "Unknown Error !"
                );
              }
              break;
            default:
              this.toasterService.error(err.error.errorDesc);
              break;
          }
          setTimeout(() => {
            this.loadingService.hide();
          }, 1000);
        }
      });
    }
  }

  async submitPhoneLogin(killExistingSession: boolean= false) {
    this.phoneLoginForm.markAllAsTouched();
    if (this.phoneLoginForm.valid) {
      const loginRequest: LoginRequest = {
        mobile: this.phoneLoginForm.get("mobile")?.value,
        otp: this.phoneLoginForm.get("otp")?.value,
        killExistingSession: killExistingSession
      };
      this.loadingService.show();
      this.authService.login(loginRequest).subscribe({
        next: async (response) => {
          // redirection will be handled by redirectInterceptor
          if(response && response.data != null && !response.data.success) {
            this.loadingService.hide();
            let message = "An active session is currently in progress. Would you like to terminate it and start a new session?"
            const modalRef = this.viewContainerRef.createComponent(ModalComponent);
            const confirmDialogRef = modalRef.instance.loadComponent(
              ConfirmDialogComponent
            );
            confirmDialogRef.instance.message = message;
            confirmDialogRef.instance.confirm.subscribe(() => {
              return this.submitPhoneLogin(true);
            });
            confirmDialogRef.instance.cancel.subscribe(() => {
              modalRef.destroy();
            });
          } else {
            this.toasterService.success("Login successful!");
          await this.saveUserProfile();
          this.menuService.fetchAndStoreMenuItems(this.loggedInUserId);
          setTimeout(() => {
            this.loadingService.hide();
          }, 1000);
          }

        },
        error: (err) => {
          console.error("Login failed", err);
          switch (err.status) {
            case 400:
              if (err.error.errorDesc.includes("Incorrect OTP")) {
                const remainingAttempts = parseInt(
                  err.error.data.remainingAttempts
                );
                if (remainingAttempts === 0) {
                  this.toasterService.error(
                    `Invalid login attempt. Your account has been locked as you have exceeded maximum login retries. Please contact the administrator for assistance.`
                  );
                } else {
                  this.toasterService.error(
                    `Invalid login attempt. You have ${remainingAttempts} ${
                      remainingAttempts === 1 ? "attempt" : "attempts"
                    } left !`
                  );
                }
              } else {
                this.toasterService.error(
                  err.error.errorDesc || "Unknown Error !"
                );
              }
              break;
            default:
              this.toasterService.error(err.error.errorDesc);
              break;
          }
          setTimeout(() => {
            this.loadingService.hide();
          }, 1000);
        }
      });
    }
  }

  navigate(route: string) {
    this.router.navigate([route]);
  }

  saveUserProfile(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.api.getMyProfile().subscribe({
        next: (res) => {
          console.log(res);
          this.loggedInUserId = res.data.id;
          this.localStorageService.setItem(
            StorageKeys.USER_PROFILE,
            JSON.stringify(res.data)
          );
          resolve();
        },
        error: (err) => {
          reject(err);
        }
      });
    });
  }
}
