<div class="container">
  <div class="header header_with_back_button">
    <app-back-button></app-back-button>
    <span>
      @if (readOnlyMode) { View } @else if (masterEditingEnabled) { Edit } @else
      { Create New } Commodity
    </span>
  </div>

  <div class="container-content">
    <form [formGroup]="masterForm">
      <div class="section">
        <div class="head">Basic Information</div>
        <div class="fields">
          <div class="grid-container">
            <div class="form-group">
              <label for="name" class="required_field_label" #formField
                >Commodity Name</label
              >
              <input
                id="name"
                name="name"
                formControlName="name"
                type="text"
                placeholder="Enter name"
                required
                autocomplete="name"
                [ngClass]="{
                  form_error:
                    masterForm.get('name')?.invalid &&
                    masterForm.get('name')?.touched &&
                    masterForm.get('name')?.errors
                }"
              />
              <div
                class="form_validation_error"
                [ngClass]="{
                  form_error:
                    masterForm.get('name')?.invalid &&
                    masterForm.get('name')?.touched
                }"
              >
                <small *ngIf="masterForm.get('name')?.errors?.['required']"
                  >Name is required</small
                >
              </div>
            </div>
            <div class="form-group">
              <label for="code" class="required_field_label" #code
                >Commodity Code</label
              >
              <input
                id="code"
                name="code"
                formControlName="code"
                type="text"
                placeholder="Enter Code"
                required
                autocomplete="code"
                [ngClass]="{
                  form_error:
                    masterForm.get('code')?.invalid &&
                    masterForm.get('code')?.touched &&
                    masterForm.get('code')?.errors
                }"
              />
              <div
                class="form_validation_error"
                [ngClass]="{
                  form_error:
                    masterForm.get('code')?.invalid &&
                    masterForm.get('code')?.touched
                }"
              >
                <small *ngIf="masterForm.get('code')?.errors?.['required']"
                  >Code is required</small
                >
              </div>
            </div>
            <div class="form-group">
              <label for="commodityCategoryId" class="required_field_label"
                >Commodity Category</label
              >
              <app-autocomplete-dropdown
                id="commodityCategoryId"
                name="commodityCategoryId"
                formControlName="commodityCategoryId"
                [placeholder]="'Entry Commodity Category Name'"
                [apiUrl]="'v1/commodity-categories/list'"
                [selectFields]="['id', 'name', 'code']"
                [limit]="10"
                [orderBy]="{ name: 'asc' }"
                [filterByKey]="'name'"
                [bindLabel]="'name'"
                [bindValue]="'id'"
                [disable]="masterForm.disabled"
                (suggestionSelected)="
                  masterDataResponse &&
                    (masterDataResponse.commodityCategoryId = $event.id)
                "
              ></app-autocomplete-dropdown
              ><br />
              <div class="form_validation_error">
                <small
                  *ngIf="masterForm.get('commodityCategoryId')?.errors?.['required']"
                  >Commodity Category is required</small
                >
              </div>
            </div>
            <div class="form-group">
              <label for="hsnCode" class="" #code>HSN Code</label>
              <input
                id="hsnCode"
                name="hsnCode"
                formControlName="hsnCode"
                type="text"
                placeholder="Entry HSN Code"
                autocomplete="hsnCode"
                [ngClass]="{
                  form_error:
                    masterForm.get('hsnCode')?.invalid &&
                    masterForm.get('hsnCode')?.touched &&
                    masterForm.get('hsnCode')?.errors
                }"
              />
              <div
                class="form_validation_error"
                *ngIf="
                  masterForm.get('hsnCode')?.invalid &&
                  masterForm.get('hsnCode')?.touched
                "
              ></div>
            </div>
            <div
              class="form-row checkbox-label checkbox-area"
              formArrayName="locationType"
            >
              <label>
                <input
                  type="checkbox"
                  (change)="onCheckboxChange($event)"
                  [checked]="showImdgForm"
                />
                IMDG Details
              </label>
            </div>
          </div>
        </div>
      </div>
      @if (showImdgForm) {
      <div class="section">
        <div class="head tabs">
          <div class="tab">IMDG Class Details</div>
        </div>
        <div class="fields">
          <div class="grid-container" formGroupName="imdgDetails">
            <div class="form-group">
              <label for="lastName">Last Name</label>
              <input
                id="lastName"
                name="lastName"
                formControlName="lastName"
                type="text"
                placeholder="Enter Last Name"
              />
            </div>

            <div class="form-group">
              <label for="flashPoint">Flash Point</label>
              <input
                id="flashPoint"
                name="flashPoint"
                formControlName="flashPoint"
                type="text"
                placeholder="Enter Flash Point"
              />
            </div>

            <div class="form-group">
              <label for="imdgClass">IMDG Class</label>
              <input
                id="imdgClass"
                name="imdgClass"
                formControlName="imdgClass"
                type="text"
                placeholder="Enter IMDG Class"
              />
            </div>

            <div class="form-group">
              <label for="properShippingName">Proper Shipping Name</label>
              <input
                id="properShippingName"
                name="properShippingName"
                formControlName="properShippingName"
                type="text"
                placeholder="Enter Proper Shipping Name"
              />
            </div>

            <div class="form-group">
              <label for="ems">EMS</label>
              <input
                id="ems"
                name="ems"
                formControlName="ems"
                type="text"
                placeholder="Enter EMS"
              />
            </div>

            <div class="form-group">
              <label for="packagingInstruction">Packaging Instruction</label>
              <input
                id="packagingInstruction"
                name="packagingInstruction"
                formControlName="packagingInstruction"
                type="text"
                placeholder="Enter Packaging Instruction"
              />
            </div>

            <div class="form-group">
              <label for="codedStowage">Coded Stowage</label>
              <input
                id="codedStowage"
                name="codedStowage"
                formControlName="codedStowage"
                type="text"
                placeholder="Enter Coded Stowage"
              />
            </div>

            <div class="form-group">
              <label for="ibcProvisions">IBC Provisions</label>
              <input
                id="ibcProvisions"
                name="ibcProvisions"
                formControlName="ibcProvisions"
                type="text"
                placeholder="Enter IBC Provisions"
              />
            </div>

            <div class="form-group">
              <label for="tankInstructions">Tank Instructions</label>
              <input
                id="tankInstructions"
                name="tankInstructions"
                formControlName="tankInstructions"
                type="text"
                placeholder="Enter Tank Instructions"
              />
            </div>

            <div class="form-group">
              <label for="properties">Properties</label>
              <input
                id="properties"
                name="properties"
                formControlName="properties"
                type="text"
                placeholder="Enter Properties"
              />
            </div>

            <div class="form-group">
              <label for="exempted">Exempted</label>
              <select id="exempted" formControlName="exempted">
                <option [value]="true">Yes</option>
                <option [value]="false">No</option>
              </select>
            </div>

            <div class="form-group">
              <label for="unoCode">UNO Code</label>
              <input
                id="unoCode"
                name="unoCode"
                formControlName="unoCode"
                type="text"
                placeholder="Enter UNO Code"
              />
            </div>

            <div class="form-group">
              <label for="unno">UNNO</label>
              <input
                id="unno"
                name="unno"
                formControlName="unno"
                type="text"
                placeholder="Enter UNNO"
              />
            </div>

            <div class="form-group">
              <label for="flashPointUnitIndicator"
                >Flash Point Unit Indicator</label
              >
              <input
                id="flashPointUnitIndicator"
                name="flashPointUnitIndicator"
                formControlName="flashPointUnitIndicator"
                type="text"
                placeholder="Enter Flash Point Unit Indicator"
              />
            </div>

            <div class="form-group">
              <label for="variant">Variant</label>
              <input
                id="variant"
                name="variant"
                formControlName="variant"
                type="text"
                placeholder="Enter Variant"
              />
            </div>

            <div class="form-group">
              <label for="subsidiaryRiskLabel1">Subsidiary Risk Label 1</label>
              <input
                id="subsidiaryRiskLabel1"
                name="subsidiaryRiskLabel1"
                formControlName="subsidiaryRiskLabel1"
                type="text"
                placeholder="Enter Subsidiary Risk Label 1"
              />
            </div>

            <div class="form-group">
              <label for="materialState">Material State</label>
              <input
                id="materialState"
                name="materialState"
                formControlName="materialState"
                type="text"
                placeholder="Enter Material State"
              />
            </div>

            <div class="form-group">
              <label for="packagingGroup">Packaging Group</label>
              <input
                id="packagingGroup"
                name="packagingGroup"
                formControlName="packagingGroup"
                type="text"
                placeholder="Enter Packaging Group"
              />
            </div>

            <div class="form-group">
              <label for="packagingProvision">Packaging Provision</label>
              <input
                id="packagingProvision"
                name="packagingProvision"
                formControlName="packagingProvision"
                type="text"
                placeholder="Enter Packaging Provision"
              />
            </div>

            <div class="form-group">
              <label for="stowageCategory">Stowage Category</label>
              <input
                id="stowageCategory"
                name="stowageCategory"
                formControlName="stowageCategory"
                type="text"
                placeholder="Enter Stowage Category"
              />
            </div>

            <div class="form-group">
              <label for="imoTankInstructions">IMO Tank Instructions</label>
              <input
                id="imoTankInstructions"
                name="imoTankInstructions"
                formControlName="imoTankInstructions"
                type="text"
                placeholder="Enter IMO Tank Instructions"
              />
            </div>

            <div class="form-group">
              <label for="stowSegregation">Stow Segregation</label>
              <input
                id="stowSegregation"
                name="stowSegregation"
                formControlName="stowSegregation"
                type="text"
                placeholder="Enter Stow Segregation"
              />
            </div>

            <div class="form-group">
              <label for="lineTariffCode">Line Tariff Code</label>
              <input
                id="lineTariffCode"
                name="lineTariffCode"
                formControlName="lineTariffCode"
                type="text"
                placeholder="Enter Line Tariff Code"
              />
            </div>

            <div class="form-group">
              <label for="subsidiaryRiskLabel2">Subsidiary Risk Label 2</label>
              <input
                id="subsidiaryRiskLabel2"
                name="subsidiaryRiskLabel2"
                formControlName="subsidiaryRiskLabel2"
                type="text"
                placeholder="Enter Subsidiary Risk Label 2"
              />
            </div>

            <div class="form-group">
              <label for="limitedQuantity">Limited Quantity</label>
              <input
                id="limitedQuantity"
                name="limitedQuantity"
                formControlName="limitedQuantity"
                type="text"
                placeholder="Enter Limited Quantity"
              />
            </div>

            <div class="form-group">
              <label for="specialProvision">Special Provision</label>
              <input
                id="specialProvision"
                name="specialProvision"
                formControlName="specialProvision"
                type="text"
                placeholder="Enter Special Provision"
              />
            </div>

            <div class="form-group">
              <label for="cargoProvision">Cargo Provision</label>
              <input
                id="cargoProvision"
                name="cargoProvision"
                formControlName="cargoProvision"
                type="text"
                placeholder="Enter Cargo Provision"
              />
            </div>

            <div class="form-group">
              <label for="ibcInstructions">IBC Instructions</label>
              <input
                id="ibcInstructions"
                name="ibcInstructions"
                formControlName="ibcInstructions"
                type="text"
                placeholder="Enter IBC Instructions"
              />
            </div>

            <div class="form-group">
              <label for="unTankInstructions">UN Tank Instructions</label>
              <input
                id="unTankInstructions"
                name="unTankInstructions"
                formControlName="unTankInstructions"
                type="text"
                placeholder="Enter UN Tank Instructions"
              />
            </div>

            <div class="form-group">
              <label for="observations">Observations</label>
              <input
                id="observations"
                name="observations"
                formControlName="observations"
                type="text"
                placeholder="Enter Observations"
              />
            </div>
          </div>
        </div>
      </div>
      }
    </form>
  </div>

  <div class="footer_actions">
    <ng-container *ngIf="readOnlyMode">
      <button type="button" class="secondary_button" (click)="goBack()">
        Back
      </button>
    </ng-container>
    <ng-container *ngIf="!readOnlyMode">
      <button type="button" class="secondary_button" (click)="goBack()">
        Cancel
      </button>
      <button type="button" class="primary_button" (click)="submitMasterForm()">
        @if (masterEditingEnabled) { Update } @else { Save }
      </button>
    </ng-container>
  </div>
</div>
