import {
  ManageExaminationTallyComponent
} from "../components/operations/tally/manage-tally/manage-examination-tally/manage-examination-tally.component";
import { Cycle, CycleType } from "./app-constants";
import {
  ManageLclDeliveryTallyComponent
} from "../components/operations/tally/manage-tally/manage-lcl-delivery-tally/manage-lcl-delivery-tally.component";
import {
  ManageSmtpStuffingTallyComponent
} from "../components/operations/tally/manage-tally/manage-smtp-stuffing-tally/manage-smtp-stuffing-tally.component";
import {
  ManageTallySmtpContainerDeliveryComponent
} from "../components/operations/tally/manage-tally/manage-tally-smtp-container-delivery/manage-tally-smtp-container-delivery.component";
import {
  ManageTallySmtpTruckMovementComponent
} from "../components/operations/tally/manage-tally/manage-tally-smtp-truck-movement/manage-tally-smtp-truck-movement.component";
import {
  ManageTallyDestuffingFclComponent
} from "../components/operations/tally/manage-tally/manage-tally-destuffing-fcl/manage-tally-destuffing-fcl.component";
import {
  ManageTallyDestuffingFclTruckComponent
} from "../components/operations/tally/manage-tally/manage-tally-destuffing-fcl-truck/manage-tally-destuffing-fcl-truck.component";
import { NoDataComponent } from "../components/no-data/no-data.component";
import {
  ManageTallyDestuffingLclComponent
} from "../components/operations/tally/manage-tally/manage-tally-destuffing-lcl/manage-tally-destuffing-lcl.component";

export const JobOrderType = {
  SEAL_CUT_EXAMINATION: { value: "Seal Cut & Examination", key: "SEAL_CUT_EXAMINATION", cycle: CycleType.IMPORT },
  RMS_AND_EXAMINATION: { value: "RMS & Examination", key: "RMS_AND_EXAMINATION", cycle: CycleType.IMPORT },
  RMS_SEAL_VERIFICATION: { value: "RMS Seal Verification", key: "RMS_SEAL_VERIFICATION", cycle: CycleType.IMPORT },
  FACTORY_DELIVERY: { value: "Factory Delivery", key: "FACTORY_DELIVERY", cycle: CycleType.IMPORT },
  DOCK_DELIVERY: { value: "Destuff Delivery", key: "DOCK_DELIVERY", cycle: CycleType.IMPORT },
  LCL_DESTUFF: { value: "LCL Destuff", key: "LCL_DESTUFF", cycle: CycleType.IMPORT },
  LCL_DELIVERY: { value: "LCL Delivery", key: "LCL_DELIVERY", cycle: CycleType.IMPORT },
  SMTP_TRUCK_MOVEMENT: { value: "Hub Truck Movement", key: "SMTP_TRUCK_MOVEMENT", cycle: CycleType.IMPORT },
  SMTP_STUFFING: { value: "Hub Stuffing", key: "SMTP_STUFFING", cycle: CycleType.IMPORT },
  SMTP_CONTAINER_DELIVERY: { value: "Hub Container Delivery", key: "SMTP_CONTAINER_DELIVERY", cycle: CycleType.IMPORT }
  //TRANSFER_TO_SCAN_AREA: {value: "Transfer To Scan Area", key: "TRANSFER_TO_SCAN_AREA", cycle: CycleType.IMPORT},
} as const;
export type JobOrderType = typeof JobOrderType[keyof typeof JobOrderType];

export const getJobOrderByKey = (key: string) => {
  return Object.values(JobOrderType).find((type) => type.key === key) || null;
};

export const getJobOrderTypeByValue = (value: string) => {
  return Object.values(JobOrderType).find((type) => type.value === value) || null;
};

export function getJobOrderTypesByCycle(cycle: Cycle) {
  return Object.entries(JobOrderType)
    .filter(([_, value]) => value.cycle.key === cycle.key)
    .map(([key, value]) => ({
      key,
      value: value.value,
      cycle: value.cycle
    }));
}

export const UploadType = {
  IGM: { label: "IGM/CGM", key: "igm" },
  CSV: { label: "CSV", key: "csv" },
  SCAN_LIST: { label: "Scan List", key: "scanList" }
} as const;

//Tally Type
export const TallyType = {
  RMS_EXAMINATION_TALLY: {
    displayName: "RMS and Examination Tally",
    code: "RMS_EXAMINATION_TALLY",
    component: ManageExaminationTallyComponent
  },
  SEAL_CUT_EXAMINATION_TALLY: {
    displayName: "Seal Cut and Examination Tally",
    code: "SEAL_CUT_EXAMINATION_TALLY",
    component: ManageExaminationTallyComponent
  },
  DOCK_DELIVERY_TALLY: {
    displayName: "Dock Delivery Tally",
    code: "DOCK_DELIVERY_TALLY",
    component: NoDataComponent,
    getComponent: (details?: any) => {
      if (details?.additionalDetails?.destuffTo === "TRUCK") {
        return ManageTallyDestuffingFclTruckComponent;
      }else{
        return ManageTallyDestuffingFclComponent;
      }
    }
  },
  LCL_DESTUFF_TALLY: {
    displayName: "LCL Destuff Tally",
    code: "LCL_DESTUFF_TALLY",
    component: ManageTallyDestuffingLclComponent
  },
  LCL_DELIVERY_TALLY: {
    displayName: "Loose Cargo Delivery Tally",
    code: "LCL_DELIVERY_TALLY",
    component: ManageLclDeliveryTallyComponent
  },
  SMTP_TRUCK_MOVEMENT_TALLY: {
    displayName: "SMTP Truck Movement Tally",
    code: "SMTP_TRUCK_DELIVERY_TALLY",
    component: ManageTallySmtpTruckMovementComponent
  },
  SMTP_STUFFING_TALLY: {
    displayName: "SMTP Stuffing Tally",
    code: "SMTP_CONTAINER_STUFFING_TALLY",
    component: ManageSmtpStuffingTallyComponent
  },
  SMTP_CONTAINER_DELIVERY_TALLY: {
    displayName: "SMTP Container Delivery Tally",
    code: "SMTP_CONTAINER_DELIVERY_TALLY",
    component: NoDataComponent //TODO: Change this to ManageTallySmtpContainerDeliveryComponent if this tally is required
  }
} as const;
export type TallyType = typeof TallyType[keyof typeof TallyType];

export const JobOrderTypeVsTallyType: Record<string, TallyType> = {
  "RMS_AND_EXAMINATION": TallyType.RMS_EXAMINATION_TALLY,
  "SEAL_CUT_EXAMINATION": TallyType.SEAL_CUT_EXAMINATION_TALLY,
  "LCL_DESTUFF": TallyType.LCL_DESTUFF_TALLY,
  "DOCK_DELIVERY": TallyType.DOCK_DELIVERY_TALLY,
  "LCL_DELIVERY": TallyType.LCL_DELIVERY_TALLY,
  "SMTP_STUFFING": TallyType.SMTP_STUFFING_TALLY,
  "SMTP_CONTAINER_DELIVERY": TallyType.SMTP_CONTAINER_DELIVERY_TALLY,
  "SMTP_TRUCK_MOVEMENT": TallyType.SMTP_TRUCK_MOVEMENT_TALLY
};

export function getTallyTypeByJobOrderType(jobOrderType: string): TallyType {
  return JobOrderTypeVsTallyType[jobOrderType];
}

export const HoldType = {
  INTERNAL: {label: "Internal", key: "INTERNAL"},
  CUSTOMS: {label: "Customs", key: "CUSTOMS"}
} as const;
export type HoldType = typeof HoldType[keyof typeof HoldType];

export const HoldSource = {
  SIIB: { label: "SIIB", key: "SIIB", holdType: HoldType.CUSTOMS },
  DRI: { label: "DRI", key: "DRI", holdType: HoldType.CUSTOMS },
  RI: { label: "R&I", key: "RI", holdType: HoldType.CUSTOMS },
  CCCP: { label: "CCCP", key: "CCCP", holdType: HoldType.CUSTOMS },
  OPERATIONS: { label: "Operations", key: "OPERATIONS", holdType: HoldType.INTERNAL },
  FINANCE: { label: "Finance", key: "FINANCE", holdType: HoldType.INTERNAL }
} as const;
export type HoldSource = typeof HoldSource[keyof typeof HoldSource];

export const HoldReason = {
  SEAL_MISMATCH: { label: "Seal Mismatch", key: "SEAL_MISMATCH", holdSource: HoldSource.OPERATIONS },
  SCAN_INFO_MISSING: { label: "Scan Info Missing", key: "SCAN_INFO_MISSING", holdSource: HoldSource.OPERATIONS },
  DELIVERY_ORDER_NOT_SUBMITTED: { label: "DO Not Submitted", key: "DELIVERY_ORDER_NOT_SUBMITTED", holdSource: HoldSource.OPERATIONS },
  CARGO_OR_CONTAINER_DAMAGED: { label: "Cargo/Container Damaged", key: "CARGO_OR_CONTAINER_DAMAGED", holdSource: HoldSource.OPERATIONS },
  DOCUMENT_NOT_SUBMITTED: { label: "Document Not Submitted", key: "DOCUMENT_NOT_SUBMITTED", holdSource: HoldSource.OPERATIONS },
  OTHER: { label: "Other", key: "OTHER" }
} as const;
export type HoldReason = typeof HoldReason[keyof typeof HoldReason];




