import { HttpInterceptorFn, HttpResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export const RedirectInterceptor: HttpInterceptorFn = (req, next) => {
  const router = inject(Router);

  return next(req).pipe(
    // Filter to only process HttpResponse events
    map((event) => {
      if (event instanceof HttpResponse && event.status === 200) {
        const responseBody: any = event.body;
        const redirectLocation: string = responseBody.data?.redirectLocation;
        if (redirectLocation) {
          console.warn('Found redirection instruction from server');

          console.warn('Forced redirection to user management');
          if (redirectLocation === 'dashboard') {
            router.navigateByUrl(`/admin/manage-users`);
          }
          // router.navigateByUrl(`/${redirectLocation}`);
          // router.navigateByUrl(`/${redirectLocation}`, { replaceUrl: true });
        }
      }
      return event;
    }),
    // Handle errors and potential redirects
    catchError((err) => {
      return throwError(() => err);
    })
  );
};
