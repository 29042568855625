@if(!isSearching){
<div class="sidebar"
     (mouseenter)="expandSidebar()"
     (mouseleave)="collapseSidebar()">
  <div class="navigation">
    <img class="shield_icon" alt="" src="assets/images/smarttos-logo-small.svg">

    <div class="nav_items">
      <div class="nav" (click)="loadSearchOverlay()">
        <div class="application-nav-menu-button"
        >
          <img class="menu_icon" alt="" src="assets/icons/search_icon_sb.svg">
        </div>
        <div class="menu_text">Search</div>
      </div>

      @for (menu of menuItems; track menu.id) {
        <div class="nav" (click)="handleClickOnNavItem(menu)">
          <div class="application-nav-menu-button"
               [ngClass]="{
            'active': isActiveParent(menu.id),
            'pre_select': isPreSelected(menu.id)
          }"
          >
            <img class="menu_icon" alt="" src="{{menu.icon}}">
          </div>
          <div class="menu_text">{{ menu.name }}</div>
        </div>
      }
      @if (expandedMenu && isExpanded) {
        <app-sidebar-expanded
          [toggleSidebar]="togglePin.bind(this)"
          [isPinned]="isPinned"
          [parentMenu]="expandedMenu"
          [childResources]="expandedMenu.childResources"
          (activeMenuChanged)="onActiveMenuChanged($event)"

        ></app-sidebar-expanded>
      }


      <!--      <div class="nav3">-->
      <!--        <div class="application-nav-menu-button3">-->
      <!--          <img class="menu_icon" alt="" src="assets/icons/admin_menu_icon.svg">-->
      <!--        </div>-->
      <!--        <div class="menu_text">Admin</div>-->
      <!--      </div>-->
    </div>
  </div>
  <div class="footer">
    <div class="footer-inner">
      <div class="nav-item-button-parent">
        <div class="nav-item-button">
          <img class="settings-01-icon" alt="" src="settings-01.svg">
        </div>
        <div class="settings">Settings</div>
      </div>
    </div>
    <img class="avatar-icon" alt="" (click)="logout()" src="assets/icons/avatar.svg">
  </div>
</div>
} @else {
  <app-search-overlay
    (searchingEmit)="setSearching($event)"
  [menuItems]="menuItems"
  ></app-search-overlay>
}
