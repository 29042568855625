<header class="header">
    <div class="header-content">
        <div class="header-text">
            <div class="title">Gate In - Trailer</div>
            <div class="subtitle">Fill in trailer information to gate in</div>
        </div>
        <div class="header-actions">
            <button class="btn secondary_button" (click)="resetForm()">Reset</button>
            <button class="btn primary_button" (click)="saveForm()">Save</button>
        </div>
    </div>
    <div class="divider"></div>
</header>
<div class="form-container">
    <form [formGroup]="gateInForm">
      <div class="form-row">
          <div class="form-group">
              <label for="cycle" class="form-label">
              Cycle<span class="required">*</span>
              </label>
              <select id="cycle" formControlName="cycle" class="form-select" required (change)="onCycleChange($event)" >
                  <option value="">Select Cycle</option>
                  <option *ngFor="let option of cycleOptions" [value]="option.value">{{ option.label }}</option>
              </select>
              <div class="form_validation_error" *ngIf="gateInForm.get('cycle')?.invalid && gateInForm.get('cycle')?.touched">
                  <small *ngIf="gateInForm.get('cycle')?.errors?.['required']">Please select the cycle</small>
              </div>
          </div>
      </div>
      <div class="form-row">
        <div class="form-group">
            <label for="gateInMode" class="form-label">
              Gate-In Mode<span class="required">*</span>
            </label>
            <select id="gateInMode" formControlName="gateInMode" class="form-select" required>
                <option value="">Select Mode</option>
                <option *ngFor="let option of gateInOptions" [value]="option.key">{{ option.label }}</option>
            </select>
            <div class="form_validation_error" *ngIf="gateInForm.get('gateInMode')?.invalid && gateInForm.get('gateInMode')?.touched">
                <small *ngIf="gateInForm.get('gateInMode')?.errors?.['required']">Please select the mode</small>
            </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group">
          <label for="laneNo" class="form-label">
            Lane No<span class="required">*</span>
          </label>
          <input type="text" id="laneNo" formControlName="laneNo" 
          class="form-input" placeholder="Enter lane no." value="122423545758" required 
          [ngClass]="{
              form_error:
                gateInForm.get('laneNo')?.invalid &&
                gateInForm.get('laneNo')?.touched &&
                gateInForm.get('laneNo')?.errors
            }"
          />
          <div class="form_validation_error" *ngIf="gateInForm.get('laneNo')?.invalid && gateInForm.get('laneNo')?.touched">
              <small *ngIf="gateInForm.get('laneNo')?.errors?.['required']">Lane No is required</small>
          </div>
        </div>
      </div>
      <div class="form-row">
          <div class="checkbox-group">
              <input type="checkbox" id="ownVehicle" class="checkbox-input" formControlName="ownVehicle" (change)="onOwnVehicleChange()" />
              <label for="ownVehicle" class="checkbox-label">Own Vehicle</label>
          </div>
          <div class="checkbox-group">
              <input type="checkbox" id="ownMovement" formControlName="ownMovement" [class.readonly]="isOwnMovementReadonly" class="checkbox-input" />
              <label for="ownMovement" class="checkbox-label">Own Movement</label>
          </div>
      </div>
      
      <div class="form-row">
          <div class="form-group">
              <label for="trailerNo" class="form-label">
                Trailer No<span class="required">*</span>
              </label>
              <input
                  *ngIf="!ownVehicle"
                  type="text"
                  id="trailerNo"
                  formControlName="trailerNo"
                  class="form-input"
                  required
                  placeholder="Enter Trailer No."
                  [ngClass]="{
                      form_error:
                        gateInForm.get('trailerNo')?.invalid &&
                        gateInForm.get('trailerNo')?.touched &&
                        gateInForm.get('trailerNo')?.errors
                    }"
              />
              
              <app-autocomplete-dropdown
              *ngIf="ownVehicle"
                  id="trailerNo"
                  formControlName="trailerNo"
                  class = "autocomplete-dropdown"
                  [placeholder]="'Search Trailer'"
                  [apiUrl]="'v1/trailers/list'"
                  [selectFields]="['id', 'trailerNumber', 'vendorId.id as vendorId', 'vendorId.name as vendorName', 'vehicleTypeId.id as vehicleTypeId', 'vehicleTypeId.generalPurposeType as vehicleTypeValue']"
                  [whereFields]="['trailerNumber']"
                  [limit]="10"
                  [orderBy]="{ trailerNumber: 'asc' }"
                  [filterByKey]="'trailerNumber'"
                  [bindLabel]="'trailerNumber'"
                  [bindValue]="'id'"
                  [displayColumns]="[
                      { key: 'trailerNumber', header: 'Trailer No' },
                      { key: 'vendorName', header: 'Transporter Name' },
                  ]"
                  (suggestionSelected) = "onTrailerSelected($event)"

                  >
                  </app-autocomplete-dropdown>
              <div class="form_validation_error" *ngIf="gateInForm.get('trailerNo')?.invalid && gateInForm.get('trailerNo')?.touched">
                  <small *ngIf="gateInForm.get('trailerNo')?.errors?.['required']">Trailer No is required</small>
              </div>
          </div>
          <div class="form-group">
              <label for="transporterName" class="form-label">
                Transportner Name<span class="required">*</span>
              </label>
              <input type="text" 
              id="transporterName" 
              formControlName="transporterName" 
              class="form-input" 
              placeholder="Enter Transporter Name" 
              required 
              [ngClass]="{
                  form_error:
                    gateInForm.get('transporterName')?.invalid &&
                    gateInForm.get('transporterName')?.touched &&
                    gateInForm.get('transporterName')?.errors
                }"
              />
              <div class="form_validation_error" *ngIf="gateInForm.get('transporterName')?.invalid && gateInForm.get('transporterName')?.touched">
                  <small *ngIf="gateInForm.get('transporterName')?.errors?.['required']">Transporter Name is required</small>
              </div>
          </div>
      </div>
      <div class="form-row">
          <div class="form-group">
              <label for="trailerTypeId" class="form-label">Trailer Type<span class="required">*</span></label>
              <app-autocomplete-dropdown
                #trailerTypeDropdown
                  id="trailerTypeId"
                  name="trailerTypeId"
                  formControlName="trailerTypeId"
                  class = "autocomplete-dropdown"
                  [placeholder]="'Select Trailer Type'"
                  [apiUrl]="'v1/vehicle-types/list'"
                  [selectFields]="['id', 'generalPurposeType',]"
                  [whereFields]="['generalPurposeType']"
                  [limit]="10"
                  [orderBy]="{ generalPurposeType: 'asc' }"
                  [filterByKey]="'generalPurposeType'"
                  [bindLabel]="'generalPurposeType'"
                  [bindValue]="'id'"
                  [disable]="gateInForm.disabled"
                  (suggestionSelected)='trailerTypeDataRes && trailerTypeDataRes.id=$event'
                  [displayColumns]="[
                      { key: 'generalPurposeType', header: 'Vehicle Type' },
                  ]"
                  [additionalFilter]='{       
                          "name": "vehicleType",
                          "alias": "vehicleType",
                          "operator": "EQ",
                          "value": [
                              "TRAILER"
                          ]
                  }'
                  >
              </app-autocomplete-dropdown>
          </div>
      </div>
      <div class="form-row">
        <div class="form-group">
          <label for="driverName" class="form-label">
            Driver Name<span class="required">*</span>
          </label>
          <input type="text" id="driverName" formControlName="driverName" class="form-input" value="Vessel" placeholder="Enter Driver Name" required 
          [ngClass]="{
              form_error:
                gateInForm.get('driverName')?.invalid &&
                gateInForm.get('driverName')?.touched &&
                gateInForm.get('driverName')?.errors
            }"
          />
          <div class="form_validation_error" *ngIf="gateInForm.get('driverName')?.invalid && gateInForm.get('driverName')?.touched">
              <small *ngIf="gateInForm.get('driverName')?.errors?.['required']">Driver Name is required</small>
          </div>
      </div>
        <div class="form-group">
          <label for="driverLicenseNo" class="form-label">
            Driver License No<span class="required">*</span>
          </label>
          <input type="text" id="driverLicenseNo" formControlName="driverLicenseNo" class="form-input" value="Vessel" placeholder="Enter Driver License no" required 
          [ngClass]="{
              form_error:
                gateInForm.get('driverLicenseNo')?.invalid &&
                gateInForm.get('driverLicenseNo')?.touched &&
                gateInForm.get('driverLicenseNo')?.errors
            }"
          />
          <div class="form_validation_error" *ngIf="gateInForm.get('driverLicenseNo')?.invalid && gateInForm.get('driverLicenseNo')?.touched">
              <small *ngIf="gateInForm.get('driverLicenseNo')?.errors?.['required']">Driver License No is required</small>
          </div>
      </div>
      </div>
      <div class="form-row">
      </div>
    </form>
  </div>



