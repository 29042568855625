<header class="page_header">
    <div class="header_content">
      <div class="text_and_supporting_text">
        <div class="page_title">Charge</div>
        <div class="page_desc">Create General Charges</div>
      </div>
      <div class="action_btn">
        <button class="add_new_btn" (click)="addNewRecord()">
          <img src="assets/icons/plus_icon.svg" height="20" width="20">Add New
        </button>
      </div>
    </div>
  </header>

  <div class="search_bar_action_btns">
    <div class="container">
      <div class="search_bar">
        <div class="search_container">
          <input
            type="text"
            name="search_table"
            id="search_table"
            [(ngModel)]="searchTerm"
            placeholder="Search by name or code"
            (focus)="focusSearch = true"
            (focusout)="focusSearch = false"
            (keyup)="handleTableSearch($event)"
          >
          <img src="assets/icons/search_icon.svg" class="search_icon">
        </div>
      </div>

      <div class="filter_btn">
        <button (click)="filter()">
          <img src="assets/icons/filter.svg">
          Filters
        </button>
      </div>
    </div>
  </div>

  <div class="tos-table-template">
    <div class="table-stats">
      <div class="left">
        <div class="total-users">
          Total Charges: {{ tableStats.total }}
        </div>
        <div class="active-users">
          Active: {{ tableStats.active }}
        </div>
        <div class="inactive-users">
          Inactive: {{ tableStats.inactive }}
        </div>
      </div>
      <div class="right">
        <div class="bulk_actions">
          <ng-container *ngIf="selectedItems.length > 0 || allTableRecordsSelected">
            <div class="selection_count">
              <div class="count">
                {{
                  allTableRecordsSelected
                    ? tableStats.total
                    : selectedItems.length
                }}
                selected
              </div>

            </div>
            <div class="actions">
              <button
                class="tick"
                (click)="bulkActionConfirmation('setActive')"
              >
                Make Charges Active
              </button>
              <button
                class="close"
                (click)="bulkActionConfirmation('setInActive')"
              >
                Make Charges Inactive
              </button>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="table-container">
      <ng-container *ngIf="tableLoaded && tableStats.total === 0">
        <app-no-data
          [msg]="
                'No charges are available in the system. Please click on Add New button to create one.'
              "
        ></app-no-data>
      </ng-container>
      <ng-container
        *ngIf="
              tableLoaded &&
              tableStats.total > 0 &&
              tableListData.records?.length > 0
            "
      >
        <table>
          <thead>
          <tr>
            <th class="cb_th select-all-cb">
              <input
                type="checkbox"
                id="select_all_users"
                [checked]="isAllRecordsOfCurrentPageSelected()"
                (change)="
                        toggleAllRecordsOfCurrentPageSelections(
                          $event.target.checked
                        )
                      "
              />
            </th>
<!--            <th class="sl_no_th">Sl No.</th>-->
            <th
              class="code_th sortable"
              [ngClass]="{ sorted: sortColumn === 'code' }"
              (click)="sortData('code')"
            >
              <div class="icon_cover">
                Charge Code
                <app-svg-icon
                  [path]="'assets/icons/sort_icon.svg'"
                ></app-svg-icon>
              </div>
            </th>
            <th
              class="name_th sortable"
              [ngClass]="{ sorted: sortColumn === 'name' }"
              (click)="sortData('name')"
            >
              <div class="icon_cover">
                Name
                <app-svg-icon
                  [path]="'assets/icons/sort_icon.svg'"
                ></app-svg-icon>
              </div>
            </th>
            <th class="description_th">Description</th>
            <th class="ifsc_code_th">HSN/SAC Code</th>
            <th
              class="status_th sortable"
              [ngClass]="{ sorted: sortColumn === 'active' }"
              (click)="sortData('active')"
            >
              <div class="icon_cover">
                Status
                <app-svg-icon
                  [path]="'assets/icons/sort_icon.svg'"
                ></app-svg-icon>
              </div>
            </th>

            <th class="branch_th">Print Name</th>
<!--            <th class="storage_non_storage_th">Storage/Non-Storage</th>-->
            <th class="gst_pecentage">GST Percentage</th>
            <!--<th class="roles_th">Determinants</th>-->
            <th
              class="created_date_th sortable"
              [ngClass]="{ sorted: sortColumn === 'createdAt' }"
              (click)="sortData('createdAt')"
            >
              <div class="icon_cover">
                Created date
                <app-svg-icon
                  [path]="'assets/icons/sort_icon.svg'"
                ></app-svg-icon>
              </div>
            </th>

            <th
              class="modified_date_th sortable"
              [ngClass]="{ sorted: sortColumn === 'modifiedAt' }"
              (click)="sortData('modifiedAt')"
            >
              <div class="icon_cover">
                Modified date
                <app-svg-icon
                  [path]="'assets/icons/sort_icon.svg'"
                ></app-svg-icon>
              </div>
            </th>
            <th
              class="modifiedBy_th sortable"
              [ngClass]="{ sorted: sortColumn === 'modifiedBy' }"
              (click)="sortData('modifiedBy')"
            >
              <div class="icon_cover">
                Modified By
                <app-svg-icon
                  [path]="'assets/icons/sort_icon.svg'"
                ></app-svg-icon>
              </div>
            </th>

            <th class="table_menu_th">
              <!-- <div class="icon_cover plus_icon">
                    <app-svg-icon
                      [path]="'assets/icons/plus_icon.svg'"
                    ></app-svg-icon>
                  </div> -->
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
            *ngFor="let record of tableListData.records; let i = index"
            [ngClass]="isSelected(record) ? 'selected_row' : ''"
          >
            <td class="cb_td row-select-cb">
              <input
                type="checkbox"
                [id]="'user_sl_no_' + i + 1"
                [checked]="isSelected(record)"
                (change)="toggleSelection(record)"
              />
            </td>
            <td class="code_td" [title]="record.code">
                <span
                  [innerHTML]="record.code | highlight : searchTerm"
                ></span>
            </td>
            <td class="name_td" [title]="record.name">
                    <span
                      [innerHTML]="record.name | highlight : searchTerm"
                    ></span>
            </td>
            <td class="description_td" [title]="record.description">{{ record.description }}</td>
            <td class="ifsc_code_td" [title]="record.hsnOrSacCode">{{ record.hsnOrSacCode }}</td>
            <td class="status_td">
              <div
                class="icon_cover"
                [ngClass]="record.active ? 'active' : 'inactive'"
              >
                <div class="badge_status">
                  <app-svg-icon
                    [path]="'assets/icons/circle_icon.svg'"
                  ></app-svg-icon>
                  {{ record.active ? "Active" : "Inactive" }}
                </div>
              </div>
            </td>
            <td class="branch_td" [title]="record.printName">{{ record.printName }}</td>
<!--            <td class="storage_non_storage">{{record}}</td>-->
            <td class="gst_pecentage">
              {{ record.gstPercentage }}
            </td>

            <!--<td class="roles_td">
                @if (record.determinants && record.determinants.length > 0) {
                  <div class="role_container">
                    <div class="first_role">{{ record.determinants[0] }}</div>
                    <div class="second_role" *ngIf="record.determinants?.[1]">{{ record.determinants?.[1] }}</div>
                    <div class="third_role" *ngIf="record.determinants?.[2]?.name">{{ record.determinants?.[2] }}</div>
                    <div
                      class="role_count"
                      [appTooltip]="record.determinants.slice(3)"
                      [keyToPick]="'name'"
                      position="bottom"
                      *ngIf="record.determinants.length > 3"
                    >
                      + {{ record.determinants.length - 3 }}
                    </div>
                  </div>
                } @else {

                }
              </td>-->

            <td class="created_date_td">
              {{ record.createdAt | date : "dd/MM/yy hh:mm a" }}
            </td>
            <td class="modified_date_td">
              {{ record.modifiedAt | date : "dd/MM/yy hh:mm a" }}
            </td>
            <!-- <td class="createdBy_td">
                {{ record.createdBy || "N/A" }}
              </td> -->
            <td class="modifiedBy_td">
              {{ record.modifiedBy || "N/A" }}
            </td>
            <td class="table_menu_td">
              <div class="menu_component">
                <app-table-menu
                  [options]="menuOptions"
                  [isOpen]="openMenuIndex === i"
                  (toggle)="handleMenuToggle(i)"
                  (optionSelected)="handleOptionSelected(record, $event)"
                  [tableRowData]="record"
                >
                </app-table-menu>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </ng-container>
      <ng-container
        *ngIf="
              tableLoaded &&
              tableStats.total > 0 &&
              tableListData.records?.length === 0
            "
      >
        <app-no-data-found [msg]="'No results found !'"></app-no-data-found>
      </ng-container>
    </div>
    <div class="pagination" [hidden]="tableListData.totalCount === 0">
      <!--        <span class="info">-->
      <!--          Showing-->
      <!--          {{-->
      <!--            currentTablePage === 1-->
      <!--              ? 1-->
      <!--              : currentTablePage * rowsPerPage + 1 - rowsPerPage-->
      <!--          }}-->
      <!--          to-->
      <!--          {{-->
      <!--            currentTablePage * rowsPerPage > tableListData.totalCount-->
      <!--              ? tableListData.totalCount-->
      <!--              : currentTablePage * rowsPerPage-->
      <!--          }}-->
      <!--          of {{ tableListData.totalCount }} users-->
      <!--        </span>-->
      <!--      <div class="buttons">-->
      <!--        <button-->
      <!--          class="previous_btn"-->
      <!--          (click)="previousPage()"-->
      <!--          [disabled]="currentTablePage === 1"-->
      <!--          title="Previous Page"-->
      <!--        >-->
      <!--          <app-svg-icon [path]="'assets/icons/left-arrow.svg'"></app-svg-icon>-->
      <!--        </button>-->

      <!--        &lt;!&ndash; <button *ngIf="getVisiblePages()[0] > 1" disabled>...</button>-->

      <!--        <button-->
      <!--          *ngFor="let page of getVisiblePages()"-->
      <!--          (click)="goToPage(page)"-->
      <!--          [class.active]="currentPage === page"-->
      <!--        >-->
      <!--          {{ page }}-->
      <!--        </button>-->

      <!--        <button-->
      <!--          *ngIf="getVisiblePages().slice(-1)[0] < totalTablePages"-->
      <!--          disabled-->
      <!--        >-->
      <!--          ...-->
      <!--        </button> &ndash;&gt;-->

      <!--        &lt;!&ndash; <div class="buttons_scroll">-->
      <!--          <button-->
      <!--            *ngFor="-->
      <!--              let page of [].constructor(totalTablePages);-->
      <!--              let i = index-->
      <!--            "-->
      <!--            (click)="goToPage(i + 1)"-->
      <!--            [class.active]="currentPage === i + 1"-->
      <!--          >-->
      <!--            {{ i + 1 }}-->
      <!--          </button>-->
      <!--        </div> &ndash;&gt;-->

      <!--        <ng-container *ngIf="totalTablePages">-->
      <!--          <button-->
      <!--            *ngFor="let page of [].constructor(totalTablePages); let i = index"-->
      <!--            (click)="goToPage(i + 1)"-->
      <!--            [class.active]="currentTablePage === i + 1"-->
      <!--          >-->
      <!--            {{ i + 1 }}-->
      <!--          </button>-->
      <!--        </ng-container>-->

      <!--        <button-->
      <!--          class="next_btn"-->
      <!--          (click)="nextPage()"-->
      <!--          [disabled]="currentTablePage === totalTablePages"-->
      <!--          title="Next Page"-->
      <!--        >-->
      <!--          <app-svg-icon [path]="'assets/icons/right-arrow.svg'"></app-svg-icon>-->
      <!--        </button>-->
      <!--      </div>-->

      <div class="button_wrapper_left" (click)="previousPage()">
        <button><img src="assets/icons/left-arrow.svg"> Previous</button>
      </div>
      <ng-container *ngIf="totalTablePages" class="page_numbers">
        <div
          *ngFor="let page of getDisplayedPages()"
          (click)="page !== '...' ? goToPage(page) : null"
          [class.page_number]="page !== '...'"
          [class.ellipsis]="page === '...'"
          [class.active]="currentTablePage === page"
        >
          {{ page }}
        </div>
      </ng-container>
      <div class="button_wrapper_right" (click)="nextPage()">
        <button>Next <img src="assets/icons/right-arrow.svg"></button>
      </div>

<!--          <span class="info"> Per page:</span>
          <select
            id="rowsPerPage"
            class="row-options"
            #rowOptions
            (change)="updateRowsPerPage(rowOptions.value)"
          >
            <option
              class="option"
              *ngFor="let option of rowsPerPageOptions"
              [value]="option"
            >
              {{ option }}
            </option>
          </select>-->
    </div>
  </div>

