{
  "filterConfig": {
    "filterFields": [ 
        {
            "name": "containerId.containerNo",
            "displayLabel": "Container No",
            "dataType": "STRING"
        },
        {
          "name": "cargoId.igmItemNo",
          "displayLabel": "IGM Item No",
          "dataType": "STRING"
        },
        {
            "name": "cargoId.chaId",
            "displayLabel": "CHA",
            "dataType": "STRING",
            "hasLookup": true,
            "searchConfig": {
                "apiUrl": "v1/customers/list",
                "selectFields": ["id", "name", "code"],
                "whereFields": ["name", "code"],
                "limit": 10,
                "orderBy": { "name": "asc" },
                "bindLabel": "name",
                "bindValue": "id",
                "displayColumns": [
                  { "key": "name", "header": "Name" }
                ]
            }
        },
        {
          "name": "cargoId.accountHolder",
          "displayLabel": "Account Holder",
          "dataType": "STRING",
          "hasLookup": true,
          "searchConfig": {
              "apiUrl": "v1/customers/list",
              "selectFields": ["id", "name", "code"],
              "whereFields": ["name", "code"],
              "limit": 10,
              "orderBy": { "name": "asc" },
              "bindLabel": "name",
              "bindValue": "id",
              "displayColumns": [
                { "key": "name", "header": "Name" }
              ]
          }
      },
      {
          "name": "holdDocumentNo",
          "displayLabel": "Hold Document No",
          "dataType": "STRING"
      }
    ]
  },
  "tableConfig": {
    "name": "hold",
    "dbName": "hold",
    "columns": [
      {
        "fieldName": "containerNo",
        "displayName": "Container No",
        "dataType": "STRING"
      },
      {
        "fieldName": "igmItemNo",
        "displayName": "IGM Item No",
        "dataType": "STRING"
      },
      {
        "fieldName": "accountHolder",
        "displayName": "Account Holder",
        "dataType": "STRING"
      },
      {
        "fieldName": "cha",
        "displayName": "CHA",
        "dataType": "STRING"
      },
      {
        "fieldName": "holdCategoryLabel",
        "displayName": "Hold Type",
        "dataType": "STRING",
        "styleConfigs": [
          {
            "value": "CUSTOMS",
            "iconPath": "",
            "className": "icon_cover",
            "style": {
              "color": "red"
            }
          },
          {
            "value": "INTERNAL",
            "iconPath": "",
            "className": "icon_cover",
            "style": {
              "color": "green"
            }
          }
        ]
      },
      {
        "fieldName": "holdIssuedByLabel",
        "displayName": "Issued By",
        "dataType": "STRING"
      },
      {
        "fieldName": "holdSubCategoryLabel",
        "displayName": "Hold Reason",
        "dataType": "STRING"
      },
      {
        "fieldName": "holdDocumentNo",
        "displayName": "Hold Document No",
        "dataType": "STRING"
    },
      {
        "fieldName": "holdDate",
        "displayName": "Hold Date",
        "dataType": "DATETIME"
      }
  
    ]
  }
}