import { Component, EventEmitter, Input, Output, ViewContainerRef } from '@angular/core';
import { StaticTableComponent } from "../../../static-table/static-table.component";
import { TableMeta } from '../../../../models/table-meta.interface';
import { OperationService } from '../../../../services/operations.service';
import { TableListRequest } from '../../../../models/table-list-request.interface';
import { PaginatedListDataResponse } from '../../../../models/paginated-list-data-response';
import { TosTableComponent } from "../../../tos-table/tos-table.component";
import { Where } from '../../../../models/user-list-request.interface';
import { SharedModule } from '../../../../modules/shared/shared.module';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SvgIconComponent } from "../../../svg-icon/svg-icon.component";
import { Router } from '@angular/router';
import { ToasterService } from '../../../../services/toaster.service';
import { MultiSelectComponent } from '../../../select/multi-select.component';
import { HoldReason, HoldSource, HoldType } from '../../../../constants/operations-contsants';

@Component({
  selector: 'app-release-document',
  standalone: true,
  imports: [SharedModule, StaticTableComponent, TosTableComponent, SvgIconComponent, MultiSelectComponent],
  templateUrl: './release-document.component.html',
  styleUrl: './release-document.component.scss'
})
export class ReleaseDocumentComponent {

  @Input() tableMeta!: TableMeta;
  @Input() holdDocumentNo!: string;
  @Input() processCycle!: string;

  @Output() onSuccessfulSave = new EventEmitter<void>();
  
  releaseForm!: FormGroup;
  initialPatchDone: boolean = false;
  holdSourceOptions = Object.values(HoldSource);
  holdTypeOptions = Object.values(HoldType);
  holdReasonOptions = Object.values(HoldReason);

  defaultFilters!: Where;
  selectedItems: any[] = [];
  tableListData: PaginatedListDataResponse = {
      totalCount: 0,
      page: 0,
      limit: 0,
      records: []
  };

  sortColumn: string = "modifiedDate";
  sortDirection: "asc" | "desc" = "desc";
  searchFilterData!: Where | null;
  focusSearch: boolean = false;
  searchTerm: string = "";
  previousTerm: string = "";
  allRecordsSelected: boolean = false;
  
  currentPage: number = 0;
  rowsPerPage: number = 15;

  tableListRequest: TableListRequest = {
    selectFields: [
      "id",
      "containerId.containerNo",
      "cargoId.igmItemNo",
      "cargoId.accountHolder.name as accountHolder",
      "cargoId.chaId.name as cha",
      "holdCategory",
      "holdIssuedBy",
      "holdSubCategory",
      "holdDocumentNo",
      "holdDate",
      "holdRemarks",
      "modifiedDate"
    ],
    orderBy: {
      [this.sortColumn]: this.sortDirection
    },
    includeTotal: true,
    limit: this.rowsPerPage,
    page: this.currentPage
  };

  constructor(private fb: FormBuilder,
                private viewContainerRef: ViewContainerRef,
                private api: OperationService,
                private router: Router,
                private toasterService: ToasterService
  ) {

  }

  ngOnInit() {
    this.processCycle = history.state.processCycle;
    this.tableMeta = history.state.tableMeta;
    this.holdDocumentNo = history.state.holdDocumentNo;
    this.initForm();
    this.initialiseDefaultFilter();
    this.listItemsOnHold();
  }

  initForm() {
    this.releaseForm = this.fb.group({
      holdIssuedBy: [{ value: null, disabled: true }, Validators.required],
      holdCategory: [{ value: null, disabled: true }, Validators.required],
      holdSubCategory: [{ value: null, disabled: true }, Validators.required],
      holdDocumentNo: ['', Validators.required],
      releaseDocumentNo: [''],
      releaseDate: [null, Validators.required]
    });
  }

  hydrateForm() {
    if (this.tableListData.records.length < 1) return;
    const record = this.tableListData.records[0];
    this.releaseForm.patchValue({
      ... record
    });
  }

  initialiseDefaultFilter() {
    this.defaultFilters = {
      filterConditions: [
        {
          name: "processCycle",
          alias: "processCycle",
          operator: "EQ",
          value: [this.processCycle]
        },
        {
          name: "holdDocumentNo",
          alias: "holdDocumentNo",
          operator: "EQ",
          value: [this.holdDocumentNo]
        },
        {
          name: "releaseDate",
          alias: "releaseDate",
          operator: "IS_NULL",
          value: []
        },
      ],
      expression: "processCycle AND holdDocumentNo AND releaseDate"
    }
  }

  handleTableSearch(event: KeyboardEvent) {
    const searchTerm = this.searchTerm.trim();

    if (searchTerm.length >= 3) {
      this.searchFilterData = {
        filterConditions: [
          {
            name: "containerId.containerNo",
            alias: "containerNoStartsWith",
            operator: "CONTAINS",
            value: [searchTerm]
          },
          {
            name: "cargoId.igmItemNo",
            alias: "cargoNoStartsWith",
            operator: "CONTAINS",
            value: [searchTerm]
          }
        ],
        expression: "containerNoStartsWith OR cargoNoStartsWith"
      };
      this.listItemsOnHold();
    }
    if (searchTerm.length === 0 && this.previousTerm.length !== 0) {
      this.searchFilterData = null;
      this.listItemsOnHold();
    }
    this.previousTerm = searchTerm;
  }

  listItemsOnHold() {
    this.mergeFilters();
    this.api.listHoldRecords(this.tableListRequest).subscribe({
      next: (res: any) => {
        this.tableListData = res.data;
        if (!this.initialPatchDone) {
          this.hydrateForm()
        }
      },
      error: (err) => {
        console.error(err);
      }
    });
  }

  private clearSelection() {
    this.selectedItems = [];
    this.allRecordsSelected = false;
  }

  private selectAll() {
    this.allRecordsSelected = true;
  }

  mergeFilters() {
    delete this.tableListRequest.where;
    this.tableListRequest.where = JSON.parse(JSON.stringify(this.defaultFilters));
    
    if (!this.searchFilterData) return;

    if (!this.tableListRequest.where) {
      this.tableListRequest.where = {filterConditions: [], expression: ''};
    }
  
    if (this.searchFilterData?.filterConditions?.length) {
      this.searchFilterData.filterConditions.forEach((filter: any) => {
        this.tableListRequest.where?.filterConditions?.push(filter);
      });
    }
  
    // Merge expressions with proper checks
    const expressions: string[] = [];
    if (this.defaultFilters?.expression) {
      expressions.push(`(${this.defaultFilters.expression})`);
    }
    
    if (this.searchFilterData?.expression) {
      expressions.push(`(${this.searchFilterData.expression})`);
    }
  
    // Safely assign the joined expression
    this.tableListRequest.where.expression = expressions.join(' AND ');
  }

  cancel() {
    this.goBack();
  }

  goBack() {
    this.router.navigateByUrl('/manage-operations/hold', {state: {processCycle: this.processCycle}});
  }

  submit() {
      this.releaseForm.markAllAsTouched();
      
      if (this.releaseForm.invalid) {
        this.toasterService.error("Invalid form");
        return;
      }
  
      if (!this.allRecordsSelected && this.selectedItems.length < 1) {
        this.toasterService.error("Please select the items for hold");
        return;
      }
  
      const holdReleaseRequest = this.createHoldRequest();
      console.log("holdRequest: ", holdReleaseRequest);
      this.api.submitHoldReleaseRequest(holdReleaseRequest).subscribe({
        next: (res: any) => {
          this.toasterService.success("Successfully processed hold request");
          this.goBack();
        },
        error: (err) => {
          console.error(err);
          this.toasterService.error(err.error.errorDesc);
        }
      });
    }
  
    createHoldRequest() {
      let holdItemIdList = null;
      if (!this.allRecordsSelected) {
        holdItemIdList = this.selectedItems.map((item: any) => item.id);
      }
      const holdRequest = {
        ...this.releaseForm.value,
        processCycle: this.processCycle,
        holdCategory: this.releaseForm.get('holdCategory')?.value,
        holdIssuedBy: this.releaseForm.get('holdIssuedBy')?.value,
        holdSubCategory: this.releaseForm.get('holdSubCategory')?.value,
        releaseDate: new Date(this.releaseForm.get('releaseDate')?.value).getTime(),
        holdItemIdList,
      }
      return holdRequest;
    }
}
