<div class="tabs-container">
  <div class="tabs">
    <div 
      *ngFor="let tab of tabs" 
      class="tab" 
      [class.active]="selectedTab === tab.id"
      (click)="selectTab(tab.id)"
    >
      {{ tab.label }}
    </div>
  </div>
</div>
