import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Menu } from "../../../models/sidebar-menu.interface";
import { Router, RouterLink } from "@angular/router";
import { NgClass } from "@angular/common";

@Component({
  selector: 'app-sidebar-expanded',
  standalone: true,
  imports: [
    RouterLink,
    NgClass
  ],
  templateUrl: './sidebar-expanded.component.html',
  styleUrl: './sidebar-expanded.component.scss'
})
export class SidebarExpandedComponent {
  @Input() childResources!: Menu[];
  @Input() parentMenu!: Menu;
  @Input() toggleSidebar!: (event: Event) => void;
  @Input() isPinned: boolean = false;

  @Output() activeMenuChanged = new EventEmitter<Menu>();
  private activeMenu: Menu | undefined;

  constructor(private router: Router) {
  }

  ngOnInit(){
    this.highlightActiveMenu()
  }

  highlightActiveMenu() {
    const currentUrl = this.router.url;

    for (const child of this.childResources) {
      if (currentUrl.includes(<string>child.resourceProperties?.url)) {
        this.activeMenu = child;
        this.activeMenuChanged.emit(this.parentMenu);
        return;
      }
    }

    // this.activeMenuName = null; // Reset if no match is found
  }

  isActive(id: string) {
    return this.activeMenu?.id === id;
  }
}
