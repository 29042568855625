{
  "columns": [
    {
      "fieldName": "isSuperAdmin",
      "styleConfigs": [
        {
          "value": true,
          "iconPath": "",
          "className": "icon_cover",
          "style": {
            "color": "red"
          }
        },
        {
          "value": false,
          "iconPath": "",
          "className": "icon_cover",
          "style": {
            "color": "green"
          }
        }
      ]
    }
  ]
}
