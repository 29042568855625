<div class="sb_search_bar">
  <div class="navbar_header">
    <div class="smart_tos_logo_and_pin">
      <div class="full_logo">
        <img src="assets/icons/back_button.svg" (click)="turnOffSearching()" class="back_btn">
        <img src="assets/images/smarttos_logo_new.svg">
      </div>
    </div>

    <div class="search_bar_sb">
      <input type="text" placeholder="Search" (input)="onSearch($event.target.value)">
    </div>


    <div class="expanded_nav_items">

      <ul class="tree">

        @for (parent of filteredMenuItems; track parent.id) {
          <li>
            <div class="parent_nav_item">{{ parent.displayLabel }}</div>
            <ul>
              @for (child of parent.childResources; track child.id) {
                <li>
                  <a class="sub_nav_item" [routerLink]="child.resourceProperties?.url"
                  [ngClass]="{
                    'active': makeNavItemActive(child.resourceProperties.url)
                  }"
                  >
                    <div class="content">
                      <div class="text">{{ child.displayLabel }}</div>
                    </div>
                  </a>
                </li>
              }
            </ul>
          </li>
        }
      </ul>

    </div>
  </div>
</div>
