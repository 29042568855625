import { Component } from "@angular/core";
import { RouterModule } from "@angular/router";
import { SidebarComponent } from "../../components/sidebar/sidebar.component";
import { CommonModule } from "@angular/common";
import { LoaderComponent } from "../../components/loader/loader.component";
import { ProgressLoaderComponent } from "../../components/progress-loader/progress-loader.component";
import { SidebarNewComponent } from "../../components/sidebar-new/sidebar-new.component";

@Component({
  selector: "app-private-layout",
  imports: [
    RouterModule,
    SidebarComponent,
    CommonModule,
    LoaderComponent,
    ProgressLoaderComponent,
    SidebarNewComponent
  ],
  standalone: true,
  templateUrl: "./private-layout.component.html",
  styleUrl: "./private-layout.component.scss"
})
export class PrivateLayoutComponent {
  sidebarWidth = 0; // collapsed width

  isPinned = false;

  onSidebarToggle(event: { expanded: boolean; pinned: boolean; searching: boolean }) {
    console.log(event);
    this.isPinned = event.pinned;

    if (event.pinned || event.expanded || event.searching) {
      this.sidebarWidth = 292;
    } else {
      this.sidebarWidth = 89;
    }

    document.documentElement.style.setProperty('--content-width', `calc(100vw - ${this.sidebarWidth + 48}px)`);
  }
}
