import { ScrollingModule } from '@angular/cdk/scrolling';

import {
  Component,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
} from 'rxjs/operators';
import { ApiService } from '../../services/api.service';
import { SharedModule } from '../../modules/shared/shared.module';
import { MaterialModule } from '../../modules/material/material.module';
import { FormControl } from '@angular/forms';

import {
  NgLabelTemplateDirective,
  NgOptionTemplateDirective,
  NgSelectComponent,
} from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { TableListRequest } from '../../models/table-list-request.interface';
import { OrderBy } from '../../models/user-list-request.interface';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';

@Component({
  selector: 'app-suggestion-dropdown',
  templateUrl: './suggestion-dropdown.component.html',
  styleUrls: ['./suggestion-dropdown.component.scss'],
  imports: [
    SharedModule,
    ScrollingModule,
    MaterialModule,
    NgLabelTemplateDirective,
    NgOptionTemplateDirective,
    NgSelectComponent,
    SvgIconComponent,
  ],
  standalone: true,
})
export class SuggestionDropdownComponent {
  @Input() apiUrl: string = '';
  @Input() selectFields: string[] = [];
  @Input() limit: number = 10;
  @Input() orderBy: OrderBy = {};
  @Input() filterByKey: string = 'name';
  @Input() placeholder: string = 'Search';
  @Input() formControl!: FormControl;
  @Input() bindLabel: string = '';
  @Input() bindValue: string = '';

  @Output() suggestionSelected = new EventEmitter<any>();

  @ViewChild('dropdown', { static: false }) dropdown!: ElementRef;

  searchTerm: string = '';
  previousTerm: string = '';
  focusSearch: boolean = false;

  searchControl = new FormControl('');
  suggestions: any[] = [];
  showSuggestions = false;
  page = 0;
  loading = false;

  // private searchTerm = new Subject<string>();
  // private currentQuery = '';
  private totalCount = 0;

  searchListRequest: TableListRequest = {
    selectFields: [],
    orderBy: this.orderBy,
    limit: this.limit | 10,
    page: this.page | 0,
  };

  constructor(private elementRef: ElementRef, private apiService: ApiService) {}

  fetchSuggestions(payload: TableListRequest) {
    // let payload: TableListRequest = {
    //   selectFields: this.selectFields,
    //   orderBy: this.orderBy,
    //   limit: this.limit | 10,
    //   page: this.page | 0,
    // };

    this.loading = true;
    this.apiService.getSuggestions(this.apiUrl, payload).subscribe({
      next: (res) => {
        const newRecords = res.data.records || [];
        if (this.page === 0) {
          this.suggestions = newRecords; // Initial load
        } else {
          this.suggestions = [...this.suggestions, ...newRecords]; // Append to existing data
        }
        this.totalCount = res.data.totalCount;
        this.loading = false;
      },
      error: (err) => {
        console.error('Error fetching suggestions:', err);
        this.loading = false;
      },
    });
  }

  ngOnInit() {
    const payload: TableListRequest = {
      selectFields: this.selectFields,
      orderBy: this.orderBy,
      limit: this.limit | 10,
      page: this.page | 0,
    };
    this.fetchSuggestions(payload);
    // this.searchControl.valueChanges
    //   .pipe(
    //     debounceTime(300),
    //     distinctUntilChanged(),
    //     switchMap((query) => this.fetchSuggestions(query, this.page))
    //   )
    //   .subscribe({
    //     next: (res) => {
    //       this.suggestions = res.data.records;
    //       this.totalCount = res.data.totalCount;
    //       this.loading = false;
    //     },
    //     error: (err) => {
    //       console.error('Error fetching suggestions:', err);
    //       this.loading = false;
    //     },
    //   });
  }

  // When user types or filters
  // onInput(value: string) {
  //   this.page = 0;
  //   this.currentQuery = value.trim();
  //   this.searchTerm.next(this.currentQuery); // Trigger API call
  // }

  // // When input box is focused, initial call
  // onFocus() {
  //   this.page = 0;
  //   this.showSuggestions = true;
  //   this.searchTerm.next(''); // Fetch the initial list without query
  // }

  // // Fetch suggestions with pagination and filtering
  // fetchSuggestions(query: string | null, page: number): Observable<any> {
  //   if (query) {
  //     console.log('query is present');
  //   } else {
  //     console.log('not present');
  //   }
  //   const payload = {
  //     selectFields: this.selectFields,
  //     orderBy: this.orderBy,
  //     limit: this.limit,
  //     page: page,
  //     where: query
  //       ? {
  //           filterConditions: [
  //             {
  //               name: this.filterByKey,
  //               alias: 'A',
  //               operator: 'CONTAINS',
  //               value: [query],
  //             },
  //           ],
  //           expression: 'A',
  //         }
  //       : undefined,
  //   };
  //   return this.apiService.getCustomerMasterList(payload);
  //   // return this.apiService
  //   //   .getCustomerMasterList(payload)
  //   //   .pipe(map((response: any) => response?.data?.records || []));
  // }

  // // Called when the dropdown opens
  // onDropdownOpen(isOpen: boolean) {
  //   if (isOpen) {
  //     this.page = 0;
  //     this.searchControl.setValue('');
  //   }
  // }

  // // Called when scrolling for pagination
  // onScroll() {
  //   if (this.suggestions.length >= this.totalCount || this.loading) return;
  //   this.loading = true;
  //   this.page += 1;
  //   this.fetchSuggestions(this.searchControl.value || '', this.page).subscribe({
  //     next: (res) => {
  //       this.suggestions = [...this.suggestions, ...res.data.records];
  //       this.loading = false;
  //     },
  //     error: (err) => {
  //       console.error('Error fetching more results:', err);
  //       this.loading = false;
  //     },
  //   });
  // }

  // // Emit selection
  // onSelect(suggestion: any) {
  //   console.log('Selected:', suggestion);
  // }

  // // Called when searching/filtering
  // onSearch(query: string) {
  //   this.page = 0;
  //   this.searchControl.setValue(query);
  // }

  // Handle search input changes
  onSearch(query: any) {
    console.log('onsearch');
    // console.log(query.term);
    const searchTerm = query.term;
    const payload: TableListRequest = {
      selectFields: this.selectFields,
      orderBy: this.orderBy,
      limit: this.limit | 10,
      page: 0,
      where: {
        filterConditions: [
          {
            name: 'name',
            alias: 'A',
            operator: 'CONTAINS',
            value: [searchTerm],
          },
        ],
        expression: 'A',
      },
    };
    console.log(payload);
    this.fetchSuggestions(payload);
  }

  // Handle lazy loading / pagination on scroll
  onScroll() {
    if (this.suggestions.length >= this.totalCount || this.loading) return;
    this.page += 1;
    console.log('onscroll');
    console.log(this.page);
    let payload: TableListRequest = {
      selectFields: this.selectFields,
      orderBy: this.orderBy,
      limit: this.limit | 10,
      page: this.page | 0,
    };
    console.log(payload);
    this.fetchSuggestions(payload);
    // this.apiService.getSuggestions(this.apiUrl, payload);
    // this.fetchSuggestions(this.searchControl.value || '', this.page);
  }

  onSelect(item: any) {
    this.suggestionSelected.emit(item);
  }
}
