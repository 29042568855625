import { Component } from '@angular/core';
import { UserRolesPermissionsComponent } from '../user-roles-permissions/user-roles-permissions.component';
import { UserManagementComponent } from '../user-management/user-management.component';

@Component({
  selector: 'app-manage-users',
  standalone: true,
  imports: [UserRolesPermissionsComponent, UserManagementComponent],
  templateUrl: './manage-users.component.html',
  styleUrl: './manage-users.component.scss',
})
export class ManageUsersComponent {
  selectedTab: string = 'users'; // 'users' // roles;

  constructor() {}

  selectTab(tabName: string) {
    this.selectedTab = tabName;
  }
}
