import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { Router, RouterLink } from "@angular/router";
import { NgClass } from "@angular/common";
import { Menu } from "../../../models/sidebar-menu.interface";

@Component({
  selector: 'app-sidebar-new-search-overlay',
  standalone: true,
  imports: [
    RouterLink,
    NgClass
  ],
  templateUrl: './sidebar-new-search-overlay.component.html',
  styleUrl: './sidebar-new-search-overlay.component.scss'
})
export class SidebarNewSearchOverlayComponent {
  @Input() menuItems: Menu[] = []

  isSearching: boolean = true;
  filteredMenuItems: Menu[] = [];
  query!: string;

  @Output() searchingOffOnMouseLeaveEmitter = new EventEmitter<void>();

  constructor(private router: Router) {
  }

  ngOnInit(){
    this.filteredMenuItems = [...this.menuItems]
  }

  @ViewChild('searchInput') searchInput: ElementRef | undefined;

  ngAfterViewInit() {
    this.searchInput?.nativeElement.focus();
  }

  onSearch(query: string) {
    console.log(query);
    if (query.length > 2) {
      this.query = query;
      this.filteredMenuItems = this.menuItems.reduce((acc: Menu[], item: Menu) => {
        const matchedItem = this.searchInMenu(item, query.toLowerCase());
        if (matchedItem) {
          acc.push(matchedItem);
        }
        return acc;
      }, []);
    } else {
      this.filteredMenuItems = [...this.menuItems];
    }

    console.log(this.filteredMenuItems);
  }

  searchInMenu(menu: Menu, query: string): Menu | null {
    if (menu.displayLabel.toLowerCase().includes(query)) {
      return menu;
    }

    if (menu.childResources) {
      const matchedChildResources = menu.childResources.filter(child => this.searchInMenu(child, query));
      if (matchedChildResources.length > 0) {
        return { ...menu, childResources: matchedChildResources };
      }
    }

    return null;
  }

  searchingOffOnMouseLeave() {
    this.isSearching = false;
    this.searchingOffOnMouseLeaveEmitter.emit();
  }

  makeNavItemActive(url: string) {
      const currentUrl = this.router.url;
      return url.includes(currentUrl)
  }
}
