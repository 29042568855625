import { Component, ViewContainerRef } from '@angular/core';
import { SvgIconComponent } from "../../../svg-icon/svg-icon.component";
import { DynamicFormComponent } from "../../../dynamic-form/dynamic-form.component";
import { SharedModule } from '../../../../modules/shared/shared.module';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MultiSelectComponent } from "../../../select/multi-select.component";
import { selectValidator } from '../../../../validators/select-validator';
import { ModalComponent } from '../../../modal/modal.component';
import { AdvancedFilterComponentV2 } from '../../../advanced-filter/advanced-filter-v2.component';
import { TableMeta } from '../../../../models/table-meta.interface';
import { FieldInfo } from '../../../../models/editable-table-header.interface';
import { Where } from '../../../../models/user-list-request.interface';
import { PaginatedListDataResponse } from '../../../../models/paginated-list-data-response';
import { TableListRequest } from '../../../../models/table-list-request.interface';
import { StaticTableComponent } from "../../../static-table/static-table.component";
import ImportCreateHoldConfig from '../config/importCreateHoldConfig.json'
import { HoldReason, HoldSource, HoldType } from '../../../../constants/operations-contsants';
import { OperationService } from '../../../../services/operations.service';
import { TosTableComponent } from "../../../tos-table/tos-table.component";
import { Router } from '@angular/router';
import { ToasterService } from '../../../../services/toaster.service';

@Component({
  selector: 'app-create-hold',
  standalone: true,
  imports: [SvgIconComponent, SharedModule, MultiSelectComponent, StaticTableComponent, TosTableComponent],
  templateUrl: './create-hold.component.html',
  styleUrl: './create-hold.component.scss'
})
export class CreateHoldComponent {

  holdForm!: FormGroup;
  selectedCycle!: string;
  holdSourceOptions = Object.values(HoldSource);
  holdTypeOptions = Object.values(HoldType);
  holdReasonOptions = Object.values(HoldReason);

  tableLoaded: boolean = false;
  tableMeta!: TableMeta;
  filterFields!: FieldInfo[];
  focusSearch: boolean = false;
  searchTerm: string = "";
  previousTerm: string = "";
  selectedItems: any[] = [];
  allRecordsSelected: boolean = false;

  filterData!: Where;
  rawFilterData!: Where;
  searchFilterData!: Where | null;

  currentPage: number = 0;
  rowsPerPage: number = 15;
  tableListData: PaginatedListDataResponse = {
    totalCount: 0,
    page: 0,
    limit: 0,
    records: []
  };
  tableListRequest: TableListRequest = {
    selectFields: [
      "id AS cargoId",
      "arrivalContainerIds.containerNo",
      "arrivalContainerIds.id AS containerId",
      "igmItemNo",
      "accountHolder.name as accountHolder",
      "chaId.name as cha",
      "importType",
      "vesselId.vesselName",
      "blNo",
      "hblNo",
    ],
    includeTotal: true,
    limit: this.rowsPerPage,
    page: this.currentPage
  };


  constructor(private fb: FormBuilder,
              private viewContainerRef: ViewContainerRef,
              private api: OperationService,
              private router: Router,
              private toasterService: ToasterService
  ) {
    this.initForm();
  }

  ngOnInit() {
    this.selectedCycle = history.state.processCycle;
    console.log("this.selectedCycle: ", this.selectedCycle);
    this.tableMeta = ImportCreateHoldConfig.tableConfig;
    this.filterFields = ImportCreateHoldConfig.filterConfig.filterFields;
    this.listItemsForHold();
  }

  private initForm() {
    this.holdForm = this.fb.group({
      holdIssuedBy: [null, selectValidator()],
      holdCategory: [{ value: null, disabled: true }],
      holdSubCategory: [null, selectValidator()],
      holdDocumentNo: ['', Validators.required],
      holdDate: [null, Validators.required],
      holdRemarks: [null, Validators.maxLength(250)]
    });
  }

  onHoldSourceChange(selectedHoldSource: HoldSource) {
    this.holdForm.get('holdCategory')?.setValue(selectedHoldSource.holdType.key);
    if (selectedHoldSource.holdType.key === 'CUSTOMS') {
      this.holdForm.get("holdDocumentNo")?.enable();
      this.holdForm.get("holdSubCategory")?.disable();
    } else {
      this.holdForm.get("holdDocumentNo")?.disable();
      this.holdForm.get("holdSubCategory")?.enable();
    }
  }

  handleTableSearch(event: KeyboardEvent) {
    const searchTerm = this.searchTerm.trim();

    if (searchTerm.length >= 3) {
      this.searchFilterData = {
        filterConditions: [
          {
            name: "arrivalContainerIds.containerNo",
            alias: "containerNoStartsWith",
            operator: "CONTAINS",
            value: [searchTerm]
          },
          {
            name: "igmItemNo",
            alias: "cargoNoStartsWith",
            operator: "CONTAINS",
            value: [searchTerm]
          }
        ],
        expression: "containerNoStartsWith OR cargoNoStartsWith"
      };
      this.listItemsForHold();
    }
    if (searchTerm.length === 0 && this.previousTerm.length !== 0) {
      this.searchFilterData = null;
      this.listItemsForHold();
    }
    this.previousTerm = searchTerm;
  }

  filter() {
    const modalRef = this.viewContainerRef.createComponent(ModalComponent);
    modalRef.instance.title = "Advanced Filter";
    const filterDialogRef = modalRef.instance.loadComponent(
      AdvancedFilterComponentV2
    );
    filterDialogRef.setInput("filterFields", this.filterFields);
    if (this.tableListRequest.where) {
      filterDialogRef.setInput("appliedFilters", this.rawFilterData);
    }
    modalRef.instance.close.subscribe((res: any) => {
      this.clearSelection();
      this.rawFilterData = res?.rawFilterData;
      if (res && res.resetAll) {
        delete this.tableListRequest.where;
        this.listItemsForHold();
      } else if (res && res.refresh) {
        if (
          res.filterData.expression &&
          res.filterData.filterConditions.length > 0
        ) {
          this.filterData = {
            filterConditions: res.filterData.filterConditions,
            expression: res.filterData.expression
          };
        } else {
          console.error("Invalid filter !");
          delete this.tableListRequest.where;
        }
        this.listItemsForHold();
      }
      modalRef.destroy();
    });
  }

  private clearSelection() {
    this.selectedItems = [];
    this.allRecordsSelected = false;
  }

  private selectAll() {
    this.allRecordsSelected = true;
  }

  listItemsForHold() {
    this.mergeFilters();
    this.api.listItemsForHold(this.tableListRequest, this.selectedCycle).subscribe({
      next: (res: any) => {
        this.tableListData = res.data;
      },
      error: (err) => {
        console.error(err);
      }
    });
  }

  mergeFilters() {
    delete this.tableListRequest.where;
    
    if (!this.searchFilterData && !this.filterData) return;

    if (!this.tableListRequest.where) {
      this.tableListRequest.where = {filterConditions: [], expression: ''};
    }
  
    if (this.filterData?.filterConditions?.length) {
      this.filterData.filterConditions.forEach((filter: any) => {
        this.tableListRequest.where?.filterConditions?.push(filter);
      });
    }
  
    if (this.searchFilterData?.filterConditions?.length) {
      this.searchFilterData.filterConditions.forEach((filter: any) => {
        this.tableListRequest.where?.filterConditions?.push(filter);
      });
    }
  
    // Merge expressions with proper checks
    const expressions: string[] = [];
    if (this.filterData?.expression) {
      expressions.push(`(${this.filterData.expression})`);
    }
    if (this.searchFilterData?.expression) {
      expressions.push(`(${this.searchFilterData.expression})`);
    }
  
    this.tableListRequest.where.expression = expressions.join(' AND ');
  }

  isCustomsHold(): boolean {
    return this.holdForm.get('holdCategory')?.value === 'CUSTOMS';
  }

  isInternalHold(): boolean {
    return this.holdForm.get('holdCategory')?.value === 'INTERNAL';
  }

  cancel() {
    this.goBack();
  }

  goBack() {
    this.router.navigateByUrl('/manage-operations/hold', {state: {processCycle: this.selectedCycle}});
  }

  saveForm() {
    this.holdForm.markAllAsTouched();
    
    if (this.holdForm.invalid) {
      return;
    }

    if (!this.allRecordsSelected && this.selectedItems.length < 1) {
      this.toasterService.error("Please select the items for hold");
      return;
    }

    const holdRequest = this.createHoldRequest();
    console.log("holdRequest: ", holdRequest);
    this.api.submitHoldRequest(holdRequest).subscribe({
      next: (res: any) => {
        this.toasterService.success("Successfully processed hold request");
        this.goBack();
      },
      error: (err) => {
        console.error(err);
        this.toasterService.error(err.error.errorDesc);
      }
    });
  }

  createHoldRequest() {
    let holdItemList = null;
    let holdFilter = null;
    if (!this.allRecordsSelected) {
      holdItemList = this.selectedItems.map((item: any) => ({
        containerId: item.containerId,
        cargoId: item.cargoId  
      }));
    } else {
      holdFilter = this.filterData;
    }
    const holdIssuedBy: HoldSource = this.holdForm.get('holdIssuedBy')?.value;
    const holdRequest = {
      ...this.holdForm.value,
      processCycle: this.selectedCycle,
      holdCategory: this.holdForm.get('holdCategory')?.value,
      holdDate: new Date(this.holdForm.get('holdDate')?.value).getTime(),
      holdItemList,
      holdFilter
    }
    return holdRequest;
  }
}
