<div class="container">

  <div class="page_header">
    <div class="left">
        <div class="title">Hold</div>
        <div class="sub_heading"></div>
    </div>
  </div>

    <div class="horizontal_tabs">
      <div class="tabs">
        <div class="tab">
          <div class="tab_name" [class.tab_name_active]="selectedTab === 'active'" (click)="selectTab('active')">Active</div>
          <div class="bottom_border" [class.bottom_border_active]="selectedTab === 'active'"
               (click)="selectTab('active')"></div>
        </div>
  
        <div class="tab">
          <div class="tab_name" [class.tab_name_active]="selectedTab === 'released'" (click)="selectTab('released')">Released</div>
          <div class="bottom_border" [class.bottom_border_active]="selectedTab === 'released'"
               (click)="selectTab('released')"></div>
        </div>
  
      </div>
      <div class="divider"></div>
    </div>
  
      @if (selectedTab === 'active') {
        <app-active-holds
          [hidden]="selectedTab !== 'active'"
        ></app-active-holds>
      } @else if (selectedTab === 'released') {
        <app-released-holds
          [hidden]="selectedTab !== 'released'"
        ></app-released-holds>
      }
</div>