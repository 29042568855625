import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';

@Component({
  selector: 'app-confirm-dialog-v2',
  standalone: true,
  imports: [SvgIconComponent],
  templateUrl: './confirm-dialog-v2.component.html',
  styleUrl: './confirm-dialog-v2.component.scss'
})
export class ConfirmDialogV2Component {
  @Input() title: string = '';
  @Input() message: string = 'Are you sure?';
  @Input() confirmButtonLabel: string = 'Confirm';
  @Output() close = new EventEmitter<void>();
  @Output() confirm = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();

  onConfirm() {
    this.confirm.emit();
  }

  onCancel() {
    this.cancel.emit();
  }
}
