<div class="multi-select-container">
    <!-- Input for selected items -->
    <div class="select-input"
        tabindex="0"
        (keydown)="handleInputKeydown($event)"
        (click)="toggleDropdown($event)"
        [ngClass]="{ focused: dropdownOpen, disabled: disable, readonly: readOnly }"
        >
        <span class="placeholder" *ngIf="selectedItems.length === 0">
            {{ placeholder || "Select items" }}
        </span>

        <span class="selected-text" *ngIf="selectedItems.length > 0">
          {{ isMultiSelect ? selectedItems.length + ' items selected' : selectedItems[0][displayKey] }}
        </span>

    <div class="svg_icon">
      @if (!dropdownOpen) {
        <app-svg-icon *ngIf="!disable"
                      [path]="'assets/icons/chevron_down.svg'"
        ></app-svg-icon>
      } @else {
        <app-svg-icon *ngIf="!disable"
                      [path]="'assets/icons/chevron_up.svg'"
        ></app-svg-icon>
      }
    </div>
  </div>

  <!-- Dropdown -->
  <div #dropdown class="dropdown" [@dropdownAnimation]="dropdownOpen ? 'open' : 'closed'">

      <div class="dropdown-search option" *ngIf="options.length > 10">
        <input
          type="text"
          [(ngModel)]="searchQuery"
          (input)="filterOptions()"
          [disabled]="disable"
          (change)="$event.stopPropagation()"
         />
         <app-svg-icon
          [path]="'assets/icons/search_icon.svg'"
      ></app-svg-icon>
    </div>

    <!-- Options List -->
    <div class="option"
         [attr.tabindex]="index"
         (keydown)="handleOptionKeydown($event, option)"
         [class.selected]="isSelected(option)"
         (focus)="highlightOption(index)"
         *ngFor="let option of filteredOptions"
         (click)="toggleSelection(option)">
      <input
        type="checkbox"
        *ngIf="isMultiSelect"
        [checked]="isSelected(option)"
        (change)="$event.stopPropagation()"
      />
      {{ option[displayKey] }}
    </div>
  </div>

  <!-- Chips for selected items -->
  <ng-container *ngIf="showChips && isMultiSelect && selectedItems.length > 0">
    <div class="role_chips">
      <div class="chip" *ngFor="let option of selectedItems">
        <span> {{ option[displayKey] }}</span>
        <app-svg-icon
          [path]="'assets/icons/close.svg'"
          (click)="removeChip(option)"
        ></app-svg-icon>
      </div>
    </div>
  </ng-container>
</div>
