import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NgClass } from "@angular/common";
import { Router, RouterLink } from "@angular/router";
import { Menu } from "../../../models/sidebar-menu.interface";
import { query } from "@angular/animations";
import { NoDataComponent } from "../../no-data/no-data.component";
import { NoDataFoundComponent } from "../../no-data-found/no-data-found.component";

@Component({
  selector: 'app-search-overlay',
  standalone: true,
  imports: [
    NgClass,
    RouterLink,
    NoDataComponent,
    NoDataFoundComponent
  ],
  templateUrl: './search-overlay.component.html',
  styleUrl: './search-overlay.component.scss'
})
export class SearchOverlayComponent {
  isSearching:boolean=true;
  filteredMenuItems: Menu[] = [];
  query!: string;

  @Output() searchingEmit= new EventEmitter<boolean>();
  @Input() menuItems: Menu[] = [];

  constructor(private router: Router) {
  }

  ngOnInit() {
    this.filteredMenuItems = this.menuItems;
  }

  onSearch(query: string) {
    console.log(query);
    if (query.length > 3) {
      this.query = query;
      this.filteredMenuItems = this.menuItems.reduce((acc: Menu[], item: Menu) => {
        const matchedItem = this.searchInMenu(item, query.toLowerCase());
        if (matchedItem) {
          acc.push(matchedItem);
        }
        return acc;
      }, []);
    } else {
      this.filteredMenuItems = this.menuItems;
    }

    console.log(this.filteredMenuItems);
  }

  searchInMenu(menu: Menu, query: string): Menu | null {
    if (menu.displayLabel.toLowerCase().includes(query)) {
      return menu;
    }

    if (menu.childResources) {
      const matchedChildResources = menu.childResources.filter(child => this.searchInMenu(child, query));
      if (matchedChildResources.length > 0) {
        return { ...menu, childResources: matchedChildResources };
      }
    }

    return null;
  }

  turnOffSearching(){
    this.isSearching = false;
    this.searchingEmit.emit(this.isSearching);
  }

  makeNavItemActive(url: string) {
    const currentUrl = this.router.url;
    return url.includes(currentUrl)
  }
}
