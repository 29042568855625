<div
  class="container full_page_container login_system_bkg_cover centered_flex_container"
>
  <div class="login_card_component">
    <div class="logo">
      <img src="assets/images/logo.png" alt="SmartTOS | Globicon" height="55" width="345" />
    </div>

    <div class="heading text_align_center">
      <div class="header_login">Sign in</div>
      <div class="sub_header">Please enter your details.</div>
    </div>

    <div class="tab_container">
      <div class="tabs">
        <div
          class="tab"
          [class.active]="loginMethod === 'email'"
          (click)="switchLoginMethod('email')"
        >
          Username
        </div>
        <div
          class="tab"
          [class.active]="loginMethod === 'phone'"
          (click)="switchLoginMethod('phone')"
        >
          Phone
        </div>
      </div>

      @if (loginMethod === 'email') {
        <form [formGroup]="emailLoginForm" (ngSubmit)="submitEmailLogin()">
          <div class="input_with_label">
            <label for="username" class="required_field_label">Username</label>
            <input
              id="username"
              name="username"
              formControlName="username"
              type="text"
              placeholder="Enter your username"
              required
              autocomplete="username"
              [ngClass]="{
            form_error:
              emailLoginForm.get('username')?.invalid &&
              emailLoginForm.get('username')?.touched &&
              emailLoginForm.get('username')?.errors
          }"
            />
            <div
              class="validation_error"
              *ngIf="
            emailLoginForm.get('username')?.invalid &&
            emailLoginForm.get('username')?.touched
          "
            >
              <small *ngIf="emailLoginForm.get('username')?.errors?.['required']"
              >Username is required</small
              >
            </div>
          </div>


          <div class="input_with_label">
            <label for="password" class="required_field_label">Password</label>
            <input
              id="password"
              name="password"
              formControlName="password"
              type="password"
              placeholder="Enter your password"
              required
              [ngClass]="{
            form_error:
              emailLoginForm.get('password')?.invalid &&
              emailLoginForm.get('password')?.touched &&
              emailLoginForm.get('password')?.errors
          }"
            />
            <div
              class="validation_error"
              *ngIf="
            emailLoginForm.get('password')?.invalid &&
            emailLoginForm.get('password')?.touched
          "
            >
              <small *ngIf="emailLoginForm.get('password')?.errors?.['required']"
              >Password is required</small
              >
            </div>
          </div>
          <div class="row">
            <div class="checkbox">
              <!--              <div class="input">-->
              <!--                <div class="checkbox-base"></div>-->
              <!--              </div>-->
              <!--              <div class="text-and-supporting">-->
              <!--                <div class="text">Remember me</div>-->
              <!--              </div>-->
            </div>
            <a href="/forgot-password" class="buttons-button">
              <div class="text-wrapper">Forgot password?</div>
            </a>
          </div>
          <button type="submit" class="primary_button sign_in_btn">Sign in</button>
        </form>
      }
      @if (loginMethod === 'phone') {
        <form [formGroup]="phoneLoginForm" (ngSubmit)="submitPhoneLogin()">
          <div class="input_with_label">
            <label for="mobile" class="required_field_label">Phone Number</label>
            <input
              id="mobile"
              name="mobile"
              formControlName="mobile"
              type="tel"
              placeholder="Enter your phone number"
              required
              autocomplete="new-phone"
              maxLength="10"
              [ngClass]="{
            form_error:
              phoneLoginForm.get('mobile')?.invalid &&
              phoneLoginForm.get('mobile')?.touched &&
              phoneLoginForm.get('mobile')?.errors
          }"
            />
            <div
              class="validation_error"
              *ngIf="
            phoneLoginForm.get('mobile')?.invalid &&
            phoneLoginForm.get('mobile')?.touched
          "
            >
              <small *ngIf="phoneLoginForm.get('mobile')?.errors?.['required']"
              >Phone number is required</small
              >
              <small *ngIf="phoneLoginForm.get('mobile')?.errors?.['pattern']"
              >Enter 10 digits</small
              >
            </div>
          </div>
          @if (isOtpGenerated) {
            <!-- <div class="otp_sent_success">
              OTP sent successfully to your phone number
            </div> -->
            <div class="input-with-label">
              <label for="otp" class="required_field_label">OTP</label>
              <app-otp-input
                [ngClass]="{
            form_error:
              phoneLoginForm.get('otp')?.invalid &&
              phoneLoginForm.get('otp')?.touched &&
              phoneLoginForm.get('otp')?.errors
          }"
                id="otp"
                (otpChange)="onOtpChange($event)"
              ></app-otp-input>
              <div
                class="validation_error"
                *ngIf="
            phoneLoginForm.get('otp')?.invalid &&
            phoneLoginForm.get('otp')?.touched
          "
              >
                <small *ngIf="phoneLoginForm.get('otp')?.errors?.['required']"
                >OTP is required</small
                >
                <small *ngIf="phoneLoginForm.get('otp')?.errors?.['pattern']"
                >Enter 6 digits</small
                >
              </div>
              <div class="phone_login_options spaced_flex_container">
                <div><span class="countdown" *ngIf="!resendAllowed">Resend OTP in {{ formatTime(countdown) }} seconds</span></div>
                <div><a [ngClass]="{ disabled: !resendAllowed }" (click)="resendOtp()">Resend OTP</a></div>
              </div>
            </div>
            <button type="submit" class="primary_button sign_in_btn">Sign in</button>
          } @else {
            <button type="button" class="primary_button sign_in_btn" (click)="requestOtp()">
              Get OTP
            </button>
          }
        </form>
      }
    </div>
  </div>
</div>
