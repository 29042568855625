import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { ForgotPasswordRequest } from "../models/forgot-password.interface";
import { ResetPasswordRequest } from "../models/reset-password.interface";
import { UserListRequest } from "../models/user-list-request.interface";
import { Customer } from "../models/customer-master.interface";
import { Role } from "../models/role.interface";
import { Location } from "../models/location-master.interface";
import { CommodityCategory } from "../models/commodity-category-master.interface";
import { Country } from "../models/country-master.interface";
import { TableListRequest } from "../models/table-list-request.interface";
import { Package } from "../models/package-master.interface";
import { Bank } from "../models/bank-master.interface";
import { Trailer } from "../models/trailer-master.interface";
import { StateMaster } from "../models/state-master.interface";
import { City } from "../models/city-master.interface";
import { VesselPortTrainDetail } from "../models/vessel-port-train-details.interface";
import { Commodity } from "../models/commodity-master.interface";
import { Vendors } from "../models/vendor-master.interface";
import { ResourcePermission } from "../models/resource-permission.interface";
import { ServiceMaster } from "../models/service-master.interface";
import { Transporter } from "../models/transporter-master.interface";
import { APIResponse } from "../models/standard-response.interface";
import { log } from "@angular-devkit/build-angular/src/builders/ssr-dev-server";
import { Charge } from "../models/charge.interface";


const sendOTPEndPoint = "v1/verification/otp/send";
const forgotPasswordEndPoint = "v1/reset-password";
const validateTokenForResetPasswordEndPoint = "v1/reset-password/validate-token";
const resetPasswordResendOtp = "v1/reset-password/resend-otp";
const resetPasswordEndPoint = "v1/reset-password";
const usersEndPoint = "v1/users";
const rolesEndPoint = "v1/roles";
const customerMasterEndPoint = "v1/customers";
const transporterMasterEndPoint = "v1/transporters";
const locationMasterEndPoint = "v1/locations";
const commodityCategoryMasterEndPoint = "v1/commodity-categories";
const countyMasterEndPoint = "v1/countries";
const stateMasterEndPoint = "v1/states";
const cityMasterEndPoint = "v1/cities";
const commodityMasterEndPoint = "v1/commodities";
const vesselPortTrainDetailMasterEndPoint = "v1/vessel-port-train-details";
const packageMasterEndPoint = "v1/packages";
const bankMasterEndPoint = "v1/banks";
const trailerMasterEndPoint = "v1/trailers";
const vendorMasterEndPoint = "v1/vendors";
const serviceMasterEndPoint = "v1/services";
const igmContainerMasterEndPoint = "v1/igm-containers";
const loginHistoryEndpoint = "v1/login-histories"
const containerUnifiedEndPoint = "v1/containers";

@Injectable({
  providedIn: "root"
})
export class ApiService {
  private baseUrl: string = environment.apiBaseUrl;

  constructor(private http: HttpClient) {
  }

  sendOtp(mobileNumber: string): Observable<any> {
    return this.http.post(`${this.baseUrl}/${sendOTPEndPoint}`, {
      mobile: mobileNumber
      // allowNotificationOnWhatsApp: true,
    });
  }

  forgotPassword(reqBody: ForgotPasswordRequest): Observable<any> {
    return this.http.post(`${this.baseUrl}/${forgotPasswordEndPoint}`, reqBody);
  }

  validateTokenForResetPassword(token: string): Observable<any> {
    return this.http.get(
      `${this.baseUrl}/${validateTokenForResetPasswordEndPoint}?reset_password_token=${token}`
    );
  }

  resentOtpPasswordReset(token: string): Observable<any> {
    return this.http.get(
      `${this.baseUrl}/${resetPasswordResendOtp}?reset_password_token=${token}`
    );
  }

  resetPassword(reqBody: ResetPasswordRequest, token: string): Observable<any> {
    return this.http.patch(
      `${this.baseUrl}/${resetPasswordEndPoint}?reset_password_token=${token}`,
      reqBody
    );
  }

  getUsers(userListRequest: UserListRequest): Observable<any> {
    console.log(userListRequest);
    return this.http.post(
      `${this.baseUrl}/${usersEndPoint}/list`,
      userListRequest
    );
  }

  getUsersStats(): Observable<any> {
    return this.http.get(`${this.baseUrl}/${usersEndPoint}/stats`);
  }

  getMyProfile(): Observable<any> {
    return this.http.get(`${this.baseUrl}/${usersEndPoint}/me`);
  }

  addUser(userObject: any) {
    return this.http.post(`${this.baseUrl}/${usersEndPoint}`, userObject);
  }

  updateUser(userObject: any, userId: string) {
    return this.http.put(
      `${this.baseUrl}/${usersEndPoint}/${userId}`,
      userObject
    );
  }

  setUserActiveStatus(shouldSetActive: boolean, userIdList: string[]) {
    return this.http.patch(
      `${this.baseUrl}/${usersEndPoint}/status?status=${shouldSetActive}`,
      userIdList
    );
  }

  setUserLock(shouldBeLocked: boolean, userIdList: string[]) {
    return this.http.patch(
      `${this.baseUrl}/${usersEndPoint}/lock?lock=${shouldBeLocked}`,
      userIdList
    );
  }

  getUser(userId: string) {
    return this.http.get(`${this.baseUrl}/${usersEndPoint}/${userId}`);
  }

  setAdminAccessStatus(shouldSetAdmin: boolean, userId: string) {
    return this.http.put(`${this.baseUrl}/${usersEndPoint}/${userId}`, {
      admin: shouldSetAdmin
    });
  }

  sendWelcomeMail(userId: string) {
    return this.http.get(`${this.baseUrl}/${usersEndPoint}/${userId}/welcome-mail`);
  }

  getRoles(limit: number = 200) {
    return this.http.post(`${this.baseUrl}/${rolesEndPoint}/list`, {
      selectFields: ["id", "name"],
      orderBy: {
        name: "asc"
      },
      limit,
      page: 0
    });
  }

  getRolesStats(): Observable<any> {
    return this.http.get(`${this.baseUrl}/${rolesEndPoint}/stats`);
  }

  getRolesList(userListRequest: UserListRequest): Observable<any> {
    console.log(userListRequest);
    return this.http.post(
      `${this.baseUrl}/${rolesEndPoint}/list`,
      userListRequest
    );
  }

  addRole(roleObject: Role) {
    return this.http.post(`${this.baseUrl}/${rolesEndPoint}`, roleObject);
  }

  updateRole(roleObject: Role, roleId: string) {
    return this.http.put(
      `${this.baseUrl}/${rolesEndPoint}/${roleId}`,
      roleObject
    );
  }

  setRoleActiveStatus(shouldSetActive: boolean, rolesIdList: string[]) {
    return this.http.patch(
      `${this.baseUrl}/${rolesEndPoint}/status?status=${shouldSetActive}`,
      rolesIdList
    );
  }

  getCustomerMasterStats(): Observable<any> {
    return this.http.get(`${this.baseUrl}/${customerMasterEndPoint}/stats`);
  }

  getCustomerMasterList(userListRequest: UserListRequest): Observable<any> {
    console.log(userListRequest);
    return this.http.post(
      `${this.baseUrl}/${customerMasterEndPoint}/list`,
      userListRequest
    );
  }

  addCustomer(customerObject: Customer) {
    return this.http.post(
      `${this.baseUrl}/${customerMasterEndPoint}`,
      customerObject
    );
  }

  getCustomer(customerId: string) {
    return this.http.get(
      `${this.baseUrl}/${customerMasterEndPoint}/${customerId}`
    );
  }

  updateCustomer(customerObject: Customer, customerId: string) {
    return this.http.put(
      `${this.baseUrl}/${customerMasterEndPoint}/${customerId}`,
      customerObject
    );
  }

  setCustomerActiveStatus(shouldSetActive: boolean, customerIdList: string[]) {
    return this.http.patch(
      `${this.baseUrl}/${customerMasterEndPoint}/status?status=${shouldSetActive}`,
      customerIdList
    );
  }

  deleteCustomer(customerId: string) {
    return this.http.delete(
      `${this.baseUrl}/${customerMasterEndPoint}/${customerId}`
    );
  }

  //Location master
  getLocationMasterStats(): Observable<any> {
    return this.http.get(`${this.baseUrl}/${locationMasterEndPoint}/stats`);
  }

  getLocationMasterList(userListRequest: UserListRequest): Observable<any> {
    console.log(userListRequest);
    return this.http.post(
      `${this.baseUrl}/${locationMasterEndPoint}/list`,
      userListRequest
    );
  }

  setLocationActiveStatus(shouldSetActive: boolean, customerIdList: string[]) {
    return this.http.patch(
      `${this.baseUrl}/${locationMasterEndPoint}/status?status=${shouldSetActive}`,
      customerIdList
    );
  }

  addLocation(locationObject: Location) {
    return this.http.post(
      `${this.baseUrl}/${locationMasterEndPoint}`,
      locationObject
    );
  }

  updateLocation(locationObject: Location, locationId: string) {
    return this.http.put(
      `${this.baseUrl}/${locationMasterEndPoint}/${locationId}`,
      locationObject
    );
  }

  getLocation(locationId: string) {
    return this.http.get(
      `${this.baseUrl}/${locationMasterEndPoint}/${locationId}`
    );
  }

  //commodity category master
  getCommodityCategoryMasterStats(): Observable<any> {
    return this.http.get(
      `${this.baseUrl}/${commodityCategoryMasterEndPoint}/stats`
    );
  }

  getCommodityCategoryMasterList(
    userListRequest: UserListRequest
  ): Observable<any> {
    console.log(userListRequest);
    return this.http.post(
      `${this.baseUrl}/${commodityCategoryMasterEndPoint}/list`,
      userListRequest
    );
  }

  setCommodityCategoryActiveStatus(shouldSetActive: boolean, idList: string[]) {
    return this.http.patch(
      `${this.baseUrl}/${commodityCategoryMasterEndPoint}/status?status=${shouldSetActive}`,
      idList
    );
  }

  getCommodityCategory(commodityCategoryId: string) {
    return this.http.get(
      `${this.baseUrl}/${commodityCategoryMasterEndPoint}/${commodityCategoryId}`
    );
  }

  addComodityCategory(commodityCategory: CommodityCategory) {
    return this.http.post(
      `${this.baseUrl}/${commodityCategoryMasterEndPoint}`,
      commodityCategory
    );
  }

  updateCommodityCategory(
    commodityCategory: CommodityCategory,
    commodityCategoryId: string
  ) {
    return this.http.put(
      `${this.baseUrl}/${commodityCategoryMasterEndPoint}/${commodityCategoryId}`,
      commodityCategory
    );
  }


  //bank master
  getBankMasterStats(): Observable<any> {
    return this.http.get(`${this.baseUrl}/${bankMasterEndPoint}/stats`);
  }

  getBankMasterList(userListRequest: TableListRequest): Observable<any> {
    console.log(userListRequest);
    return this.http.post(
      `${this.baseUrl}/${bankMasterEndPoint}/list`,
      userListRequest
    );
  }

  setBankActiveStatus(shouldSetActive: boolean, idList: string[]) {
    return this.http.patch(
      `${this.baseUrl}/${bankMasterEndPoint}/status?status=${shouldSetActive}`,
      idList
    );
  }

  getBankMaster(id: string) {
    return this.http.get(
      `${this.baseUrl}/${bankMasterEndPoint}/${id}`
    );
  }

  addBankMaster(bank: Bank) {
    return this.http.post(
      `${this.baseUrl}/${bankMasterEndPoint}`,
      bank
    );
  }

  updateBankMaster(bank: Bank, id: string) {
    return this.http.put(
      `${this.baseUrl}/${bankMasterEndPoint}/${id}`,
      bank
    );
  }


  //country master
  getCountryMasterStats(): Observable<any> {
    return this.http.get(`${this.baseUrl}/${countyMasterEndPoint}/stats`);
  }

  getCountryMasterList(userListRequest: UserListRequest): Observable<any> {
    console.log(userListRequest);
    return this.http.post(
      `${this.baseUrl}/${countyMasterEndPoint}/list`,
      userListRequest
    );
  }

  setCountryActiveStatus(shouldSetActive: boolean, idList: string[]) {
    return this.http.patch(
      `${this.baseUrl}/${countyMasterEndPoint}/status?status=${shouldSetActive}`,
      idList
    );
  }

  getCountry(countyId: string) {
    return this.http.get(
      `${this.baseUrl}/${countyMasterEndPoint}/${countyId}`
    );
  }

  addCountry(country: Country) {
    return this.http.post(
      `${this.baseUrl}/${countyMasterEndPoint}`,
      country
    );
  }

  updateCountry(country: Country, countryId: string) {
    return this.http.put(
      `${this.baseUrl}/${countyMasterEndPoint}/${countryId}`,
      country
    );
  }

  //package master
  getPackageMasterStats(): Observable<any> {
    return this.http.get(`${this.baseUrl}/${packageMasterEndPoint}/stats`);
  }

  getPackageMasterList(tableListRequest: TableListRequest): Observable<any> {
    console.log(tableListRequest);
    return this.http.post(
      `${this.baseUrl}/${packageMasterEndPoint}/list`,
      tableListRequest
    );
  }

  setPackageActiveStatus(shouldSetActive: boolean, idList: string[]) {
    return this.http.patch(
      `${this.baseUrl}/${packageMasterEndPoint}/status?status=${shouldSetActive}`,
      idList
    );
  }

  getPackage(packageId: string) {
    return this.http.get(
      `${this.baseUrl}/${packageMasterEndPoint}/${packageId}`
    );
  }

  addPackage(packages: Package) {
    return this.http.post(
      `${this.baseUrl}/${packageMasterEndPoint}`,
      packages
    );
  }

  updatePackage(packages: Package, packageId: string) {
    return this.http.put(
      `${this.baseUrl}/${packageMasterEndPoint}/${packageId}`,
      packages
    );
  }

  //trailer master
  getTrailerMasterStats(): Observable<any> {
    return this.http.get(`${this.baseUrl}/${trailerMasterEndPoint}/stats`);
  }

  getTrailerMasterList(userListRequest: TableListRequest): Observable<any> {
    console.log(userListRequest);
    return this.http.post(
      `${this.baseUrl}/${trailerMasterEndPoint}/list`,
      userListRequest
    );
  }

  setTrailerActiveStatus(shouldSetActive: boolean, idList: string[]) {
    return this.http.patch(
      `${this.baseUrl}/${trailerMasterEndPoint}/status?status=${shouldSetActive}`,
      idList
    );
  }

  getTrailerMaster(id: string) {
    return this.http.get(
      `${this.baseUrl}/${trailerMasterEndPoint}/${id}`
    );
  }

  addTrailerMaster(trailer: Trailer) {
    return this.http.post(
      `${this.baseUrl}/${trailerMasterEndPoint}`,
      trailer
    );
  }

  updateTrailerMaster(trailer: Trailer, id: string) {
    return this.http.put(
      `${this.baseUrl}/${trailerMasterEndPoint}/${id}`,
      trailer
    );
  }

  //state master
  getStateMasterStats(): Observable<any> {
    return this.http.get(`${this.baseUrl}/${stateMasterEndPoint}/stats`);
  }

  getStateMasterList(stateMasterList: TableListRequest): Observable<any> {
    console.log(stateMasterList);
    return this.http.post(
      `${this.baseUrl}/${stateMasterEndPoint}/list`,
      stateMasterList
    );
  }

  setStateActiveStatus(shouldSetActive: boolean, idList: string[]) {
    return this.http.patch(
      `${this.baseUrl}/${stateMasterEndPoint}/status?status=${shouldSetActive}`,
      idList
    );
  }

  getStateMaster(id: string) {
    return this.http.get(
      `${this.baseUrl}/${stateMasterEndPoint}/${id}`
    );
  }

  addStateMaster(trailer: StateMaster) {
    return this.http.post(
      `${this.baseUrl}/${stateMasterEndPoint}`,
      trailer
    );
  }

  updateStateMaster(trailer: StateMaster, id: string) {
    return this.http.put(
      `${this.baseUrl}/${stateMasterEndPoint}/${id}`,
      trailer
    );
  }

  //city master
  getCityMasterStats(): Observable<any> {
    return this.http.get(`${this.baseUrl}/${cityMasterEndPoint}/stats`);
  }

  getCityMasterList(cityMasterList: TableListRequest): Observable<any> {
    console.log(cityMasterList);
    return this.http.post(
      `${this.baseUrl}/${cityMasterEndPoint}/list`,
      cityMasterList
    );
  }

  setCityActiveStatus(shouldSetActive: boolean, idList: string[]) {
    return this.http.patch(
      `${this.baseUrl}/${cityMasterEndPoint}/status?status=${shouldSetActive}`,
      idList
    );
  }

  getCityMaster(id: string) {
    return this.http.get(
      `${this.baseUrl}/${cityMasterEndPoint}/${id}`
    );
  }

  addCityMaster(city: City) {
    return this.http.post(
      `${this.baseUrl}/${cityMasterEndPoint}`,
      city
    );
  }

  updateCityMaster(city: City, id: string) {
    return this.http.put(
      `${this.baseUrl}/${cityMasterEndPoint}/${id}`,
      city
    );
  }

  //commodity master
  getCommodityMasterStats(): Observable<any> {
    return this.http.get(`${this.baseUrl}/${commodityMasterEndPoint}/stats`);
  }

  getCommodityMasterList(commodityMasterList: TableListRequest): Observable<any> {
    console.log(commodityMasterList);
    return this.http.post(
      `${this.baseUrl}/${commodityMasterEndPoint}/list`,
      commodityMasterList
    );
  }

  setCommodityActiveStatus(shouldSetActive: boolean, idList: string[]) {
    return this.http.patch(
      `${this.baseUrl}/${commodityMasterEndPoint}/status?status=${shouldSetActive}`,
      idList
    );
  }

  getCommodityMaster(id: string) {
    return this.http.get(
      `${this.baseUrl}/${commodityMasterEndPoint}/${id}`
    );
  }

  addCommodityMaster(commodity: Commodity) {
    return this.http.post(
      `${this.baseUrl}/${commodityMasterEndPoint}`,
      commodity
    );
  }

  updateCommodityMaster(commodity: Commodity, id: string) {
    return this.http.put(
      `${this.baseUrl}/${commodityMasterEndPoint}/${id}`,
      commodity
    );
  }


  //vessel port train detail master
  getVesselPortTrainDetailMasterStats(): Observable<any> {
    return this.http.get(`${this.baseUrl}/${vesselPortTrainDetailMasterEndPoint}/stats`);
  }

  getVesselPortTrainDetailMasterList(tableListRequest: TableListRequest): Observable<any> {
    console.log(tableListRequest);
    return this.http.post(
      `${this.baseUrl}/${vesselPortTrainDetailMasterEndPoint}/list`,
      tableListRequest
    );
  }

  setVesselPortTrainDetailActiveStatus(shouldSetActive: boolean, idList: string[]) {
    return this.http.patch(
      `${this.baseUrl}/${vesselPortTrainDetailMasterEndPoint}/status?status=${shouldSetActive}`,
      idList
    );
  }

  getVesselPortTrainDetailMaster(id: string) {
    return this.http.get(
      `${this.baseUrl}/${vesselPortTrainDetailMasterEndPoint}/${id}`
    );
  }

  addVesselPortTrainDetailMaster(vesselPortTrainDetail: VesselPortTrainDetail) {
    return this.http.post(
      `${this.baseUrl}/${vesselPortTrainDetailMasterEndPoint}`,
      vesselPortTrainDetail
    );
  }

  updateVesselPortTrainDetailMaster(vesselPortTrainDetail: VesselPortTrainDetail, id: string) {
    return this.http.put(
      `${this.baseUrl}/${vesselPortTrainDetailMasterEndPoint}/${id}`,
      vesselPortTrainDetail
    );
  }

  //vendor master
  getVendorMasterStats(): Observable<any> {
    return this.http.get(`${this.baseUrl}/${vendorMasterEndPoint}/stats`);
  }

  getVendorMasterList(tableListRequest: TableListRequest): Observable<any> {
    console.log(tableListRequest);
    return this.http.post(
      `${this.baseUrl}/${vendorMasterEndPoint}/list`,
      tableListRequest
    );
  }

  setVendorActiveStatus(shouldSetActive: boolean, idList: string[]) {
    return this.http.patch(
      `${this.baseUrl}/${vendorMasterEndPoint}/status?status=${shouldSetActive}`,
      idList
    );
  }

  getVendorMaster(id: string, includeServices: boolean) {
    return this.http.get(
      `${this.baseUrl}/${vendorMasterEndPoint}/${id}?includeServices=${includeServices}`
    );
  }

  addVendorMaster(vendors: Vendors) {
    return this.http.post(
      `${this.baseUrl}/${vendorMasterEndPoint}`,
      vendors
    );
  }

  updateVendorMaster(vendors: Vendors, id: string) {
    return this.http.put(
      `${this.baseUrl}/${vendorMasterEndPoint}/${id}`,
      vendors
    );
  }

  //service master
  getServiceMasterStats(): Observable<any> {
    return this.http.get(`${this.baseUrl}/${serviceMasterEndPoint}/stats`);
  }

  getServiceMasterList(tableListRequest: TableListRequest): Observable<any> {
    console.log(tableListRequest);
    return this.http.post(
      `${this.baseUrl}/${serviceMasterEndPoint}/list`,
      tableListRequest
    );
  }

  setServiceActiveStatus(shouldSetActive: boolean, idList: string[]) {
    return this.http.patch(
      `${this.baseUrl}/${serviceMasterEndPoint}/status?status=${shouldSetActive}`,
      idList
    );
  }

  getServiceMaster(id: string) {
    return this.http.get(
      `${this.baseUrl}/${serviceMasterEndPoint}/${id}`
    );
  }

  addServiceMaster(serviceMaster: ServiceMaster) {
    return this.http.post(
      `${this.baseUrl}/${serviceMasterEndPoint}`,
      serviceMaster
    );
  }

  updateServiceMaster(serviceMaster: ServiceMaster, id: string) {
    return this.http.put(
      `${this.baseUrl}/${serviceMasterEndPoint}/${id}`,
      serviceMaster
    );
  }

  getBankDetailsByIfscCode(bank: Bank): Observable<Bank> {
    return this.http.post<APIResponse>(
      `${this.baseUrl}/${bankMasterEndPoint}/ifsc`,
      bank).pipe(
      map((res: APIResponse) => res.data as Bank)
    );
  }

  getSuggestions(endPoint: string, payload: TableListRequest): Observable<any> {
    return this.http.post(`${this.baseUrl}/${endPoint}`, payload);
  }

  getUserMenuItems(id: string, resourcePermission: ResourcePermission): Observable<Object> {
    return this.http.post(
      `${this.baseUrl}/${usersEndPoint}/${id}/resources`,
      resourcePermission
    );
  }

  //transporter master
  getTransporterMasterStats(): Observable<any> {
    return this.http.get(`${this.baseUrl}/${transporterMasterEndPoint}/stats`);
  }

  getTransporterMasterList(userListRequest: UserListRequest): Observable<any> {
    console.log(userListRequest);
    return this.http.post(
      `${this.baseUrl}/${transporterMasterEndPoint}/list`,
      userListRequest
    );
  }

  addTransporter(transporter: Transporter) {
    return this.http.post(
      `${this.baseUrl}/${transporterMasterEndPoint}`,
      transporter
    );
  }

  getTransporter(transporterId: string) {
    return this.http.get(
      `${this.baseUrl}/${transporterMasterEndPoint}/${transporterId}`
    );
  }

  updateTransporter(transporter: Transporter, transporterId: string) {
    return this.http.put(
      `${this.baseUrl}/${transporterMasterEndPoint}/${transporterId}`,
      transporter
    );
  }

  setTransporterActiveStatus(shouldSetActive: boolean, transporterIdList: string[]) {
    return this.http.patch(
      `${this.baseUrl}/${transporterMasterEndPoint}/status?status=${shouldSetActive}`,
      transporterIdList
    );
  }

  uploadIgmData(formData: FormData): Observable<any> {
    return this.http.post(`${this.baseUrl}/${igmContainerMasterEndPoint}/import`, formData);
  }

  uploadScanList(formData: FormData): Observable<any> {
    return this.http.post(`${this.baseUrl}/${igmContainerMasterEndPoint}/upload/scan-list`, formData);
  }

  getUserLoginHistory(userId: string,limit: number,offset: number): Observable<any> {
    return this.http.get(`${this.baseUrl}/${loginHistoryEndpoint}/${userId}?limit=${limit}&offset=${offset}`);
  }

  searchContainers(containerNumber: string, selectFieldsList: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/${containerUnifiedEndPoint}/search?containerNo=${containerNumber}&selectFields=${selectFieldsList}`);
  }

  getUnifiedContainerById(id: string,fromIgm: boolean = false): Observable<any> {
    return this.http.get(`${this.baseUrl}/${containerUnifiedEndPoint}/${id}?fromIgm=${fromIgm}`);
  }

  checkPanNumberValidity(panNumber: string) {
    return this.http.get(`${this.baseUrl}/${customerMasterEndPoint}/check-pan?panNumber=${panNumber}`);
  }
}
