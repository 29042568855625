<div class="side_navbar" (mouseleave)="collapseSideBar()">
  <app-sidebar-header
    [isExpanded]="isExpanded"
    [isPinned]="isPinned"
    [isSearching]="isSearching"
    (isPinnedEmitter)="togglePin($event)"
    (searchOffEmitter)="turnOffSearching()"
  ></app-sidebar-header>

  @if (isSearching) {
<!--    (searchingOffOnMouseLeaveEmitter)="turnOffSearching($event)"-->
    <app-sidebar-new-search-overlay
      [menuItems]="menuItems"
    ></app-sidebar-new-search-overlay>
  } @else {
    <app-sidebar-new-collapsed
      [menuItems]="menuItems"
      [isExpanded]="isExpanded"
      [activeMenuParent]="selectedMenu"
      (menuChildResources)="handleMenuChildResources($event)"
      (isSearchingEvent)="handleSearchingEvent($event)"
      (collapseSidebarEvent)="collapseSideBar()"
    ></app-sidebar-new-collapsed>
    @if (isExpanded) {
      <app-sidebar-new-expanded
        [selectedMenu]="selectedMenu"
        [selectingMenu]="selectingMenu"
        [childMenu]="activeChild"
      ></app-sidebar-new-expanded>
    }
  }
</div>

