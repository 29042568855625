<div class="job-order-container">
  <app-vertical-tabs
    [tabs]="tabList"
    (tabSelected)="selectTab($event)"
  ></app-vertical-tabs>

<!--    <div class="horizontal_tabs">
      <div class="tabs">
        <div class="tab" (click)="selectTab('basicDetailsSection')">
          <div class="tab_name" [class.tab_name_active]="selectedTab === 'basicDetailsSection'">JO Details</div>
          <div class="bottom_border" [class.bottom_border_active]="selectedTab === 'basicDetailsSection'"></div>
        </div>

        <div class="tab" (click)="selectTab('itemDetailsSection')">
          <div class="tab_name" [class.tab_name_active]="selectedTab === 'itemDetailsSection'">Item & Vendor Details</div>
          <div class="bottom_border" [class.bottom_border_active]="selectedTab === 'itemDetailsSection'"></div>
        </div>
     </div>
      <div class="divider"></div>
    </div>-->

    <div class="page-content">
        <div class="section jo-details-section card-layout" [hidden]="selectedTab !== 'basicDetailsSection'">
        <app-dynamic-form
        [formConfig]="dynamicForm"
        [formGroup]="jobOrderForm">
        >
        </app-dynamic-form>
        </div>

        <div class="section card-layout" [hidden]="selectedTab !== 'itemDetailsSection'">
            @if (!editMode && !readOnlyMode) {
                <div class="search-container">
                    <app-autocomplete-dropdown
                    id="search-bar"
                    class="autocomplete-dropdown"
                    [placeholder]="'Search container'"
                    [apiUrl]="'v1/containers/list'"
                    [selectFields]="['id', 'containerNo', 'containerSize', 'containerType']"
                    [whereFields]="['containerNo']"
                    [limit]="10"
                    [orderBy]="{'containerNo': 'asc'}"
                    [bindLabel]="'containerNo'"
                    [bindValue]="'id'"
                    [displayColumns]="[
                        { key: 'containerNo', header: 'Container No' },
                    ]"
                    [(ngModel)]="searchText"
                    (suggestionSelected)="onSuggestionSelected($event.id)"
                >
                </app-autocomplete-dropdown>
                <button type="button" class="add-button secondary_button" [disabled]="addButtonDisabled" (click)="fetchAndAddItem()">
                    <app-svg-icon class="image-icon"
                                [path]="'assets/icons/add_plus_icon.svg'"></app-svg-icon>
                    <span>Add</span>
                </button>
                </div>
            }

      <div class="page_stats">
        @if (containerAdded) {
        <div class="container-details">
          <div class="section-label"><span>Container Details</span></div>
          <app-stats-horizontal [stats]="containerStats"></app-stats-horizontal>
        </div>
        }
      </div>

            <div class="item-details">
                @if (containerAdded) {
                    <div class="accordian-container">
                        <div class="section-label"><span>Item Details</span></div>
                        <app-accordion-table
                        [tableHeaderAndData]= "itemTableHeaderAndData"
                        [readOnly]="true"
                        (onAccordionOpen)="loadAccordionContent($event)"
                    ></app-accordion-table>
                    </div>
                }
            </div>

        </div>
    </div>
  </div>
