<div class="determinants_filter">
  <form [formGroup]="filterForm">
    <div formArrayName="rules" class="rules">
      <div *ngFor="let rule of rules.controls; let i = index" [formGroupName]="i" class="form_inputs">
        <div class="sl_no">{{ i + 1 }}</div>
        <!-- Determinant Select -->
        <app-multi-select
          formControlName="determinant"
          [options]="allDeterminants"
          [valueKey]="'name'"
          [displayKey]="'displayName'"
          [isMultiSelect]="false"
          [placeholder]="'Select a determinant'"
          [showChips]="false"
          [disable]="false"
          (selectionChange)="onDeterminantSelect($event, i)"
        ></app-multi-select>
        <!-- Operator Select -->
        <app-multi-select
          formControlName="operator"
          [options]="ruleConfigs[i]?.supportedOperators || []"
          [valueKey]="'name'"
          [displayKey]="'name'"
          [isMultiSelect]="false"
          [placeholder]="'Select an operator'"
          [disable]="!ruleConfigs[i]?.supportedOperators?.length"
        ></app-multi-select>
        <!-- Dynamic Value Controls -->
        <div class="input_field dynamic_input" [ngSwitch]="ruleConfigs[i]?.inputType">

          <!-- Reference Type (Multi-select) -->
          <app-multi-select
            *ngSwitchCase="'REFERENCE'"
            formControlName="values"
            [options]="ruleConfigs[i]?.referenceOptions || []"
            [valueKey]="ruleConfigs[i]?.referenceValueKey"
            [displayKey]="ruleConfigs[i]?.referenceDisplayKey"
            [isMultiSelect]="true"
            [showChips]="false"
            [placeholder]="'Select values'"
          ></app-multi-select>

          <!-- Dropdown Type (Single-select) -->
          <app-multi-select
            *ngSwitchCase="'DROPDOWN'"
            formControlName="values"
            [options]="ruleConfigs[i]?.dropdownOptions"
            [valueKey]="'value'"
            [displayKey]="'text'"
            [isMultiSelect]="false"
            [placeholder]="'Select value'"
          ></app-multi-select>

          <!-- Boolean Type -->
          <app-multi-select
            *ngSwitchCase="'BOOLEAN'"
            formControlName="values"
            [options]="booleanOptions"
            [valueKey]="'value'"
            [displayKey]="'text'"
            [isMultiSelect]="false"
            [placeholder]="'Select true/false'"
          ></app-multi-select>

          <!-- Number Inputs -->
          <input
            *ngSwitchCase="'NUMBER'"
            type="number"
            formControlName="values"
            [placeholder]="ruleConfigs[i]?.numberPlaceholder"
            [step]="ruleConfigs[i]?.inputStep"
          >

          <!-- Default/Text Input -->
          <input
            *ngSwitchDefault
            type="text"
            formControlName="values"
            [placeholder]="'Enter value'"
          >
        </div>
        <div (click)="addRule()" class="btn add_btn">
          <app-svg-icon [path]="'/assets/icons/add_plus_icon.svg'"></app-svg-icon>
        </div>
        <div (click)="removeRule(i)" class="btn">
          <app-svg-icon [path]="'/assets/icons/delete_icon.svg'"></app-svg-icon>
        </div>
      </div>
    </div>
  </form>
</div>
