<!--<div class="search_bar_action_btns">
  <div class="container">
    <div class="search_bar">
      <div class="search_container">
        <input
          type="text"
          name="search_table"
          id="search_table"
          [(ngModel)]="searchTerm"
          placeholder="Search by name or code"
          (focus)="focusSearch = true"
          (focusout)="focusSearch = false"
          (keyup)="handleTableSearch($event)"
        >
        <img src="assets/icons/search_icon.svg" class="search_icon">
      </div>
    </div>
  </div>
</div>-->
<!--<div class="tos-table-template">
  <div class="table-container">
    <ng-container *ngIf="tableLoaded && tableStats.total === 0">
      <app-no-data
        [msg]="
                'No charges are available in the system. Please click on Add New button to create one.'
              "
      ></app-no-data>
    </ng-container>
    <ng-container
      *ngIf="
              tableLoaded &&
              tableStats.total > 0 &&
              tableListData.records?.length > 0
            "
    >
      <table>
        <thead>
        <tr>
          <th class="cb_th select-all-cb">
            <input
              type="checkbox"
              id="select_all_users"
              [checked]="isAllRecordsOfCurrentPageSelected()"
              (change)="
                        toggleAllRecordsOfCurrentPageSelections(
                          $event.target.checked
                        )
                      "
            />
          </th>
          &lt;!&ndash;            <th class="sl_no_th">Sl No.</th>&ndash;&gt;
          <th
            class="code_th sortable"
            [ngClass]="{ sorted: sortColumn === 'code' }"
            (click)="sortData('code')"
          >
            <div class="icon_cover">
              Charge Code
              <app-svg-icon
                [path]="'assets/icons/sort_icon.svg'"
              ></app-svg-icon>
            </div>
          </th>
          <th
            class="name_th sortable"
            [ngClass]="{ sorted: sortColumn === 'name' }"
            (click)="sortData('name')"
          >
            <div class="icon_cover">
              Name
              <app-svg-icon
                [path]="'assets/icons/sort_icon.svg'"
              ></app-svg-icon>
            </div>
          </th>
          <th class="description_th">Description</th>
          <th class="ifsc_code_th">HSN/SAC Code</th>
          <th
            class="status_th sortable"
            [ngClass]="{ sorted: sortColumn === 'active' }"
            (click)="sortData('active')"
          >
            <div class="icon_cover">
              Status
              <app-svg-icon
                [path]="'assets/icons/sort_icon.svg'"
              ></app-svg-icon>
            </div>
          </th>

          <th class="branch_th">Print Name</th>
          <th class="storage_non_storage_th">Storage/Non-Storage</th>
          <th class="gst_pecentage">GST Percentage</th>
          &lt;!&ndash;<th class="roles_th">Determinants</th>&ndash;&gt;
          <th
            class="created_date_th sortable"
            [ngClass]="{ sorted: sortColumn === 'createdAt' }"
            (click)="sortData('createdAt')"
          >
            <div class="icon_cover">
              Created date
              <app-svg-icon
                [path]="'assets/icons/sort_icon.svg'"
              ></app-svg-icon>
            </div>
          </th>

          <th
            class="modified_date_th sortable"
            [ngClass]="{ sorted: sortColumn === 'modifiedAt' }"
            (click)="sortData('modifiedAt')"
          >
            <div class="icon_cover">
              Modified date
              <app-svg-icon
                [path]="'assets/icons/sort_icon.svg'"
              ></app-svg-icon>
            </div>
          </th>
          <th
            class="modifiedBy_th sortable"
            [ngClass]="{ sorted: sortColumn === 'modifiedBy' }"
            (click)="sortData('modifiedBy')"
          >
            <div class="icon_cover">
              Modified By
              <app-svg-icon
                [path]="'assets/icons/sort_icon.svg'"
              ></app-svg-icon>
            </div>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
          *ngFor="let record of tableListData.records; let i = index"
          [ngClass]="isSelected(record) ? 'selected_row' : ''"
        >
          <td class="cb_td row-select-cb">
            <input
              type="checkbox"
              [id]="'user_sl_no_' + i + 1"
              [checked]="isSelected(record)"
              (change)="toggleSelection(record)"
            />
          </td>
          <td class="code_td" [title]="record.code">
                  <span
                    [innerHTML]="record.code | highlight : searchTerm"
                  ></span>
          </td>
          <td class="name_td" [title]="record.name">
                    <span
                      [innerHTML]="record.name | highlight : searchTerm"
                    ></span>
          </td>
          <td class="description_td" [title]="record.description">{{ record.description }}</td>
          <td class="ifsc_code_td" [title]="record.hsnOrSacCode">{{ record.hsnOrSacCode }}</td>
          <td class="status_td">
            <div
              class="icon_cover"
              [ngClass]="record.active ? 'active' : 'inactive'"
            >
              <div class="badge_status">
                <app-svg-icon
                  [path]="'assets/icons/circle_icon.svg'"
                ></app-svg-icon>
                {{ record.active ? "Active" : "Inactive" }}
              </div>
            </div>
          </td>
          <td class="branch_td" [title]="record.printName">{{ record.printName }}</td>
          <td class="storage_non_storage">{{ record }}</td>
          <td class="gst_pecentage">
            {{ record.gstPercentage }}
          </td>

          &lt;!&ndash;<td class="roles_td">
              @if (record.determinants && record.determinants.length > 0) {
                <div class="role_container">
                  <div class="first_role">{{ record.determinants[0] }}</div>
                  <div class="second_role" *ngIf="record.determinants?.[1]">{{ record.determinants?.[1] }}</div>
                  <div class="third_role" *ngIf="record.determinants?.[2]?.name">{{ record.determinants?.[2] }}</div>
                  <div
                    class="role_count"
                    [appTooltip]="record.determinants.slice(3)"
                    [keyToPick]="'name'"
                    position="bottom"
                    *ngIf="record.determinants.length > 3"
                  >
                    + {{ record.determinants.length - 3 }}
                  </div>
                </div>
              } @else {

              }
            </td>&ndash;&gt;

          <td class="created_date_td">
            {{ record.createdAt | date : "dd/MM/yy hh:mm a" }}
          </td>
          <td class="modified_date_td">
            {{ record.modifiedAt | date : "dd/MM/yy hh:mm a" }}
          </td>
          &lt;!&ndash; <td class="createdBy_td">
              {{ record.createdBy || "N/A" }}
            </td> &ndash;&gt;
          <td class="modifiedBy_td">
            {{ record.modifiedBy || "N/A" }}
          </td>
        </tr>
        </tbody>
      </table>
    </ng-container>
    <ng-container
      *ngIf="
              tableLoaded &&
              tableStats.total > 0 &&
              tableListData.records?.length === 0
            "
    >
      <app-no-data-found [msg]="'No results found !'"></app-no-data-found>
    </ng-container>
  </div>
  <div class="pagination" [hidden]="tableListData.totalCount === 0">


    <div class="button_wrapper_left">
      <button (click)="previousPage()"><img src="assets/icons/left-arrow.svg"> Previous</button>
    </div>
    <ng-container *ngIf="totalTablePages" class="page_numbers">
      <div
        *ngFor="let page of getDisplayedPages()"
        (click)="page !== '...' ? goToPage(page) : null"
        [class.page_number]="page !== '...'"
        [class.ellipsis]="page === '...'"
        [class.active]="currentTablePage === page"
      >
        {{ page }}
      </div>
    </ng-container>

    <div class="button_wrapper_right">
      <div class="rows_per_page">
        Rows per page
        <select (change)="updateRowsPerPage($event.target.value)" class="select_dropdown">
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </div>
      <button (click)="nextPage()">Next <img src="assets/icons/right-arrow.svg"></button>
    </div>
  </div>
</div>-->


<div class="top_action_bar">
  <div class="search-container">
    <app-autocomplete-dropdown
      id="search-bar"
      class="autocomplete-dropdown"
      [placeholder]="'Search And Add Charges'"
      [apiUrl]="'v1/charges/list'"
      [selectFields]=tableListRequest.selectFields
      [whereFields]="['name']"
      [limit]="10"
      [orderBy]="{'name': 'asc'}"
      [bindLabel]="'name'"
      [bindValue]="'id'"
      [displayColumns]="[
    {key: 'name', header: 'Charge Name'},
    {key: 'code', header: 'Code'}
    ]"
      [additionalFilters]="[
                {
                  'name': 'active',
                  'alias': 'active',
                  'operator': 'EQ',
                  'value': ['true']
                },
                {
                  'name': 'id',
                  'alias': 'IdNotIn',
                  'operator': 'NOT_IN',
                  'value': preSelectedRowsIds.size > 0 ? Array.from(preSelectedRowsIds) : ['']
                }
              ]"
      (suggestionSelected)="handleSuggestionSelected($event)"
      [(ngModel)]="searchAndAddText"
    >
    </app-autocomplete-dropdown>
    <button type="button"
            class="add-button tertiary_button"
            [ngClass]="{ disabled: disableAddBtn }"
            (click)="addNewChargesToChargeGroup()"
            [disabled]="disableAddBtn">
      <app-svg-icon class="image-icon" [path]="'assets/icons/add_plus_icon.svg'"></app-svg-icon>
      <span>Add</span>
    </button>
  </div>
  @if (selectedItems.length > 0) {
    <div class="action_btns">
      <button class="secondary_button" (click)="handleBulkDelete()">Delete</button>
    </div>
  }
</div>

<app-static-table
  [(data)]="chargesAlreadyInChargeGroup"
  [tableMeta]="tableMeta"
  [showCheckBox]="true"
  (dataDeleted)="handleDataDeletion($event)"
  [(selectedItems)]="selectedItems"
>
</app-static-table>
