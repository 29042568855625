<div class="modal-backdrop"></div>
<div class="confirm-dialog">
    <div class="dialog-header">
        <div class="header-content">
            <div class="confirm-title">
                {{ title }}
                <app-svg-icon
                class="close-btn"
                (click)="onCancel()"
                [path]="'assets/icons/close_icon.svg'"></app-svg-icon>
            </div>
            <div class="confirm-message">
                {{ message }}
            </div>
        </div>
    </div>
    <div class="dialog-actions">
      <button class="secondary_button" (click)="onCancel()">Cancel</button>
      <button class="primary_button" (click)="onConfirm()">{{ confirmButtonLabel }}</button>
    </div>

</div>