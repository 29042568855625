import { Component, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { SharedModule } from '../../../../modules/shared/shared.module';
import { SvgIconComponent } from '../../../svg-icon/svg-icon.component';
import { BackButtonComponent } from '../../../back-button/back-button.component';
import { MatSelectModule } from '@angular/material/select';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { ApiService } from '../../../../services/api.service';
import { ToasterService } from '../../../../services/toaster.service';
import { LoadingService } from '../../../../services/loading.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommodityCategory } from '../../../../models/commodity-category-master.interface';

@Component({
  selector: 'app-manage-commodity-category',
  standalone: true,
  imports: [SharedModule, SvgIconComponent,BackButtonComponent,MatSelectModule],
  templateUrl: './manage-commodity-category.component.html',
  styleUrl: './manage-commodity-category.component.scss'
})
export class ManageCommodityCategoryComponent {
  readOnlyMode = true;
  masterEditingEnabled = false;
  masterForm: FormGroup;
  masterId: string = '';
  cargoTypes: string[] = ['Hazardous','Non-Hazardous'];

  @ViewChildren('formField') formFields!: QueryList<ElementRef>;

  private destroy$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private toasterService: ToasterService,
    private loadingService: LoadingService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.masterForm = this.initForm();
  }

  ngOnInit(): void {
    this.masterId = this.route.snapshot.paramMap.get('commodityCategoryId') || '';
    this.setupComponentMode();
    if (this.masterId) {
      this.loadMasterData();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initForm(): FormGroup {
    return this.fb.group({
      name: ['', Validators.required],
      code: ['', Validators.required],
      cargoType: [''],
      isHighValue: ['']
    });
  }

  private setupComponentMode(): void {
    if (this.masterId) {
      const path = this.route.snapshot.url[0].path;
      this.masterEditingEnabled = path === 'edit';
      this.readOnlyMode = !this.masterEditingEnabled;
      if (this.readOnlyMode) {
        this.masterForm.disable();
      }
    } else {
      this.readOnlyMode = false;
      this.masterEditingEnabled = false;
    }
  }

  private loadMasterData(): void {
    this.loadingService.show();
    this.api.getCommodityCategory(this.masterId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: any) => {
          const commodityCategoryData: CommodityCategory = res.data;
          this.patchFormWithMasterData(commodityCategoryData);
          this.loadingService.hide();
        },
        error: (err) => {
          console.error('Error loading commodity category data:', err);
          this.toasterService.error('Failed to load commodity category data');
          this.loadingService.hide();
        },
      });
  }

  private patchFormWithMasterData(commodityCategoryData: CommodityCategory): void {
    this.masterForm.patchValue(commodityCategoryData);
  }

  submitMasterForm(): void {
    if (this.masterForm.valid) {
      const formValue = this.masterForm.value;
      const masterData: CommodityCategory = formValue;
      masterData.active = true; //always create new master resource with active=true
      
      this.loadingService.show();
      const action$ = this.masterEditingEnabled
        ? this.api.updateCommodityCategory(masterData, this.masterId)
        : this.api.addComodityCategory(masterData);

      action$.pipe(takeUntil(this.destroy$)).subscribe({
        next: () => {
          const message = this.masterEditingEnabled
            ? `Commodity Category ${masterData.name} has been updated!`
            : `Commodity Category ${masterData.name} has been added!`;
          this.toasterService.success(message);
          this.loadingService.hide();
          this.goBack();
        },
        error: (err) => {
          this.toasterService.error(err.error.errorDesc || 'An error occurred');
          console.error('Error submitting form:', err);
          this.loadingService.hide();
        },
      });
    } else {
      this.markFormGroupTouched(this.masterForm);
      this.scrollToFirstInvalidControl();
    }
  }

  private markFormGroupTouched(formGroup: FormGroup | FormArray): void {
    Object.values(formGroup.controls).forEach(control => {
      if (control instanceof FormGroup || control instanceof FormArray) {
        this.markFormGroupTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }

  scrollToFirstInvalidControl(): void {
    const firstInvalidControl = this.formFields.find((element) => {
      const nativeElement = element.nativeElement as HTMLElement;
      return nativeElement.classList.contains('ng-invalid');
    });

    if (firstInvalidControl) {
      firstInvalidControl.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      (firstInvalidControl.nativeElement as HTMLElement).focus();
    }
  }

  goBack(): void {
    this.router.navigateByUrl('/manage-masters/commodity-categories');
  }
}
