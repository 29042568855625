import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  Optional,
  Output,
  SimpleChange, SimpleChanges
} from "@angular/core";
import { DatePipe, NgClass, NgForOf, NgIf, NgStyle } from "@angular/common";
import { HighlightPipe } from "../../pipes/highlight.pipe";
import { NoDataComponent } from "../no-data/no-data.component";
import { NoDataFoundComponent } from "../no-data-found/no-data-found.component";
import { ReactiveFormsModule } from "@angular/forms";
import { ResizableModule, ResizeEvent } from "angular-resizable-element";
import { SvgIconComponent } from "../svg-icon/svg-icon.component";
import { TableMenuComponent } from "../table-menu/table-menu.component";
import { StatsData } from "../../models/stats-response.interface";
import { StyleConfig, TableMeta } from "../../models/table-meta.interface";
import { UserListDataResponse } from "../../models/user-list-data-response";
import { NgxSkeletonLoaderComponent } from "ngx-skeleton-loader";
import { UserListRequest } from "../../models/user-list-request.interface";
import { PaginatedListDataResponse } from "../../models/paginated-list-data-response";
import { TableListRequest } from "../../models/table-list-request.interface";
import { User } from "../../models/user.interface";
import { TosTableTdComponent } from "./tos-table-td/tos-table-td.component";

@Component({
  selector: "app-tos-table",
  standalone: true,
  imports: [
    DatePipe,
    HighlightPipe,
    NgForOf,
    NgIf,
    NoDataComponent,
    NoDataFoundComponent,
    ReactiveFormsModule,
    ResizableModule,
    SvgIconComponent,
    TableMenuComponent,
    NgStyle,
    NgxSkeletonLoaderComponent,
    NgClass,
    TosTableTdComponent
  ],
  templateUrl: "./tos-table.component.html",
  styleUrl: "./tos-table.component.scss"
})
export class TosTableComponent {
  totalPages: number = 0;
  openMenuIndex: number | null = null;
  filterTableMenuOptions: any[] = [];

  @Input() selectedItems: any[] = [];
  @Input() stats!: StatsData;
  @Input() tableMeta!: TableMeta;
  @Input() styleConfig!: StyleConfig;
  @Input() dataListRequest!: TableListRequest;
  @Input() dataListResponse!: PaginatedListDataResponse;
  @Input() showTableStats: boolean = true;
  @Input() tableMenuOptions: any[] = [];
  @Input() showMenuOptions: boolean = true;
  @Input() allTableRecordsSelected: boolean = false;

  @Output() dataListRequestChange = new EventEmitter<TableListRequest>();
  @Output() tosTableAction = new EventEmitter<any>();
  @Output() selectedItemsChange = new EventEmitter<any[]>();
  @Output() allTableRecordsSelectedChange = new EventEmitter<void>();
  @Output() tableMenuOptionSelected = new EventEmitter<any>();

  constructor(private cdr: ChangeDetectorRef) {}


  ngOnChanges(changes: SimpleChanges) {
    if (changes["dataListResponse"] && changes["dataListResponse"].currentValue) {
      this.calculateTotalPages();
    }
  }

  handlePreviousBtnClick() {
    if (!(this.dataListRequest.page > 0)) return;

    this.dataListRequest.page -= 1;
    this.tosTableAction.emit();
  }

  handleNextButtonClick() {
    if (!(this.dataListRequest.page >= 0 && this.dataListRequest.page < this.totalPages - 1)) return;

    this.dataListRequest.page += 1;
    this.tosTableAction.emit();
  }

  handleRowsPerPage(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    const selectedValue = selectElement.value;
    this.dataListRequest.limit = Number(selectedValue);
    this.dataListRequest.page = 0;
    this.tosTableAction.emit();
  }

  goToPage(page: number) {
    this.dataListRequest.page = page - 1;
    this.tosTableAction.emit();
  }

  getDisplayedPages() {
    const total = this.totalPages;
    const current = this.dataListRequest.page;
    const pages: Array<number | string> = [];
    const windowSize = 3; // Numbers to show on each side

    // If total pages is less than or equal to windowSize * 2, show all pages
    if (total <= windowSize * 2) {
      for (let i = 1; i <= total; i++) {
        pages.push(i);
      }
      return pages;
    }

    // Calculate start of left window based on current page
    let leftStart = Math.max(1, current - windowSize + 1);

    // Calculate start of right window
    let rightStart = Math.max(total - windowSize + 1, leftStart + windowSize + 1);

    // Add left window numbers
    for (let i = leftStart; i < leftStart + windowSize && i <= total; i++) {
      pages.push(i);
    }

    // Add ellipsis if there's a gap
    if (rightStart - leftStart > windowSize) {
      pages.push("...");
    }

    // Add right window numbers
    for (let i = rightStart; i <= total; i++) {
      pages.push(i);
    }

    return pages;
  }

  onResizeEnd(event: ResizeEvent, column: string): void {
    if (event.edges.right) {
      const columnHeader = document.querySelector(`th.${column}_th`) as HTMLElement;
      const columnCells = document.querySelectorAll(`td.${column}_td`);
      const newWidth = `${event.rectangle.width}px`;

      if (columnHeader) {
        columnHeader.style.width = newWidth;
      }

      columnCells.forEach(cell => {
        const cellHtml = cell as HTMLElement;
        cellHtml.style.width = newWidth;
      });
    }
  }

  sortData(column: string) {
    if (!this.dataListRequest.orderBy) {
      this.dataListRequest.orderBy = {};
    }

    const currentOrder = this.dataListRequest.orderBy[column];

    if (!currentOrder) {
      this.dataListRequest.orderBy = { [column]: "desc", ...this.dataListRequest.orderBy };
    } else if (currentOrder === "desc") {
      this.dataListRequest.orderBy[column] = "asc";
    } else if (currentOrder === "asc") {
      delete this.dataListRequest.orderBy[column];
    }

    this.tosTableAction.emit();
  }

  calculateTotalPages() {
    if (this.dataListRequest.where) {
      this.totalPages = Math.ceil(
        this.dataListResponse.totalCount / this.dataListRequest.limit
      );
    } else if (this.stats) {
      this.totalPages = Math.ceil(
        this.stats.total / this.dataListRequest.limit
      );
    }
  }

  orderByListContainsField(fieldName: string): any {
    return this.dataListRequest.orderBy && this.dataListRequest.orderBy.hasOwnProperty(fieldName);
  }

  toggleSelection(record: any) {
    if (!this.isSelected(record)) {
      this.selectedItems.push(record);
    } else {
      const uniqueColumns = this.tableMeta?.uniqueColumns ?? [];
      this.selectedItems = this.selectedItems.filter((i) => 
        uniqueColumns.length > 0 
          ? !uniqueColumns.every((key) => i?.[key] === record?.[key]) 
          : i?.id !== record?.id
      );
      if (this.allTableRecordsSelected) {
        this.allTableRecordsSelected = false;
        this.allTableRecordsSelectedChange.emit();
      }
    }
    this.selectedItemsChange.emit(this.selectedItems);
  }

  isSelected(item: any): boolean {
    if (this.allTableRecordsSelected) {
      return true;
    }
  
    const uniqueColumns = this.tableMeta?.uniqueColumns ?? [];
    if (uniqueColumns.length > 0) {
      return this.selectedItems.some((x) =>
        uniqueColumns.every((key) => x[key] === item[key])
      );
    }
  
    return this.selectedItems.some((x) => x.id === item.id);
  }

  isAllRecordsOfCurrentPageSelected(): boolean {
    return (
      this.allTableRecordsSelected ||
      (this.dataListResponse.records?.length > 0 &&
        this.dataListResponse.records?.every((item: User) =>
          this.isSelected(item)
        ))
    );
  }

  toggleAllRecordsOfCurrentPageSelections(isChecked: boolean) {
    if (isChecked) {
      this.dataListResponse.records?.forEach((item) => {
        if (!this.isSelected(item)) {
          this.selectedItems.push(item);
        }
      });
    } else {
      const uniqueColumns = this.tableMeta?.uniqueColumns ?? [];
      this.dataListResponse.records?.forEach((item) => {
        this.selectedItems = this.selectedItems.filter((i) => 
          uniqueColumns.length > 0 
            ? !uniqueColumns.every((key) => i?.[key] === item?.[key]) 
            : i?.id !== item?.id
        );;
      });
    }

    this.selectedItemsChange.emit(this.selectedItems);
  }

  handleMenuToggle(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? null : index;
  }

  handleOptionSelected(record: any, actionName: any) {
    this.tableMenuOptionSelected.emit({ record, actionName });
  }

  filterTableMenu(record: any,) {
    // Filter out the options that are not applicable to the current record
    const menuOptionMap: any = {
      'active': record.active,
      'inactive': !record.active,
      'lock': record.isLocked,
      'unlock': !record.isLocked,
      'grantAdmin': record.isSuperAdmin,
      'revokeAdmin': !record.isSuperAdmin,
      'releaseDocument': !(record.holdCategory === 'CUSTOMS')
    };

    this.filterTableMenuOptions = this.tableMenuOptions.filter((option) => !menuOptionMap[option.value]);
  }
}
