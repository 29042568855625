import { Component,
  ElementRef,
  Input,
  QueryList,
  ViewContainerRef,
  ViewChild,
  ViewChildren, } from '@angular/core';
import { SharedModule } from '../../../../modules/shared/shared.module';
import { FormBuilder, FormGroup, FormsModule, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { OperationService } from '../../../../services/operations.service';
import { debounceTime, switchMap } from 'rxjs/operators';
import { ConfirmDialogV2Component } from '../../../confirm-dialog/confirm-dialog-v2.component';
import { LoadingService } from '../../../../services/loading.service';
import { SuggestionDropdownComponent } from '../../../suggestion-dropdown/suggestion-dropdown.component';
import { AutocompleteDropdownComponent } from '../../../autocomplete-dropdown/autocomplete-dropdown.component';
import {VehicleType} from "../../../../models/vehicle-type.interface"
import { InventoryType, CycleType, getGateInOptions } from '../../../../constants/gate-ops-constants';
import { GateInRequest } from '../../../../models/gate-in-request.interface';

@Component({
  selector: 'app-trailer-gate-in',
  standalone: true,
  imports: [
    SharedModule,
    FormsModule,
    SuggestionDropdownComponent,
    AutocompleteDropdownComponent
  ],
  templateUrl: './trailer-gate-in.component.html',
  styleUrl: './trailer-gate-in.component.scss'
})
export class TrailerGateInComponent {
  gateInForm: FormGroup;
  ownVehicle: boolean = false;
  isOwnMovementReadonly: boolean = false;
  selectedTrailerId: string | null = null;
  selectedTrailerNo: string | null = null;
  cycleOptions = [
    { label: 'Import', value: 'IMPORT' },
    { label: 'Export', value: 'EXPORT' },
    { label: 'Empty', value: 'EMPTY' },
    { label: 'Bond', value: 'BOND' }
];
  supportedGateInOptions: Array<{ key: string; label: string; cycle: typeof CycleType[keyof typeof CycleType] | null}> = [];
  gateInOptions: Array<{ key: string; label: string; cycle: typeof CycleType[keyof typeof CycleType] | null}> = [];

  @ViewChild('trailerTypeDropdown', { static: false }) trailerTypeDropdown?: AutocompleteDropdownComponent;
  @ViewChildren('formField') formFields!: QueryList<ElementRef>;
    public trailerTypeDataRes?: VehicleType;

  constructor(
    private fb: FormBuilder,
    private api: OperationService,
    private loadingService: LoadingService,
    private viewContainerRef: ViewContainerRef,
    private dialog: MatDialog
  ) {
    this.gateInForm = this.fb.group({
      cycle: ['', Validators.required],
      gateInMode: ['', Validators.required],
      laneNo: ['', Validators.required],
      ownVehicle: [false],
      ownMovement: [{ value: false }],
      trailerNo: ['', Validators.required],
      transporterName: ['', Validators.required],
      trailerTypeId: ['', Validators.required],
      //inspectionType: ['', Validators.required],
      driverName: ['', Validators.required],
      driverLicenseNo: ['', Validators.required]
    });
  }
  ngOnInit(): void {
    this.loadDropdownData();
  }

  loadDropdownData(): void {
      this.supportedGateInOptions = getGateInOptions(InventoryType.TRAILER);
      console.log('Gate In Options:', this.gateInOptions);
      this.gateInForm.get('gateInMode')?.disable();
  }

  onCycleChange(event: Event): void {
    const selectedCycleLabel = (event.target as HTMLSelectElement).value;
    console.log("selected cycle =>" + selectedCycleLabel);
    this.gateInOptions = this.supportedGateInOptions.filter(option =>
      !option.cycle || option.cycle.key === selectedCycleLabel
    );
    console.log("Filtered Modes =>", this.gateInOptions);
    this.gateInForm.get('gateInMode')?.setValue('');
    this.gateInForm.get('gateInMode')?.enable();
  }

  onOwnVehicleChange(): void {
    const ownVehicleChecked = this.gateInForm.get('ownVehicle')?.value;
    this.ownVehicle = ownVehicleChecked;
    if (ownVehicleChecked) {
      this.gateInForm.get('ownMovement')?.setValue(true);
      this.isOwnMovementReadonly = true;
    } else {
      this.isOwnMovementReadonly = false;
    }
  }

  resetForm() {
    this.gateInForm.reset({
      ownVehicle: false,
      ownMovement: false,
      cycle: '',
      gateInMode: '' ,
    });
    this.gateInOptions = [];
    this.gateInForm.get('gateInMode')?.disable();
    this.ownVehicle = false;
    this.isOwnMovementReadonly = false;
  }

  saveForm() {
    this.gateInForm.markAllAsTouched();
    console.log(this.gateInForm.value);
    console.log(this.gateInForm.valid);
    if (this.gateInForm.valid) {
      const formData = this.gateInForm.value;
      const requestPayload : GateInRequest = {
        joNo: '',
        gateInType: formData.gateInMode,
        gateInVehicleType: InventoryType.TRAILER,
        laneNo: formData.laneNo,
        vehicle: {
          vehicleId: this.selectedTrailerId ? this.selectedTrailerId : null,
          vehicleNo: this.selectedTrailerNo ? this.selectedTrailerNo : formData.trailerNo,
          vehicleType: formData.trailerTypeId,
          transporterName: formData.transporterName,
          ownVehicle: formData.ownVehicle,
          ownMovement: formData.ownMovement,
          driverName: formData.driverName,
          driverLicenseNo: formData.driverLicenseNo,
        },
      };
    
      this.api.submitGateInRequest(requestPayload).subscribe((response) => {
        console.log('Submission successful:', response);
        this.openConfirmDialog()
      },
      (error) => {
        console.log('Request failed:', error);
      }
    );
    }
  }

  openConfirmDialog() {
    const confirmDialogRef = this.viewContainerRef.createComponent(ConfirmDialogV2Component);

    // Set the message in the modal
    confirmDialogRef.instance.title = "GateIn Successful"
    confirmDialogRef.instance.message = "Do you want to review and edit the details?";
    confirmDialogRef.instance.confirmButtonLabel = "Yes";

    // Subscribe to modal confirm action
    confirmDialogRef.instance.confirm.subscribe(() => {
      console.log("Clicked on YES!!");
      confirmDialogRef.destroy();
    });

    // Subscribe to modal cancel action
    confirmDialogRef.instance.cancel.subscribe(() => {
      this.gateInForm.reset();
      confirmDialogRef.destroy();
    });
  }

  onTrailerSelected(selectedItem: any) {
    this.gateInForm.get('transporterName')?.setValue(selectedItem.vendorName);
    this.selectedTrailerId = selectedItem.id;
    this.selectedTrailerNo = selectedItem.trailerNumber;
    if (this.trailerTypeDropdown) {
      const trailerTypeResult = {
        [this.trailerTypeDropdown.bindLabel]: selectedItem.vehicleTypeValue,
        [this.trailerTypeDropdown.bindValue]: selectedItem.vehicleTypeId,
      };

      console.log("TrailerType Object => ", trailerTypeResult);
      if (typeof this.trailerTypeDropdown.select === 'function') {
        this.trailerTypeDropdown.select(trailerTypeResult);
      } else {
        console.error('select method is not available on trailerTypeDropdown');
      }
    } else {
      console.error('trailerTypeDropdown is undefined');
    }
  }
}
