<div class="dynamic_input_container" [formGroup]="form">
  @if (control?.fieldInfo.dataType === "picklist") {
    <app-autocomplete-dropdown
      [id]="control.label"
      [placeholder]="control?.placeHolder"
      [formControlName]="control.label"
      [apiUrl]="control?.fieldInfo?.lookupDetails?.apiUrl"
      [selectFields]="control?.fieldInfo?.lookupDetails?.selectFields"
      [whereFields]="control?.fieldInfo?.lookupDetails?.whereFields"
      [limit]="control?.fieldInfo?.lookupDetails?.limit"
      [orderBy]="control?.fieldInfo?.lookupDetails.orderBy"
      [filterByKey]="control?.fieldInfo?.lookupDetails?.filterByKey"
      [bindLabel]="control?.fieldInfo?.lookupDetails?.bindLabel"
      [bindValue]="control?.fieldInfo?.lookupDetails?.bindValue"
      [displayColumns]="control?.fieldInfo?.lookupDetails?.displayColumns"
      [disable]="isControlDisabled()"
      (suggestionSelected) = "onSearchSuggestionSelect($event)"
      (searchCleared) = "onAutoCompleteSearchTextCleared()"
      [preSelectedValue]="control?.defaultValue"
    >
    </app-autocomplete-dropdown>
  } @else if (control?.fieldInfo?.dataType === "select") {
    <select [formControlName]="control.label" [id]="control.label" >
      <option value="" disabled selected>Select</option>
      @for (option of control?.fieldInfo?.options; track option.value) {
        <option [value]="option?.value">{{ option?.label }}</option>
      }

    </select>
  } @else if (control?.fieldInfo?.dataType === "boolean") {
    <app-toggle [id]="control.label"  [formControlName]="control.label" [label]="control?.displayLabel" [disable]="control.disabled" [toggleId]="control.label" [isActive]="form.get(control.label)?.value"></app-toggle>
  } @else if (control?.fieldInfo?.dataType === 'textarea') {
    <textarea [id]="control.label" [placeholder]="control?.placeHolder" [formControlName]="control.label" [disabled]="control.disabled"></textarea>
  } @else if (control?.fieldInfo?.dataType === "multi-select") {
    <app-multi-select
        [id]="control.label"
        [options]="control?.fieldInfo?.options"
        [displayKey] = "control?.fieldInfo?.displayKey"
        [valueKey] = "control?.fieldInfo?.valueKey"
        [isMultiSelect]="true"
        [placeholder]="control?.placeHolder"
        [disable]="isControlDisabled()"
        [readonly]="control?.isReadOnly ?? false"
        [formControlName]="control.label"
      ></app-multi-select>
  } @else if (control?.fieldInfo?.dataType === "chip-input") {
    <app-chip-input
        [id]="control.label"
        [placeholder]="control?.placeHolder"
        [formControlName]="control.label"
      ></app-chip-input>
  }  @else {
    <input
      [id]="control.label"
      [type]="control?.fieldInfo?.dataType"
      [placeholder]="control?.placeHolder || ''"
      autocomplete="off"
      [ngClass]="{'form_error': form.get(control.label)?.invalid && form.get(control.label)?.touched && form.get(control.label)?.errors}"
      [formControlName]="control.label"
      [readonly]="control?.isReadOnly ?? false"
    >
  }
  <div class="form_validation_error_new" [ngClass]="{'visible': form.get(control.label)?.invalid && form.get(control.label)?.touched}">
    @for (error of control?.validators; track error.type) {
      <small *ngIf="form.get(control.label)?.errors?.[error.type]">
        {{ error.message }}
      </small>
    }
  </div>
</div>
