<div class="suggestions-dropdown-container">
  <div class="search-bar" [class.search-input-focus]="focusSearch">
    <ng-select
      class="suggestion_dropdown"
      [items]="suggestions"
      [bindLabel]="bindLabel"
      [bindValue]="bindValue"
      [virtualScroll]="true"
      (scrollToEnd)="onScroll()"
      (search)="onSearch($event)"
      [loading]="loading"
      [placeholder]="placeholder"
      (change)="onSelect($event)"
    >
    </ng-select>
    <!-- <button>
      <app-svg-icon [path]="'assets/icons/search_icon.svg'"></app-svg-icon>
    </button> -->
  </div>
  <div class="search-results" *ngIf="showSuggestions && focusSearch">
    <div
      class="result"
      *ngFor="let result of suggestions"
      (click)="select($event)"
    >
      {{ result.name.trim() ? result.name : "N/A" }}
      <div class="hint">Code : {{ result.code }}</div>
    </div>
  </div>
</div>
