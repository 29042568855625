<div class="container">
    <div class="header header_with_back_button">
      <app-back-button></app-back-button>
      <span>
        @if (readOnlyMode) { View } @else if(masterEditingEnabled) { Edit }
        @else {Create New} Package
      </span>
    </div>
  
    <div class="container-content">
      <form [formGroup]="masterForm">
        <div class="section">
          <div class="head">Basic Information</div>
          <div class="fields">
            <div class="grid-container">
              <div class="form-group">
                <label for="name" class="required_field_label" #formField
                  >Package Name</label
                >
                <input
                  id="name"
                  name="name"
                  formControlName="name"
                  type="text"
                  placeholder="Enter name"
                  required
                  autocomplete="name"
                  [ngClass]="{
                    form_error:
                      masterForm.get('name')?.invalid &&
                      masterForm.get('name')?.touched &&
                      masterForm.get('name')?.errors
                  }"
                />
                <div
                  class="form_validation_error"
                  *ngIf="
                    masterForm.get('name')?.invalid &&
                    masterForm.get('name')?.touched
                  "
                >
                  <small *ngIf="customerForm.get('name')?.errors?.['required']"
                    >Name is required</small
                  >
                </div>
              </div>
  
              <div class="form-group">
                <label for="code" class="required_field_label" #formField
                  >Package Code</label
                >
                <input
                  id="code"
                  name="code"
                  formControlName="code"
                  type="text"
                  placeholder="Enter code"
                  required
                  autocomplete="code"
                  [ngClass]="{
                    form_error:
                      masterForm.get('code')?.invalid &&
                      masterForm.get('code')?.touched &&
                      masterForm.get('code')?.errors
                  }"
                />
                <div
                  class="form_validation_error"
                  *ngIf="
                    masterForm.get('code')?.invalid &&
                    masterForm.get('code')?.touched
                  "
                >
                  <small *ngIf="customerForm.get('code')?.errors?.['required']"
                    >Code is required</small
                  >
                </div>
              </div>
  
              <div class="form-group">
                <label for="description">Package Description</label>
                <input
                  id="description"
                  name="description"
                  formControlName="description"
                  type="text"
                  placeholder="Enter Package Description"
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  
    <div class="footer_actions">
      <ng-container *ngIf="readOnlyMode">
        <button type="button" class="secondary_button" (click)="goBack()">
          Back
        </button>
      </ng-container>
      <ng-container *ngIf="!readOnlyMode">
        <button type="button" class="secondary_button" (click)="goBack()">
          Cancel
        </button>
        <button
          type="button"
          class="primary_button"
          (click)="submitCustomerForm()"
        >
          @if(customerEditingEnabled){ Update }@else { Save }
        </button>
      </ng-container>
    </div>
  </div>
  