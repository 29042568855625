{
  "TRAILER": {
    "fetchApi": "v1/inventory/trailers/{id}",
    "idField": "id",
    "displayValueField": "trailerNumber"
  },
  "CARGO": {
    "fetchApi": "v1/import-cargo-items/{id}?withContainers=true&includeHoldDetails=true",
    "idField": "id",
    "displayValueField": "igmItemNo"
  },
  "CONTAINER": {
    "fetchApi": "v1/containers/{id}?withItems=true&includeHoldDetails=true",
    "idField": "id",
    "displayValueField": "containerNo"
  },
  "TRUCK": {
    "fetchApi": "v1/trailer-truck/{id}",
    "idField": "id",
    "displayValueField": "vehicleNo"
  },
  "JOB_ORDER_INVENTORY_LIST_CONTAINER": {
    "fetchApi": "v1/containers/{id}?withItems=true",
    "idField": "inventoryId",
    "displayValueField": "inventoryNo"
  }

}
