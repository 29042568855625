<div class="vertical-tabs">
  <div
    *ngFor="let tab of tabs; let last = last"
    class="tab-item"
    [class.active]="selectedTab === tab.id"
    (click)="selectTab(tab.id)"
  >
    <!-- Left Section: Icon + Connector -->
    <div class="tab-left">

      <div class="icon-wrapper">
        <!--
            <div class="icon-cover"> -->
        <app-svg-icon class="tab-icon" [path]="'assets/icons/'+ tab.icon"></app-svg-icon>

        <!-- </div> -->
      </div>
      <div class="connector-wrapper" *ngIf="!last">
        <div class="connector"></div>
      </div>
    </div>

    <!-- Right Section: Text Container -->
    <div class="tab-text">
      <div class="tab-title">{{ tab.label }}</div>
      <div class="tab-subtitle">{{ tab.subtitle }}</div>
    </div>
  </div>
</div>
