import { Component, EventEmitter, Input, Output, ViewChild, ElementRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, FormArray, FormsModule } from "@angular/forms";
import { DynamicForm } from '../../../../../models/dynamic-form';
import { DynamicFormComponent } from '../../../../dynamic-form/dynamic-form.component';
import { AutocompleteDropdownComponent } from '../../../../autocomplete-dropdown/autocomplete-dropdown.component';
import { SvgIconComponent } from "../../../../svg-icon/svg-icon.component";
import { DynamicFormService } from '../../../../../services/dynamic-form.service';
import { TableHeaderAndData } from '../../../../../models/table-header-and-data';
import { MultiSelectComponent } from '../../../../select/multi-select.component';
import { LoadingService } from '../../../../../services/loading.service';
import { MasterService } from '../../../../../services/masters-service';
import { forkJoin } from 'rxjs';
import { convertEpochToReadableISTDateTime } from '../../../../../utils/date-time-utils';
import { VerticalTabsComponent } from "../../../../vertical-tabs/vertical-tabs.component";

@Component({
  selector: 'app-job-order-tab-view',
  standalone: true,
  imports: [DynamicFormComponent, AutocompleteDropdownComponent, SvgIconComponent, CommonModule, FormsModule, MultiSelectComponent, VerticalTabsComponent],
  templateUrl: './job-order-tab-view.component.html',
  styleUrl: './job-order-tab-view.component.scss'
})
export class JobOrdeTabViewComponent {

  @Input() vendorList !: any;
  @Input() itemDetails!: any;
  @Input() index!: number;
  @Input() formGroup!: FormGroup;
  @Input() dynamicForm!: DynamicForm;
  @Input() readOnly: boolean = false;
  @Input() tableHeaderAndData!: TableHeaderAndData;
  @Input() requiredTabs: any[] = ["itemDetailsSection", "vendorDetailsSection", "containerDetailsSection"];

  @Output() onVendorAddition = new EventEmitter<any>();
  @Output() onVendorDeletion = new EventEmitter<any>();
  @Output() onAccordionOpen = new EventEmitter<any>();

  convertEpochToISTDateTime = convertEpochToReadableISTDateTime;

  serviceVendorList: any[] = [];
  serviceEquipmentList: any[] = [];
  selectedVendors: any[] = [];
  selectedService!: any;
  selectedEquipments: any[] = [];
  serviceSearchQuery: string = '';

  selectedTab: string = "itemDetailsSection";
  private sectionMap: Map<string, any> = new Map();


  vendorHeaderMap: any = [
    { displayName: "Vendor", fieldName: "vendorName" },
    { displayName: "Service", fieldName: "serviceName" },
    { displayName: "Equipment", fieldName: "equipmentName" }
  ];

  @ViewChild("itemDetailsSection") itemDetailsSection: ElementRef | undefined;
  @ViewChild("vendorDetailsSection") vendorDetailsSection: ElementRef | undefined;
  @ViewChild("containerDetailsSection", { read: ElementRef }) containerDetailsSection: ElementRef | undefined;

  @ViewChild('vendorDropdown', { static: false }) vendorDropdown!: MultiSelectComponent;
  @ViewChild('equipmentDropdown', { static: false }) equipmentDropdown!: MultiSelectComponent;

  addedServiceVendorEquipmentSet: Set<string> = new Set();

  constructor (private dynamicFormService: DynamicFormService,
               private loadingService: LoadingService,
               private masterService: MasterService
  ) {
  }

  ngOnInit() {
    console.log("Inside JobOrdeTabViewComponent: ", this.tableHeaderAndData?.headers);
    if (this.dynamicForm && this.formGroup) {
      this.dynamicFormService.addControlsToForm(this.formGroup, this.dynamicForm, false);
      this.formGroup.patchValue({
        ...this.itemDetails
      });
    }
    if (this.vendorList?.length > 0) {
      this.vendorList.forEach((item: any) => this.addToSet(item?.vendorId, item?.serviceId, item?.equipmentId));
    }
  }

  ngAfterViewInit() {
    if (this.itemDetailsSection) this.sectionMap.set("itemDetailsSection", this.itemDetailsSection.nativeElement);
    if (this.vendorDetailsSection) this.sectionMap.set("vendorDetailsSection", this.vendorDetailsSection.nativeElement);
    if (this.containerDetailsSection) this.sectionMap.set("containerDetailsSection", this.containerDetailsSection.nativeElement);
  }

  get showItemTable(): boolean {
    return !!this.tableHeaderAndData?.headers && !!this.tableHeaderAndData?.data?.length;
  }

  onServiceSelection(result: any) {
      this.selectedService = result;
      this.loadingService.show();
      forkJoin({
            vendorResult: this.masterService.listVendorsByService(result.id, true),
            equipmentResult: this.masterService.listEquipmentsByService(result.id, true)
          }).subscribe({
            next: ({vendorResult, equipmentResult}: any) => {
              this.serviceVendorList = vendorResult.data;
              this.serviceEquipmentList = equipmentResult.data;
              this.vendorDropdown.setOptions(this.serviceVendorList);
              this.equipmentDropdown.setOptions(this.serviceEquipmentList);
              this.loadingService.hide();
            },
            error: (err) => {
              console.error(err);
              this.loadingService.hide();
            },
          });
    }

  onDeleteRow(index: number): void {
    console.log('Delete row at index:', index);
    const vendor = this.vendorList[index];
    this.vendorList.splice(index, 1);
    this.removeFromSet(vendor?.vendorId, vendor?.serviceId, vendor?.equipmentId);
    //this.vendorFormArray.removeAt(index);
  }

  serviceSearchCleared() {
    this.selectedService = null;
    this.selectedVendors = [];
    this.selectedEquipments = [];
  }

  // get vendorFormArray() {
  //   return this.formGroup.get("vendorDetails") as FormArray;
  // }

  addVendorToList() {
    const selectedVendor = this.selectedVendors[0];
    this.selectedEquipments.forEach((equipment: any) => {
      if (this.alreadyAdded(selectedVendor.vendorId, this.selectedService.id, equipment.equipmentId)) {
        //this.toasterService.warning("Already added");
      } else if (selectedVendor && this.selectedService && equipment) {
          const vendor = {
            vendorId: selectedVendor.vendorId,
            vendorName: selectedVendor.vendorName,
            serviceId: this.selectedService.id,
            serviceName: this.selectedService.name,
            equipmentId: equipment.equipmentId,
            equipmentName: equipment.equipmentName
          }
          this.vendorList.push(vendor);
          this.addToSet(selectedVendor.vendorId, this.selectedService.id, equipment.equipmentId);
        }
    });

      // const vendorFormGroup = this.dynamicFormService.createEmptyFormGroup();
      // this.addVendorControlToGroup(vendorFormGroup, this.selectedVendor.id, this.selectedService.id, this.selectedEquipment.id);
      // this.vendorFormArray.push(vendorFormGroup);
    console.log(this.vendorList);
    this.selectedService = null;
    this.serviceSearchQuery = '';
    this.serviceVendorList = [];
    this.serviceEquipmentList = [];
    this.selectedEquipments = [];
    this.selectedVendors = [];
  }

  private addToSet(vendorId: string, serviceId: string, equipmentId: string) {
    this.addedServiceVendorEquipmentSet.add(vendorId + serviceId + equipmentId)
  }

  private removeFromSet(vendorId: string, serviceId: string, equipmentId: string) {
    this.addedServiceVendorEquipmentSet.delete(vendorId + serviceId + equipmentId)
  }

  private alreadyAdded(vendorId: string, serviceId: string, equipmentId: string): boolean {
    return this.addedServiceVendorEquipmentSet.has(vendorId + serviceId + equipmentId)
  }

  selectTab(sectionId: string) {
    this.selectedTab = sectionId;
    const section = this.sectionMap.get(sectionId);
    const container = document.querySelector(".full_page_container");

    if (section && container) {
      const sectionTop = section.getBoundingClientRect().top - container.getBoundingClientRect().top;
      container.scrollTo({
        top: sectionTop + container.scrollTop,
        behavior: "smooth"
      });
    }
  }

  get canAddVendors(): boolean {
    return this.selectedVendors.length > 0 && this.selectedService && this.selectedEquipments.length > 0;
  }
}
