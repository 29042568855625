<div class="container">
    <div class="search_bar_action_btns">
        <div class="search_bar">
            <div class="view-selection-container">
                <app-multi-select
                #processCycleDropdown
                    id="vendorId"
                    [options]="cycleOptions"
                    [displayKey]="'value'"
                    [valueKey]="'key'"
                    [isMultiSelect]="false"
                    [placeholder]="'Select Cycle'"
                    (selectionChange)="onCycleChange($event)"
                    ></app-multi-select>
            </div>
            
            <div class="search_container">
                <input
                    type="text"
                    name="search_table"
                    id="search_table"
                    [(ngModel)]="searchTerm"
                    placeholder="Search by Container or Item No"
                    (focus)="focusSearch = true"
                    (focusout)="focusSearch = false"
                    (keyup)="handleTableSearch($event)"
                >
                    <img src="assets/icons/search_icon.svg" class="search_icon">
            </div>

            <div class="filter_btn">
                <button (click)="filter()">
                <img src="assets/icons/filter.svg">
                Filters
                </button>
            </div>
        </div>
    
        <div class="action_section">
            <div class="selection-message" *ngIf="selectedItems.length > 0">
                @if (allTableRecordsSelected || tableListData.totalCount === selectedItems.length) {
                    <span>Selected: {{ tableListData.totalCount }} of {{ tableListData.totalCount }}</span>
                } @else {
                    <span>Selected: {{ selectedItems.length }} of {{ tableListData.totalCount }}</span>
                }
            </div>
            <button class="secondary_button" *ngIf="selectedItems.length > 0 
            && !allTableRecordsSelected && tableListData.totalCount != selectedItems.length" (click)="selectAll()">
                Select all {{tableListData.totalCount}} records
            </button>
            <button class="secondary_button" *ngIf="allTableRecordsSelected" (click)="clearSelection()">
                Clear Selection
            </button>
            <button class="primary_button" *ngIf="allTableRecordsSelected || selectedItems.length > 0" (click)="releaseSelectedItems()">
                Release Hold
            </button>
            <div class="create_hold_action">
                <button class="add_new_btn primary_button" (click)="createHold()">
                    <app-svg-icon [path]="'/assets/icons/add_plus_icon.svg'" class="add_icon"></app-svg-icon>
                    Create Hold
                </button>
            </div>
        </div>
    </div>
    
    <app-tos-table
        [showTableStats]="false"
        [tableMeta]="tableMeta"
        [tableMenuOptions]="menuOptions"
        [(dataListRequest)]="tableListRequest"
        [dataListResponse]="tableListData"
        [(allTableRecordsSelected)]="allTableRecordsSelected"
        [(selectedItems)]="selectedItems"
        (tosTableAction)="listHoldRecords()"
        (tableMenuOptionSelected)="handleMenuOptionSelection($event)"
    ></app-tos-table>
</div>