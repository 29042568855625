<div class="sidebar_expanded">


    <div class="navbar_header">

      <div class="smart_tos_logo_and_pin">
        <div class="full_logo">
          <div class="logo_text">Smart</div><span class="tos">TOS</span>
        </div>
        <span class="pin" (click)="toggleSidebar($event)" [ngClass]="{
           'pin_active': isPinned
        }">
          <img src="assets/icons/sb.svg">
        </span>
      </div>
    </div>

  <div class="expanded_nav_items">
    <div class="expanded_nav_header">
      <div class="text">{{ parentMenu.displayLabel }}</div>
    </div>
    @for (child of childResources; track child.id){
    <div class="navigation">
      <div class="sub_nav">
        <a class="sub_nav_item" [routerLink]="child.resourceProperties.url" [class.active]="isActive(child.id)">
          <div class="content" ><div class="text">{{child.displayLabel}}</div></div>
        </a>
      </div>
    </div>
    }


<!--    <div class="navigation">
      <div class="sub_nav">
        <div class="nav-item-base">
          <div class="content">
            <div class="dot">
            </div>
            <div class="arrow-narrow-left">
            </div>
            <div class="text">User Management</div>
          </div>
          <div class="badge">
          </div>
          <img class="chevron-up-icon" alt="" src="assets/icons/chevron_up.svg">
        </div>
        <div class="menu">
          <div class="nav-item-base1">
            <div class="content2">
              <div class="text2">Users</div>
            </div>
          </div>
          <div class="nav-item-base2">
            <div class="content2">
              <div class="text2">Roles & Permissions</div>
            </div>
          </div>
          <div class="nav-item-base3">
          </div>
          <div class="nav-item-base3">
          </div>
          <div class="nav-item-base3">
          </div>
          <div class="nav-item-base3">
          </div>
        </div>
      </div>
    </div>
    <div class="navigation">
      <div class="sub_nav">
        <div class="sub_nav_item">
          <div class="content">
            <div class="dot">
            </div>
            <div class="arrow-narrow-left">
            </div>
            <div class="text">Other Nav Item</div>
          </div>
          <div class="badge">
          </div>
          <img class="chevron-up-icon1" alt="" src="assets/icons/chevron_down.svg">
        </div>
        <div class="menu1">
          <div class="nav-item-base1">
            <div class="content2">
              <div class="text2">Users</div>
            </div>
          </div>
          <div class="nav-item-base2">
            <div class="content2">
              <div class="text2">Roles & Permissions</div>
            </div>
          </div>
          <div class="nav-item-base3">
          </div>
          <div class="nav-item-base3">
          </div>
          <div class="nav-item-base3">
          </div>
          <div class="nav-item-base3">
          </div>
        </div>
      </div>
    </div>-->
  </div>


</div>
