<div class="content_add_another_dialog">
  @if (featureIconPath !== "") {
    <div class="feature_icon">
      <img [src]="featureIconPath" alt="Feature Icon" />
    </div>
  }

  <div class="text_and_supporting_text">
    <div class="title">{{ title }}</div>
    <div class="title_desc">{{ titleDescription }}</div>
  </div>

  <div class="action_buttons">
    <button class="primary_button" (click)="handlePrimaryBtnClick()"> {{primaryBtnText}} </button>
    <small class="secondary_btn_text" (click)="handleSecondaryBtnClick()"> {{secondaryBtnText}} </small>
  </div>
</div>
