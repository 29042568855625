import { Injectable } from '@angular/core';
import { ResourcePermission } from "../models/resource-permission.interface";
import { Menu } from "../models/sidebar-menu.interface";
import { BehaviorSubject, Observable } from "rxjs";
import { ApiService } from "./api.service";
import { LocalStorageService } from "./local-storage.service";
import { StorageKeys } from "../constants/app-constants";

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  private menuItems: BehaviorSubject<Menu[]>;

  constructor(
    private apiService: ApiService,
    private localStorageService: LocalStorageService
  ) {
    this.menuItems = new BehaviorSubject<Menu[]>(this.getStoredMenuItems());
  }

  private getStoredMenuItems(): Menu[] {
    const storedItems = this.localStorageService.getItem(StorageKeys.MENU_ITEMS);
    return storedItems ? JSON.parse(storedItems as string) : [];
  }

  getMenuItems(): Observable<Menu[]> {
    return this.menuItems.asObservable();
  }

  fetchAndStoreMenuItems(userId: string): void {
    const resourcePermission: ResourcePermission = {
      userId: userId,
      roleId: "",
      resourceType: "NAV_ITEM",
      action: "GET"
    };

    this.apiService.getUserMenuItems(userId, resourcePermission).subscribe({
      next: (res: any) => {
        this.menuItems.next(res.data);
        this.localStorageService.setItem(StorageKeys.MENU_ITEMS, JSON.stringify(res.data));
      },
      error: (err) => console.error('Error fetching menu items:', err)
    });
  }

  clearMenuItems(): void {
    this.menuItems.next([]);
    this.localStorageService.removeItem(StorageKeys.MENU_ITEMS);
  }
}
