import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { GateInRequest } from "../models/gate-in-request.interface";

const operationsEndPoint = "v1/operations";

const gateInEndPoint = "v1/operations/gate-in";

@Injectable({
    providedIn: 'root',  // Ensures the service is available app-wide
  })
export class OperationService {
    
    private baseUrl: string = environment.apiBaseUrl;
  
    constructor(private http: HttpClient) {
    }
    
    submitGateInRequest(gateInRequest: GateInRequest) {
        return this.http.post(
          `${this.baseUrl}/${gateInEndPoint}`,
          gateInRequest
        );
      }
}

