<div class="container">
    <header class="header page-header">
        <div class="header-content">
            <div class="header-text">
                <div class="section-title text">Gate In - Container</div>
                <div class="section-subtitle text">Fill in trailer and container information to gate in</div>
            </div>
            <div class="header-actions">
                <button class="btn secondary_button" (click)="resetForm()">Reset</button>
                <button class="btn primary_button" (click)="saveForm()">Save</button>
            </div>
        </div>
        <div class="divider"></div>
    </header>
    <div class="scrollable-section container">
        <section class="mode-selection card-layout">
            <form [formGroup]="gateInForm">
                <div class="form-row">
                    <div class="form-group">
                        <label for="cycle" class="form-label">
                        Cycle<span class="required">*</span>
                        </label>
                        <select id="cycle" formControlName="cycle" class="form-select" required (change)="onCycleChange($event)" >
                            <option value="" disabled>Select Cycle</option>
                            <option *ngFor="let option of cycleOptions" [value]="option.value">{{ option.label }}</option>
                        </select>
                        <div class="form_validation_error" *ngIf="gateInForm.get('cycle')?.invalid && gateInForm.get('cycle')?.touched">
                            <small *ngIf="gateInForm.get('cycle')?.errors?.['required']">Please select the cycle</small>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="gateInMode" class="form-label">
                          Gate-In Mode<span class="required">*</span>
                        </label>
                        <select id="gateInMode" formControlName="gateInMode" class="form-select" required>
                            <option value="" disabled>Select Mode</option>
                            <option *ngFor="let option of gateInOptions" [value]="option.key">{{ option.label }}</option>
                        </select>
                        <div class="form_validation_error" *ngIf="gateInForm.get('gateInMode')?.invalid && gateInForm.get('gateInMode')?.touched">
                            <small *ngIf="gateInForm.get('gateInMode')?.errors?.['required']">Please select the mode</small>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="gateInMode" class="form-label">
                          Lane<span class="required">*</span>
                        </label>
                        <select id="laneNo" formControlName="laneNo" class="form-select" required>
                            <option value="" disabled>Select Lane</option>
                            <option *ngFor="let option of laneOptions" [value]="option">{{ option }}</option>
                        </select>
                        <div class="form_validation_error" *ngIf="gateInForm.get('laneNo')?.invalid && gateInForm.get('laneNo')?.touched">
                            <small *ngIf="gateInForm.get('laneNo')?.errors?.['required']">Please select the mode</small>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="load-recent-data">
                            <app-svg-icon
                                [path]="'assets/icons/refresh_icon.svg'"
                            ></app-svg-icon>
                            <span>Load Recent Data</span>
                        </div>
                    </div>
                </div>
            </form>
        </section>
        
        <section class="trailer-section container">
            <header class="header section-header">
                <div class="header-content">
                    <div class="header-text">
                        <div class="section-title">Trailer Details</div>
                        <div class="section-subtitle">Fill in Customer Information to Begin Freight Management</div>
                    </div>
                    <div class="header-actions">
                        <button class="btn survey-btn text">
                            <app-svg-icon class="image-icon"
                                [path]="'assets/icons/image_icon.svg'"
                            ></app-svg-icon>
                            Survey Images
                        </button>
                    </div>
                </div>
            </header>
        
            <div class = "content-section card-layout">
                <form [formGroup]="gateInForm">
                    <div class="form-row checkbox-row">
                        <app-checkbox
                            checkboxId="ownVehicle"
                            label="Own Vehicle"
                            formControlName="ownVehicle"
                            (change)="onOwnVehicleChange()">
                        </app-checkbox>
                        <app-checkbox
                            checkboxId="ownMovement"
                            label="Own Movement"
                            formControlName="ownMovement"
                            (change)="onOwnVehicleChange()">
                        </app-checkbox>
                    </div>
                    
                    <div class="form-row">
                        <div class="form-group">
                            <label for="trailerNo" class="form-label">
                                Trailer No<span class="required">*</span>
                            </label>
                            <input
                                *ngIf="!ownVehicle"
                                type="text"
                                id="trailerNo"
                                formControlName="trailerNo"
                                class="form-input"
                                required
                                placeholder="Enter Trailer No."
                            />
                            
                            <app-autocomplete-dropdown
                            *ngIf="ownVehicle"
                                id="trailerNo"
                                formControlName="trailerNo"
                                class = "autocomplete-dropdown"
                                [placeholder]="'Search Trailer'"
                                [apiUrl]="'v1/trailers/list'"
                                [selectFields]="['id', 'trailerNumber', 'vendorId.id as vendorId', 'vendorId.name as vendorName', 'vehicleTypeId.id as vehicleTypeId', 'vehicleTypeId.generalPurposeType as vehicleTypeValue']"
                                [whereFields]="['trailerNumber']"
                                [limit]="10"
                                [orderBy]="{ trailerNumber: 'asc' }"
                                [filterByKey]="'trailerNumber'"
                                [bindLabel]="'trailerNumber'"
                                [bindValue]="'id'"
                                [displayColumns]="[
                                    { key: 'trailerNumber', header: 'Trailer No' },
                                    { key: 'vendorName', header: 'Transporter Name' },
                                ]"
                                (suggestionSelected) = "onTrailerSelected($event)"
                
                                >
                                </app-autocomplete-dropdown>
                            <div class="form_validation_error" *ngIf="gateInForm.get('trailerNo')?.invalid && gateInForm.get('trailerNo')?.touched">
                                <small *ngIf="gateInForm.get('trailerNo')?.errors?.['required']">Trailer No is required</small>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="transporterName" class="form-label">
                                Transportner Name<span class="required">*</span>
                            </label>
                            <input type="text" 
                            id="transporterName" 
                            formControlName="transporterName" 
                            class="form-input" 
                            placeholder="Enter Transporter Name" 
                            required 
                            />
                            <div class="form_validation_error" *ngIf="gateInForm.get('transporterName')?.invalid && gateInForm.get('transporterName')?.touched">
                                <small *ngIf="gateInForm.get('transporterName')?.errors?.['required']">Transporter Name is required</small>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group">
                            <label for="trailerTypeId" class="form-label">Trailer Type<span class="required">*</span></label>
                            <app-autocomplete-dropdown
                                id="trailerTypeId"
                                name="trailerTypeId"
                                formControlName="trailerTypeId"
                                class = "autocomplete-dropdown"
                                [placeholder]="'Select Trailer Type'"
                                [apiUrl]="'v1/vehicle-types/list'"
                                [selectFields]="['id', 'generalPurposeType',]"
                                [whereFields]="['generalPurposeType']"
                                [limit]="10"
                                [orderBy]="{ generalPurposeType: 'asc' }"
                                [filterByKey]="'generalPurposeType'"
                                [bindLabel]="'generalPurposeType'"
                                [bindValue]="'id'"
                                [disable]="gateInForm.disabled"
                                [displayColumns]="[
                                    { key: 'generalPurposeType', header: 'Vehicle Type' },
                                ]"
                                [additionalFilter]='{       
                                        "name": "vehicleType",
                                        "alias": "vehicleType",
                                        "operator": "EQ",
                                        "value": [
                                            "TRAILER"
                                        ]
                                }'
                                >
                            </app-autocomplete-dropdown>
                        </div>
                        <div class="form-group">
                            <label for="inspectionType" class="form-label">
                                Inspection Type<span class="required">*</span>
                                </label>
                                <select id="inspectionType" formControlName="inspectionType" class="form-select" required >
                                    <option value="">Select</option>
                                    <option *ngFor="let option of cycleOptions" [value]="option.value">{{ option.label }}</option>
                                </select>
                                <div class="form_validation_error" *ngIf="gateInForm.get('cycle')?.invalid && gateInForm.get('cycle')?.touched">
                                    <small *ngIf="gateInForm.get('cycle')?.errors?.['required']">Please select the cycle</small>
                                </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group">
                        <label for="driverName" class="form-label">
                            Driver Name<span class="required">*</span>
                        </label>
                        <input type="text" id="driverName" formControlName="driverName" class="form-input" value="Vessel" placeholder="Enter Driver Name" required 
                        />
                        <div class="form_validation_error" *ngIf="gateInForm.get('driverName')?.invalid && gateInForm.get('driverName')?.touched">
                            <small *ngIf="gateInForm.get('driverName')?.errors?.['required']">Driver Name is required</small>
                        </div>
                    </div>
                        <div class="form-group">
                            <label for="driverLicenseNo" class="form-label">
                            Driver License No<span class="required">*</span>
                            </label>
                            <input type="text" id="driverLicenseNo" formControlName="driverLicenseNo" class="form-input" value="Vessel" placeholder="Enter Driver License no" required 
                            />
                            <div class="form_validation_error" *ngIf="gateInForm.get('driverLicenseNo')?.invalid && gateInForm.get('driverLicenseNo')?.touched">
                                <small *ngIf="gateInForm.get('driverLicenseNo')?.errors?.['required']">Driver License No is required</small>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    
        <section class="container-section container">
            <header class="header section-header">
                <div class="header-content">
                    <div class="header-text">
                        <div class="section-title">Container Details</div>
                        <div class="section-subtitle">Fill in Container(s) information to gate-in</div>
                    </div>
                </div>
            </header>
    
            <div class = "content-section card-layout">
                <div class="container-item">
                    <header class="header container-header">
                        <div class="header-content">
                            <div class="header-text">
                                <div class="container-title">Container Details</div>
                            </div>
                            <div class="header-actions">
                                <button class="btn fetch-eir-btn">
                                    <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/54e912bfd9604ffba60011840deb69ba824a268d02cf4e71b16b187b4af4220f?placeholderIfAbsent=true&apiKey=44df273bd2a1415ea8e3d654384f92bc" alt="" />
                                    Fetch EIR
                                </button>
                            </div>
                        </div>
                    </header>
                    <div class="divider"></div>
                    <div class="container-form">
                        <form [formGroup]="gateInForm">
                        </form> 
                    </div>
                </div>
            </div>
        </section>
    
        <button class="btn add-container-btn">
            <app-svg-icon class="image-icon"
                [path]="'assets/icons/add_plus_icon.svg'"
            ></app-svg-icon>
            <span>Add Container</span>
        </button>
    </div>
</div>