import { Component, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { OperationService } from '../../../../services/operations.service';
import { LoadingService } from '../../../../services/loading.service';
import { SvgIconComponent } from '../../../svg-icon/svg-icon.component';

import { AutocompleteDropdownComponent } from '../../../autocomplete-dropdown/autocomplete-dropdown.component';
import { CheckboxComponent } from '../../../common/checkbox/checkbox.component';

@Component({
  selector: 'app-container-gate-in',
  standalone: true,
  imports: [AutocompleteDropdownComponent, 
    CheckboxComponent, 
    SvgIconComponent, 
    ReactiveFormsModule,
    CommonModule],
  templateUrl: './container-gate-in.component.html',
  styleUrl: './container-gate-in.component.scss'
})
export class ContainerGateInComponent {
  gateInForm: FormGroup;
  ownVehicle: boolean = true;
  gateInOptions: any = [];
  laneOptions: any = [];
  cycleOptions: any = [];
  constructor(
    private fb: FormBuilder,
    private api: OperationService,
    private loadingService: LoadingService,
    private viewContainerRef: ViewContainerRef,
  ) {
    this.gateInForm = this.fb.group({
      cycle: ['', Validators.required],
      gateInMode: ['', Validators.required],
      laneNo: ['', Validators.required],
      ownVehicle: [false],
      ownMovement: [{ value: false }],
      trailerNo: ['', Validators.required],
      transporterName: ['', Validators.required],
      trailerTypeId: ['', Validators.required],
      inspectionType: ['', Validators.required],
      driverName: ['', Validators.required],
      driverLicenseNo: ['', Validators.required]
    });
    
  }

  onCycleChange(event: Event): void {
  }

  onOwnVehicleChange(): void {
    const ownVehicleChecked = this.gateInForm.get('ownVehicle')?.value;
    this.ownVehicle = ownVehicleChecked;
  }

  saveForm() {

  }

  resetForm() {

  }
}
