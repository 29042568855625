import { Component, ViewContainerRef, ViewChild, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators, FormArray, AbstractControl } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { OperationService } from '../../../../services/operations.service';
import { LoadingService } from '../../../../services/loading.service';
import { SvgIconComponent } from '../../../svg-icon/svg-icon.component';

import { AutocompleteDropdownComponent } from '../../../autocomplete-dropdown/autocomplete-dropdown.component';
import { ConfirmDialogV2Component } from '../../../confirm-dialog/confirm-dialog-v2.component';
import { CheckboxComponent } from '../../../common/checkbox/checkbox.component';
import { ToggleComponent } from "../../../common/toggle/toggle.component";
import {ContainerFormComponent} from "./container-form/container-form.component"
import { InventoryType, Lane, getGateInOptions } from '../../../../constants/gate-ops-constants';
import { Container } from '../../../../models/container.interface';
import { ToasterService } from '../../../../services/toaster.service';
import { formLayoutConfig} from './container-form/form-layout-config';
import { AutocompleteDropdownComponentValidator } from "../../../../validators/autoCompleteValidator";
import { CycleType, StorageKeys } from '../../../../constants/app-constants';
import { DateTimeValidator } from '../../../../validators/dateTimeValidator';
import { convertEpochToISTDateTime, getMaxDateTimeForDatePicker } from '../../../../utils/date-time-utils';
import { ModalComponent } from '../../../modal/modal.component';
import { SurveyImageViewerComponent } from '../../../document-viewer/survey-images/survey-image-viewer.component';
import { User } from '../../../../models/user.interface';
import { LocalStorageService } from '../../../../services/local-storage.service';
//import surveyImageResponse from "../../../document-viewer/survey-images/image-response.json"

@Component({
  selector: 'app-container-gate-in',
  standalone: true,
  imports: [AutocompleteDropdownComponent,
    CheckboxComponent,
    SvgIconComponent,
    ReactiveFormsModule,
    CommonModule, ToggleComponent, ContainerFormComponent],
  templateUrl: './container-gate-in.component.html',
  styleUrl: './container-gate-in.component.scss'
})
export class ContainerGateInComponent {
  gateInForm: FormGroup;
  
  ownVehicle: boolean = false;
  isOwnMovement: boolean = false;
  isOwnMovementDisabled: boolean = false;
  laneOptions = Object.values(Lane);
  cycleOptions = Object.values(CycleType);
  selectedTrailerNo: string | null = null;
  selectedTrailerId: string | null = null;
  selectedTrailerMasterId: string | null = null;
  canAddMoreContainers: boolean = false;
  containerFormLoaded: boolean = false;
  showTrailerForm: boolean = false;
  showContainerForm: boolean = false;
  trailerSearchableFinal: boolean = false;
  trailerAlwaysSearchable: boolean = false;
  trailerSearchEndpoint: string = '';
  trailerSearchSelectFields: string[] = [];
  trailerAdditionalFilters: any = [];
  fetchContainerOnTrailerSelection: boolean = false;
  maxDateTime: string = '';


  eirMap: Map<string, string> = new Map();
  surveyImageResponse: any = {};
  canLoadRecentData: boolean = true;
  userProfile!: User;
  isUserAllowed: boolean = false;
  allowedUsers: Set<string> = new Set(["1TUS5DNMSFGOFXX792D55Q615OWNC1BFSXOZ", "1TUS5DNMSFGOFXX792HTIQ9GR78ZFX7IDT95", "1TUS5DNMSFGOFXX79246CXMKOQ2YSXT1MYL4"]);

  supportedGateInOptions: Array<{ key: string; label: string; cycle: typeof CycleType[keyof typeof CycleType] | null}> = [];
  gateInOptions: Array<{ key: string; label: string; cycle: typeof CycleType[keyof typeof CycleType] | null}> = [];
  inspectionTypeOptions = [
    { value: 'On Wheel', key: 'ON_WHEEL' },
    { value: 'On Ground', key: 'ON_GROUND' },
  ];

  @ViewChild('trailerTypeDropdown', { static: false }) trailerTypeDropdown?: AutocompleteDropdownComponent;
  @ViewChildren('containerForms') containerForms!: QueryList<ContainerFormComponent>;
  
  constructor(
    private fb: FormBuilder,
    private api: OperationService,
    private loadingService: LoadingService,
    private viewContainerRef: ViewContainerRef,
    private toasterService: ToasterService,
    private cdr: ChangeDetectorRef,
    private localStorageService: LocalStorageService,
  ) {
    this.gateInForm = this.fb.group({
      cycle: ['IMPORT', Validators.required],
      gateInMode: ['', Validators.required],
      laneNo: ['LANE01', Validators.required],
      ownVehicle: [false],
      ownMovement: [this.isOwnMovement],
      trailerNo: ['', Validators.required],
      transporterId: ['', AutocompleteDropdownComponentValidator()],
      trailerTypeId: ['', Validators.required],
      inspectionType: ['ON_GROUND', Validators.required],
      driverName: ['', Validators.required],
      driverLicenseNo: ['', Validators.required],
      gateInTime: ['', [Validators.required, DateTimeValidator()]],
      containerList: this.fb.array([]),
    });
  }

  ngOnInit(): void {
    this.loadDropdownData();
    this.updateGateInTime();

    const userProfile = this.localStorageService.getItem(StorageKeys.USER_PROFILE);
    if (userProfile) {
      this.userProfile = JSON.parse(userProfile.toString());
      this.isUserAllowed = this.allowedUsers.has(this.userProfile.id);
    }
    
    this.gateInForm.valueChanges.subscribe(() => {
        const { cycle, gateInMode, laneNo } = this.gateInForm.controls;
        const isSelected = cycle.value && gateInMode.value && laneNo.value;
        this.showTrailerForm = this.showTrailerForm || isSelected;
        if (isSelected) {
          const modeConfig = formLayoutConfig[gateInMode.value] || formLayoutConfig["DEFAULT"];
          this.trailerSearchEndpoint = modeConfig.trailerSearchEndpoint;
          this.trailerSearchSelectFields = modeConfig.trailerSelectFields;
          this.trailerAlwaysSearchable = modeConfig.trailerAlwaysSearchable ?? false;
          this.trailerSearchableFinal = this.trailerAlwaysSearchable || this.ownVehicle;
          this.trailerAdditionalFilters = modeConfig.trailerFilters ?? [];
          this.fetchContainerOnTrailerSelection = (gateInMode.value === 'SCAN_AREA_RETURN');
          if (this.containerFormList.length === 0 && !this.fetchContainerOnTrailerSelection) {
            this.addItem();
          }
        }
        this.showContainerForm = (isSelected || this.showContainerForm) && this.containerFormList.length > 0;
      });
  }

  updateGateInTime() {
    this.maxDateTime = getMaxDateTimeForDatePicker();
    this.gateInForm.get("gateInTime")?.setValue(convertEpochToISTDateTime(new Date().getTime()));
  }

  evaluateContainerRules() {
    console.log("Evaluating container rules");
    const containers: Container[] = this.containerFormList.value;

    // Extract the size and type of the first container
    const firstContainerSize = containers[0].containerSize;
    const firstContainerType = containers[0].containerType;

    console.log("First container size & type:", firstContainerSize,  firstContainerType);

    // Rule 1: Max five containers allowed if type is FR
    if (firstContainerType === 'FR') {
      const allSizesAndTypesMatch = containers.every(
        (container) =>
          container.containerSize === firstContainerSize &&
          container.containerType === firstContainerType
      );
      this.canAddMoreContainers = allSizesAndTypesMatch && containers.length < 5;
      return;
    }

    // Rule 2: Only one container allowed if size is 40 ft
    if (firstContainerSize === '40') {
      this.canAddMoreContainers = containers.length < 1;
      return;
    }

    // Rule 3: Max two containers allowed if size is 20 ft
    if (firstContainerSize === '20') {
      const allSizesMatch = containers.every(
        (container: Container) => container.containerSize === '20'
      );
      this.canAddMoreContainers = allSizesMatch && containers.length < 2;
      return;
    }


    // Default: Disable adding more containers if none of the rules match
    this.canAddMoreContainers = false;
  }

  loadDropdownData(): void {
      this.supportedGateInOptions = getGateInOptions(InventoryType.CONTAINER);
      console.log('Gate In Options:', this.gateInOptions);
      this.gateInForm.get('gateInMode')?.disable();
      this.updateGateInOptions(this.gateInForm.get('cycle')?.value);
  }

  onCycleChange(event: Event): void {
    const selectedCycle = (event.target as HTMLSelectElement).value;
    this.updateGateInOptions(selectedCycle);
  }

  updateGateInOptions(selectedCycle: string) {
    if (!selectedCycle) return;
    console.log("Selected cycle => ", selectedCycle);
    this.gateInOptions = this.supportedGateInOptions.filter(option =>
      !option.cycle || option.cycle.key === selectedCycle
    );
    this.gateInForm.get('gateInMode')?.setValue('');
    this.gateInForm.get('gateInMode')?.enable();
  }

  getGateInMode(): string {
    const gateInModeValue = this.gateInForm.get('gateInMode')?.value;
    return gateInModeValue;
  }

  addItem() {
    const containerFormGroup = this.fb.group({
      containerId: [''],
      containerNo: [''],
      accountHolderId: [{key: '', value: ''}],
      shippingLineId: [{key: '', value: ''}],
      vesselId: [{key: '', value: ''}],
      isoCode: [{key: '', value: ''}],
      containerSize: [''],
      containerType: [''],
      lineSealNo: [''],
      physicalSealNo: [''],
      eirNo: [''],
      eirDate: [null],
      fromLocationId: [{key: '', value: ''}],
      tareWeight: [null],
      grossWeight: [null],
      scanType: [''],
      scanStatus:[''],
      hazardous: [false],
      odc: [false],
      reeferPlugin: [false],
      setTemperature: [{ value: null, disabled: true }],
      temperature: [{ value: null, disabled: true }],
      condition: ['']
    });

    this.containerFormList.push(containerFormGroup);
    this.evaluateContainerRules();
    return containerFormGroup;
  }

  removeContainerForm(index: number) {
    this.containerFormList.removeAt(index);
  }

  get containerFormList(): FormArray {
    return this.gateInForm.get('containerList') as FormArray;
  }

  onOwnVehicleChange(ownVehicleChecked: boolean): void {
    console.log("Inside #onOwnVehicleChange: ", ownVehicleChecked);
    this.gateInForm.get('ownVehicle')?.setValue(ownVehicleChecked);
    this.ownVehicle = ownVehicleChecked;
    this.trailerSearchableFinal = this.trailerAlwaysSearchable || ownVehicleChecked;
    console.log(this.trailerAlwaysSearchable , ownVehicleChecked, "=>", this.trailerSearchableFinal);
    if (ownVehicleChecked) {
      this.isOwnMovementDisabled = true;
      this.isOwnMovement = true;
      this.gateInForm.get('ownMovement')?.setValue(true);
      this.gateInForm.get('ownMovement')?.disable();
      this.selectedTrailerNo = null;
      this.selectedTrailerId = null;
      this.selectedTrailerMasterId = null;
    } else {
      this.isOwnMovementDisabled = false;
      this.gateInForm.get('ownMovement')?.enable();
    }
  }

  onOwnMovementChange(ownMovementChecked: boolean): void {
    console.log("Inside #onOwnVehicleChange: ", ownMovementChecked);
    this.gateInForm.get('ownMovement')?.setValue(ownMovementChecked);
  }

  saveForm() {
    this.gateInForm.markAllAsTouched();
    this.containerFormList.controls.forEach((containerForm: AbstractControl) => {
      if (containerForm instanceof FormGroup) {
        containerForm.markAllAsTouched();
        containerForm.updateValueAndValidity();
      }
    });
    const containerFormsValid = this.containerFormList.controls.every((containerForm: AbstractControl) => {
      const isValid = containerForm instanceof FormGroup && containerForm.valid;
      if (!isValid) this.logInvalidControls(containerForm);
      return isValid;
    });
    console.log("containerFormsValid: ", containerFormsValid);
    if (this.gateInForm.valid && containerFormsValid) {
      this.openConfirmDialog();
    }


  }

  logInvalidControls(form: AbstractControl) {

    if (form instanceof FormGroup) {
      Object.keys(form.controls).forEach((key) => {
        const control = form.get(key);
        if (control instanceof FormGroup || control instanceof FormArray) {
          // Recursively log nested forms or arrays
          this.logInvalidControls(control);
        } else if (control?.invalid) {
          console.log(`Field: ${key}, Errors: `, control.errors);
        }
      });
    }
    
  }

  resetForm() {
    this.containerFormList.clear();
    this.gateInForm.reset({
      ownVehicle: false,
      ownMovement: false,
      cycle: 'IMPORT',
      laneNo: this.gateInForm.get('laneNo')?.value,
      gateInMode: '' ,
      inspectionType: 'ON_GROUND'
    });
    this.gateInForm.setControl('containerList', this.fb.array([]));
    this.updateGateInOptions('IMPORT');
    this.ownVehicle = false;
    this.selectedTrailerNo = null;
    this.selectedTrailerId = null;
    this.selectedTrailerMasterId = null;
    this.canAddMoreContainers = false;
    this.containerFormLoaded = false;
    this.showTrailerForm = false;
    this.showContainerForm = false;
    this.trailerAlwaysSearchable = false;
    this.trailerSearchableFinal = false;
    this.trailerSearchEndpoint = '';
    this.trailerSearchSelectFields = [];
    this.trailerAdditionalFilters = [];
    this.updateGateInTime();
    this.canLoadRecentData = true;
  }

  onTrailerSelected(selectedItem: any): void {
    this.gateInForm.get('transporterId')?.setValue({key: selectedItem.transporterId, value: selectedItem.transporterName});
    this.gateInForm.get('trailerTypeId')?.setValue({key: selectedItem.vehicleTypeId , value: selectedItem.vehicleTypeValue});
    this.selectedTrailerMasterId = selectedItem.id;
    this.selectedTrailerNo = selectedItem.trailerNumber;
    if (this.fetchContainerOnTrailerSelection) {
      this.fetchAndLoadTrailerAndContainerData(selectedItem.id);
    }
  }

  fetchAndLoadTrailerAndContainerData(trailerId: any) {
    this.api.fetchTrailerById(trailerId, true, false).subscribe({
      next: (res: any) => {
        const trailer: any = res.data;
        const containers: any[] = res.data.containerList;
        console.log(res.data);
        this.selectedTrailerId = trailer?.id;
        this.gateInForm.get('trailerNo')?.setValue({key: trailer.id, value: trailer.vehicleNo});
        this.gateInForm.get('driverName')?.setValue(trailer.driverName);
        this.gateInForm.get('driverLicenseNo')?.setValue(trailer.driverLicenseNo);
        
        if (containers && containers.length > 0) {
          this.containerFormList.clear();
          containers.forEach(container => {
            const containerForm = this.addItem(); 
            containerForm.patchValue({
              ...container,
            });
            containerForm.get('containerId')?.setValue(container.id);
            containerForm.get('isoCode')?.setValue({ key: container.isoCode, value: container.isoCode});
            if (container.vesselId) {
              containerForm.get('vesselId')?.setValue({ key: container.vesselId, value: container.vesselName});
            }
            if (container.accountHolderId) {
              containerForm.get('accountHolderId')?.setValue({key: container.accountHolderId, value: container.accountHolderName});
            }
            if (container.shippingLineId) {
              containerForm.get('shippingLineId')?.setValue({key: container.shippingLineId, value: container.shippingLineName});
            }
            if (container.fromLocationId) {
              containerForm.get('fromLocationId')?.setValue({ key: container.fromLocationId, value: container.fromLocationName});
            }
          });
          this.showContainerForm = true;
        }
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  constructRequest(): any {
    const formData = this.gateInForm.value;
    const gateInRequest = {
        joNo: '',
        gateInType: formData.gateInMode,
        gateInVehicleType: InventoryType.CONTAINER,
        laneNo: formData.laneNo,
        gateInTime: new Date(formData.gateInTime).getTime(),
        containerList: this.getContainerList(),
        vehicle: {
          id: formData.trailerNo?.key,
          trailerMasterId: this.selectedTrailerMasterId,
          vehicleNo: this.selectedTrailerNo,
          vehicleTypeId: formData.trailerTypeId.key,
          inspectionType: formData.inspectionType,
          transporterId: formData.transporterId.key,
          ownVehicle: formData.ownVehicle,
          ownMovement: this.isOwnMovement,
          driverName: formData.driverName,
          driverLicenseNo: formData.driverLicenseNo,
        },
    };

    console.log(gateInRequest);
    return gateInRequest;
  }

  getContainerList(): any[] {
    const containerListFormArray = this.gateInForm.get('containerList') as FormArray;
    console.log("Container size: ", containerListFormArray.length);
    return containerListFormArray.controls.map(containerForm => {
      let container: any = containerForm.value;
      container.id = containerForm.get('containerId')?.value;
      container.containerNo = containerForm.get('containerNo')?.value?.value ?? containerForm.get('containerNo')?.value ;
      container.isoCode = containerForm.get('isoCode')?.value.key;
      container.containerSize = containerForm.get('containerSize')?.value;
      container.containerType = containerForm.get('containerType')?.value;
      container.accountHolderId = containerForm.get('accountHolderId')?.value.key
      container.vesselId = containerForm.get('vesselId')?.value.key ?? null;
      container.fromLocationId = containerForm.get('fromLocationId')?.value.key ?? null;
      container.shippingLineId = containerForm.get('shippingLineId')?.value.key
      container.reeferPlugin = containerForm.get('reeferPlugin')?.value ?? false;
      container.odc = containerForm.get('odc')?.value ?? false;
      container.hazardous = containerForm.get('hazardous')?.value ?? false;
      console.log('container: ', container);
      return container;
    });
  }

  getEirImage(index: number): string | undefined {
    const containerNo = this.containerFormList.at(index).get("containerNo")?.value;
    if (containerNo) {
      return this.eirMap.get(containerNo); 
    }
    return undefined;
  }

  openConfirmDialog() {
    const confirmDialogRef = this.viewContainerRef.createComponent(ConfirmDialogV2Component);

    // Set the message in the modal
    confirmDialogRef.instance.title = "Gate-In Confirmation"
    confirmDialogRef.instance.message = "Do you want to confirm and save the changes?";
    confirmDialogRef.instance.confirmButtonLabel = "Confirm";

    // Subscribe to modal confirm action
    confirmDialogRef.instance.confirm.subscribe(() => {
      const gateInRequest = this.constructRequest();
      console.log("GateInRequest => ", gateInRequest);
        this.api.submitGateInRequest(gateInRequest).subscribe((response) => {
          console.log('Submission successful:', response);
          this.toasterService.success("Gate-in was successful");
          this.resetForm();
        },
        (error) => {
          console.log('Request failed:', error);
          this.toasterService.error(error.error.errorDesc);
        }
      );
      confirmDialogRef.destroy();
    });

    // Subscribe to modal cancel action
    confirmDialogRef.instance.cancel.subscribe(() => {
      confirmDialogRef.destroy();
    });
  }

  openSurveyImageViewer(): void {
    const modalRef = this.viewContainerRef.createComponent(ModalComponent);
        modalRef.instance.title = "Survey Images";
        const filterDialogRef = modalRef.instance.loadComponent(
          SurveyImageViewerComponent
        );
        filterDialogRef.setInput("images", this.surveyImageResponse?.images);
        modalRef.instance.close.subscribe((res: any) => {
          modalRef.destroy();
        });
  }

  loadRecentData() {
    this.loadingService.show();
    this.api.fetchRecentData().subscribe({
      next: (res: any) => {
        console.log("fetch-recent-data response: ", res);
        const containerData = res.data?.containerInfo;
        this.surveyImageResponse = res.data?.surveyData;
        containerData.eirNo = this.surveyImageResponse?.eirNo;
        containerData.eirDate = this.surveyImageResponse?.eirDate;
        containerData.condition = this.surveyImageResponse?.condition;
        this.selectedTrailerNo = this.surveyImageResponse?.trailerNumber;
        this.addContainer(containerData);
        this.eirMap.set(containerData.containerNo, this.surveyImageResponse?.eirImage);
        this.canLoadRecentData = false;
        //this.showTrailerForm = true;
        //this.showContainerForm = true;
        this.cdr.detectChanges();
      },
      error: (error: any) => {
        this.toasterService.warning(error.error.errorDesc)
      }
    });
    this.loadingService.hide();
  }

  addContainer(containerDto: any) {
    if (this.containerFormList.length < 1) 
      this.addItem();

    const childComponent = this.containerForms.toArray()[0];
    if (childComponent) {
      childComponent.patchContainerData(containerDto);
    } else {
      console.warn('Child component not found for index:', 0);
    }
  }
}
