import { Component } from '@angular/core';
import { ToasterService, Toast } from '../../services/toaster.service';
import { CommonModule } from '@angular/common';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';

@Component({
  selector: 'app-toaster',
  standalone: true,
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss'],
  imports: [CommonModule, SvgIconComponent],
  animations: [
    trigger('toastAnimation', [
      state('void', style({ transform: 'translateY(100%)', opacity: 0 })),
      state('*', style({ transform: 'translateY(0)', opacity: 1 })),
      transition('void => *', [animate('300ms ease-out')]),
      transition('* => void', [animate('300ms ease-in')]),
    ]),
  ],
})
export class ToasterComponent {
  toasts: Toast[] = [];

  constructor(private toasterService: ToasterService) {
    this.toasterService.toasts$.subscribe((toasts) => (this.toasts = toasts));
  }

  dismiss(toast: Toast) {
    this.toasterService.dismiss(toast);
  }

  onAction(toast: Toast) {
    if (toast.actionCallback) {
      toast.actionCallback();
    }
    this.dismiss(toast);
  }
}
