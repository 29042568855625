<div class="table-container">
  <table class="table-view-data">
    <thead>
    <tr>
      @if(showCheckBox){
        <th class="cb_th select-all-cb">
          <input type="checkbox" id="selected_all" [checked]="isAllRecordsOfCurrentPageSelected()"
                 (change)="toggleAllRecordsOfCurrentPageSelections($event.target.checked)" />
        </th>
      }
      <th><span>Sl No</span></th>
      <th *ngFor="let column of tableMeta.columns">{{ column.displayName }}</th>
      <th class="table_menu_th" *ngIf="!readOnlyMode && deleteAllowed"><span></span></th>
    </tr>
    </thead>
    @if (data.length > 0) {
      <tbody>
      <tr *ngFor="let result of data; let i = index" [ngClass]="isSelected(result) ? 'selected_row' : ''">
        @if(showCheckBox){
          <td class="cb_td row-select-cb">
            <input type="checkbox" (change)="toggleSelection(result)" [checked]="isSelected(result)" />
          </td>
        }
        <td><span> {{ i + 1 }} </span></td>
        <td *ngFor="let column of tableMeta.columns" [ngClass]="{ primary_column: column.fieldName === 'name' }">
          <app-tos-table-td
            [record]="result[column.fieldName]"
            [column]="column"
          ></app-tos-table-td>
        </td>
        <td *ngIf="!readOnlyMode && deleteAllowed" class="table_menu_td">
          <span class="delete_icon">
          <app-svg-icon class="delete-icon"
                        [path]="'assets/icons/delete_icon.svg'"
                        (click)="removeElement(i,result)"></app-svg-icon>
            </span>
        </td>
      </tr>
      </tbody>
    }
  </table>
  @if (data.length === 0) {
    <app-no-data-found [msg]="'No Data Found.'"></app-no-data-found>
  }
</div>

