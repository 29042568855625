import { ChangeDetectorRef, Component, ViewChild, ViewContainerRef } from "@angular/core";
import { TariffTable } from "../../tariff-table/tariff-table";
import { Rule, RuleValue, TariffRuleSet } from "../../../../models/tariff-rule-set";
import { FinanceService } from "../../../../services/finance.service";
import { LoadingService } from "../../../../services/loading.service";
import { ToasterService } from "../../../../services/toaster.service";
import { MultiSelectComponent } from "../../../select/multi-select.component";
import { Charge, Determinant } from "../../../../models/charge.interface";
import { APIResponse } from "../../../../models/standard-response.interface";
import { DeterminantDetails } from "../../../../models/determinant-details";
import { DynamicInputComponent } from "../../../dynamic-form/dynamic-input/dynamic-input.component";
import { ChipInputComponent } from "../../../common/chip-input/chip-input.component";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Control, DynamicForm } from "../../../../models/dynamic-form";
import { TariffTableRulesComponent } from "../../tariff-table/tariff-table-rules/tariff-table-rules.component";
import { RightPanelComponent } from "../../../right-panel/right-panel.component";
import { MatChip } from "@angular/material/chips";
import { NgClass, NgForOf, NgIf } from "@angular/common";
import ManageTariffFormConfig from "../../config/ManageTariffFormConfig.json";
import { DynamicFormService } from "../../../../services/dynamic-form.service";
import { DynamicFormComponent } from "../../../dynamic-form/dynamic-form.component";
import { ActivatedRoute, NavigationExtras, Route, Router } from "@angular/router";
import { NgxSkeletonLoaderComponent } from "ngx-skeleton-loader";

@Component({
  selector: "app-manage-tariff",
  standalone: true,
  imports: [
    TariffTable,
    MultiSelectComponent,
    DynamicInputComponent,
    ChipInputComponent,
    TariffTableRulesComponent,
    MatChip,
    NgForOf,
    NgIf,
    DynamicFormComponent,
    NgClass,
    NgxSkeletonLoaderComponent
  ],
  templateUrl: "./manage-tariff.component.html",
  styleUrl: "./manage-tariff.component.scss"
})
export class ManageTariffComponent {
  manageTariffForm!: FormGroup;
  manageTariffFormConfig!: DynamicForm;
  ruleSets: TariffRuleSet[] = [];
  commonCriteria: Rule[] = [];
  allDeterminants: DeterminantDetails[] = [];
  chargeId: string = "";
  tariffId: string = "";
  editingEnabled: boolean = false;
  readOnlyMode: boolean = false;
  activeTab: number = 1;
  charge!: Charge;


  constructor(private financeService: FinanceService,
              private viewContainerRef: ViewContainerRef,
              private dynamicFormService: DynamicFormService,
              private fb: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private loadingService: LoadingService,
              private toasterService: ToasterService) {
    this.manageTariffForm = fb.group({});
  }

  ngOnInit() {
    this.chargeId = this.route.snapshot.paramMap.get('chargeId') || '';
    this.tariffId = this.route.snapshot.paramMap.get('tariffId') || '';

    this.manageTariffFormConfig = ManageTariffFormConfig;
    this.dynamicFormService.addControlsToForm(this.manageTariffForm, this.manageTariffFormConfig);
    this.setupComponentMode()
    this.loadDeterminants();
    this.getChargeInfoFromNavigationExtras();
    if(this.tariffId){
      this.loadTariffDetails(this.tariffId);
    }
  }

  private getChargeInfoFromNavigationExtras() {
    this.charge = history.state?.charge
  }

  private loadDeterminants() {
    this.financeService.getDeterminantDetails().subscribe({
      next: (res) => {
        console.log("Determinants response:", res);
        this.allDeterminants = res.data;
        // console.log("All determinants set:", this.allDeterminants);
      },
      error: (err) => console.error("Failed to load determinants", err)
    });
  }

  addRulesForCommonCriteria() {
    const panelComponentComponentRef = this.setUpRightPanel();

    const determinantRulesComponentRef = panelComponentComponentRef.instance.loadComponent(
      TariffTableRulesComponent
    );
    determinantRulesComponentRef.setInput("allDeterminants", this.allDeterminants);

    // if (this.commonCriteria.length !== 0)
      determinantRulesComponentRef.instance.patchRulesValueForCommonCriteria(this.commonCriteria);

    //check for apply all button of the right panel and then call the applyRules method of determinantRulesComponent
    panelComponentComponentRef.instance.primaryActionBtnEmitter.subscribe(() => {
      determinantRulesComponentRef.instance.applyRulesForCommonCriteria();
      if (determinantRulesComponentRef.instance.filterForm.valid) panelComponentComponentRef.destroy();
    });
    determinantRulesComponentRef.instance.applyAllButtonClicked.subscribe((commonCriteria: any) => {
      this.commonCriteria = commonCriteria;
    });
  }

  private setUpRightPanel() {
    const modalRef = this.viewContainerRef.createComponent(RightPanelComponent);
    modalRef.instance.title = "Add Rule";
    modalRef.instance.showActionBtns = true;
    modalRef.instance.primaryActionBtnText = "Apply";
    modalRef.instance.close.subscribe(() => {
      modalRef.destroy();
    });
    return modalRef;
  }

  goBack() {
    const navigationExtras: NavigationExtras = {
      state: { charge: this.charge },
      relativeTo: this.route
    };
    this.router.navigateByUrl(`/manage-finance/${this.charge.id}/tariffs`, navigationExtras);
  }

  saveTariff() {
    this.manageTariffForm.markAllAsTouched();
    if(!this.manageTariffForm.valid){
      return;
    }

    if(this.ruleSets.length ===0){
      this.toasterService.error("Please add at least one rule for tariff");
      return;
    }

    const saveTariffRequest = {
      ...this.manageTariffForm.value,
      fromTime: new Date(this.manageTariffForm.value.fromTime).getTime(),
      toTime: new Date(this.manageTariffForm.value.toTime).getTime(),
      chargeId: this.charge.id,
      rulesets: this.ruleSets,
      criteria: {
        rules: [...this.commonCriteria]
      }
    };



    this.editingEnabled ? this.callApiToUpdateTariff(saveTariffRequest,this.tariffId): this.callApiToSaveTariff(saveTariffRequest);
  }

  private callApiToSaveTariff(saveTariffRequest: { rulesets: TariffRuleSet[]; commonCriteria: { rules: Rule[] } }) {
    this.loadingService.show();
    this.financeService.saveTariff(saveTariffRequest).subscribe({
      next: (res: any) => {
        this.loadingService.hide();
        this.toasterService.success(res.data);
        this.goBack()
      },
      error: (err) => {
        this.loadingService.hide();
        this.toasterService.error(err.error.message)
        console.error("Failed to save tariff", err.error)
      }
    });
  }

  getValuesToPrint(rule: Rule) {
    return rule.values.slice(0, 3).map(value => value.text).join(", ");
  }

  private setupComponentMode(): void {
    if (this.tariffId) {
      const path = this.route.snapshot.url[0].path;
      this.editingEnabled = path === "edit";
      this.readOnlyMode = !this.editingEnabled;
      if (this.readOnlyMode) {
        this.manageTariffForm.disable();
      }
    } else {
      this.readOnlyMode = false;
      this.editingEnabled = false;
    }
  }

  private loadTariffDetails(tariffId: string) {
    this.loadingService.show()
    this.financeService.getTariffDetails(tariffId).subscribe({
      next: (res: APIResponse) => {
        console.log("Tariff details response:", res);
        const data = res.data;

        // Convert epoch values to ISO 8601 format
        data.fromTime = new Date(data.fromTime).toISOString().slice(0, 16);
        data.toTime = new Date(data.toTime).toISOString().slice(0, 16);

        this.manageTariffForm.patchValue(data);

        this.ruleSets = data.rulesets;
        this.commonCriteria = data.criteria.rules;
        this.loadingService.hide()
      },
      error: (err) => {
        this.loadingService.hide()
        console.error("Failed to load tariff details", err)
      }
    });
  }

  activateTab(number: number) {
    this.manageTariffForm.markAllAsTouched();
    if(!this.manageTariffForm.valid && !this.readOnlyMode){
      console.log("Form is invalid");
      return;
    }
    this.activeTab = number;
  }

  private callApiToUpdateTariff(saveTariffRequest: any, tariffId: string) {
    this.financeService.updateTariff(tariffId,saveTariffRequest).subscribe({
      next: (res: any) => {
        this.loadingService.hide();
        this.toasterService.success(res.data);
        this.goBack()
      },
      error: (err) => {
        this.loadingService.hide();
        this.toasterService.error(err.error.message)
        console.error("Failed to save tariff", err)
      }
    });

  }

  getDisplayTextForDeterminant(determinantName: string | undefined) {
    return this.allDeterminants.find(determinant=> determinantName === determinant.name)?.displayName || determinantName;
  }

  getDisplayTextForOperator(determinantName: string | undefined,operatorName: string | undefined) {
    const operatorValue = this.allDeterminants.find(determinant=> determinantName === determinant.name)?.valueTypeResponse?.supportedOperators.find(operator => operatorName === operator.name)?.operator || operatorName;
    if(operatorValue === '==' || operatorValue === 'text') return '=';
    return operatorValue;
  }
}
