import { CommonModule } from '@angular/common';
import { Component, SimpleChanges, ViewContainerRef } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject, forkJoin, takeUntil } from 'rxjs';
import { TooltipDirective } from '../../../directives/tooltip.directive';
import { ApiService } from '../../../services/api.service';
import { LoadingService } from '../../../services/loading.service';
import { ToasterService } from '../../../services/toaster.service';
import { UserDialogComponent } from '../../admin/user-management/user-dialog/user-dialog.component';
import { AdvancedFilterComponent } from '../../advanced-filter/advanced-filter.component';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { DropdownComponent } from '../../dropdown/dropdown.component';
import { ModalComponent } from '../../modal/modal.component';
import { NoDataFoundComponent } from '../../no-data-found/no-data-found.component';
import { SvgIconComponent } from '../../svg-icon/svg-icon.component';
import { TableBoxComponent } from '../../table-box/table-box.component';
import { TableMenuComponent } from '../../table-menu/table-menu.component';
import { Bank } from '../../../models/bank-master.interface';
import { PaginatedListDataResponse } from '../../../models/paginated-list-data-response';
import { StatsData, StatsResponse } from '../../../models/stats-response.interface';
import { TableListRequest } from '../../../models/table-list-request.interface';
import { User } from '../../../models/user.interface';
import { HighlightPipe } from '../../../pipes/highlight.pipe';
import { UserRolesPermissionsComponent } from '../../admin/user-roles-permissions/user-roles-permissions.component';
import { NoDataComponent } from "../../no-data/no-data.component";
import { TosTableComponent } from "../../tos-table/tos-table.component";
import BankMasterMetaConfig from './config/bankMasterMeta.json';
import { TableMeta } from "../../../models/table-meta.interface";
import { NgxSkeletonLoaderComponent } from "ngx-skeleton-loader";

@Component({
  selector: 'app-bank-master',
  standalone: true,
  imports: [CommonModule,
    TableBoxComponent,
    SvgIconComponent,
    DropdownComponent,
    TooltipDirective,
    TableMenuComponent,
    UserDialogComponent,
    FormsModule,
    UserRolesPermissionsComponent,
    NoDataFoundComponent,
    HighlightPipe, NoDataComponent, TosTableComponent, NgxSkeletonLoaderComponent],
  templateUrl: './bank-master.component.html',
  styleUrl: './bank-master.component.scss',
})
export class BankMasterComponent{
  //table
  tableLoaded: boolean = false;
  focusSearch: boolean = false;
  searchTerm: string = '';
  previousTerm: string = '';
  selectedItems: User[] = [];
  sortColumn: string = 'modifiedAt';
  sortDirection: 'asc' | 'desc' = 'desc';
  allTableRecordsSelected: boolean = false;
  tableStats: StatsData = {
    total: 0,
    active: 0,
    inactive: 0,
  };
  tableListData: PaginatedListDataResponse = {
    totalCount: 0,
    page: 0,
    limit: 0,
    records: [],
  };
  tableListRequest: TableListRequest = {
    selectFields: [
      'id',
      'active',
      'name',
      'code',
      'ifscCode',
      'branch',
      'createdBy.name as createdBy',
      'modifiedBy.name as modifiedBy',
      'createdAt',
      'modifiedAt',
    ],
    orderBy: {
      [this.sortColumn]: this.sortDirection,
    },
    limit: 15,
    page: 0,
  };

  // table menu
  openMenuIndex: number | null = null;
  menuOptions = [
    { label: 'Edit', value: 'edit' },
    { label: 'Mark as active', value: 'active' },
    { label: 'Mark as inactive', value: 'inactive' },
    { label: 'View', value: 'view' },
  ];

  // filter
  filterFields = [
    {
      displayName: 'Bank Name',
      key: 'name',
    },
    {
      displayName: 'Bank Code',
      key: 'code',
    },
  ];
  isFilterApplied: boolean = false;
  tableMeta!: TableMeta

  constructor(
    private api: ApiService,
    private toasterService: ToasterService,
    private viewContainerRef: ViewContainerRef,
    private router: Router,
    private route: ActivatedRoute,
    private loadingService: LoadingService
  ) { }

  ngOnInit() {
    this.tableMeta = BankMasterMetaConfig
    this.tableLoaded = false;
    this.fetchAllData();
  }

  filter() {
    // console.log(this.customerMasterListRequest);
    const modalRef = this.viewContainerRef.createComponent(ModalComponent);
    modalRef.instance.title = 'Advanced Filter';
    const filterDialogRef = modalRef.instance.loadComponent(
      AdvancedFilterComponent
    );

    filterDialogRef.setInput('filterFields', this.filterFields);

    if (!this.searchTerm && this.tableListRequest.where) {
      filterDialogRef.setInput('appliedFilters', this.tableListRequest.where);
    }

    modalRef.instance.close.subscribe((res: any) => {
      this.clearSelection();
      if (res && res.resetAll) {
        delete this.tableListRequest.where;
        this.isFilterApplied = false;
        this.refreshTableList();
      } else if (res && res.refresh) {
        if (
          res.filterData.expression &&
          res.filterData.filterConditions.length > 0
        ) {
          this.tableListRequest.where = {
            filterConditions: res.filterData.filterConditions,
            expression: res.filterData.expression,
          };
          this.isFilterApplied = true;
        } else {
          console.error('Invalid filter !');
          delete this.tableListRequest.where;
          this.isFilterApplied = false;
        }
        this.refreshTableList();
      }
      modalRef.destroy();
    });
  }

  // fetch table data and stats
  fetchAllData(): void {
    this.loadingService.show();
    const getTableStatsApiCall = this.api.getBankMasterStats();
    const getTableListApiCall = this.api.getBankMasterList(
      this.tableListRequest
    );
    forkJoin([getTableStatsApiCall, getTableListApiCall]).subscribe({
      next: ([tableStats, tableList]) => {
        this.tableStats = tableStats.data;
        console.log(tableStats.data)
        this.tableListData = tableList.data;
        this.tableLoaded = true;
        this.loadingService.hide();
      },
      error: (err) => {
        console.error(err);
        this.toasterService.error(err.error.message);
        this.loadingService.hide();
      },
    });
  }

  handleTableSearch(event: KeyboardEvent) {
    const searchTerm = this.searchTerm.trim();
    if (searchTerm.length > 0) {
      this.tableListRequest.where = {
        filterConditions: [
          {
            name: 'name',
            alias: 'A',
            operator: 'CONTAINS',
            value: [searchTerm],
          },
        ],
        expression: 'A',
      };
      // Search when Enter is pressed
      if (event.key === 'Enter') {
        this.searchTableList();
      }
    }
    // Call reset only when the search bar is cleared (avoid on multiple backspaces)
    if (searchTerm.length === 0 && this.previousTerm.length !== 0) {
      delete this.tableListRequest.where;
      this.searchTableList();
    }
    this.previousTerm = searchTerm;
  }

  handleOptionSelected(event: any) {
    const { record: bank, actionName } = event;
    console.log(bank);
    const bankId = bank.id;
    if (actionName === 'edit') {
      this.router.navigate([`edit/${bankId}`], { relativeTo: this.route });
    } else if (actionName === 'view') {
      this.router.navigate([`view/${bankId}`], { relativeTo: this.route });
    } else {
      // need to confirm before executing the action
      let actionTitle = 'Confirmation';
      let message = 'Are you sure ?';
      let successMsg = 'Done !';
      let flag = false;
      switch (actionName) {
        case 'active':
        case 'inactive':
          if (actionName === 'active') {
            flag = true;
          }
          actionTitle = `${flag ? 'Activate' : 'Inactivate'} Bank`;
          message = `Are you sure to ${flag ? 'activate' : 'inactivate'} ${bank.name
            } ?`;
          successMsg = `Successfully ${flag ? 'activated' : 'inactived'} ${bank.name
            } !`;
          break;

        default:
          console.error('unknown actionName');
          break;
      }
      const modalRef = this.viewContainerRef.createComponent(ModalComponent);
      modalRef.instance.title = actionTitle;
      const confirmDialogRef = modalRef.instance.loadComponent(
        ConfirmDialogComponent
      );
      confirmDialogRef.instance.message = message;
      confirmDialogRef.instance.confirm.subscribe(() => {
        this.menuAction(actionName, successMsg, flag, bankId!);
        modalRef.destroy();
      });
      confirmDialogRef.instance.cancel.subscribe(() => {
        modalRef.destroy();
      });
      modalRef.instance.close.subscribe(() => modalRef.destroy());
    }
  }

  menuAction(
    actionName: string,
    successMsg: string,
    flag: boolean,
    userId: string
  ) {
    switch (actionName) {
      case 'active':
      case 'inactive':
        this.api.setBankActiveStatus(flag, [userId]).subscribe({
          next: () => {
            this.toasterService.success(successMsg);
            this.refreshTableListWithStats();
          },
          error: (err) => {
            console.error(err);
            this.toasterService.error(err.error.errorDesc || 'Unknown Error !');
          },
        });
        break;
      default:
        console.error('unknown actionName');
        break;
    }
  }

  bulkActionConfirmation(actionName: string) {
    let actionTitle = 'Confirmation';
    let message = 'Are you sure ?';
    let successMsg = 'Done !';
    const selectedUserCount = this.selectedItems.length;
    switch (actionName) {
      case 'setActive':
        actionTitle = `Activate ${this.selectedItems.length > 1 ? 'Banks' : 'Bank'
          } Confirmation`;
        message = `Are you sure to activate ${this.selectedItems.length > 1
            ? ` the selected ${selectedUserCount} Bank?`
            : `${this.selectedItems[0].name}'s account?`
          }`;
        successMsg = `Successfully activated ${this.selectedItems.length > 1
            ? `${selectedUserCount} Banks`
            : `${this.selectedItems[0].name}`
          }`;
        break;
      case 'setInActive':
        actionTitle = `Inactivate ${this.selectedItems.length > 1 ? 'Bank' : 'Banks'
          } Confirmation`;
        message = `Are you sure to inactivate ${this.selectedItems.length > 1
            ? ` the selected ${selectedUserCount} banks?`
            : `${this.selectedItems[0].name}'s account?`
          }`;
        successMsg = `Successfully inactivated ${this.selectedItems.length > 1
            ? `${selectedUserCount} banks`
            : `${this.selectedItems[0].name}`
          }`;
        break;
      default:
        console.error('unknown actionName');
        break;
    }
    const modalRef = this.viewContainerRef.createComponent(ModalComponent);
    modalRef.instance.title = actionTitle;
    const confirmDialogRef = modalRef.instance.loadComponent(
      ConfirmDialogComponent
    );
    confirmDialogRef.instance.message = message;
    confirmDialogRef.instance.confirm.subscribe(() => {
      this.bulkAction(actionName, successMsg);
      modalRef.destroy();
    });
    confirmDialogRef.instance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.instance.close.subscribe(() => modalRef.destroy());
  }

  bulkAction(actionName: string, successMsg: string) {
    const selectedUserIds = this.selectedItems.map((user: User) => {
      return user.id!;
    });
    switch (actionName) {
      case 'setActive':
        this.api.setBankActiveStatus(true, selectedUserIds).subscribe({
          next: () => {
            this.clearSelection();
            this.toasterService.success(successMsg);

            this.refreshTableListWithStats();
          },
          error: (err) => {
            console.error(err);
            this.toasterService.error(err.error.errorDesc || 'Unknown Error !');
          },
        });
        break;
      case 'setInActive':
        this.api.setBankActiveStatus(false, selectedUserIds).subscribe({
          next: () => {
            this.clearSelection();
            this.toasterService.success(successMsg);
            this.refreshTableListWithStats();
          },
          error: (err) => {
            console.error(err);
            this.toasterService.error(err.error.errorDesc || 'Unknown Error !');
          },
        });
        break;
      default:
        console.error('unknown actionName');
        break;
    }
  }

  getTableStats() {
    this.api.getBankMasterStats().subscribe({
      next: (res: StatsResponse) => {
        this.tableStats = res.data;
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  getTableList() {
    this.api.getBankMasterList(this.tableListRequest).subscribe({
      next: (res) => {
        this.tableListData = res.data;
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  searchTableList() {
    this.api.getBankMasterList(this.tableListRequest).subscribe({
      next: (res) => {
        this.tableListData = res.data;
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  clearSearchQuery() {
    this.searchTerm = '';
  }

  refreshTableList() {
    this.getTableList();
  }

  refreshTableListWithStats() {
    this.getTableList();
    this.getTableStats();
  }

  addNewRecord() {
    this.router.navigate(['add-new'], { relativeTo: this.route });
  }

  private clearSelection() {
    this.selectedItems = [];
  }
}
