import { Component, Input } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from "@angular/router";
import { SidebarComponent } from "../../components/sidebar/sidebar.component";
import { CommonModule } from "@angular/common";
import { LoaderComponent } from "../../components/loader/loader.component";
import { ProgressLoaderComponent } from "../../components/progress-loader/progress-loader.component";
import { SidebarNewComponent } from "../../components/sidebar-new/sidebar-new.component";
import { filter } from "rxjs/operators";

@Component({
  selector: "app-private-layout",
  imports: [
    RouterModule,
    SidebarComponent,
    CommonModule,
    LoaderComponent,
    ProgressLoaderComponent,
    SidebarNewComponent
  ],
  standalone: true,
  templateUrl: "./private-layout.component.html",
  styleUrl: "./private-layout.component.scss"
})
export class PrivateLayoutComponent {
  sidebarWidth = 89;
  isPinned = false;
  showSideNav: boolean = true;

  constructor(private router: Router) {
    this.router.events
      .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
      .subscribe(() => {
        this.updateSideNavVisibility();
      });
  }

  ngOnInit() {
    this.updateSideNavVisibility();
  }

  private updateSideNavVisibility(): void {
    let currentRoute = this.router.routerState.root;
    let showSideNav = true;

    while (currentRoute.firstChild) {
      currentRoute = currentRoute.firstChild;
      if (currentRoute.snapshot.data['showSideNav'] !== undefined) {
        showSideNav = currentRoute.snapshot.data['showSideNav'];
      }
    }

    this.showSideNav = showSideNav;
  }


  onSidebarToggle(event: { expanded: boolean; pinned: boolean; searching: boolean }) {
    this.isPinned = event.pinned;

    if (event.pinned || event.expanded || event.searching) {
      this.sidebarWidth = 292;
    } else {
      this.sidebarWidth = 89;
    }

    document.documentElement.style.setProperty('--content-width', `calc(100vw - ${this.sidebarWidth}px)`);
  }
}
