<div class="private-layout">
<!--  <app-sidebar (toggle)="onSidebarToggle($event)"></app-sidebar>-->

  @if (showSideNav){
  <app-sidebar-new (toggle)="onSidebarToggle($event)"></app-sidebar-new>
  <div class="content" [ngStyle]="{ 'margin-left.px': sidebarWidth }">
    <app-progress-loader></app-progress-loader>
    <router-outlet></router-outlet>
  </div>
  } @else {
    <div class="content">
      <app-progress-loader></app-progress-loader>
      <router-outlet></router-outlet>
    </div>
  }
</div>
