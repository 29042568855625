{
  "name": "Charge",
  "dbName": "charge",
  "columns": [
    {
      "fieldName": "name",
      "dbName": "name",
      "displayName": "Name",
      "dataType": "STRING"
    },
    {
      "fieldName": "code",
      "dbName": "code",
      "displayName": "Code",
      "dataType": "STRING"
    },
    {
      "fieldName": "printName",
      "dbName": "print_name",
      "displayName": "Print Name",
      "dataType": "STRING"
    },
    {
      "fieldName": "hsnOrSacCode",
      "dbName": "hsn_or_sac_code",
      "displayName": "HSN OR SAC Code",
      "dataType": "STRING"
    },
    {
      "fieldName": "active",
      "dbName": "active",
      "displayName": "Active",
      "dataType": "BOOLEAN"
    },
    {
      "fieldName": "gstPercentage",
      "dbName": "gst_percentage",
      "displayName": "GST Percentage",
      "dataType": "DOUBLE"
    },
    {
      "fieldName": "description",
      "dbName": "description",
      "displayName": "Description",
      "dataType": "STRING"
    },
    {
      "fieldName": "modifiedBy",
      "dbName": "modified_by",
      "displayName": "Modified By",
      "dataType": "STRING",
      "hasLookup": true,
      "lookupDetail": {
        "lookupObjectName": "users",
        "lookupFieldName": "id",
        "lookupDbName": "id"
      }
    },
    {
      "fieldName": "modifiedAt",
      "dbName": "modified_date",
      "displayName": "Modified Date",
      "dataType": "DATETIME"
    }
  ]
}
