import { Component, ComponentRef, ViewChild, ViewContainerRef } from '@angular/core';
import { TosTableComponent } from '../../../tos-table/tos-table.component';
import { MultiSelectComponent } from "../../../select/multi-select.component";
import { Cycle, CycleType } from '../../../../constants/app-constants';
import { TableListRequest } from '../../../../models/table-list-request.interface';
import { PaginatedListDataResponse } from '../../../../models/paginated-list-data-response';
import { SharedModule } from '../../../../modules/shared/shared.module';
import { FilterCondition, Where } from '../../../../models/user-list-request.interface';
import ImportHoldConfig from '../config/importActiveHoldConfig.json'
import { TableMeta } from '../../../../models/table-meta.interface';
import { AdvancedFilterComponentV2 } from '../../../advanced-filter/advanced-filter-v2.component';
import { ModalComponent } from '../../../modal/modal.component';
import { FieldInfo } from '../../../../models/editable-table-header.interface';
import { OperationService } from '../../../../services/operations.service';
import { SvgIconComponent } from '../../../svg-icon/svg-icon.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ReleaseHoldDialogComponent } from '../release-hold-dialog/release-hold-dialog.component';
import { HoldSource, HoldType } from '../../../../constants/operations-contsants';

@Component({
  selector: 'app-active-holds',
  standalone: true,
  imports: [
    SharedModule,
    TosTableComponent,
    MultiSelectComponent,
    SvgIconComponent
  ],
  templateUrl: './active-holds.component.html',
  styleUrl: './active-holds.component.scss'
})
export class ActiveHoldsComponent {

  cycleOptions = Object.values(CycleType);

  tableLoaded: boolean = false;
  selectedCycle!: Cycle
  tableMeta!: TableMeta;
  filterFields!: FieldInfo[];
  totalTablePages = 0;
  currentTablePage = 1;
  currentPage: number = 0;
  rowsPerPage: number = 15;
  rowsPerPageOptions: number[] = [];
  focusSearch: boolean = false;
  searchTerm: string = "";
  previousTerm: string = "";
  selectedItems: any[] = [];
  sortColumn: string = "modifiedDate";
  sortDirection: "asc" | "desc" = "desc";
  allTableRecordsSelected: boolean = false;
  filterData!: Where;
  rawFilterData!: Where;
  searchFilterData!: Where | null;
  tableListData: PaginatedListDataResponse = {
    totalCount: 0,
    page: 0,
    limit: 0,
    records: []
  };
  tableListRequest: TableListRequest = {
    selectFields: [
      "id",
      "containerId",
      "containerId.containerNo",
      "cargoId",
      "cargoId.igmItemNo",
      "cargoId.accountHolder.name as accountHolder",
      "cargoId.chaId.name as cha",
      "holdCategory",
      "holdSubCategory",
      "holdIssuedBy",
      "holdDocumentNo",
      "holdDate",
      "holdRemarks",
      "modifiedDate"
    ],
    orderBy: {
      [this.sortColumn]: this.sortDirection
    },
    includeTotal: true,
    limit: this.rowsPerPage,
    page: this.currentPage
  };

  defaultFilters: Where = {
    filterConditions: [
    {
      name: "processCycle",
      alias: "processCycle",
      operator: "EQ",
      value: ["IMPORT"]
    },
    {
      name: "releaseDate",
      alias: "releaseDate",
      operator: "IS_NULL",
      value: []
    }
  ],
  expression: "processCycle AND releaseDate"
}

  // table menu
  openMenuIndex: number | null = null;
  menuOptions = [
    { label: "Release", value: "release" },
    { label: "Release Document", value: "releaseDocument" }
  ];

  @ViewChild('processCycleDropdown', { static: false }) processCycleDropdown!: MultiSelectComponent;


  constructor(private viewContainerRef: ViewContainerRef,
              private api: OperationService,
              private route: ActivatedRoute,
              private router: Router) {

  }


  ngOnInit() {
    this.tableMeta = ImportHoldConfig.tableConfig;
    this.filterFields = ImportHoldConfig.filterConfig.filterFields;
    this.selectedCycle = Object.values(this.cycleOptions).find(cycle => cycle.key === history.state?.processCycle) ?? CycleType.IMPORT;
    this.listHoldRecords();
  }

  ngAfterViewInit() {
    this.processCycleDropdown.writeValue(this.selectedCycle.key);
  }

  onCycleChange(selectedCycleOption: Cycle): void {
    console.log(selectedCycleOption);
    this.selectedCycle = selectedCycleOption;
  }

  handleTableSearch(event: KeyboardEvent) {
    const searchTerm = this.searchTerm.trim();

    if (searchTerm.length >= 3) {
      this.searchFilterData = {
        filterConditions: [
          {
            name: "containerId.containerNo",
            alias: "containerNoStartsWith",
            operator: "CONTAINS",
            value: [searchTerm]
          },
          {
            name: "cargoId.igmItemNo",
            alias: "cargoNoStartsWith",
            operator: "CONTAINS",
            value: [searchTerm]
          }
        ],
        expression: "containerNoStartsWith OR cargoNoStartsWith"
      };
      this.listHoldRecords();
    }
    if (searchTerm.length === 0 && this.previousTerm.length !== 0) {
      this.searchFilterData = null;
      this.listHoldRecords();
    }

    this.previousTerm = searchTerm;
  }

  filter() {
      const modalRef = this.viewContainerRef.createComponent(ModalComponent);
      modalRef.instance.title = "Advanced Filter";
      const filterDialogRef = modalRef.instance.loadComponent(
        AdvancedFilterComponentV2
      );
      filterDialogRef.setInput("filterFields", this.filterFields);
      if (this.tableListRequest.where) {
        filterDialogRef.setInput("appliedFilters", this.rawFilterData);
      }
      modalRef.instance.close.subscribe((res: any) => {
        this.clearSelection();
        this.rawFilterData = res?.rawFilterData;
        if (res && res.resetAll) {
          delete this.tableListRequest.where;
          this.listHoldRecords();
        } else if (res && res.refresh) {
          if (
            res.filterData.expression &&
            res.filterData.filterConditions.length > 0
          ) {
            this.filterData = {
              filterConditions: res.filterData.filterConditions,
              expression: res.filterData.expression
            };
          } else {
            console.error("Invalid filter !");
            delete this.tableListRequest.where;
          }
          this.listHoldRecords();
        }
        modalRef.destroy();
      });
    }



  listHoldRecords() {
    this.mergeFilters();
    this.api.listHoldRecords(this.tableListRequest).subscribe({
      next: (res: any) => {
        this.tableListData = res.data;
      },
      error: (err) => {
        console.error(err);
      }
    });
  }

  mergeFilters() {
    delete this.tableListRequest.where;
    this.tableListRequest.where = JSON.parse(JSON.stringify(this.defaultFilters));

    if (!this.searchFilterData && !this.filterData) return;

    if (!this.tableListRequest.where) {
      this.tableListRequest.where = {filterConditions: [], expression: ''};
    }

    if (this.filterData?.filterConditions?.length) {
      this.filterData.filterConditions.forEach((filter: any) => {
        this.tableListRequest.where?.filterConditions?.push(filter);
      });
    }

    if (this.searchFilterData?.filterConditions?.length) {
      this.searchFilterData.filterConditions.forEach((filter: any) => {
        this.tableListRequest.where?.filterConditions?.push(filter);
      });
    }

    // Merge expressions with proper checks
    const expressions: string[] = [];
    if (this.defaultFilters?.expression) {
      expressions.push(`(${this.defaultFilters.expression})`);
    }
    if (this.filterData?.expression) {
      expressions.push(`(${this.filterData.expression})`);
    }
    if (this.searchFilterData?.expression) {
      expressions.push(`(${this.searchFilterData.expression})`);
    }

    // Safely assign the joined expression
    this.tableListRequest.where.expression = expressions.join(' AND ');
  }



  handleMenuOptionSelection(event: any) {
    const { record, actionName } = event;
    if (actionName === 'releaseDocument') {
      this.router.navigate(['release-hold'], { relativeTo: this.route, state: {processCycle: this.selectedCycle.key, tableMeta: this.tableMeta, holdDocumentNo: record.holdDocumentNo}});
    } else if (actionName === 'release') {
      const holdSourceForSelectedRecord = Object.values(HoldSource).find((hs) => hs.key === record.holdIssuedBy) || null;
      this.openReleaseHoldModal(holdSourceForSelectedRecord, record.holdSubCategory, record.holdDocumentNo, [record]);
    }
  }

  clearSelection() {
    this.selectedItems = [];
    this.allTableRecordsSelected = false;
  }

  selectAll() {
    this.allTableRecordsSelected = true;
  }

  createHold() {
    this.router.navigate(['create-new'], { relativeTo: this.route, state: {processCycle: this.selectedCycle.key}});
  }

  releaseSelectedItems() {
    const { holdSource, holdSubCategory, holdDocumentNo } = this.getCommonHoldDetailsFromSelectedItemsIfValid() ?? {};
    this.openReleaseHoldModal(holdSource, holdSubCategory, holdDocumentNo, this.selectedItems);
  }

  openReleaseHoldModal(holdSource: HoldSource | null, holdSubCategory: string | null, holdDocumentNo: string | null, selectedItems: any[]) {
    const modalRef = this.viewContainerRef.createComponent(ModalComponent);
      modalRef.instance.title = "Release Hold";
      console.log("selected items: ", selectedItems.length);
      const releaseHoldDialogRef = modalRef.instance.loadComponent(ReleaseHoldDialogComponent, {
        selectedFilter: this.allTableRecordsSelected ? this.filterData : null,
        selectedItems: selectedItems,
        processCycle: this.selectedCycle.key,
        holdSource: holdSource,
        holdDocumentNo: holdDocumentNo,
        holdSubCategory: holdSubCategory
      });
      modalRef.instance.close.subscribe((res: any) => {
        if (res && res.refresh) {
          this.clearSelection();
          this.listHoldRecords();
        } else if (res && res.resetAll) {
          this.clearSelection();
        }
        modalRef.destroy();
      });
  }

  getCommonHoldDetailsFromSelectedItemsIfValid(): any {
    if (this.allTableRecordsSelected || this.selectedItems.length === 0) {
      return null;
    }

    const firstItem = this.selectedItems[0];
    const holdSourceForFirstRecord = Object.values(HoldSource).find((hs) => hs.key === firstItem.holdIssuedBy) || null;
    const allSame = this.selectedItems.every(
      (item) =>
        item.holdType === firstItem.holdType &&
        item.holdIssuedBy === firstItem.holdIssuedBy &&
        (firstItem.holdType !== HoldType.CUSTOMS.key || item.holdDocumentNo === firstItem.holdDocumentNo) &&
        (firstItem.holdType !== HoldType.INTERNAL.key || item.holdDocumentNo === firstItem.holdSubCategory)
    );

    return allSame ? {holdSource: holdSourceForFirstRecord, holdSubCategory: firstItem.holdSubCategory, holdDocumentNo: firstItem.holdDocumentNo}  : null;
  }


}
