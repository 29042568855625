<div class="advanced_filter">
    <form [formGroup]="filterForm">
      <div formArrayName="filterConditions" class="filter_conditions">
        <div
          *ngFor="let condition of filterConditions.controls; let i = index"
          [formGroupName]="i"
        >
        <div class="alias_holder">
            <input
            class="condition_alias"
            formControlName="alias"
            placeholder="Alias"
            readonly
          />
          <div  *ngIf="condition.get('alias')?.errors?.['required']" class="form_validation_error_new" [ngClass]="{'visible': condition.get('alias')?.invalid && condition.get('alias')?.invalid?.touched}">
            <small *ngIf="condition.get('alias')?.errors?.['required']">
                Field is required
            </small>
        </div>
        </div>
  
          <div class="columns_holder">
            <select
            class="condition_fieldName"
            formControlName="name"
            (change)="fieldSelection($event.target.value, i)"
            >
                <option class="disabled_option" value="" disabled selected>
                Select Field
                </option>
                <option *ngFor="let field of filterFields" [value]="field.name">
                {{ field.displayLabel }}
                </option>
            </select>
            <div *ngIf="condition.get('name')?.errors?.['required']" class="form_validation_error_new" [ngClass]="{'visible': condition.get('name')?.invalid && condition.get('name')?.invalid?.touched}">
                <small *ngIf="condition.get('name')?.errors?.['required']">
                    Field is required
                </small>
            </div>
          </div>
          
          <div class="operators_holder">
            <select
            class="condition_operator"
            formControlName="operator"
            >
                <option value="" disabled selected>Select Operator</option>
                <option
                *ngFor="let operator of fieldOperatorMap.get(condition.get('name')?.value)"
                [value]="operator.key"
                >
                {{ operator.label }}
                </option>
            </select>
            <div *ngIf="condition.get('operator')?.errors?.['required']" class="form_validation_error_new" [ngClass]="{'visible': condition.get('operator')?.invalid && condition.get('operator')?.invalid?.touched}">
                <small *ngIf="condition.get('operator')?.errors?.['required']">
                    Operator is required
                </small>
            </div>
          </div>
  
          <div class="value_holder" formArrayName="value" *ngFor="let control of condition.get('value').controls; let vi = index">
            <ng-container *ngIf="getFieldInfo(condition.get('name')?.value) as fieldInfo; else defaultField">
                @if (fieldInfo?.hasLookup) {
                    <app-autocomplete-dropdown
                        class="value-input"
                        [id]="'value'"
                        [placeholder]="'Search value'"
                        [formControlName]="vi"
                        [apiUrl]="fieldInfo?.searchConfig.apiUrl"
                        [selectFields]="fieldInfo?.searchConfig.selectFields"
                        [whereFields]="fieldInfo?.searchConfig.whereFields"
                        [limit]="fieldInfo?.searchConfig.limit"
                        [orderBy]="fieldInfo?.searchConfig.orderBy"
                        [filterByKey]="fieldInfo?.searchConfig.filterByKey"
                        [bindLabel]="fieldInfo?.searchConfig.bindLabel"
                        [bindValue]="fieldInfo?.searchConfig.bindValue"
                        [displayColumns]="fieldInfo?.searchConfig.displayColumns"
                    >
                    </app-autocomplete-dropdown>
                } @else if (fieldInfo?.dataType === 'number') {
                    <input
                        class="value-input"
                        type="number"
                        [formControlName]="vi"
                        placeholder="Enter number"
                    />
                } @else if (fieldInfo?.dataType === 'checkbox') {
                    <input
                        class="value-input"
                        type="checkbox"
                        [formControlName]="vi"
                    />
                } @else if (fieldInfo?.dataType === 'dropdown') {
                  <app-multi-select
                      [formControlName]="vi"
                      [options]="fieldInfo?.options"
                      [displayKey]="'label'"
                      [valueKey]="'value'"
                      [isMultiSelect]="true"
                      [placeholder]="'Select'"
                  ></app-multi-select>
                } @else if (fieldInfo?.dataType === 'picklist') {
                  <app-multi-select
                    [formControlName]="vi"
                    [options]="fieldInfo?.options"
                    [displayKey]="'label'"
                    [valueKey]="'value'"
                    [isMultiSelect]="true"
                    [placeholder]="'Select'"
                  ></app-multi-select>
                } @else {
                    <input
                    class="value-input"
                        [formControlName]="vi"
                        placeholder="Enter value"
                    />
                }
            </ng-container>
            <ng-template #defaultField>
                <input class="value-input" [formControlName]="vi" placeholder="Enter value" />
            </ng-template>
            <div *ngIf="control?.errors?.['required']" class="form_validation_error_new" [ngClass]="{'visible': control?.invalid && control?.invalid?.touched}">
                <small *ngIf="control?.errors?.['required']">
                    Value is required
                </small>
            </div>
        </div>
  
          <div class="remove_condition_icon" *ngIf="getFieldInfo(condition.get('name')?.value)?.deleteable ?? true" (click)="removeCondition(i)">
            <app-svg-icon [path]="'assets/icons/close.svg'"></app-svg-icon>
          </div>
        </div>
      </div>
  
      <div class="centered_flex_container">
        <div class="add_condition" (click)="addCondition()">
          <app-svg-icon [path]="'assets/icons/plus_icon.svg'"></app-svg-icon>
          <span>Add condition</span>
        </div>
      </div>
  
      <div class="expression_area">
        <textarea
          name="finalExpression"
          id="finalExpression"
          formControlName="expression"
          rows="2"
        ></textarea>
      </div>
    </form>
  
    <div class="fixed_modal_footer">
      <div class="centered_flex_container modal_actions">
        <button
          type="button"
          class="inversed_danger_button"
          (click)="resetFilter()"
        >
          Reset
        </button>
        <button type="button" class="secondary_button" (click)="close()">
          Cancel
        </button>
        <button type="submit" class="primary_button" (click)="applyFilter()">
          Apply
        </button>
      </div>
    </div>
  </div>
  