import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ProgressLoaderComponent } from "../../components/progress-loader/progress-loader.component";

@Component({
  selector: 'app-public-layout',
  imports: [RouterModule, ProgressLoaderComponent],
  standalone: true,
  templateUrl: './public-layout.component.html',
  styleUrl: './public-layout.component.scss',
})
export class PublicLayoutComponent {}
