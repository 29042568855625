import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from "@angular/core";
import { DatePipe, NgClass, NgForOf, NgIf } from "@angular/common";
import { SvgIconComponent } from "../svg-icon/svg-icon.component";
import { TableDataType, TableMeta } from "../../models/table-meta.interface";
import { TosTableTdComponent } from "../tos-table/tos-table-td/tos-table-td.component";
import { NoDataFoundComponent } from "../no-data-found/no-data-found.component";
import { NoDataComponent } from "../no-data/no-data.component";
import { User } from "../../models/user.interface";

@Component({
  selector: "app-static-table",
  standalone: true,
  imports: [
    NgForOf,
    NgIf,
    SvgIconComponent,
    NgClass,
    DatePipe,
    TosTableTdComponent,
    NoDataFoundComponent,
    NoDataComponent
  ],
  templateUrl: "./static-table.component.html",
  styleUrl: "./static-table.component.scss"
})
export class StaticTableComponent {
  private allTableRecordsSelected: boolean = false;

  @Input() data: any[] = [];
  @Input() tableMeta!: TableMeta;
  @Input() readOnlyMode: boolean = false;
  @Input() deleteAllowed: boolean = true;
  @Input() showCheckBox: boolean = true;
  @Input() selectedItems: any[] = [];

  @Output() dataChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() dataDeleted: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedItemsChange: EventEmitter<any> = new EventEmitter<any>();

  removeElement(i: number,result: any) {
    this.data = this.data.filter((charge: any) => charge.id !== result.id)
    this.dataChange.emit(this.data)
    this.dataDeleted.emit(result);
  }

  isAllRecordsOfCurrentPageSelected() {
    return (
      this.allTableRecordsSelected ||
      (this.data?.length > 0 &&
        this.data?.every((item: User) =>
          this.isSelected(item)
        ))
    );
  }

  toggleAllRecordsOfCurrentPageSelections(isChecked: any) {
    if (isChecked) {
      this.data?.forEach((item) => {
        if (!this.isSelected(item)) {
          this.selectedItems.push(item);
        }
      });
    } else {
      this.data?.forEach((item) => {
        this.selectedItems = this.selectedItems.filter((i) => i.id !== item.id);
      });
    }

    this.selectedItemsChange.emit(this.selectedItems);
  }

  toggleSelection(record: any) {
    if (!this.isSelected(record)) {
      this.selectedItems.push(record);
    } else {
      this.selectedItems = this.selectedItems.filter((i) => i.id !== record.id);
    }
    this.selectedItemsChange.emit(this.selectedItems);
  }

  isSelected(item: any) {
    if (this.allTableRecordsSelected) {
      return true;
    } else {
      const recordIdx = this.selectedItems.findIndex((x) => x.id === item.id);
      if (recordIdx !== -1) {
        return true;
      } else {
        return false;
      }
    }
  }
}
