import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight',
  standalone: true,
})
export class HighlightPipe implements PipeTransform {
  transform(value: string, search: string): any {
    if (!search || !value) {
      return value;
    }

    // Escape special characters in the search term
    const escapedSearch = search.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');

    // Create a case-insensitive regular expression for the search term
    const regExp = new RegExp(escapedSearch, 'gi');

    // Replace the search term with highlighted text
    const highlightedValue = value.replace(
      regExp,
      (match: string) => `<mark>${match}</mark>`
    );
    return highlightedValue;
  }
}
