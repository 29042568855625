{
  "name": "Tariff",
  "dbName": "tariff",
  "columns": [
    {
      "fieldName": "priority",
      "dbName": "priority",
      "displayName": "Priority",
      "dataType": "INTEGER"
    },
    {
      "fieldName": "fromDate",
      "dbName": "from_date",
      "displayName": "From Date",
      "dataType": "DATETIME"
    },
    {
      "fieldName": "toDate",
      "dbName": "to_date",
      "displayName": "To Date",
      "dataType": "DATETIME"
    },
    {
      "fieldName": "description",
      "dbName": "description",
      "displayName": "Description",
      "dataType": "STRING"
    },
    {
      "fieldName": "modifiedBy",
      "dbName": "modified_by",
      "displayName": "Modified By",
      "dataType": "STRING",
      "hasLookup": true,
      "lookupDetail": {
        "lookupObjectName": "users",
        "lookupFieldName": "id",
        "lookupDbName": "id"
      }
    },
    {
      "fieldName": "modifiedAt",
      "dbName": "modified_date",
      "displayName": "Modified Date",
      "dataType": "DATETIME"
    }
  ]
}
