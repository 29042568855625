<div class="full_page_container">
  <header class="header">
    <div class="page_title_close_btn">
      <div class="close_btn" (click)="goBack()">
        <img src="assets/icons/close_icon.svg" alt="close icon" />
      </div>
      <div class="page_title">
        @if (readOnlyMode) {
          View
        } @else if (editingEnabled) {
          Edit
        } @else {
          Add
        }
        Tariff
      </div>
    </div>
    <div class="tabs">
      <div class="tab" (click)="activateTab(1)">
        <div class="page_number_tab_line">
          <div class="page_number" [ngClass]="{'active_tab': activeTab===1}">1</div>
          <div class="tab_line"></div>
        </div>
        Basic Details
      </div>
      <div class="tab" (click)="activateTab(2)">
        <div class="page_number_tab_line">
          <div class="page_number" [ngClass]="{'active_tab': activeTab===2}">2</div>
          <div class="tab_line"></div>
        </div>
        Rule Set Configuration
      </div>
    </div>
    <div class="save_cancel_btn">

      @if (!readOnlyMode) {
        <button class="secondary_button add_new_btn" (click)="goBack()">Cancel</button>
        <button class="primary_button add_new_btn" (click)="saveTariff()">
        <span>
          @if (editingEnabled) {
            Update
          } @else {
            Save
          }
        </span>
        </button>
      } @else {
        <button class="secondary_button add_new_btn" (click)="goBack()">Go Back</button>
      }
    </div>
  </header>

  @if (activeTab === 1) {
    <div class="card">
      <app-dynamic-form [formConfig]="manageTariffFormConfig" [formGroup]="manageTariffForm"></app-dynamic-form>
    </div>

    <div class="card">
      <div class="title">Common Criteria</div>
      <div class="divider"></div>
      <div class="common_criteria" (click)="addRulesForCommonCriteria()">+ Add Rules</div>
      <div class="rules-container" *ngIf="commonCriteria.length > 0">
        <div class="rule-pill" *ngFor="let rule of commonCriteria" (click)="addRulesForCommonCriteria()">
          {{ getDisplayTextForDeterminant(rule.determinant) }} {{ getDisplayTextForOperator(rule.determinant, rule.operator) }}
          {{ getValuesToPrint(rule) }} <span *ngIf="rule.values.length > 3">...</span>
        </div>
      </div>
    </div>
  } @else if (activeTab === 2) {
    <app-tariff-table
      [(ruleSets)]="ruleSets"
      [determinants]="allDeterminants"
    ></app-tariff-table>
  }
</div>
