{
  "name": "ChargeGroup",
  "dbName": "charge_group",
  "columns": [
    {
      "fieldName": "name",
      "dbName": "name",
      "displayName": "Name",
      "dataType": "STRING"
    },
    {
      "fieldName": "description",
      "dbName": "description",
      "displayName": "Description",
      "dataType": "STRING"
    },
    {
      "fieldName": "invoiceHeaderTitle",
      "dbName": "invoice_header_title",
      "displayName": "Invoice Header Title",
      "dataType": "STRING"
    },
    {
      "fieldName": "operationalProcess",
      "dbName": "operational_process",
      "displayName": "Operational Process",
      "dataType": "STRING"
    },
    {
      "fieldName": "charges",
      "dbName": "charges",
      "displayName": "Charges",
      "dataType": "ARRAY"
    },
    {
      "fieldName": "active",
      "dbName": "active",
      "displayName": "Active",
      "dataType": "BOOLEAN"
    },
    {
      "fieldName": "modifiedBy",
      "dbName": "modified_by",
      "displayName": "Modified By",
      "dataType": "STRING",
      "hasLookup": true,
      "lookupDetail": {
        "lookupObjectName": "users",
        "lookupFieldName": "id",
        "lookupDbName": "id"
      }
    },
    {
      "fieldName": "modifiedAt",
      "dbName": "modified_date",
      "displayName": "Last Modified Date",
      "dataType": "DATETIME"
    }
  ]
}
