<div class="checkbox-group">
    <input
      type="checkbox"
      [id]="checkboxId"
      [checked]="isChecked"
      [disabled]="isDisabled"
      class="checkbox-input"
      (change)="onCheckboxChange($event)"
    />
    <label [for]="checkboxId" class="checkbox-label">{{ label }}</label>
</div>