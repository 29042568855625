import { Component, ViewChild } from '@angular/core';
import {
  AbstractControl,
  AbstractControlOptions,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { SharedModule } from '../../modules/shared/shared.module';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { LoadingService } from '../../services/loading.service';
import { ResetPasswordRequest } from '../../models/reset-password.interface';
import { ToasterService } from '../../services/toaster.service';
import { OtpInputComponent } from "../otp-input/otp-input.component";
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'app-reset-password',
  standalone: true,
  imports: [SharedModule, OtpInputComponent],
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.scss',
})
export class ResetPasswordComponent {
  resetPasswordForm: FormGroup;
  passwordContainsLowercase = false;
  passwordContainsUppercase = false;
  passwordContainsNumber = false;
  passwordContainsSpecial = false;
  passwordMinLength = false;
  passwordInvalid = true;
  token: string = '';
  validToken: boolean = false;
  otpRequired: boolean = false;
  obfuscatedMobileNumber: string = '';
  validatingToken: boolean = false;
  passwordUpdatedSuccessfully: boolean = false;
  resendAllowed: boolean = false;
  resendOtpBufferTime: number = 30; //in seconds
  countdown: number = 0;
  countdownSubscription: Subscription = new Subscription();

  @ViewChild(OtpInputComponent) otpInputComponent!: OtpInputComponent;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private api: ApiService,
    private router: Router,
    private loadingService: LoadingService,
    private toasterService: ToasterService
  ) {
    this.resetPasswordForm = this.fb.group(
      {
        newPassword: ['', [Validators.required]],
        confirmPassword: ['', [Validators.required]],
        otp: ['']
      },
      { validators: this.passwordsMatchValidator } as AbstractControlOptions
    );
    this.resetPasswordForm.get('newPassword')?.valueChanges.subscribe(() => {
      this.resetPasswordForm.get('confirmPassword')?.reset();
    });

    this.applyOtpValidator();
  }

  applyOtpValidator() {
    if (this.otpRequired) {
      this.resetPasswordForm.get('otp')?.setValidators([Validators.required]);
    } else {
      this.resetPasswordForm.get('otp')?.clearValidators();
    }
    this.resetPasswordForm.get('otp')?.updateValueAndValidity();
  }

  formatTime(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${this.padZero(minutes)}:${this.padZero(remainingSeconds)}`;
  }

  padZero(value: number): string {
    return value < 10 ? '0' + value : value.toString();
  }

  ngOnInit(): void {
    this.token = this.route.snapshot.paramMap.get('token') || '';
    this.validatingToken = true;
    this.loadingService.show();
    this.api.validateTokenForResetPassword(this.token).subscribe({
      next: (res) => {
        console.log(res.data)
        const { tokenValid, optRequired, mobileNumber } = res.data;

        if (mobileNumber !== null)
          this.toasterService.success(`Otp sent to ${mobileNumber}`);

        this.validToken = tokenValid;
        this.otpRequired = optRequired;
        this.obfuscatedMobileNumber = mobileNumber;
        this.validatingToken = false;
        this.startCountdown()
        setTimeout(() => {
          this.loadingService.hide();
        }, 1000);
      },
      error: (err) => {
        this.validToken = false;
        this.validatingToken = false;
        console.log(err);
        setTimeout(() => {
          this.loadingService.hide();
        }, 1000);
      },
    });
  }

  startCountdown(): void {
    this.resendAllowed = false;
    this.countdown = this.resendOtpBufferTime;
    this.countdownSubscription = interval(1000).subscribe(() => {
      if (this.countdown > 0) {
        this.countdown--;
      } else {
        this.resendAllowed = true;
        this.countdownSubscription.unsubscribe();
      }
    });
  }

  validatePassword(): void {
    const password = this.resetPasswordForm.get('newPassword')?.value;
    this.passwordContainsLowercase = /[a-z]/.test(password);
    this.passwordContainsUppercase = /[A-Z]/.test(password);
    this.passwordContainsNumber = /\d/.test(password);
    this.passwordContainsSpecial = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    this.passwordMinLength = password.length >= 8;
    this.passwordInvalid = !(
      this.passwordContainsLowercase &&
      this.passwordContainsUppercase &&
      this.passwordContainsNumber &&
      this.passwordContainsSpecial &&
      this.passwordMinLength
    );
  }

  passwordsMatchValidator(control: AbstractControl): ValidationErrors | null {
    const password = control.get('newPassword')?.value;
    const confirmPassword = control.get('confirmPassword')?.value;
    return password === confirmPassword ? null : { passwordMismatch: true };
  }

  onOtpChange(newOtp: string): void {
    this.resetPasswordForm.get('otp')?.setValue(newOtp);
  }

  requestResetPassword() {
    this.resetPasswordForm.markAllAsTouched();
    if (!this.passwordInvalid && this.resetPasswordForm.valid) {
      const resetPasswordRes: ResetPasswordRequest = {
        password: this.resetPasswordForm.get('newPassword')?.value,
        confirmPassword: this.resetPasswordForm.get('confirmPassword')?.value,
        otp: this.resetPasswordForm.get('otp')?.value
      };
      this.loadingService.show();
      this.api.resetPassword(resetPasswordRes, this.token).subscribe({
        next: () => {
          this.passwordUpdatedSuccessfully = true;
          setTimeout(() => {
            this.loadingService.hide();
          }, 1000);
        },
        error: (err) => {
          this.passwordUpdatedSuccessfully = false;
          this.toasterService.error(err.error.errorDesc);
          setTimeout(() => {
            this.loadingService.hide();
          }, 1000);
        },
      });
    }
  }

  resendOtp() {
    if (!this.resendAllowed) {
      return;
    } else {
      this.resetPasswordForm.get('otp')?.reset();
      this.otpInputComponent.otpForm.reset();
    }
    this.requestNewOtp();
  }

  requestNewOtp() {
    this.api.resentOtpPasswordReset(this.token).subscribe({
      next: (res) => {
        console.log(res.data)
        this.toasterService.success(`Otp sent successfully`);
        this.startCountdown()
        setTimeout(() => {
          this.loadingService.hide();
        }, 1000);
      },
      error: (err) => {
        console.log(err);
        setTimeout(() => {
          this.loadingService.hide();
        }, 1000);
      },
    })
  }

  goToForgotPassword() {
    this.router.navigate(['/forgot-password']);
  }

  goToLogin() {
    this.router.navigate(['/login']);
  }
}
