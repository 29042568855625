import { Component, ViewChild, ViewChildren, Input, ViewContainerRef, ElementRef, QueryList } from '@angular/core';
import { DynamicFormService } from '../../../../../services/dynamic-form.service';
import { Control, DynamicForm } from '../../../../../models/dynamic-form';
import { DynamicFormComponent } from '../../../../dynamic-form/dynamic-form.component';
import { ItemSelectorPanelComponent } from '../../../../item-selector-panel/item-selector-panel.component';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators, FormArray, AbstractControl, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TableHeaderAndData } from '../../../../../models/table-header-and-data';
import { JobOrderVendorComponent } from '../../vendor-form/job-order-vendor.component';
import { NoDataComponent } from '../../../../no-data/no-data.component';
import { AccordionTableComponent } from '../../../../accordion-table/accordion-table.component';
import { JobOrderInventoryInfo, JobOrderInventory, JobOrderItem, JobOrderVendor, allowedJobOrderAdditionalInfoKeys } from '../../../../../models/job-orders.interface';
import { OperationService } from '../../../../../services/operations.service';
import { LoadingService } from '../../../../../services/loading.service';
import { ToasterService } from '../../../../../services/toaster.service';
import { ConfirmDialogV2Component } from '../../../../confirm-dialog/confirm-dialog-v2.component';
import { DatePipe } from '@angular/common'
import { JobOrderFormActions } from '../host/manage-jo-renderer.component';
import { JobOrderType } from '../../../../../constants/operations-contsants';
import { coerceStringArray } from '@angular/cdk/coercion';
import { InventoryType } from '../../../../../constants/gate-ops-constants';
import { convertEpochToISTDateTime } from '../../../../../utils/date-time-utils';
import { VerticalTabsComponent } from "../../../../vertical-tabs/vertical-tabs.component";

@Component({
  selector: 'app-stuffing',
  standalone: true,
  imports: [DynamicFormComponent, ItemSelectorPanelComponent, CommonModule, AccordionTableComponent, ConfirmDialogV2Component, VerticalTabsComponent],
  templateUrl: './stuffing.component.html',
  styleUrl: './stuffing.component.scss',
  providers:[DatePipe]
})
export class StuffingComponent implements JobOrderFormActions {
  @Input() jobOrderType!: string;
  @Input() jobOrderId!: string;
  @Input() jobOrderConfig!: any;

  jobOrderForm!: FormGroup;
  dynamicForm!: DynamicForm;
  containerDynamicForm!: DynamicForm;
  itemSearchConfig!: any;
  containerSearchConfig!:any
  containerTableHeaderAndData: any = {};
  containerList: any = [];
  itemList: any = [];
  selectedItemIndex!: number;
  readOnlyMode:boolean = false;
  editMode: boolean = false;
  vendorMap: Map<string, any[]> =  new Map();
  selectedConsignee: any = {};
  selectedShippingLine: any = {};
  private initialVendorIds: Set<string> = new Set();
  private initialInventoryMap: Map<string, string> = new Map();
  private sectionMap: Map<string, any> = new Map();
  loadedAccordianIndexSet: Set<string> = new Set();
  protected readonly JobOrderVendorComponent = JobOrderVendorComponent;

  containerFormfieldMapping = {
    joInvId: 'joInvId',
    id: 'inventoryId',
    containerNo: 'inventoryNo',
    allocatedItems: 'additionalDetails.selectedItems'
  };

  selectedTab: string = "basicDetailsSection";
  tabList: any[] = [
    {
      id: "basicDetailsSection",
      label: "Basic Jo details",
      subtitle: "Please fill Issue and valid Dates",
      icon: "job_order.svg"
    },
    {
      id: "itemDetailsSection",
      label: "Item details",
      subtitle: "A few details about your Item",
      icon: "package.svg"
    },
    {
      id: "vendorDetailsSection",
      label: "Container Details",
      subtitle: "Enter Container & vendor info",
      icon: "cube.svg"
    }
  ];

  @ViewChild("basicDetailsSection") basicDetailsSection: ElementRef | undefined;
  @ViewChild("itemDetailsSection") itemDetailsSection: ElementRef | undefined;
  @ViewChild("vendorDetailsSection", { read: ElementRef }) vendorDetailsSection: ElementRef | undefined;
  @ViewChild('itemSelectorComponent', { static: false }) itemSelectorComponent?: ItemSelectorPanelComponent;
  @ViewChild('accordianTableComponent', { static: false }) accordianTableComponent?: AccordionTableComponent;
  @ViewChildren("formField") formFields!: QueryList<ElementRef>;
  @ViewChild(AccordionTableComponent) accordionTableComponent!: AccordionTableComponent;


  constructor (private dynamicFormService: DynamicFormService,
               private fb: FormBuilder,
               private api: OperationService,
               private toasterService: ToasterService,
               private loadingService: LoadingService,
               private router: Router,
               private route: ActivatedRoute,
               private viewContainerRef: ViewContainerRef,
               private datePipe: DatePipe
  ) {
    this.jobOrderForm = fb.group({
      itemDetails : this.fb.array([]),
    });
  }

  ngOnInit() {
    console.log(this.jobOrderConfig);
    this.dynamicForm = this.jobOrderConfig?.jobOrderDetailsFormElements;
    this.containerDynamicForm = this.jobOrderConfig?.containerFormElements;
    this.itemSearchConfig = this.jobOrderConfig?.itemSearchConfig;
    this.containerSearchConfig = this.jobOrderConfig?.containerSearchConfig;
    this.dynamicFormService.addControlsToForm(this.jobOrderForm, this.dynamicForm);
    this.containerTableHeaderAndData = {
      headers: this.jobOrderConfig?.containerColumnMeta,
      data: this.containerList
    };

    if (this.jobOrderId === "") {
      console.log("Job Order Id not found");
      this.readOnlyMode = false;
      this.editMode = false;
      this.setDefaultValues()
      return;
    }

    if (this.route.snapshot.url[0].path === "edit") {
      this.editMode = true;
      this.readOnlyMode = false;
    } else {
      this.editMode = false;
      this.readOnlyMode = true;
    }

    this.loadingService.show();
    this.loadJobOrderData(this.jobOrderId);
  }

  selectTab(sectionId: string) {
    this.selectedTab = sectionId;
    const section = this.sectionMap.get(sectionId);
    const container = document.querySelector(".full_page_container");

    if (section && container) {
      const sectionTop = section.getBoundingClientRect().top - container.getBoundingClientRect().top;
      container.scrollTo({
        top: sectionTop + container.scrollTop,
        behavior: "smooth"
      });
    }
  }

  ngAfterViewInit() {
    if (this.basicDetailsSection) this.sectionMap.set("basicDetailsSection", this.basicDetailsSection.nativeElement);
    if (this.itemDetailsSection) this.sectionMap.set("itemDetailsSection", this.itemDetailsSection.nativeElement);
    if (this.vendorDetailsSection) this.sectionMap.set("vendorDetailsSection", this.vendorDetailsSection.nativeElement);
  }

  get itemFormArray(): FormArray {
    return this.jobOrderForm.get('itemDetails') as FormArray;
  }

  get containerFormArray(): FormArray {
    return this.jobOrderForm.get('containerDetails') as FormArray;
  }

  handleItemSelection(event: { item: any, index: number }) {
    const formGroup = this.itemFormArray.at(event.index) as FormGroup;
    this.selectedItemIndex = event.index;
    const dynamicFormConfig = {
      formConfig: this.jobOrderConfig?.itemFormElements,
      formGroup: formGroup,
    };
    const componentsToLoad: any = [
      { component: DynamicFormComponent, data: dynamicFormConfig }
    ];
    this.itemSelectorComponent?.loadComponents(componentsToLoad);
  }

  handleItemAddition(event: { item: any, index: number }) {
    this.dynamicFormService.addToFormArray(this.jobOrderForm, "itemDetails", this.jobOrderConfig?.itemFormElements);
    const itemFormGroup = this.itemFormArray.at(event.index) as FormGroup;
    itemFormGroup.patchValue({
      ...event.item,
    });

    const multiSelectField = this.containerDynamicForm?.formRows.flatMap(row => row.formGroups).find((formGroup: any) => formGroup?.control?.label === 'allocatedItems');

    if (multiSelectField?.control?.fieldInfo?.options) {
      multiSelectField.control.fieldInfo.options.push({label: event.item.igmItemNo, value: event.item.id});
    }
    console.log("Post Item Addition: ", multiSelectField?.control?.fieldInfo?.options);
  }

  handleItemDeletion(event: { item: any, index: number }) {
    const index = event.index;
    const deletedItem = event.item;
    this.itemFormArray.removeAt(index);


    const multiSelectField = this.containerDynamicForm?.formRows.flatMap(row => row.formGroups).find((formGroup: any) => formGroup?.control?.label === 'allocatedItems');

    if (multiSelectField?.control?.fieldInfo?.options) {
      multiSelectField.control.fieldInfo.options = multiSelectField.control.fieldInfo.options.filter(
        (option: any) => option.value !== deletedItem.id
      );
    }

    this.containerFormArray.controls.forEach((control, i) => {
      const selectedValues = control.get('allocatedItems')?.value || [];

      if (selectedValues.some((selectedValue: any) => selectedValue.value === deletedItem.id)) {
        control.get('allocatedItems')?.setValue(
          selectedValues.filter((selectValue: any) => selectValue.value !== deletedItem.id)
        );
      }
    });
  }

  handleContainerAddition(event: {item: any, index: number}) {
    this.dynamicFormService.addToFormArray(this.jobOrderForm, "containerDetails", this.containerDynamicForm);
    const containerFormGroup = this.containerFormArray.at(event.index) as FormGroup;
    containerFormGroup.patchValue({
      id: event.item.id,
      containerNo: event.item.containerNo,
      allocatedItems: event.item?.allocatedItems
    });
    console.log("Post Container Addition: ", containerFormGroup.value);
  }

  handleContainerDeletion(event: {rowId: string, rowIndex: number}) {
    this.containerFormArray.removeAt(event.rowIndex);
    this.vendorMap.delete(event.rowId);
    this.loadedAccordianIndexSet.delete(event.rowId);
  }


  loadAccordionContent(event: {rowId: string, rowIndex: number}) {
    if (this.loadedAccordianIndexSet.has(event.rowId)) return;
    const containerFormGroup = this.containerFormArray.at(event.rowIndex);
    const containerInFocus = this.containerList[event.rowIndex];
    if (!this.vendorMap.has(containerInFocus.id)) {
      this.vendorMap.set(containerInFocus.id, []);
    }
    const vendorComponentInput = {
      dynamicForm: this.containerDynamicForm,
      formGroup: containerFormGroup,
      index: event.rowIndex,
      itemDetails: containerInFocus,
      vendorList: this.vendorMap.get(containerInFocus.id),
      readOnly: this.readOnlyMode
    };
    this.accordionTableComponent.loadComponent(JobOrderVendorComponent, event.rowIndex, vendorComponentInput);
    this.loadedAccordianIndexSet.add(event.rowId);
  }

  cancel() {
    this.confirmConcellation();
  }

  goBack() {
    this.router.navigateByUrl("/manage-operations/import/job-orders");
  }

  saveForm() {
    this.jobOrderForm.markAllAsTouched();

    const jobOrderRequest = this.mapJobOrderFormToRequest();
    console.log("Inside Save: ", jobOrderRequest);
    if (this.jobOrderForm.invalid) {
      this.scrollToFirstInvalidControl();
      this.printFormErrors(this.jobOrderForm);
      return;
    }



    if (this.editMode) {
      this.api.updateJobOrder(this.jobOrderId, jobOrderRequest).subscribe((response) => {
          this.toasterService.success("Successfully created the job order");
          this.goBack();
        },
        (error) => {
          console.log('Request failed:', error);
          this.toasterService.error(error.error.errorDesc);
        }
      );
    } else {
      this.api.saveJobOrder(jobOrderRequest).subscribe((response) => {
          this.toasterService.success("Successfully created the job order");
          this.goBack();
        },
        (error) => {
          console.log('Request failed:', error);
          this.toasterService.error(error.error.errorDesc);
        }
      );
    }

  };

  scrollToFirstInvalidControl() {
    const firstInvalidControl = this.formFields.find((element) => {
      return !element.nativeElement.validity?.valid;
    });

    if (firstInvalidControl) {
      firstInvalidControl.nativeElement.scrollIntoView({
        behavior: "smooth",
        block: "center"
      });
    }
  }

  mapJobOrderFormToRequest(): any {
    const jobOrderFormValue = this.jobOrderForm.value;
    const jobOrderAdditionalInfo = Object.keys(jobOrderFormValue).reduce((acc: any, key) => {
      if (allowedJobOrderAdditionalInfoKeys.includes(key)) {
        acc[key] = jobOrderFormValue[key];
      }
      return acc;
    }, {});

    const jobOrderInventoryList: JobOrderInventory[] = [];
    this.containerFormArray.controls.forEach((control) => {
      const containerData: JobOrderInventory = {
        joInvId: this.initialInventoryMap.get(control.get('id')?.value),
        inventoryId: control.get('id')?.value,
        inventoryNo: control.get('containerNo')?.value,
        inventoryType: 'CONTAINER',
        additionalDetails: {
          selectedItems: control.get('allocatedItems')?.value.map((item: any) => ({
            id: item.value,
            igmItemNo: item.label,
          })),
        },
      };
      jobOrderInventoryList.push(containerData);
    });

    const itemList = this.jobOrderForm.get('itemDetails')?.value as JobOrderItem[];
    this.itemFormArray.controls.forEach((control) => {
      const itemData: JobOrderInventory = {
        joInvId: this.initialInventoryMap.get(control.get('id')?.value),
        inventoryId: control.get('id')?.value,
        inventoryNo: control.get('igmItemNo')?.value,
        inventoryType: 'CARGO'
      };
      jobOrderInventoryList.push(itemData);
    });


    const isJobOrderAdditionalInfoEmpty = Object.values(jobOrderAdditionalInfo).every(
      (value) => value === undefined || value === null || value === ''
    );
    const jobOrderVendorMap = Object.fromEntries(this.vendorMap);
    const deletedJobOrderVendorIds = this.getDeletedVendorIds();
    const deletedJobOrderInventoryIds = this.getDeletedInventoryIds(jobOrderInventoryList);
    console.log(deletedJobOrderVendorIds);
    return {
      jobOrderType: this.jobOrderType,
      expiryDate: new Date(this.jobOrderForm.get('expiryDate')?.value).getTime(),
      jobOrderAdditionalInfo : isJobOrderAdditionalInfoEmpty ? null : jobOrderAdditionalInfo,
      itemList,
      jobOrderInventoryList,
      jobOrderVendorMap,
      deletedJobOrderInventoryIds,
      deletedJobOrderVendorIds
    };
  }

  confirmConcellation() {
    const confirmDialogRef = this.viewContainerRef.createComponent(ConfirmDialogV2Component);

    confirmDialogRef.instance.title = "Are you sure you want to cancel? "
    confirmDialogRef.instance.message = "All the data entered in this form will be lost. This action cannot be undone.";
    confirmDialogRef.instance.confirmButtonLabel = "Confirm";

    confirmDialogRef.instance.confirm.subscribe(() => {
      confirmDialogRef.destroy();
      this.goBack();
    });

    confirmDialogRef.instance.cancel.subscribe(() => {
      confirmDialogRef.destroy();
    });
  }


  loadJobOrderData(jobOrderId: string) {
    let data = null;
    this.loadingService.show();
    this.api.getJobOrderById(jobOrderId, true, true, true, false).subscribe({
      next: (response: any) => {
        data = response?.data;
        this.hydrateForm(data);
        this.loadingService.hide();
      },
      error: (error) => {
        console.error(error);
        this.toasterService.error("Failed to get job order data");
        this.loadingService.hide();
      }
    });
  }

  hydrateForm(jobOrderData: any) {
    if (jobOrderData?.vendorDetails) {
      this.vendorMap = new Map(Object.entries(jobOrderData.vendorDetails));
      this.vendorMap.forEach((vendors) => {
        vendors.forEach((vendor: any) => {
          if (vendor.jovId) {
            this.initialVendorIds.add(vendor.jovId);
          }
        });
      });
    }
    const containerAddtionDetails: Map<string, any> = new Map();
    jobOrderData?.jobOrderInventoryList.forEach((joInv: JobOrderInventory) => {
      if (joInv.inventoryId && joInv.joInvId) {
        this.initialInventoryMap.set(joInv.inventoryId, joInv.joInvId);
      }
      if (joInv.inventoryType === 'CONTAINER' && joInv.additionalDetails) {
        containerAddtionDetails.set(joInv.inventoryId, joInv.additionalDetails);
      }
    });


    this.itemList = jobOrderData?.cargoList;
    this.containerList = jobOrderData?.containerList;
    this.containerTableHeaderAndData.data = this.containerList;
    this.accordianTableComponent?.updateAddedItems();
    this.itemList.forEach((item: any, index: number) => this.handleItemAddition({item, index}));
    this.containerList.forEach((container: any, index: number) => {
      container.allocatedItems = containerAddtionDetails.get(container.id)?.selectedItems?.map((item: any) => ({
        value: item.id,
        label: item.igmItemNo
      }));
      this.handleContainerAddition({item: container, index});
    })

    console.log(this.vendorMap);
    this.jobOrderForm.patchValue({
      ...jobOrderData,
      ...jobOrderData?.additionalDetails,
      expiryDate: convertEpochToISTDateTime(jobOrderData?.expiryDate),
      issueDate: convertEpochToISTDateTime(jobOrderData?.issueDate),
    });
    //this.selectedCHA = jobOrderData?.additionalDetails?.chaId

    if (this.readOnlyMode) {
      this.jobOrderForm.disable();
    }
  }

  getDeletedInventoryIds(finalJoInvList: any[]): string[] {
    const deletedInventoryIds: string[] = [];
    const currentInvIds = new Set<string>(
      finalJoInvList
        .filter((joInv: JobOrderInventory) => joInv?.joInvId)
        .map((joInv: JobOrderInventory) => joInv.joInvId!)
    );

    this.initialInventoryMap.forEach((value, key) => {
      if (!currentInvIds.has(value)) {
        deletedInventoryIds.push(value);
      }
    });
    return deletedInventoryIds;
  }

  getDeletedVendorIds(): string[] {
    const currentVendorIds = new Set<string>();

    this.vendorMap.forEach((vendors) => {
      vendors.forEach((vendor: any) => {
        if (vendor.jovId) {
          currentVendorIds.add(vendor.jovId);
        }
      });
    });

    // Find the IDs that were in the initial set but are no longer in the current set
    const deletedVendorIds: string[] = [];
    this.initialVendorIds.forEach((jovId) => {
      if (!currentVendorIds.has(jovId)) {
        deletedVendorIds.push(jovId);
      }
    });

    return deletedVendorIds;
  }

  setDefaultValues() {
    this.jobOrderForm.get("issueDate")?.setValue(convertEpochToISTDateTime(new Date().getTime()));
  }

  printFormErrors(formGroup: FormGroup | FormArray, path: string = ''): void {
    Object.keys(formGroup.controls).forEach((key) => {
      const control = formGroup.get(key);
      const controlPath = path ? `${path}.${key}` : key;

      if (control instanceof FormGroup || control instanceof FormArray) {
        this.printFormErrors(control, controlPath);
      } else if (control && control.invalid) {
        console.log(`Control: ${controlPath}`, control.errors);
      }
    });
  }
}
