import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProgressLoaderService {
  private progressSubject = new BehaviorSubject<number | null>(null);
  progress$ = this.progressSubject.asObservable();

  start(): void {
    this.progressSubject.next(0); // Start with progress 0
  }

  complete(): void {
    this.progressSubject.next(100); // Set progress to 100 (complete)
    setTimeout(() => this.progressSubject.next(null), 500); // Hide after completion
  }

  setProgress(value: number): void {
    this.progressSubject.next(value); // Set progress manually
  }
}
