import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewContainerRef } from "@angular/core";
import { StorageKeys } from "../../../constants/app-constants";
import { LocalStorageService } from "../../../services/local-storage.service";
import { User } from "../../../models/user.interface";
import { ModalComponent } from "../../modal/modal.component";
import { ConfirmDialogComponent } from "../../confirm-dialog/confirm-dialog.component";
import { AuthService } from "../../../services/auth.service";

@Component({
  selector: 'app-sidebar-options',
  standalone: true,
  imports: [],
  templateUrl: './sidebar-options.component.html',
  styleUrl: './sidebar-options.component.scss'
})
export class SidebarOptionsComponent {
  userProfile!: User

  constructor(private localStorageService: LocalStorageService,
              private authService: AuthService,
              private elementRef: ElementRef) {
  }
  ngOnInit(){
    const userProfile = this.localStorageService.getItem(StorageKeys.USER_PROFILE);
    if (userProfile) {
      this.userProfile = JSON.parse(userProfile.toString());
    } else {
      setTimeout(() => {
        const userProfile = this.localStorageService.getItem(StorageKeys.USER_PROFILE);
        if (userProfile) {
          this.userProfile = JSON.parse(userProfile.toString());
        }
      }, 1000);
    }
  }

  logout() {
      this.authService.logout();
      // this.localStorageService.removeItem("userProfile");
      this.localStorageService.clear()
  }
}
