<div class="container">
  <!-- Header -->
  <div class="header">
    <span class="header-title">
      @if (editingEnabled) {
        Edit
      } @else {
        Create
      } Charge Group
    </span>
    <div class="header-actions">
      <button class="btn secondary_button" (click)="cancel()">Cancel</button>
      @if (!readOnlyMode) {
        <button class="btn primary_button" (click)="save()">{{ editingEnabled ? 'Update' : 'Save' }}</button>
      }
    </div>
  </div>

  <!-- Content -->
  <div class="content">
    <app-dynamic-form [formConfig]="formConfig" [formGroup]="form"></app-dynamic-form>
  </div>
</div>
