import { Component, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import {
  FormControl,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

@Component({
  selector: 'app-checkbox',
  standalone: true,
  imports: [],
  templateUrl: './checkbox.component.html',
  styleUrl: './checkbox.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent implements ControlValueAccessor {
  value: boolean = false;
  @Input() label: string = '';           // The label text
  @Input() isChecked: boolean = false;    // Checked state
  @Input() isDisabled: boolean = false;   // Disabled state
  @Input() checkboxId: string = '';       // Checkbox ID for accessibility
  
  @Output() checkedChange = new EventEmitter<boolean>();

  // Event handler for checkbox change.
  onChange: any = () => {};
  onTouched: any = () => {};

  onCheckboxChange(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.checkedChange.emit(target.checked);
  }

  // Set initial value from form control
  writeValue(value: boolean): void {
    if (value !== undefined) {
      this.value = value;
    }
  }

  // When value changes in the form control
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // On blur or touch event
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  toggleCheck(event: Event): void {
    this.value = !this.value;
    this.onChange(this.value);
    this.onTouched();
  }
}
