<!--@if(!styleConfigPresentForColumn(column.fieldName)){-->
@switch (column.dataType) {
  @case ('DATETIME') {
    {{ record | date : "dd/MM/yy hh:mm a" }}
  }
  @case ('BOOLEAN') {
    @if (column.fieldName === 'active' || column.fieldName === 'isActive') {
      <div class="icon_cover">
        <div class="badge_status" [ngClass]="record ? 'active' : 'inactive'">
          <app-svg-icon [path]="'assets/icons/circle_icon.svg'"></app-svg-icon>
          {{ record ? "Active" : "Inactive" }}
        </div>
      </div>
    } @else if (column.fieldName === 'isLocked') {
      <!--   TODO: Handle these from ui config -->
      <div
        class="icon_cover"
        [ngClass]="record ? 'locked' : 'not_locked'"
      >
        <div class="badge_locked">
          <app-svg-icon
            [path]="'assets/icons/lock_icon.svg'"
          ></app-svg-icon>
          {{ record ? "Yes" : "No" }}
        </div>

      </div>
    } @else if (column.fieldName === 'isSuperAdmin') {
      <div
        class="icon_cover"
        [ngClass]="record ? 'admin' : 'not_admin'"
      >
        <div class="badge_locked">
          <app-svg-icon
            [path]="'assets/icons/shield_icon.svg'"
          ></app-svg-icon>
          {{ record ? "Yes" : "No" }}
        </div>
      </div>
    } @else {
      {{ record ? 'Yes' : 'No' }}
    }
  }
  @case ('ARRAY') {
    @if (record?.length > 0) {
      <div class="role_container">
        <div class="first_role">{{ record?.[0]?.name }}</div>
        <div class="second_role" *ngIf="record?.[1]?.name">{{ record[1]?.name }}</div>
        <div class="third_role" *ngIf="record?.[2]?.name">{{ record?.[2]?.name }}</div>
        <div
          class="role_count"
          [appTooltip]="record.slice(3)"
          [keyToPick]="'name'"
          position="bottom"
          *ngIf="record?.length > 3"
        >
          + {{ record?.length - 3 }}
        </div>
      </div>
    }
  }
  @default {
    @if (column.fieldName === 'code') {
      <div class="code_badge">
        {{ record || 'N/A' }}
      </div>
    } @else if (column?.lookupDetail?.lookupObjectName === 'users') {
      <div class="user_badge">
        <div class="avatar_icon">{{ record?.charAt(0) || "-" }}</div>
        {{ record }}
      </div>
    } @else {
      {{ record }}
    }
  }
}
<!--} @else {
  <div *ngFor="let config of styleConfigForField" [ngClass]="config?.className" [ngStyle]="config?.style">
    <img *ngIf="config.iconPath" [src]="config?.iconPath" alt="icon">
    @if(record === config.value){
      {{ record }}
    }
  </div>
}-->
