<div class="container">
  <div class="header header_with_back_button">
    <app-back-button></app-back-button>
    <span>
      @if (readOnlyMode) { View } @else if(masterEditingEnabled) { Edit } @else
      {Create New} Location
    </span>
  </div>

  <div class="container-content">
    <form [formGroup]="masterForm">
      <div class="section">
        <div class="head">Basic Information</div>
        <div class="fields">
          <div class="form-group">
            <div class="label required_field_label" #formField>
              Location Type
            </div>
            <div
              class="form-row checkbox-label checkbox-area"
              formArrayName="locationType"
            >
              <label *ngFor="let locationType of locationTypes; let i = index">
                <input
                  type="checkbox"
                  [formControlName]="i"
                  (change)="onCheckboxChange($event, i)"
                />
                {{ locationType.name }}
              </label>
            </div>
            <div
              class="form_validation_error"
              *ngIf="
                masterForm.get('locationType')?.invalid &&
                masterForm.get('locationType')?.touched &&
                masterForm.get('locationType')?.errors
              "
            >
              <small
                *ngIf="
                  masterForm
                    .get('locationType')
                    ?.hasError('atLeastOneCheckboxChecked')
                "
              >At least one customer type must be selected</small
              >
            </div>
          </div>
          <div class="grid-container">
            <div class="form-group">
              <label for="panNumber" class="required_field_label" #formField
              >PAN Number</label
              >
              <input
                id="panNumber"
                name="panNumber"
                formControlName="panNumber"
                type="text"
                placeholder="Enter PAN Number"
                required
                autocomplete="panNumber"
                [ngClass]="{
                  form_error:
                    masterForm.get('panNumber')?.invalid &&
                    masterForm.get('panNumber')?.touched &&
                    masterForm.get('panNumber')?.errors
                }"
              />
              <div
                class="form_validation_error"
                [ngClass]="{
                  form_error:
                    masterForm.get('panNumber')?.invalid &&
                    masterForm.get('panNumber')?.touched 
                }"
                *ngIf="
                  masterForm.get('panNumber')?.invalid &&
                  masterForm.get('panNumber')?.touched
                "
              >
                <small *ngIf="masterForm.get('panNumber')?.errors?.['required']"
                >PAN Number is required</small
                >
              </div>
            </div>
            <div class="form-group">
              <label for="name" class="required_field_label" #formField
                >Location Name</label
              >
              <input
                id="name"
                name="name"
                formControlName="name"
                type="text"
                placeholder="Enter name"
                required
                autocomplete="name"
                [ngClass]="{
                  form_error:
                    masterForm.get('name')?.invalid &&
                    masterForm.get('name')?.touched &&
                    masterForm.get('name')?.errors
                }"
              />
              <div
                class="form_validation_error"
                [ngClass]="{
                  form_error:
                    masterForm.get('name')?.invalid &&
                    masterForm.get('name')?.touched 
                }"
               
              >
                <small *ngIf="masterForm.get('name')?.errors?.['required']"
                  >Name is required</small
                >
              </div>
            </div>
            <div class="form-group">
              <label for="distanceFromCFS">Distance from CFS (in KM)</label>
              <input
                id="distanceFromCFS"
                name="distanceFromCFS"
                formControlName="distanceFromCFS"
                type="number"
                placeholder="Enter distance in km (optional)"
                autocomplete="distanceFromCFS"
              />
            </div>

          </div>
        </div>
      </div>

      <div class="section">
        <div class="head tabs">
          <div class="tab">Contact 1</div>
          <!-- <div class="tab">Contact 2</div> -->
        </div>

        <div class="fields">
          <div formArrayName="contactInformation">
              <div class="form-group text-area-form">
                <label for="address">Full Address</label>
                <textarea
                  id="address"
                  name="address"
                  formControlName="address"
                  type="text"
                  placeholder="Enter full address"
                  rows="4"
                ></textarea>
              </div>

              <div class="form-group text-area-form">
                <label for="billingAddress">Billing Address</label>
                <textarea
                  id="billingAddress"
                  name="billingAddress"
                  formControlName="billingAddress"
                  type="text"
                  placeholder="Enter Billing Address"
                  rows="4"
                ></textarea>
              </div>

              <div class="grid-container">
                <div class="form-group">
                  <label for="cityId">City</label>
                  <input
                    id="cityId"
                    name="cityId"
                    formControlName="cityId"
                    type="text"
                    placeholder="Enter City"
                  />
                </div>

                <div class="form-group">
                  <label for="countryId">Country</label>
                  <input
                    id="countryId"
                    name="countryId"
                    formControlName="countryId"
                    type="text"
                    placeholder="Enter Country"
                  />
                </div>

                <div class="form-group">
                  <label for="stateId">State</label>
                  <input
                    id="stateId"
                    name="stateId"
                    formControlName="stateId"
                    type="text"
                    placeholder="Enter State"
                  />
                </div>

                <div class="form-group">
                  <label for="pinCode">Postal Code</label>
                  <input
                    id="pinCode"
                    name="pinCode"
                    formControlName="pinCode"
                    type="text"
                    placeholder="Enter Postal Code"
                  />
                </div>

                <div class="form-group">
                  <label for="email">Email</label>
                  <input
                    id="email"
                    name="email"
                    formControlName="email"
                    type="text"
                    placeholder="Enter Email"
                  />
                </div>

                <div class="form-group">
                  <label for="mobileNumber1">Mobile Numer</label>
                  <input
                    id="mobileNumber1"
                    name="mobileNumber1"
                    formControlName="mobileNumber1"
                    type="text"
                    placeholder="Enter Mobile Number"
                  />
                </div>

                <div class="form-group">
                  <label for="mobileNumber2">Alternative Mobile Numer</label>
                  <input
                    id="mobileNumber2"
                    name="mobileNumber2"
                    formControlName="mobileNumber2"
                    type="text"
                    placeholder="Enter Alternative Mobile Number"
                  />
                </div>

                <div class="form-group">
                  <label for="defaultContact">Default Contact</label>
                  <select id="defaultContact" formControlName="defaultContact">
                    <option [value]="true">Yes</option>
                    <option [value]="false">No</option>
                  </select>
                </div>

                <div class="form-group">
                  <label for="communicationPreference"
                    >Communication Preference</label
                  >
                  <select
                    id="communicationPreference"
                    formControlName="communicationPreference"
                    value="mobile"
                  >
                    <option value="mobile">Mobile Phone</option>
                    <option value="email">Email</option>
                  </select>
                </div>
              </div>

              <!-- <h4>Contact {{ i + 1 }}</h4> -->

              <!-- <label for="address">Address</label>
              <input id="address" type="text" formControlName="address" />

              <label for="mobileNumber1">Mobile Number 1</label>
              <input
                id="mobileNumber1"
                type="text"
                formControlName="mobileNumber1"
              /> -->

              <!-- Other contact fields can be added here similarly -->

              <!-- <button type="button" (click)="removeContact(i)">
                Remove Contact
              </button> -->
            </div>


          <!-- <button type="button" (click)="addContact()">Add Contact</button> -->
        </div>
      </div>

      <div class="section">
        <div class="head tabs">
          <div class="tab">Person Details</div>
        </div>

        <div class="fields">
          <div formArrayName="person">
            <div *ngFor="let person of personInformation.controls; let i = index" [formGroupName]="i">
            <div class="grid-container">
              <!-- Person's Name -->
              <div class="form-group">
                <label for="personName">Person's Name</label>
                <input
                  id="personName"
                  name="name"
                  formControlName="name"
                  type="text"
                  placeholder="Enter person's name"
                />
              </div>

              <!-- Person's Designation -->
              <div class="form-group">
                <label for="personDesignation">Designation</label>
                <input
                  id="personDesignation"
                  name="designation"
                  formControlName="designation"
                  type="text"
                  placeholder="Enter designation"
                />
              </div>

                <!-- Email -->

                <div class="form-group">
                  <label for="email">Email</label>
                  <input
                    id="email_person"
                    name="email"
                    formControlName="email"
                    type="email"
                    placeholder="Enter email"
                  />
                </div>

                <!-- Mobile Number 1 -->
                <div class="form-group">
                  <label for="mobileNumber1">Mobile Number</label>
                  <input
                    id="mobileNumber1_person"
                    name="mobileNumber1"
                    formControlName="mobileNumber1"
                    type="text"
                    placeholder="Enter mobile number"
                  />
                </div>

                <!-- Mobile Number 2 -->
                <div class="form-group">
                  <label for="mobileNumber2">Alternative Mobile Number</label>
                  <input
                    id="mobileNumber2_person"
                    name="mobileNumber2"
                    formControlName="mobileNumber2"
                    type="text"
                    placeholder="Enter alternative mobile number"
                  />
                </div>

                <!-- Communication Preference -->
                <div class="form-group">
                  <label for="communicationPreference">Communication Preference</label>
                  <select id="communicationPreference_person" formControlName="communicationPreference">
                    <option value="mobile">Mobile Phone</option>
                    <option value="email">Email</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>




    </form>
  </div>
  <div class="footer_actions">
    <ng-container *ngIf="readOnlyMode">
      <button type="button" class="secondary_button" (click)="goBack()">
        Back
      </button>
    </ng-container>
    <ng-container *ngIf="!readOnlyMode">
      <button type="button" class="secondary_button" (click)="goBack()">
        Cancel
      </button>
      <button
        type="button"
        class="primary_button"
        (click)="submitMasterForm()"
      >
        @if(masterEditingEnabled){ Update }@else { Save }
      </button>
    </ng-container>
  </div>

</div>
