import { Component, ElementRef, QueryList, ViewChildren } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { Subject, takeUntil } from "rxjs";
import { ApiService } from "../../../../services/api.service";
import { ToasterService } from "../../../../services/toaster.service";
import { LoadingService } from "../../../../services/loading.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ServiceMaster } from "../../../../models/service-master.interface";
import { BackButtonComponent } from "../../../back-button/back-button.component";
import { NgClass, NgForOf, NgIf } from "@angular/common";

@Component({
  selector: 'app-manage-service',
  standalone: true,
  imports: [
    BackButtonComponent,
    NgIf,
    ReactiveFormsModule,
    NgForOf,
    NgClass
  ],
  templateUrl: './manage-service.component.html',
  styleUrl: './manage-service.component.scss'
})
export class ManageServiceComponent {
  serviceTypes = [
    { name: 'Weighment Service', value: 'WEIGHMENT_SERVICE', checked: false },
    { name: 'Normal Service', value: 'NORMAL_SERVICE', checked: false },
    { name: 'SSR Service', value: 'SSR_SERVICE', checked: false },
    { name: 'Transportation Service', value: 'TRANSPORTATION_SERVICE', checked: false },
    { name: 'Repairing Service', value: 'REPAIRING_SERVICE', checked: false },
    { name: 'Fixed Labour', value: 'FIXED_LABOUR', checked: false },
    { name: 'Temporary Labour', value: 'TEMPORARY_LABOUR', checked: false },
    { name: 'Equipment', value: 'EQUIPMENT', checked: false },
    { name: 'Equipment Operator', value: 'EQUIPMENT_OPERATOR', checked: false },
    { name: 'LCLDestuff Check List', value: 'LCLDESTUFF_CHECK_LIST', checked: false },
    { name: 'DockDelivery Check List', value: 'DOCKDELIVERY_CHECK_LIST', checked: false },
    { name: 'LCLDelivery Check List', value: 'LCLDELIVERY_CHECK_LIST', checked: false },
  ];

  readOnlyMode = true;
  masterEditingEnabled = false;
  masterForm: FormGroup;
  masterId: string = "";

  @ViewChildren("formField") formFields!: QueryList<ElementRef>;
  private destroy$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private toasterService: ToasterService,
    private loadingService: LoadingService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.masterForm = this.initForm();
  }

  ngOnInit(): void {
    this.masterId = this.route.snapshot.paramMap.get("serviceId") || "";
    console.log(this.masterId);
    this.setupComponentMode();
    if (this.masterId) {
      this.loadMasterData();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initForm(): FormGroup {
    return this.fb.group({
      name: ["", Validators.required],
      code: ["", Validators.required],
      type: [''],
      chargeId: [''],
      serviceType: ['']
    });
  }

  private setupComponentMode(): void {
    if (this.masterId) {
      const path = this.route.snapshot.url[0].path;
      this.masterEditingEnabled = path === "edit";
      this.readOnlyMode = !this.masterEditingEnabled;
      if (this.readOnlyMode) {
        this.masterForm.disable();
      }
    } else {
      this.readOnlyMode = false;
      this.masterEditingEnabled = false;
    }
  }

  private loadMasterData(): void {
    this.loadingService.show();
    this.api.getServiceMaster(this.masterId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: any) => {
          const serviceMaster: ServiceMaster = res.data;
          this.patchFormWithMasterData(serviceMaster);
          this.loadingService.hide();
        },
        error: (err) => {
          console.error("Error loading service data:", err);
          this.toasterService.error("Failed to load service data");
          this.loadingService.hide();
        }
      });
  }

  private patchFormWithMasterData(serviceMaster: ServiceMaster): void {
    this.masterForm.patchValue(serviceMaster);
  }

  submitMasterForm(): void {
    if (this.masterForm.valid) {
      const serviceMasterData: ServiceMaster = {
        ...this.masterForm.value
      };
      serviceMasterData.active = true;

      this.loadingService.show();
      const action$ = this.masterEditingEnabled
        ? this.api.updateServiceMaster(serviceMasterData, this.masterId)
        : this.api.addServiceMaster(serviceMasterData);

      action$.pipe(takeUntil(this.destroy$)).subscribe({
        next: () => {
          const message = this.masterEditingEnabled
            ? `Service ${serviceMasterData.name} has been updated!`
            : `Service ${serviceMasterData.name} has been added!`;
          this.toasterService.success(message);
          this.loadingService.hide();
          this.goBack();
        },
        error: (err) => {
          this.toasterService.error(err.error.errorDesc || "An error occurred");
          console.error("Error submitting form:", err);
          this.loadingService.hide();
        }
      });
    } else {
      this.markFormGroupTouched(this.masterForm);
      this.scrollToFirstInvalidControl();
    }
  }

  private markFormGroupTouched(formGroup: FormGroup | FormArray): void {
    Object.values(formGroup.controls).forEach(control => {
      if (control instanceof FormGroup || control instanceof FormArray) {
        this.markFormGroupTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }

  scrollToFirstInvalidControl(): void {
    const firstInvalidControl = this.formFields.find((element) => {
      const nativeElement = element.nativeElement as HTMLElement;
      return nativeElement.classList.contains("ng-invalid");
    });

    if (firstInvalidControl) {
      firstInvalidControl.nativeElement.scrollIntoView({
        behavior: "smooth",
        block: "center"
      });
      (firstInvalidControl.nativeElement as HTMLElement).focus();
    }
  }

  goBack(): void {
    this.router.navigateByUrl("/manage-masters/services");
  }
}
