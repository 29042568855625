<div class="sidebar_options">
  <div class="sidebar_options_header">
    <div class="avatar_label_group">
      <img class="avatar-icon" alt="" src="assets/icons/avatar.svg">
      <div class="text_and_supporting_text">
        <div class="name">{{userProfile.name}}</div>
        <div class="username">{{userProfile.username}}</div>
      </div>
    </div>
  </div>

  <div class="options_menu">
    <div class="dropdown_list_item">
      <div class="content">
        <div class="icon_and_text">
          <img src="assets/icons/admin_menu_icon.svg">
          <div class="text">View Profile</div>
        </div>
      </div>
    </div>
    <div class="dropdown_list_item">
      <div class="content">
        <div class="icon_and_text">
          <img src="assets/icons/settings_menu_icon.svg">
          <div class="text">Settings</div>
        </div>
      </div>
    </div>
    <div class="divider"></div>
    <div class="dropdown_list_item" (click)="logout()">
      <div class="content">
        <div class="icon_and_text" >
          <img src="assets/icons/logout.svg">
          <div class="text logout">Log Out</div>
        </div>
      </div>
    </div>
  </div>
</div>
