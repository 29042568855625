{
  "name": "Bank Master",
  "dbName": "bank_master",
  "columns": [
    {
      "fieldName": "name",
      "dbName": "name",
      "displayName": "Name",
      "dataType": "STRING"
    },
    {
      "fieldName": "code",
      "dbName": "code",
      "displayName": "Code",
      "dataType": "STRING"
    },
    {
      "fieldName": "ifscCode",
      "dbName": "ifsc_code",
      "displayName": "IFSC Code",
      "dataType": "STRING"
    },
    {
      "fieldName": "branch",
      "dbName": "branch",
      "displayName": "Branch",
      "dataType": "STRING"
    },
    {
      "fieldName": "active",
      "dbName": "active",
      "displayName": "Active",
      "dataType": "BOOLEAN"
    },
    {
      "fieldName": "createdAt",
      "dbName": "created_date",
      "displayName": "Created Date",
      "dataType": "DATETIME"
    },
    {
      "fieldName": "modifiedAt",
      "dbName": "modified_date",
      "displayName": "Modified Date",
      "dataType": "DATETIME"
    },
    {
      "fieldName": "modifiedBy",
      "dbName": "modified_by",
      "displayName": "Modified By",
      "dataType": "STRING",
      "hasLookup": true,
      "lookupDetail": {
        "lookupObjectName": "users",
        "lookupFieldName": "id",
        "lookupDbName": "id",
        "defaultResolutionField": "name"
      }
    }
  ]
}
