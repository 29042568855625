import {
  Component,
  EventEmitter, Input, OnInit,
  Output,
  ViewContainerRef
} from "@angular/core";
import { SharedModule } from "../../modules/shared/shared.module";
import { NavigationEnd, Router, RouterModule } from "@angular/router";
import { SvgIconComponent } from "../svg-icon/svg-icon.component";
import { ModalComponent } from "../modal/modal.component";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";
import { AuthService } from "../../services/auth.service";
import { ApiService } from "../../services/api.service";
import { filter } from "rxjs/operators";
import { Menu } from "../../models/sidebar-menu.interface";
import { LocalStorageService } from "../../services/local-storage.service";
import { debounceTime } from "rxjs/operators";
import { User } from "../../models/user.interface";
import { LoginComponent } from "../login/login.component";
import { MenuService } from "../../services/menu.service";
import { StorageKeys } from "../../constants/app-constants";
import { SidebarExpandedComponent } from "./sidebar-expanded/sidebar-expanded.component";
import { SearchOverlayComponent } from "./search-overlay/search-overlay.component";

@Component({
  selector: "app-sidebar",
  standalone: true,
  imports: [SharedModule, SvgIconComponent, RouterModule, SidebarExpandedComponent, SearchOverlayComponent],
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"]
})
export class SidebarComponent {
  @Output() toggle = new EventEmitter<{ expanded: boolean; pinned: boolean,searching: boolean }>();

  menuItems: Menu[] = [];
  isPinned = false;
  isExpanded = false;
  isSearching: boolean = false;
  activeMenu: Menu | null = null;
  expandedMenuId: string | undefined;
  expandedMenu: Menu | null = null;

  activeMenuName: string | null = null;
  searchQuery = "";

  sideBarMenuItems = [
    // {
    //   id: 1,
    //   displayName: 'Dashboard',
    //   mainRoute: '/dashboard',
    //   subRoute: null,
    //   subMenuItems: [],
    // },
    {
      id: 2,
      displayName: "Admin",
      mainRoute: null,
      subRoute: null,
      subMenuItems: [
        {
          id: 21,
          displayName: "Users",
          mainRoute: "/admin/user-management",
          subRoute: null,
          subMenuItems: []
        },
        {
          id: 22,
          displayName: "Roles & Permissions",
          mainRoute: "/admin/roles-and-permissions",
          subRoute: null,
          subMenuItems: []
        }
      ]
    },
    {
      id: 3,
      displayName: "Masters",
      mainRoute: null,
      subRoute: null,
      subMenuItems: [
        {
          id: 31,
          displayName: "Customers",
          mainRoute: "/masters/manage/customers",
          subRoute: null,
          subMenuItems: []
        },
        {
          id: 32,
          displayName: "Locations",
          mainRoute: "/masters/manage/locations",
          subRoute: null,
          subMenuItems: []
        },
        {
          id: 33,
          displayName: "Banks",
          mainRoute: "/masters/manage/banks",
          subRoute: null,
          subMenuItems: []
        },
        {
          id: 34,
          displayName: "Vehicles",
          mainRoute: "/masters/manage/vehicles",
          subRoute: null,
          subMenuItems: []
        }
      ]
    },
    {
      id: 4,
      displayName: "Settings",
      mainRoute: "/settings",
      subRoute: null,
      subMenuItems: [
        {
          id: 41,
          displayName: "Profile",
          mainRoute: "/settings/profile",
          subRoute: null,
          subMenuItems: []
        },
        {
          id: 42,
          displayName: "Security",
          mainRoute: "/settings/security",
          subRoute: null,
          subMenuItems: [
            {
              id: 421,
              displayName: "Change Password",
              mainRoute: "/settings/security",
              subRoute: "/change-password",
              subMenuItems: []
            },
            {
              id: 422,
              displayName: "Deactivate Account",
              mainRoute: "/settings/account-deactivation",
              subRoute: "/request",
              subMenuItems: []
            }
          ]
        }
      ]
    }
  ];

  sideBarFixedItems = [
    // {
    //   id: 100,
    //   displayName: 'Support',
    //   mainRoute: '/support',
    //   subRoute: null,
    //   subMenuItems: [],
    // },
    {
      id: 101,
      displayName: "Settings",
      mainRoute: "/settings",
      subRoute: null,
      subMenuItems: []
    }
    // {
    //   id: 102,
    //   displayName: 'Settings 2',
    //   mainRoute: '/settings',
    //   subRoute: null,
    //   subMenuItems: [],
    // },
  ];

  userProfile!: User;

  filteredMenuItems: Menu[] = [];

  constructor(
    private router: Router,
    private viewContainerRef: ViewContainerRef,
    private authService: AuthService,
    private api: ApiService,
    private localStorageService: LocalStorageService,
    private menuService: MenuService
  ) {
  }


  ngOnInit() {
    this.loadSidebarState();
    const userProfile = this.localStorageService.getItem(StorageKeys.USER_PROFILE);
    if (userProfile) {
      this.userProfile = JSON.parse(userProfile.toString());
    } else {
      setTimeout(() => {
        const userProfile = this.localStorageService.getItem(StorageKeys.USER_PROFILE);
        if (userProfile) {
          this.userProfile = JSON.parse(userProfile.toString());
        }
      }, 1000);
    }
    this.menuService.getMenuItems().subscribe(items => {
      this.menuItems = items;
      this.filteredMenuItems = [...this.menuItems];
      this.highlightActiveMenu();
    });
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        debounceTime(100)
      ) // Debounce to smooth the transitions)
      .subscribe(() => {
        this.highlightActiveMenu();
      });

    // Initial highlight based on the current route
    // this.highlightActiveMenu();
  }

  highlightActiveMenu() {
    const currentUrl = this.router.url;

    for (const menu of this.menuItems) {
      if (currentUrl.toLowerCase().includes(menu.name.toLowerCase())) {
        this.activeMenu = menu
        this.expandedMenu = menu// Highlight parent menu
        return;
      }
    }

    // this.activeMenuName = null; // Reset if no match is found
  }

  isActiveParent(menuId: string): boolean {
    return this.activeMenu?.id === menuId;
  }

  isPreSelected(menuId: string): boolean{
    return this.expandedMenuId === menuId && this.activeMenu?.id !== menuId;
  }

  isActiveChild(childLink?: string): boolean {
    return !!childLink && this.router.url.includes(childLink);
  }

  emitToggle() {
    this.toggle.emit({ expanded: this.isExpanded, pinned: this.isPinned , searching: this.isSearching});
  }

  expandSidebar() {
    if (!this.isPinned) {
      this.isExpanded = true;
      this.emitToggle();
    }
  }

  collapseSidebar() {
    if (!this.isPinned) {
      this.isExpanded = false;

      //reset expanded menu
      this.expandedMenu = this.activeMenu;
      this.expandedMenuId = ''
      this.emitToggle();
    }
  }

  togglePin(event: Event) {
    event.stopPropagation(); // Prevent the sidebar from collapsing when clicking the button
    this.isPinned = !this.isPinned;
    this.isExpanded = this.isPinned;
    this.emitToggle();
    this.saveSidebarState();
  }

  saveSidebarState() {
    this.localStorageService.setItem("isSidebarPinned", this.isPinned);
    this.localStorageService.setItem("isSidebarExpanded", this.isExpanded);
  }

  loadSidebarState() {
    const isSidebarPinned = this.localStorageService.getItem("isSidebarPinned");
    if (isSidebarPinned) {
      this.isPinned = true;
    }
    const isSidebarExpanded =
      this.localStorageService.getItem("isSidebarExpanded");
    if (isSidebarExpanded) {
      this.isExpanded = true;
    }
    this.emitToggle();
  }

  toggleMenu(menuId: string) {
    if (this.searchQuery) {
      return; // Prevent manual toggling when a search query is active
    }

    if (this.activeMenuName === menuId) {
      this.activeMenuName = null; // Close the menu if it's already open
    } else {
      this.activeMenuName = menuId; // Open the clicked menu and close others
    }
  }

  isOpen(menuId: string): boolean {
    if (this.searchQuery) {
      return (
        this.activeMenuName === menuId ||
        this.filteredMenuItems.some((menu) => menu.id === menuId)
      );
    }
    return this.activeMenuName === menuId;
  }

  isActive(route: string): boolean {
    return this.router.url.startsWith(route);
  }

  filterMenuItems() {
    const query = this.searchQuery.toLowerCase().trim();
    this.activeMenuName = null; // Reset active menu when searching

    if (!query) {
      this.filteredMenuItems = [...this.menuItems];
      return;
    }

    this.filteredMenuItems = this.menuItems
      .map((menu) => {
        const filteredChildren = menu.childResources?.filter((child) =>
          child.displayLabel.toLowerCase().includes(query)
        );

        if (filteredChildren && filteredChildren.length > 0) {
          return { ...menu, children: filteredChildren };
        } else {
          return null;
        }
      })
      .filter((menu) => menu !== null);
  }

  logout() {
    const modalRef = this.viewContainerRef.createComponent(ModalComponent);
    // Set modal properties
    modalRef.instance.title = "Logout Confirmation";

    // Load the ConfirmDialogComponent inside the modal
    const confirmDialogRef = modalRef.instance.loadComponent(
      ConfirmDialogComponent
    );
    confirmDialogRef.instance.message = "Are you sure you want to logout?";

    // Handle confirmation
    confirmDialogRef.instance.confirm.subscribe(() => {
      console.log("Confirmed");
      this.authService.logout();
      this.localStorageService.removeItem("userProfile");
      modalRef.destroy();
    });

    // Handle cancellation
    confirmDialogRef.instance.cancel.subscribe(() => {
      console.log("Cancelled");
      modalRef.destroy();
    });

    // Handle closing the modal
    modalRef.instance.close.subscribe(() => modalRef.destroy());
  }

  loadExpandedMenuBar(menu: Menu) {
    this.expandedMenu = menu;
    this.expandSidebar();
  }

  onActiveMenuChanged(activeMenu: Menu) {
    console.log('Active menu ID:', activeMenu);
    this.activeMenu = activeMenu;
  }

  protected readonly name = name;

  handleClickOnNavItem(menu:Menu) {
    this.loadExpandedMenuBar(menu);
    this.expandedMenuId = menu.id
  }

  loadSearchOverlay(){
    this.isPinned=false;
    this.isExpanded=false;
    this.isSearching = true;
    this.emitToggle();

  }

  setSearching(value: boolean){
    this.isSearching = value;
    this.loadSidebarState();
    this.emitToggle();
  }
}
