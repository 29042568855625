import { inject } from '@angular/core';
import { HttpInterceptorFn } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService);

  // Avoid intercepting the logout request to prevent infinite loop
  if (req.url.includes('/logout')) {
    return next(req); // Proceed without interception
  }

  return next(req).pipe(
    catchError((error) => {
      if (error.status === 401) {
        authService.logout();
      }

      // Re-throw the error if it's not handled
      return throwError(() => error);
    })
  );
};
