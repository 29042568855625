<header class="page_header">
  <div class="header_content">
    <div class="text_and_supporting_text">
      <div class="page_title">Bank Master</div>
      <div class="page_desc">View and manage the list of banks</div>
    </div>
    <div class="action_btn">
      <button class="add_new_btn" (click)="addNewRecord()">
        <img src="assets/icons/plus_icon.svg" height="20" width="20">Add New
      </button>
    </div>
  </div>
</header>

<div class="search_bar_action_btns">
  <div class="container">
    <div class="search_bar">
      <div class="search_container">
        <input
          type="text"
          name="search_table"
          id="search_table"
          [(ngModel)]="searchTerm"
          placeholder="Search by name or code"
          (focus)="focusSearch = true"
          (focusout)="focusSearch = false"
          (keyup)="handleTableSearch($event)"
        >
        <img src="assets/icons/search_icon.svg" class="search_icon">
      </div>
    </div>

    <div class="filter_btn">
      <button (click)="filter()">
        <img src="assets/icons/filter.svg">
        Filters
      </button>
    </div>

    <div class="bulk_actions">
      @if(selectedItems.length > 0 || allTableRecordsSelected){
        <div class="selection_count">
          <div class="count">
            {{
              allTableRecordsSelected
                ? tableStats.total
                : selectedItems.length
            }}
            selected
          </div>
        </div>
        <div class="actions">
          <button class="tick" (click)="bulkActionConfirmation('setActive')">Make Banks Active</button>
          <button class="close" (click)="bulkActionConfirmation('setInActive')">Make Banks Inactive</button>
        </div>
      }
    </div>
  </div>
</div>

<!--<div class="tos-table-template">
  <div class="table-stats">
    <div class="left">
      <div class="total-users">
        Total Banks: {{ tableStats.total }}
      </div>
      <div class="active-users">
        Active: {{ tableStats.active }}
      </div>
      <div class="inactive-users">
        Inactive: {{ tableStats.inactive }}
      </div>
    </div>
    <div class="right">
      <div class="bulk_actions">
        <ng-container *ngIf="selectedItems.length > 0 || allTableRecordsSelected">
          <div class="selection_count">
            <div class="count">
              {{
                allTableRecordsSelected
                  ? tableStats.total
                  : selectedItems.length
              }}
              selected
            </div>
            &lt;!&ndash; <button
              class="table_select_all_button"
              *ngIf="tableStats.total_users"
              (click)="selectAllTableRecords()"
              [disabled]="allTableRecordsSelected"
            >
              <span *ngIf="!allTableRecordsSelected">
                Select all ({{ tableStats.total_users }}
                users)
              </span>
              <span *ngIf="allTableRecordsSelected">
                Selected all ({{ tableStats.total_users }} users)
              </span>
            </button> &ndash;&gt;
          </div>
          <div class="actions">
            <button
              class="tick"
              (click)="bulkActionConfirmation('setActive')"
            >
              &lt;!&ndash;                <app-svg-icon [path]="'assets/icons/tick.svg'"></app-svg-icon>&ndash;&gt;
              Make Banks Active
            </button>
            <button
              class="close"
              (click)="bulkActionConfirmation('setInActive')"
            >
              &lt;!&ndash;                <app-svg-icon [path]="'assets/icons/close.svg'"></app-svg-icon>&ndash;&gt;
              Make Banks Inactive
            </button>
            &lt;!&ndash; <button
              class="secondary_button lock"
              (click)="bulkActionConfirmation('unlock')"
            >
              <app-svg-icon [path]="'assets/icons/lock_icon.svg'"></app-svg-icon>
              Unlock
            </button> &ndash;&gt;
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="table-container">
    <ng-container *ngIf="tableLoaded && tableStats.total === 0">
      <app-no-data
        [msg]="
              'No banks are available in the system. Please click on Add New Bank button to create one.'
            "
      ></app-no-data>
    </ng-container>
    <ng-container
      *ngIf="
            tableLoaded &&
            tableStats.total > 0 &&
            tableListData.records?.length > 0
          "
    >
      <table>
        <thead>
        <tr>
          <th class="cb_th select-all-cb">
            <input
              type="checkbox"
              id="select_all_users"
              [checked]="isAllRecordsOfCurrentPageSelected()"
              (change)="
                      toggleAllRecordsOfCurrentPageSelections(
                        $event.target.checked
                      )
                    "
            />
          </th>
          <th class="sl_no_th">Sl No.</th>
          <th
            class="name_th sortable"
            [ngClass]="{ sorted: sortColumn === 'name' }"
            (click)="sortData('name')"
          >
            <div class="icon_cover">
              Bank Name
              <app-svg-icon
                [path]="'assets/icons/sort_icon.svg'"
              ></app-svg-icon>
            </div>
          </th>
          <th
            class="code_th sortable"
            [ngClass]="{ sorted: sortColumn === 'code' }"
            (click)="sortData('code')"
          >
            <div class="icon_cover">
              Bank Code
              <app-svg-icon
                [path]="'assets/icons/sort_icon.svg'"
              ></app-svg-icon>
            </div>
          </th>

          <th
            class="status_th sortable"
            [ngClass]="{ sorted: sortColumn === 'active' }"
            (click)="sortData('active')"
          >
            <div class="icon_cover">
              Status
              <app-svg-icon
                [path]="'assets/icons/sort_icon.svg'"
              ></app-svg-icon>
            </div>
          </th>
          <th class="ifsc_code_th">IFSC Code</th>
          <th class="branch_th">Branch</th>

          <th
            class="created_date_th sortable"
            [ngClass]="{ sorted: sortColumn === 'createdAt' }"
            (click)="sortData('createdAt')"
          >
            <div class="icon_cover">
              Created date
              <app-svg-icon
                [path]="'assets/icons/sort_icon.svg'"
              ></app-svg-icon>
            </div>
          </th>

          <th
            class="modified_date_th sortable"
            [ngClass]="{ sorted: sortColumn === 'modifiedAt' }"
            (click)="sortData('modifiedAt')"
          >
            <div class="icon_cover">
              Modified date
              <app-svg-icon
                [path]="'assets/icons/sort_icon.svg'"
              ></app-svg-icon>
            </div>
          </th>

          &lt;!&ndash; <th
              class="createdBy_th sortable"
              [ngClass]="{ sorted: sortColumn === 'createdBy' }"
              (click)="sortData('createdBy')"
            >
              <div class="icon_cover">
                Created By
                <app-svg-icon
                  [path]="'assets/icons/sort_icon.svg'"
                ></app-svg-icon>
              </div>
            </th> &ndash;&gt;
          <th
            class="modifiedBy_th sortable"
            [ngClass]="{ sorted: sortColumn === 'modifiedBy' }"
            (click)="sortData('modifiedBy')"
          >
            <div class="icon_cover">
              Modified By
              <app-svg-icon
                [path]="'assets/icons/sort_icon.svg'"
              ></app-svg-icon>
            </div>
          </th>

          <th class="table_menu_th">
            &lt;!&ndash; <div class="icon_cover plus_icon">
                  <app-svg-icon
                    [path]="'assets/icons/plus_icon.svg'"
                  ></app-svg-icon>
                </div> &ndash;&gt;
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
          *ngFor="let record of tableListData.records; let i = index"
          [ngClass]="isSelected(record) ? 'selected_row' : ''"
        >
          <td class="cb_td row-select-cb">
            <input
              type="checkbox"
              [id]="'user_sl_no_' + i + 1"
              [checked]="isSelected(record)"
              (change)="toggleSelection(record)"
            />
          </td>
          <td class="sl_no_td">
            {{ record.slNo }}
          </td>
          <td class="name_td" [title]="record.name">
                  <span
                    [innerHTML]="record.name | highlight : searchTerm"
                  ></span>
          </td>
          <td class="code_td" [title]="record.code">
            {{ record.code }}
          </td>
          <td class="status_td">
            <div
              class="icon_cover"
              [ngClass]="record.active ? 'active' : 'inactive'"
            >
              <div class="badge_status">
                <app-svg-icon
                  [path]="'assets/icons/circle_icon.svg'"
                ></app-svg-icon>
                {{ record.active ? "Active" : "Inactive" }}
              </div>
            </div>
          </td>
          <td class="ifsc_code_td" [title]="record.ifscCode">{{ record.ifscCode }}</td>
          <td class="branch_td" [title]="record.branch">{{ record.branch }}</td>
          <td class="created_date_td">
            {{ record.createdAt | date : "dd/MM/yy hh:mm a" }}
          </td>
          <td class="modified_date_td">
            {{ record.modifiedAt | date : "dd/MM/yy hh:mm a" }}
          </td>
          &lt;!&ndash; <td class="createdBy_td">
              {{ record.createdBy || "N/A" }}
            </td> &ndash;&gt;
          <td class="modifiedBy_td">
            {{ record.modifiedBy || "N/A" }}
          </td>
          <td class="table_menu_td">
            <div class="menu_component">
              <app-table-menu
                [options]="menuOptions"
                [isOpen]="openMenuIndex === i"
                (toggle)="handleMenuToggle(i)"
                (optionSelected)="handleOptionSelected(record, $event)"
                [tableRowData]="record"
              >
              </app-table-menu>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </ng-container>
    <ng-container
      *ngIf="
            tableLoaded &&
            tableStats.total > 0 &&
            tableListData.records?.length === 0
          "
    >
      <app-no-data-found [msg]="'No results found !'"></app-no-data-found>
    </ng-container>
  </div>
  <div class="pagination" [hidden]="tableListData.totalCount === 0">
    &lt;!&ndash;        <span class="info">&ndash;&gt;
    &lt;!&ndash;          Showing&ndash;&gt;
    &lt;!&ndash;          {{&ndash;&gt;
    &lt;!&ndash;            currentTablePage === 1&ndash;&gt;
    &lt;!&ndash;              ? 1&ndash;&gt;
    &lt;!&ndash;              : currentTablePage * rowsPerPage + 1 - rowsPerPage&ndash;&gt;
    &lt;!&ndash;          }}&ndash;&gt;
    &lt;!&ndash;          to&ndash;&gt;
    &lt;!&ndash;          {{&ndash;&gt;
    &lt;!&ndash;            currentTablePage * rowsPerPage > tableListData.totalCount&ndash;&gt;
    &lt;!&ndash;              ? tableListData.totalCount&ndash;&gt;
    &lt;!&ndash;              : currentTablePage * rowsPerPage&ndash;&gt;
    &lt;!&ndash;          }}&ndash;&gt;
    &lt;!&ndash;          of {{ tableListData.totalCount }} users&ndash;&gt;
    &lt;!&ndash;        </span>&ndash;&gt;
    &lt;!&ndash;      <div class="buttons">&ndash;&gt;
    &lt;!&ndash;        <button&ndash;&gt;
    &lt;!&ndash;          class="previous_btn"&ndash;&gt;
    &lt;!&ndash;          (click)="previousPage()"&ndash;&gt;
    &lt;!&ndash;          [disabled]="currentTablePage === 1"&ndash;&gt;
    &lt;!&ndash;          title="Previous Page"&ndash;&gt;
    &lt;!&ndash;        >&ndash;&gt;
    &lt;!&ndash;          <app-svg-icon [path]="'assets/icons/left-arrow.svg'"></app-svg-icon>&ndash;&gt;
    &lt;!&ndash;        </button>&ndash;&gt;

    &lt;!&ndash;        &lt;!&ndash; <button *ngIf="getVisiblePages()[0] > 1" disabled>...</button>&ndash;&gt;

    &lt;!&ndash;        <button&ndash;&gt;
    &lt;!&ndash;          *ngFor="let page of getVisiblePages()"&ndash;&gt;
    &lt;!&ndash;          (click)="goToPage(page)"&ndash;&gt;
    &lt;!&ndash;          [class.active]="currentPage === page"&ndash;&gt;
    &lt;!&ndash;        >&ndash;&gt;
    &lt;!&ndash;          {{ page }}&ndash;&gt;
    &lt;!&ndash;        </button>&ndash;&gt;

    &lt;!&ndash;        <button&ndash;&gt;
    &lt;!&ndash;          *ngIf="getVisiblePages().slice(-1)[0] < totalTablePages"&ndash;&gt;
    &lt;!&ndash;          disabled&ndash;&gt;
    &lt;!&ndash;        >&ndash;&gt;
    &lt;!&ndash;          ...&ndash;&gt;
    &lt;!&ndash;        </button> &ndash;&gt;&ndash;&gt;

    &lt;!&ndash;        &lt;!&ndash; <div class="buttons_scroll">&ndash;&gt;
    &lt;!&ndash;          <button&ndash;&gt;
    &lt;!&ndash;            *ngFor="&ndash;&gt;
    &lt;!&ndash;              let page of [].constructor(totalTablePages);&ndash;&gt;
    &lt;!&ndash;              let i = index&ndash;&gt;
    &lt;!&ndash;            "&ndash;&gt;
    &lt;!&ndash;            (click)="goToPage(i + 1)"&ndash;&gt;
    &lt;!&ndash;            [class.active]="currentPage === i + 1"&ndash;&gt;
    &lt;!&ndash;          >&ndash;&gt;
    &lt;!&ndash;            {{ i + 1 }}&ndash;&gt;
    &lt;!&ndash;          </button>&ndash;&gt;
    &lt;!&ndash;        </div> &ndash;&gt;&ndash;&gt;

    &lt;!&ndash;        <ng-container *ngIf="totalTablePages">&ndash;&gt;
    &lt;!&ndash;          <button&ndash;&gt;
    &lt;!&ndash;            *ngFor="let page of [].constructor(totalTablePages); let i = index"&ndash;&gt;
    &lt;!&ndash;            (click)="goToPage(i + 1)"&ndash;&gt;
    &lt;!&ndash;            [class.active]="currentTablePage === i + 1"&ndash;&gt;
    &lt;!&ndash;          >&ndash;&gt;
    &lt;!&ndash;            {{ i + 1 }}&ndash;&gt;
    &lt;!&ndash;          </button>&ndash;&gt;
    &lt;!&ndash;        </ng-container>&ndash;&gt;

    &lt;!&ndash;        <button&ndash;&gt;
    &lt;!&ndash;          class="next_btn"&ndash;&gt;
    &lt;!&ndash;          (click)="nextPage()"&ndash;&gt;
    &lt;!&ndash;          [disabled]="currentTablePage === totalTablePages"&ndash;&gt;
    &lt;!&ndash;          title="Next Page"&ndash;&gt;
    &lt;!&ndash;        >&ndash;&gt;
    &lt;!&ndash;          <app-svg-icon [path]="'assets/icons/right-arrow.svg'"></app-svg-icon>&ndash;&gt;
    &lt;!&ndash;        </button>&ndash;&gt;
    &lt;!&ndash;      </div>&ndash;&gt;

    <div class="button_wrapper_left" (click)="previousPage()">
      <button><img src="assets/icons/left-arrow.svg"> Previous</button>
    </div>
    <ng-container *ngIf="totalTablePages" class="page_numbers">
      <div
        *ngFor="let page of getDisplayedPages()"
        (click)="page !== '...' ? goToPage(page) : null"
        [class.page_number]="page !== '...'"
        [class.ellipsis]="page === '...'"
        [class.active]="currentTablePage === page"
      >
        {{ page }}
      </div>
    </ng-container>
    <div class="button_wrapper_right" (click)="nextPage()">
      <button>Next <img src="assets/icons/right-arrow.svg"></button>
    </div>

    &lt;!&ndash;    <span class="info"> Per page:</span>&ndash;&gt;
    &lt;!&ndash;    <select&ndash;&gt;
    &lt;!&ndash;      id="rowsPerPage"&ndash;&gt;
    &lt;!&ndash;      class="row-options"&ndash;&gt;
    &lt;!&ndash;      #rowOptions&ndash;&gt;
    &lt;!&ndash;      (change)="updateRowsPerPage(rowOptions.value)"&ndash;&gt;
    &lt;!&ndash;    >&ndash;&gt;
    &lt;!&ndash;      <option&ndash;&gt;
    &lt;!&ndash;        class="option"&ndash;&gt;
    &lt;!&ndash;        *ngFor="let option of rowsPerPageOptions"&ndash;&gt;
    &lt;!&ndash;        [value]="option"&ndash;&gt;
    &lt;!&ndash;      >&ndash;&gt;
    &lt;!&ndash;        {{ option }}&ndash;&gt;
    &lt;!&ndash;      </option>&ndash;&gt;
    &lt;!&ndash;    </select>&ndash;&gt;
  </div>
</div>-->
  <app-tos-table
    [stats]="tableStats"
    [showTableStats]="true"
    [tableMeta]="tableMeta"
    [tableMenuOptions]="menuOptions"
    [(dataListRequest)]="tableListRequest"
    [dataListResponse]="tableListData"
    [(selectedItems)]="selectedItems"
    (tosTableAction)="getTableList()"
    (tableMenuOptionSelected)="handleOptionSelected($event)"
  ></app-tos-table>

