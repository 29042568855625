import { ChangeDetectorRef,Component, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { NgFor } from '@angular/common';

@Component({
  selector: 'app-horizontal-tabs',
  standalone: true,
  imports: [NgFor],
  templateUrl: './horizontal-tabs.component.html',
  styleUrl: './horizontal-tabs.component.scss'
})
export class HorizontalTabsComponent {
  @Input() tabs: any[] = [];
  @Input() selectedTab: string = ''; 

  @Output() tabSelected = new EventEmitter<string>();

    constructor(private cdr: ChangeDetectorRef) {}
  
    ngOnChanges(changes: SimpleChanges) {
      if (changes['selectedTab'] && changes['selectedTab'].currentValue) {
        this.selectedTab = changes['selectedTab'].currentValue;
        this.cdr.detectChanges();
      }
    }
 

  // ngAfterViewInit() {
  //   if (this.tabs?.length > 0) {
  //     this.selectTab(this.tabs[0].id);
  //     this.cdr.detectChanges();
  //   }
  // }

  selectTab(tabId: string) {
    this.selectedTab = tabId;
    this.tabSelected.emit(tabId);
  }
}
