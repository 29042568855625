<div class="advanced_filter">
  <form [formGroup]="filterForm">
    <div formArrayName="filterConditions" class="filter_conditions">
      <div
        *ngFor="let condition of filterConditions.controls; let i = index"
        [formGroupName]="i"
      >
        <input
          class="alias_holder"
          formControlName="alias"
          placeholder="Alias"
          readonly
        />

        <select
          class="columns_holder"
          formControlName="name"
          (change)="fieldSelection($event.target.value)"
        >
          <option class="disabled_option" value="" disabled selected>
            Select Field
          </option>
          <option *ngFor="let filter of filterFields" [value]="filter.key">
            {{ filter.displayName }}
          </option>
        </select>

        <select class="operators_holder" formControlName="operator">
          <option value="" disabled selected>Select Operator</option>
          <option
            *ngFor="let operator of operators"
            [value]="operator.operatorKey"
          >
            {{ operator.displayName }}
          </option>
        </select>

        <div class="value_holder" formArrayName="value">
          <input
            *ngFor="
              let value of condition.get('value').controls;
              let vi = index
            "
            [formControlName]="vi"
            placeholder="Enter field value"
          />
        </div>

        <div class="remove_condition_icon" (click)="removeCondition(i)">
          <app-svg-icon [path]="'assets/icons/close.svg'"></app-svg-icon>
        </div>
      </div>
    </div>

    <div class="centered_flex_container">
      <div class="add_condition" (click)="addCondition()">
        <app-svg-icon [path]="'assets/icons/plus_icon.svg'"></app-svg-icon>
        <span>Add condition</span>
      </div>
    </div>

    <!-- <div
      class="final_expression"
      formArrayName="finalExpression"
      *ngIf="finalExpression.controls.length > 1"
    >
      <ng-container
        *ngFor="let expr of finalExpression.controls; let i = index"
      >
        <div [formGroupName]="i">
          <select
            *ngIf="expr.get('type')?.value === 'operator'"
            [value]="expr.get('value')?.value"
            (change)="onOperatorChange(i, $event.target.value)"
          >
            <option *ngFor="let op of logicalOperators" [value]="op">
              {{ op }}
            </option>
          </select>
          <span
            class="alias_in_expression"
            *ngIf="expr.get('type')?.value === 'alias'"
          >
            {{ expr.get("value")?.value }}
          </span>
        </div>
      </ng-container>
    </div> -->

    <div class="expression_area">
      <textarea
        name="finalExpression"
        id="finalExpression"
        formControlName="expression"
        rows="2"
      >
      </textarea>
    </div>
  </form>

  <div class="fixed_modal_footer">
    <!-- <div>
      <p>Expression: {{ filterForm.get("expression")?.value }}</p>
    </div> -->
    <div class="centered_flex_container modal_actions">
      <button
        type="button"
        class="inversed_danger_button"
        (click)="resetFilter()"
      >
        Reset
      </button>
      <button type="button" class="secondary_button" (click)="close()">
        Cancel
      </button>
      <button type="submit" class="primary_button" (click)="applyFilter()">
        Apply
      </button>
    </div>
  </div>
</div>
