import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { ProgressLoaderService } from '../services/progress-loader.service';

export const progressLoaderInterceptor: HttpInterceptorFn = (req, next) => {
  const progressLoaderService = inject(ProgressLoaderService);

  // Show the loader before the request
  progressLoaderService.start();

  return next(req).pipe(
    // Hide the loader once the response is received or an error occurs
    finalize(() => progressLoaderService.complete())
  );
};
