import { EditableTableHeader } from "./editable-table-header.interface";
import { DynamicForm } from "./dynamic-form";

export interface TableMeta {
  name: string;
  uniqueColumns?: string[],
  columns: ColumnDto[];
  styleConfig?: StyleConfig;
}

export interface ColumnDto {
  fieldName: string;
  displayName: string;
  hidden?: boolean;
  dataType: string;
  hasLookup?: boolean;
  lookupDetail?: LookupDetail;
}

export interface LookupDetail {
  lookupObjectName: string;
  lookupFieldName: string;
  defaultResolutionField?: string;
}

export interface StyleConfig {
  columns: {
    fieldName: string;
    styleConfigs: StyleConfigValue[];
  }[];
}

export interface StyleConfigValue {
  value: any;
  iconPath?: string;
  className?: string;
  style?: any;
}

export enum TableDataType {
  STRING = "STRING",
  NUMBER = "NUMBER",
  BOOLEAN = "BOOLEAN",
  DATETIME = "DATETIME",
  ARRAY = "ARRAY",
  JSON = "JSON"
}
