<div class="container">
  <div class="horizontal_tabs">
    <div class="tabs">
      <div class="tab">
        <div class="tab_name" [class.tab_name_active]="selectedTab === 'users'" (click)="selectTab('users')">Users</div>
        <div class="bottom_border" [class.bottom_border_active]="selectedTab === 'users'"
             (click)="selectTab('users')"></div>
      </div>

      <div class="tab">
        <div class="tab_name" [class.tab_name_active]="selectedTab === 'roles'" (click)="selectTab('roles')">Roles &
          Permissions
        </div>
        <div class="bottom_border" [class.bottom_border_active]="selectedTab === 'roles'"
             (click)="selectTab('roles')"></div>
      </div>

    </div>
    <div class="divider"></div>
  </div>

    @if (selectedTab === 'users') {
      <app-user-management
        [hidden]="selectedTab !== 'users'"
      ></app-user-management>
    } @else if (selectedTab === 'roles') {
      <app-user-roles-permissions
        [hidden]="selectedTab !== 'roles'"
      ></app-user-roles-permissions>
    }
  </div>
