export interface FieldInfo {
    name: string;
    displayLabel: string;
    dataType: string;
    hasLookup?: boolean;
    searchConfig?: any;
    supportedOperators?: ComparisonOperator[];
    defaultValue?: any;
    readonly?: boolean;
    deletable?: boolean;
}

export const ComparisonOperator = {
    STARTS_WITH: {label: "Starts With", key: "STARTS_WITH"},
    ENDS_WITH: {label: "Ends With", key: "ENDS_WITH"},
    EQ: {label: "Equals", key: "EQ"},
    EQ_CIS: {label: "Equals", key: "EQ_CIS"},
    NE: {label: "Not Equals", key: "NE"},
    NE_CIS: {label: "Not Equals", key: "NE_CIS"},
    IN: {label: "In", key: "IN"},
    NOT_IN: {label: "Not In", key: "NOT_IN"},
    IS_NULL: {label: "Is Null", key: "IS_NULL"},
    IS_NOT_NULL: {label: "Is Not Null", key: "IS_NOT_NULL"},
    LT: {label: "Less Than", key: "LT"},
    GT: {label: "Greater Than", key: "GT"}

} as const;
export type ComparisonOperator = typeof ComparisonOperator[keyof typeof ComparisonOperator];

export function getOperatorsByKey(operatorKeys: any[]) {
    if (operatorKeys && operatorKeys.length > 0) {
        const keySet = new Set(operatorKeys);
        return Object.values(ComparisonOperator).filter(operator => keySet.has(operator.key));
    }
    return null;
}