<div class="container">
    <div class="header header_with_back_button">
      <app-back-button></app-back-button>
      <span>
        @if (readOnlyMode) { View } @else if(masterEditingEnabled) { Edit } @else
        {Create New} Bank
      </span>
    </div>

    <div class="container-content">
      <form [formGroup]="masterForm">
        <div class="section">
          <div class="head">Basic Information</div>
          <div class="fields">
            <div class="grid-container">
              <div class="form-group">
                <label for="ifscCode" class="required_field_label" #formField
                >IFSC Code</label
                >
                <input
                  id="ifscCode"
                  name="ifscCode"
                  formControlName="ifscCode"
                  type="text"
                  placeholder="Enter IFSC Code"
                  required
                  autocomplete="ifscCode"
                  (blur)="getBankDetailsByIfscCode()"
                  [ngClass]="{
                    form_error:
                      masterForm.get('ifscCode')?.invalid &&
                      masterForm.get('ifscCode')?.touched &&
                      masterForm.get('ifscCode')?.errors
                  }"
                />
                <div
                  class="form_validation_error"
                  *ngIf="
                    masterForm.get('ifscCode')?.invalid &&
                    masterForm.get('ifscCode')?.touched
                  "
                >
                  <small *ngIf="masterForm.get('ifscCode')?.errors?.['required']"
                  >IFSC Code is required</small
                  >
                </div>
              </div>
              <div class="form-group">
                <label for="name" class="required_field_label" #formField
                  >Bank Name</label
                >
                <input
                  id="name"
                  name="name"
                  formControlName="name"
                  type="text"
                  placeholder="Enter name"
                  required
                  autocomplete="name"
                  [ngClass]="{
                    form_error:
                      masterForm.get('name')?.invalid &&
                      masterForm.get('name')?.touched &&
                      masterForm.get('name')?.errors
                  }"
                />
                <div
                  class="form_validation_error"
                  *ngIf="
                    masterForm.get('name')?.invalid &&
                    masterForm.get('name')?.touched
                  "
                >
                  <small *ngIf="masterForm.get('name')?.errors?.['required']"
                    >Name is required</small
                  >
                </div>
              </div>
              <div class="form-group">
                <label for="code" class="required_field_label" #code
                  >Bank Code</label
                >
                <input
                  id="code"
                  name="code"
                  formControlName="code"
                  type="text"
                  placeholder="Enter Code"
                  required
                  autocomplete="code"
                  [ngClass]="{
                    form_error:
                      masterForm.get('code')?.invalid &&
                      masterForm.get('code')?.touched &&
                      masterForm.get('code')?.errors
                  }"
                />
                <div
                  class="form_validation_error"
                  *ngIf="
                    masterForm.get('code')?.invalid &&
                    masterForm.get('code')?.touched
                  "
                >
                  <small *ngIf="masterForm.get('code')?.errors?.['required']"
                    >Code is required</small
                  >
                </div>
              </div>
              <div class="form-group">
                <label for="branch" class="" #formField
                >Branch</label
                >
                <input
                  id="branch"
                  name="branch"
                  formControlName="branch"
                  type="text"
                  placeholder="Enter Branch"
                  autocomplete="branch"
                />
              </div>
            </div>
          </div>
        </div>




      </form>
    </div>
    <div class="footer_actions">
      <ng-container *ngIf="readOnlyMode">
        <button type="button" class="secondary_button" (click)="goBack()">
          Back
        </button>
      </ng-container>
      <ng-container *ngIf="!readOnlyMode">
        <button type="button" class="secondary_button" (click)="goBack()">
          Cancel
        </button>
        <button
          type="button"
          class="primary_button"
          (click)="submitMasterForm()"
        >
          @if(masterEditingEnabled){ Update }@else { Save }
        </button>
      </ng-container>
    </div>

  </div>
