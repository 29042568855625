import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function selectValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
  
      if (
        value === null || 
        value === undefined || 
        (Array.isArray(value) && value.length === 0) || 
        (!Array.isArray(value) && value === '')
      ) {
        return { selectRequired: true, required: true };
      }
      
      return null; // Valid selection
    };
  }
