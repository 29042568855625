import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormArray,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';
import { AdvancedFilter } from '../../models/advanced-filter.interface';
import { ComparisonOperator, FieldInfo, getOperatorsByKey } from '../../models/advanced-filter-config.interface';
import { AutocompleteDropdownComponent } from '../autocomplete-dropdown/autocomplete-dropdown.component';
import { AutocompleteDropdownComponentValidator } from '../../validators/autoCompleteValidator';
import { MultiSelectComponent } from '../select/multi-select.component';

@Component({
  selector: 'app-advanced-filter-v2',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, SvgIconComponent, AutocompleteDropdownComponent, MultiSelectComponent],
  templateUrl: './advanced-filter-v2.component.html',
  styleUrl: './advanced-filter-v2.component.scss',
})
export class AdvancedFilterComponentV2 {
  @Input() appliedFilters!: AdvancedFilter;
  @Input() filterFields!: FieldInfo[];

  filterForm: FormGroup;
  aliasCounter = 0;

  operatorsMap: { [key: string]: ComparisonOperator[] } = {
    STRING: [ComparisonOperator.STARTS_WITH, ComparisonOperator.ENDS_WITH, ComparisonOperator.EQ_CIS, ComparisonOperator.NE_CIS],
    NUMBER: [ComparisonOperator.EQ, ComparisonOperator.NE, ComparisonOperator.LT, ComparisonOperator.GT],
    BOOLEAN: [ComparisonOperator.EQ],
    PICKLIST: [ComparisonOperator.IN, ComparisonOperator.NOT_IN],
    LOOKUP: [ComparisonOperator.EQ, ComparisonOperator.NE],
  };

  logicalOperators = ['AND', 'OR'];

  fieldOperatorMap : Map<String, ComparisonOperator[] > = new Map();
  fieldInfoMap : Map<String, FieldInfo > = new Map();

  constructor(private fb: FormBuilder) {
    this.filterForm = this.fb.group({
      filterConditions: this.fb.array([]),
      finalExpression: this.fb.array([]),
      expression: [''],
    });
  }

  closeModal!: (params?: any) => void;

  close(params?: any) {
    if (this.closeModal) {
      this.closeModal(params);
    }
  }

  ngOnInit() {
    if (this.appliedFilters) {
      this.setFilters(this.appliedFilters);
    } else {
      this.addCondition();
    }

    this.filterForm.get('expression')?.valueChanges.subscribe((value) => {
      console.log('Textarea updated:', value);
    });

    this.populateFieldOperators();
  }

  get filterConditions(): FormArray {
    return this.filterForm.get('filterConditions') as FormArray;
  }

  getFieldInfo(fieldName: string) {
    return this.fieldInfoMap.get(fieldName);
  }

  get finalExpression(): FormArray {
    return this.filterForm.get('finalExpression') as FormArray;
  }

  private populateFieldOperators() {
    this.filterFields.forEach((fieldInfo: FieldInfo) => {
      const dataType = fieldInfo?.hasLookup ? "LOOKUP" : fieldInfo?.dataType || 'STRING';
      const supportedOperators = getOperatorsByKey(fieldInfo?.supportedOperators || []) || this.operatorsMap[dataType];
      this.fieldOperatorMap.set(fieldInfo.name, supportedOperators);
      this.fieldInfoMap.set(fieldInfo.name, fieldInfo);
    });

    console.log(this.fieldOperatorMap);
  } 

  generateAlias(): string {
    const baseChar = 'A'.charCodeAt(0);
    let alias = '';
    let counter = this.aliasCounter;

    while (counter >= 0) {
      alias = String.fromCharCode(baseChar + (counter % 26)) + alias;
      counter = Math.floor(counter / 26) - 1;
    }

    this.aliasCounter++;
    return alias;
  }

  addCondition() {
    const alias = this.generateAlias();
    this.filterConditions.push(
      this.fb.group({
        name: ['', Validators.required],
        alias: [{ value: alias, disabled: true }, Validators.required],
        operator: [{ value: '', disabled: true }, Validators.required],
        value: this.fb.array([this.fb.control('', Validators.required)]),
        //value: [{ value: '', disabled: true }, Validators.required],
      })
    );

    if (this.finalExpression.length > 0) {
      this.finalExpression.push(
        this.fb.group({
          type: 'operator',
          value: 'AND', // Default operator
        })
      );
    }

    this.finalExpression.push(
      this.fb.group({
        type: 'alias',
        value: alias,
      })
    );

    this.updateExpressionString();
  }

  removeCondition(index: number) {
    const alias = this.filterConditions.at(index).get('alias')?.value;
    this.filterConditions.removeAt(index);

    // Find the alias in finalExpression
    const aliasIndex = this.finalExpression.controls.findIndex(
      (group) =>
        group.get('value')?.value === alias &&
        group.get('type')?.value === 'alias'
    );

    if (aliasIndex !== -1) {
      this.finalExpression.removeAt(aliasIndex); // Remove alias

      // Remove preceding logical operator if exists (and not the first element)
      if (
        aliasIndex > 0 &&
        this.finalExpression.at(aliasIndex - 1)?.get('type')?.value ===
          'operator'
      ) {
        this.finalExpression.removeAt(aliasIndex - 1);
      }
    }

    this.updateAliases();
    this.updateExpressionString();
  }

  // updateExpressionString() {
  //   console.log('updateExpressionString');
  //   const expressionString = this.finalExpression.controls
  //     .map((group) => group.get('value')?.value)
  //     .join(' ');
  //   this.filterForm.get('expression')?.setValue(expressionString);

  //   //   let processedExpressionString;
  //   //   if (expressionString.length) {
  //   //     processedExpressionString = '( ' + expressionString + ' )';
  //   //   }
  //   //   this.filterForm.get('expression')?.setValue(processedExpressionString);
  // }

  // // updateAliases() {
  // //   this.aliasCounter = 0;
  // //   this.filterConditions.controls.forEach((group) => {
  // //     group.get('alias')?.setValue(this.generateAlias());
  // //   });
  // // }

  updateExpressionString() {
    const expressionString = this.finalExpression.controls
    .map((group) => group.get('value')?.value)
    .join(' ');

    // Set the updated expression to the filter form
    this.filterForm.get('expression')?.setValue(expressionString);
    
    // Optionally wrap in parentheses for clearer grouping
    const processedExpressionString = `(${expressionString})`;
    this.filterForm.get('expression')?.setValue(processedExpressionString);
  }

  updateAliases() {
    let currentAliasIndex = 0;
  
  // Update aliases for filterConditions
    this.filterConditions.controls.forEach((group, index) => {
      const newAlias = this.generateAlias();
      group.get('alias')?.setValue(newAlias);
    
      // Update alias in finalExpression too
      const aliasIndex = this.finalExpression.controls.findIndex(
        (exprGroup) => exprGroup.get('value')?.value === group.get('alias')?.value
      );
      
      if (aliasIndex !== -1) {
        this.finalExpression.at(aliasIndex).get('value')?.setValue(newAlias);
      }

      currentAliasIndex++;
    });

    // Rebuild the expression string after alias changes
    this.updateExpressionString();
  }

  applyFilter() {
    this.filterForm.markAllAsTouched;
    if (this.filterForm.invalid) return;
    //this.cleanUnfilledConditions(); // Clean unfilled conditions before applying filter
    const filterData = this.getProcessedFilterData();
    console.log(filterData);
    this.closeModal({
      refresh: true,
      rawFilterData: this.filterForm.getRawValue(),
      filterData,
    });
  }

  getProcessedFilterData() {
    // Get the raw values of the form
    const filterData = this.filterForm.getRawValue();
    
    // Iterate through filterConditions and process each condition
    filterData.filterConditions = filterData.filterConditions.map((condition: any) => {
      const fieldInfo = this.fieldInfoMap.get(condition.name);
      if (fieldInfo?.hasLookup) {
        condition.value = condition.value.map((val: any) => val.key);
      } else if (condition.operator === 'IN' || condition.operator === 'NOT_IN') {
        condition.value = condition.value?.get(0)?.split(",");
      }
      return condition;
    });
  
    return filterData;
  }

  // cleanUnfilledConditions() {
  //   const filledConditions = this.filterConditions.controls.filter(
  //     (control, index) => {
  //       const name = control.get('name')?.value;
  //       const operator = control.get('operator')?.value;
  //       const valuesArray = control.get('value') as FormArray;
  //       const values = valuesArray.value;

  //       if (name && operator && values.every((value: string) => !!value)) {
  //         return true;
  //       } else {
  //         // Remove corresponding alias and operator from the finalExpression
  //         const alias = control.get('alias')?.value;
  //         let aliasIndex = this.finalExpression.controls.findIndex(
  //           (group) =>
  //             group.get('value')?.value === alias &&
  //             group.get('type')?.value === 'alias'
  //         );

  //         if (aliasIndex !== -1) {
  //           this.finalExpression.removeAt(aliasIndex);
  //           if (
  //             aliasIndex > 0 &&
  //             this.finalExpression.at(aliasIndex - 1)?.get('type')?.value ===
  //               'operator'
  //           ) {
  //             this.finalExpression.removeAt(aliasIndex - 1);
  //           }
  //         }

  //         return false;
  //       }
  //     }
  //   );

  //   this.filterForm.setControl(
  //     'filterConditions',
  //     this.fb.array(filledConditions)
  //   );
  //   this.updateExpressionString(); // Update the expression string after cleaning
  // }

  setFilters(filterData: any) {
    console.log('setting filters now');
    const filterConditions = filterData.filterConditions || [];
    const expressionArray = filterData.expression
      ? filterData.expression.split(' ')
      : [];

    // Clear existing filter conditions and final expression
    this.filterConditions.clear();
    this.finalExpression.clear();
    this.aliasCounter = 0;

    // Track the maximum alias index found in filter data
    let maxAliasIndex = -1;

    // Loop through each filter condition from the provided filterData
    filterConditions.forEach((condition: any) => {
      this.filterConditions.push(
        this.fb.group({
          name: [condition.name, Validators.required],
          alias: [
            { value: condition.alias, disabled: true },
            Validators.required,
          ],
          operator: [condition.operator, Validators.required],
          value: this.fb.array(condition.value || [], Validators.required)
        })
      );

      // Push the alias to the finalExpression
      this.finalExpression.push(
        this.fb.group({
          type: 'alias',
          value: condition.alias,
        })
      );

      // Determine the alias index from its last character if it's numeric
      const aliasLastChar = condition.alias.slice(-1);
      const aliasIndex = isNaN(Number(aliasLastChar))
        ? condition.alias.charCodeAt(0) - 'A'.charCodeAt(0)
        : Number(aliasLastChar) +
          26 * (condition.alias.charCodeAt(0) - 'A'.charCodeAt(0));

      if (aliasIndex > maxAliasIndex) {
        maxAliasIndex = aliasIndex;
      }
    });

    // Loop through the expressionArray and fill finalExpression with operators
    for (let i = 0; i < expressionArray.length; i++) {
      if (this.logicalOperators.includes(expressionArray[i])) {
        this.finalExpression.insert(
          i,
          this.fb.group({
            type: 'operator',
            value: expressionArray[i],
          })
        );
      }
    }

    // Set aliasCounter to the next available alias index after maxAliasIndex
    this.aliasCounter = maxAliasIndex + 1;

    // Update the final expression string
    this.updateExpressionString();
  }

  // Helper function to set aliasCounter based on the last alias used
  incrementAliasCounter(alias: string) {
    const alphabet = alias.replace(/[0-9]/g, '');
    const number = parseInt(alias.replace(alphabet, ''), 10) || 0;
    this.aliasCounter =
      alphabet.charCodeAt(0) - 'a'.charCodeAt(0) + number * 26 + 1;
  }

  onOperatorChange(index: number, operator: string) {
    this.finalExpression.at(index).get('value')?.setValue(operator);
    this.updateExpressionString();
  }

  resetFilter() {
    console.log('clearFilters');
    this.closeModal({
      refresh: true,
      resetAll: true,
    });
  }

  fieldSelection(selectedField: string, index: number) {
    const condition = this.filterConditions.at(index);
    const fieldInfo = this.filterFields.find(field => field.name === selectedField);    
    
    if (fieldInfo) {
      condition.get('operator')?.enable();
      condition.get('operator')?.setValue('');

      const valueArray = condition.get('value') as FormArray;
      valueArray.clear();
      this.addValueControls(valueArray, fieldInfo);
    } else {
      condition.get('operator')?.disable();
      const valueArray = condition.get('value') as FormArray;
      valueArray.clear();
    }
    console.log("post field selection");
  }

  addValueControls(valueArray: FormArray, fieldInfo: FieldInfo) {
    switch (fieldInfo.dataType) {
      case 'STRING':
        if (fieldInfo.hasLookup) {
          valueArray.push(this.fb.control('', AutocompleteDropdownComponentValidator()));
        } else {
          valueArray.push(this.fb.control('', Validators.required));
        }
        break;
      case 'NUMBER':
        valueArray.push(this.fb.control(null, Validators.required));
        break;
      case 'BOOLEAN':
        valueArray.push(this.fb.control(false, Validators.required));
        break;
      case 'PICKLIST':
        valueArray.push(this.fb.control('', Validators.required));
        break;
      default:
        valueArray.push(this.fb.control('', Validators.required));
    }
  }
}
