<div class="container">
  <div class="header header_with_back_button">
    <app-back-button></app-back-button>
    <span>
      <ng-container *ngIf="readOnlyMode; else editOrCreate">
        View Bank
      </ng-container>
      <ng-template #editOrCreate>
        <ng-container *ngIf="masterEditingEnabled; else createNew">
          Edit Bank
        </ng-container>
        <ng-template #createNew>
          Create New Service
        </ng-template>
      </ng-template>
    </span>
  </div>

  <div class="container-content">
    <form [formGroup]="masterForm">
      <div class="section">
        <div class="head">Basic Information</div>
        <div class="fields">
          <div class="fields">
            <div class="grid-container">
              <div class="form-group">
                <label for="name" class="required_field_label" #formField>
                  Service Name
                </label>
                <input
                  id="name"
                  name="name"
                  formControlName="name"
                  type="text"
                  placeholder="Enter name"
                  required
                  autocomplete="name"
                  [ngClass]="{
                    form_error:
                      masterForm.get('name')?.invalid &&
                      masterForm.get('name')?.touched &&
                      masterForm.get('name')?.errors
                  }"
                />
                <div
                  class="form_validation_error"
                  *ngIf="
                    masterForm.get('name')?.invalid &&
                    masterForm.get('name')?.touched
                  "
                >
                  <small *ngIf="masterForm.get('name')?.errors?.['required']">
                    Name is required
                  </small>
                </div>
              </div>
              <div class="form-group">
                <label for="code" class="required_field_label" #code>
                  Service Code
                </label>
                <input
                  id="code"
                  name="code"
                  formControlName="code"
                  type="text"
                  placeholder="Enter Code"
                  required
                  autocomplete="code"
                  [ngClass]="{
                    form_error:
                      masterForm.get('code')?.invalid &&
                      masterForm.get('code')?.touched &&
                      masterForm.get('code')?.errors
                  }"
                />
                <div
                  class="form_validation_error"
                  *ngIf="
                    masterForm.get('code')?.invalid &&
                    masterForm.get('code')?.touched
                  "
                >
                  <small *ngIf="masterForm.get('code')?.errors?.['required']">
                    Code is required
                  </small>
                </div>
              </div>
              <div class="form-group">
                <label> Service Type </label>
                <select id="serviceType" formControlName="serviceType">
                  @for (serviceType of serviceTypes;track serviceType.value){
                  <option [value]="serviceType.name">{{serviceType.name}}</option>
                  }
                </select>
              </div>
              <div class="form-group">
                <label for="type" #formField>
                  Type
                </label>
                <input
                  id="type"
                  name="type"
                  formControlName="type"
                  type="text"
                  placeholder="Enter Type"
                  autocomplete="type"
                />
              </div>
<!--              TODO: Add autocomplete drop-down here and charge master-->
              <div class="form-group">
                <label for="chargeId" #formField>
                  Charge
                </label>
                <input
                  id="chargeId"
                  name="chargeId"
                  formControlName="chargeId"
                  type="text"
                  placeholder="Enter Charge"
                  autocomplete="chargeId"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="footer_actions">
    <ng-container *ngIf="readOnlyMode">
      <button type="button" class="secondary_button" (click)="goBack()">
        Back
      </button>
    </ng-container>
    <ng-container *ngIf="!readOnlyMode">
      <button type="button" class="secondary_button" (click)="goBack()">
        Cancel
      </button>
      <button
        type="button"
        class="primary_button"
        (click)="submitMasterForm()"
      >
        <ng-container *ngIf="masterEditingEnabled; else save">
          Update
        </ng-container>
        <ng-template #save>Save</ng-template>
      </button>
    </ng-container>
  </div>
</div>
