import { Component, ViewChild, ViewContainerRef } from "@angular/core";
import dynamicFormPoc from "./dynamic-form-poc.json";
import { DynamicFormService } from "../../services/dynamic-form.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { DynamicFormComponent } from "../dynamic-form/dynamic-form.component";
import { DynamicForm } from "../../models/dynamic-form";
import { AccordionTableComponent } from "../accordion-table/accordion-table.component";
import { LoginHistoryComponent } from "../login-history/login-history.component";
import { TableHeaderAndData } from "../../models/table-header-and-data";
import { SelectComponent } from "../select/select.component";
import { LoginComponent } from "../login/login.component";
import { NoDataComponent } from "../no-data/no-data.component";
import { EditableTableComponent } from "../editable-table/editable-table.component";
import { ManageItemFilingComponent } from "../operations/item-filing/manage-item-filing/manage-item-filing.component";
import { InputComponent } from "../input/input.component";
import { FileUploadDialogComponent } from "../operations/igm-desk/file-upload-dialog/file-upload-dialog.component";
import { TosTableComponent } from "../tos-table/tos-table.component";
import { ModalComponent } from "../modal/modal.component";
import { AdvancedFilterComponentV2 } from "../advanced-filter/advanced-filter-v2.component";
import { AdvancedFilterComponent } from "../advanced-filter/advanced-filter.component";
import filterConfig from "./filter-poc.json";
import { TariffTable } from "../finance/tariff-table/tariff-table";

@Component({
  selector: "app-dynamic-form-poc",
  standalone: true,
  imports: [
    DynamicFormComponent,
    AccordionTableComponent,
    TosTableComponent,
    ModalComponent,
    AdvancedFilterComponentV2,
    TosTableComponent,
    TariffTable
  ],
  templateUrl: "./dynamic-form-poc.component.html",
  styleUrl: "./dynamic-form-poc.component.scss"
})
export class DynamicFormPocComponent {
  form!: FormGroup;
  dynamicForm: DynamicForm | undefined;
  tableHeaderAndData!: TableHeaderAndData;
  @ViewChild(AccordionTableComponent) accordionTableComponent!: AccordionTableComponent;

  constructor(
    private dynamicFormService: DynamicFormService,
    private viewContainerRef: ViewContainerRef,
    private fb: FormBuilder
  ) {
    this.form = fb.group({});
  }

  ngOnInit() {
    this.dynamicForm = dynamicFormPoc;
    this.dynamicFormService.addControlsToForm(this.form, this.dynamicForm);
    console.log(this.form);
    this.tableHeaderAndData = {
      headers: [
        {
          fieldName: "id",
          displayName: "ID"
        },
        {
          fieldName: "name",
          displayName: "Name"
        },
        {
          fieldName: "lastName",
          displayName: "Last Name"
        },
        {
          fieldName: "email",
          displayName: "Email"
        },
        {
          fieldName: "phone",
          displayName: "Phone"
        }
      ],
      data: [
        {
          "id": "1TUS5DNMSFGOFXX792KHTXZHK857I0P0ZXQ9",
          "name": "Tejas Thakur",
          "lastName": "Hello"
        },
        {
          "id": "1TUS5DNMSFGOFXX7928ZFGUWVM6EE0L8I7RV",
          "name": "Nitin Dange",
          "lastName": "Hello"
        },
        {
          "id": "1TUS5DNMSFGOFXX792BH0QSIF30ZI342PBDP",
          "name": "Yatin Patil",
          "lastName": "Hello"
        }
      ]
    };

  }

  submitForm() {
    this.form.markAllAsTouched();
    console.log(this.form.value);
    if (!this.form.valid) {
      console.log("Form is invalid");
      return;
    }
  }

  loadAccordionContent(rowIndex: number) {
    this.accordionTableComponent.loadComponent(FileUploadDialogComponent, rowIndex)
  }

  // protected readonly NoDataComponent = NoDataComponent;

    filter() {
      const filterFields = filterConfig.filterFields;
      const modalRef = this.viewContainerRef.createComponent(ModalComponent);
      modalRef.instance.title = 'Advanced Filter';
      const filterDialogRef = modalRef.instance.loadComponent(
        AdvancedFilterComponentV2
      );

      filterDialogRef.setInput('filterFields', filterFields);


      modalRef.instance.close.subscribe((res: any) => {
        if (res && res.refresh) {
          console.log(res.filterData);
        }
        modalRef.destroy();
      });
    }
}
