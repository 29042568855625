{
  "formName": "Create New Charge",
  "formRows": [
    {
      "formGroups": [
        {
          "style": { "gridColumn": "span 3" },
          "control": {
            "label": "name",
            "displayLabel": "Charge Group Name",
            "disabled": false,
            "placeHolder": "Enter Charge Group Name",
            "fieldInfo": { "dataType": "text" },
            "validators": [{ "type": "required", "message": "Charge Group Name is required" }]
          }
        }
      ]
    },
    {
      "formGroups": [
        {
          "style": { "gridColumn": "span 3" },
          "control": {
            "label": "invoiceHeaderTitle",
            "displayLabel": "Invoice Header Title",
            "disabled": false,
            "placeHolder": "Enter Invoice Header Title",
            "fieldInfo": { "dataType": "text" },
            "validators": [{ "type": "required", "message": "Invoice Header Title is required" }]
          }
        },
        {
          "style": {
            "gridColumn": "span 3"
          },
          "control": {
            "label": "operationalProcess",
            "displayLabel": "Operational Process",
            "disabled": false,
            "placedHolder": "Select",
            "fieldInfo": {
              "dataType": "select",
              "options": [
                {
                  "label": "Bonding",
                  "value": "BONDING"
                },
                {
                  "label": "Empty",
                  "value": "EMPTY"
                },
                {
                  "label": "Export",
                  "value": "EXPORT"
                },
                {
                  "label": "Hub",
                  "value": "HUB"
                },
                {
                  "label": "Import",
                  "value": "IMPORT"
                },
                {
                  "label": "SSR",
                  "value": "SSR"
                }
              ]
            },
            "validators": [{ "type": "required", "message": "Operational Process is required" }]
          }
        }
      ]
    },
    {
      "formGroups": [
        {
          "style": { "gridColumn": "span 3" },
          "control": {
            "label": "active",
            "displayLabel": "Active",
            "disabled": false,
            "defaultValue": true,
            "fieldInfo": { "dataType": "boolean" }
          }
        }
      ]
    },
    {
      "formGroups": [
        {
          "style": { "gridColumn": "span 6", "gridRow": "span 3" },
          "control": {
            "label": "description",
            "displayLabel": "Description",
            "disabled": false,
            "placeHolder": "Enter a description...",
            "fieldInfo": { "dataType": "textarea" }
          }
        }
      ]
    }
  ]
}
