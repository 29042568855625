import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { DeterminantDetails } from "../../../../models/determinant-details";
import { FinanceService } from "../../../../services/finance.service";
import { MultiSelectComponent } from "../../../select/multi-select.component";
import { DynamicInputComponent } from "../../../dynamic-form/dynamic-input/dynamic-input.component";
import { NgClass, NgForOf, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from "@angular/common";
import { TableListRequest } from "../../../../models/table-list-request.interface";
import { SvgIconComponent } from "../../../svg-icon/svg-icon.component";
import { AutocompleteDropdownComponent } from "../../../autocomplete-dropdown/autocomplete-dropdown.component";
import { Rule, RuleValue, TariffRuleSet } from "../../../../models/tariff-rule-set";
import { resolve } from "@angular/compiler-cli";
import { LoadingService } from "../../../../services/loading.service";

interface RuleConfig {
  supportedOperators?: any[];
  inputType?: string;
  referenceOptions?: any[];
  referenceValueKey?: string;
  referenceDisplayKey?: string;
  dropdownOptions?: any[];
  numberPlaceholder?: string;
  inputStep?: number;
}

const BOOLEAN_OPTIONS = [
  { text: "True", value: "True" },
  { text: "False", value: "False" }
];

const DEFAULT_TABLE_LIST_REQUEST: TableListRequest = {
  selectFields: ["id", "name", "modifiedAt"],
  orderBy: { ["modifiedAt"]: "desc" },
  includeTotal: true,
  limit: 1000,
  page: 0
};

const DEFAULT_TABLE_LIST_REQUEST_CONTAINER_TYPE: TableListRequest = {
  selectFields: ["id", "type", "modifiedAt"],
  orderBy: { ["modifiedAt"]: "desc" },
  includeTotal: true,
  limit: 1000,
  page: 0
};

@Component({
  selector: "app-tariff-table-rules",
  standalone: true,
  imports: [
    MultiSelectComponent,
    DynamicInputComponent,
    ReactiveFormsModule,
    NgForOf,
    NgSwitchCase,
    NgSwitch,
    NgSwitchDefault,
    SvgIconComponent,
    AutocompleteDropdownComponent,
    NgClass,
    NgIf
  ],
  templateUrl: "./tariff-table-rules.component.html",
  styleUrl: "./tariff-table-rules.component.scss"
})
export class TariffTableRulesComponent {
  ruleConfigs: RuleConfig[] = [];
  readonly booleanOptions = BOOLEAN_OPTIONS;

  filterForm: FormGroup;
  @Input() allDeterminants: DeterminantDetails[] = [];
  @Input() ruleSets: TariffRuleSet[] = [];
  @Output() applyAllButtonClicked = new EventEmitter<any>();

  constructor(
    private fb: FormBuilder,
    private financeService: FinanceService,
    private cdr: ChangeDetectorRef,
    private loadingService: LoadingService
  ) {
    this.filterForm = this.fb.group({
      rules: this.fb.array([])
    });
  }

  ngOnInit() {
    // this.addRule();
  }

  get rules(): FormArray {
    return this.filterForm.get("rules") as FormArray;
  }

  addRule() {
    const ruleGroup = this.fb.group({
      determinant: ["", Validators.required],
      operator: ["", Validators.required],
      values: [{ value: "", disabled: true }, Validators.required]
    });

    ruleGroup.get("operator")?.valueChanges.subscribe(value => {
      value ? ruleGroup.get("values")?.enable() : ruleGroup.get("values")?.disable();
    });

    this.rules.push(ruleGroup);
    this.ruleConfigs.push({});
    return ruleGroup;
  }

  removeRule(index: number) {
    this.rules.removeAt(index);
    this.ruleConfigs.splice(index, 1);
  }

  async onDeterminantSelect(determinant: DeterminantDetails, index: number) {
    await this.handleDeterminantConfig(determinant, index);
  }

  applyRules(ruleSetIndex: number, headerIndex: number, headerType: string) {
    this.filterForm.markAllAsTouched();
    if (!this.filterForm.valid) {
      console.log("Form in invalid");
      return;
    }

    const formattedRules = this.rules.value.map((rule: any, index: number) =>
      this.formatRuleForSubmission(rule, index)
    );

    this.updateRuleSet(headerType, formattedRules, ruleSetIndex, headerIndex);
    this.applyAllButtonClicked.emit(this.ruleSets);
  }

  applyRulesForCommonCriteria() {
    this.filterForm.markAllAsTouched();
    if (!this.filterForm.valid) {
      console.log("Form in invalid");
      return;
    }

    const formattedRules = this.rules.value.map((rule: any, index: number) =>
      this.formatRuleForSubmission(rule, index)
    );

    this.applyAllButtonClicked.emit(formattedRules);
  }

  async patchRulesValue(ruleSetIndex: number, headerIndex: number, headerType: string) {
    const rules = this.getRulesFromSet(ruleSetIndex, headerIndex, headerType);
    if(rules.length === 0) {
      this.addRule(); //add new rule
      return
    }

    this.loadingService.show();
    for (const rule of rules) {
      const ruleIndex: number = rules.indexOf(rule);
      await this.patchRuleValues(rule, ruleIndex);
    }
    this.loadingService.hide();
  }

  async patchRulesValueForCommonCriteria(commonCriteriaRules: Rule[]) {
    if(commonCriteriaRules.length === 0) {
      this.addRule(); //add new rule
      return
    }

    this.loadingService.show()
    for (const rule of commonCriteriaRules) {
      const commonCriteriaIndex: number = commonCriteriaRules.indexOf(rule);
      await this.patchRuleValues(rule, commonCriteriaIndex);
    }
    this.loadingService.hide();
  }

  private async patchRuleValues(rule: Rule, index: number) {
    const ruleGroup = this.addRule();
    const determinant = this.allDeterminants.find(d => d.name === rule.determinant);

    if (!determinant) return;

    await this.handleDeterminantConfig(determinant, index);

    ruleGroup.patchValue({
      determinant: rule.determinant,
      operator: rule.operator,
      values: this.getFormValueForType(rule.values, index)
    });
  }

  private handleDeterminantConfig(details: DeterminantDetails, index: number): Promise<void> {
    return new Promise((resolve, reject) => {
      const valueType = details.valueTypeResponse.valueType;
      const config: RuleConfig = {
        supportedOperators: details.valueTypeResponse.supportedOperators
      };

      switch (valueType) {
        case "REFERENCE":
          this.setupReferenceInput(config, details, index).then(() => resolve()).catch(err => reject(err));
          break;
        case "DROPDOWN":
          this.setupDropdownInput(config, details);
          resolve();
          break;
        case "BOOLEAN":
          config.inputType = "BOOLEAN";
          resolve();
          break;
        case "INTEGER":
          this.setupNumberInput(config, "Enter whole number", 1);
          resolve();
          break;
        case "BIGDECIMAL":
          this.setupNumberInput(config, "Enter decimal number", 0.01);
          resolve();
          break;
        case "FORMULA":
          config.inputType = "TEXT";
          resolve();
          break;
        default:
          config.inputType = "TEXT";
          resolve();
      }

      this.ruleConfigs[index] = config;
    });
  }

  private setupReferenceInput(config: RuleConfig, details: DeterminantDetails, index: number): Promise<void> {
    config.inputType = "REFERENCE";
    return new Promise((resolve, reject) => {
      if(details.name === "CONTAINER_TYPE"){
        this.financeService.getDeterminantValues(details.name, DEFAULT_TABLE_LIST_REQUEST_CONTAINER_TYPE)
          .subscribe({
            next: (options: any) => {
              config.referenceOptions = options.data.records;
              config.referenceValueKey = "id";
              config.referenceDisplayKey = "type";
              resolve();
            },
            error: (err) => {
              console.error("Failed to load reference options", err);
              reject(err);
            }
          });
      } else{
        this.financeService.getDeterminantValues(details.name, DEFAULT_TABLE_LIST_REQUEST)
          .subscribe({
            next: (options: any) => {
              config.referenceOptions = options.data.records;
              config.referenceValueKey = "id";
              config.referenceDisplayKey = "name";
              resolve();
            },
            error: (err) => {
              console.error("Failed to load reference options", err);
              reject(err);
            }
          });
      }

    });
  }

  private setupDropdownInput(config: RuleConfig, details: DeterminantDetails) {
    config.inputType = "DROPDOWN";
    config.dropdownOptions = details.values.map(value => ({ value: value.toString(), text: value.toString() }));
  }

  private setupNumberInput(config: RuleConfig, placeholder: string, step: number) {
    config.inputType = "NUMBER";
    config.numberPlaceholder = placeholder;
    config.inputStep = step;
  }

  private formatRuleForSubmission(rule: any, index: number) {
    const config = this.ruleConfigs[index];
    const valueHandler = this.getValueHandler(config?.inputType);
    return {
      determinant: rule.determinant,
      operator: rule.operator,
      values: valueHandler(rule.values, config)
    };
  }

  private getValueHandler(inputType?: string): (values: any, config?: RuleConfig) => any[] {
    switch (inputType) {
      case "REFERENCE":
        return (values, config) => values.map((id: any) => ({
          value: id,
          text: config?.referenceOptions?.find(opt => opt[config.referenceValueKey!] === id)?.[config.referenceDisplayKey!] || id
        }));
      case "BOOLEAN":
        return (values) => [{
          value: values,
          text: values
        }];
      case "DROPDOWN":
      case "NUMBER":
      case "TEXT":
      default:
        return (values) => [{
          value: values,
          text: values?.toString() || ""
        }];
    }
  }

  private getFormValueForType(values: any[], index: number) {
    const config = this.ruleConfigs[index];
    if (!config) return "";

    switch (config.inputType) {
      case "REFERENCE":
        return values.map(v => v.value);
      case "DROPDOWN":
      case "BOOLEAN":
      case "NUMBER":
      default:
        return values[0].value || "";
    }
  }

  private updateRuleSet(headerType: string, rules: any[], ruleSetIndex: number, headerIndex: number) {
    const target = headerType === "column"
      ? this.ruleSets[ruleSetIndex].cols[headerIndex]
      : this.ruleSets[ruleSetIndex].rows[headerIndex];

    target.rules = [...rules];
  }

  private getRulesFromSet(ruleSetIndex: number, headerIndex: number, headerType: string) {
    return headerType === "column"
      ? this.ruleSets[ruleSetIndex].cols[headerIndex].rules
      : this.ruleSets[ruleSetIndex].rows[headerIndex].rules;
  }
}
