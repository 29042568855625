@if (isExpanded || isSearching) {
  <div class="navbar_header">
    <div class="smart_tos_logo_and_pin">
<!--      <ng-container *ngIf="isSearching">-->
<!--        <img src="assets/icons/back_button.svg" (click)="turnOffSearching()" class="back_btn" alt="">-->
<!--      </ng-container>-->
      <div class="full_logo"><img src="assets/images/smarttos-logo-small.svg">
        <div class="logo_text">Smart</div>
        <span class="tos">TOS</span>
      </div>

      <span class="pin"
            (click)="togglePin()"
            [ngClass]="{
              'pin_active': isPinned
            }"
      ><img src="assets/icons/sb-pin.svg"></span>
    </div>
  </div>
} @else {
  <div class="navbar_header_collapsed">
    <img src="assets/images/smarttos-logo-small.svg">
  </div>
}

