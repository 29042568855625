<div class="modal-backdrop"></div>
<div class="panel" [@slideInOut]="panelState">
  <div class="header">
    <div class="title">{{ title }}</div>
    <app-svg-icon
      class="close_btn"
      (click)="onClose()"
      [path]="'assets/icons/close_icon.svg'"
    ></app-svg-icon>
  </div>
  <div class="divider"></div>

  <div class="body">
    <ng-template #rightPanelContent></ng-template>
  </div>
  @if (showActionBtns) {
    <div class="divider"></div>
    <div class="action_btns">
      <button class="secondary_button" (click)="onClose()">Cancel</button>
      <button class="primary_button" (click)="handlePrimaryBtnClick()">{{primaryActionBtnText}}</button>
    </div>
  }
</div>

