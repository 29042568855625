export const CycleType = {
    IMPORT: {label: "Import", key: "IMPORT"},
    EXPORT: {label: "Export", key: "EXPORT"},
    EMPTY: {label: "Empty", key: "EMPTY"},
    BOND: {label: "Bond", key: "BOND"}
} as const;
export type Cycle = typeof CycleType[keyof typeof CycleType];

export enum InventoryType {
    CONTAINER = "CONTAINER",
    TRUCK = "TRUCK",
    TRAILER = "TRAILER"
}

type GateInOption = {
    label: string;
    cycle: typeof CycleType[keyof typeof CycleType] | null;
    vehicleType: InventoryType[];
};

export const GateInTypes: Record<string, GateInOption> = {
    IMPORT_LOADED_CONTAINER: { label: "Loaded Container", cycle: CycleType.IMPORT, vehicleType: [InventoryType.CONTAINER] },
    IMPORT_EMPTY_CONTAINER: { label: "Empty Container", cycle: CycleType.IMPORT, vehicleType: [InventoryType.CONTAINER] },
    SCAN_AREA_RETURN: { label: "Scan Area Return", cycle: CycleType.IMPORT, vehicleType: [InventoryType.CONTAINER] },
    IMPORT_FCL_DESTUFF_DELIVERY: { label: "FCL Destuff Delivery", cycle: CycleType.IMPORT, vehicleType: [InventoryType.TRUCK, InventoryType.TRAILER] },
    IMPORT_FCL_LOADED_DELIVERY: { label: "FCL Loaded Delivery", cycle: CycleType.IMPORT, vehicleType: [InventoryType.TRUCK, InventoryType.TRAILER] },
    IMPORT_LCL_DELIVERY: { label: "LCL Delivery", cycle: CycleType.IMPORT, vehicleType: [InventoryType.TRUCK] },
    IMPORT_HUB_DELIVERY: { label: "Hub Delivery", cycle: CycleType.IMPORT, vehicleType: [InventoryType.TRUCK] },
    IMPORT_BREAK_BULK: { label: "Break Bulk", cycle: CycleType.IMPORT, vehicleType: [InventoryType.TRAILER] },
    
    EXPORT_STUFFING: { label: "Export Stuffing", cycle: CycleType.EXPORT, vehicleType: [InventoryType.CONTAINER] },
    EXPORT_BUFFER_CONTAINER: { label: "Factory/Buffer Container", cycle: CycleType.EXPORT, vehicleType: [InventoryType.CONTAINER] },
    EXPORT_HUB: { label: "Export Hub", cycle: CycleType.EXPORT, vehicleType: [InventoryType.TRUCK, InventoryType.CONTAINER] },
    ON_WHEEL: { label: "On Wheel", cycle: CycleType.EXPORT, vehicleType: [InventoryType.CONTAINER] },
    EXPORT_TERMINAL_RETURN: { label: "Return From Terminal", cycle: CycleType.EXPORT, vehicleType: [InventoryType.CONTAINER] },
    EXPORT_CARTING: { label: "Export Carting", cycle: CycleType.EXPORT, vehicleType: [InventoryType.TRUCK] },
    EXPORT_BACK_TO_TOWN: { label: "Back Top Town", cycle: CycleType.EXPORT, vehicleType: [InventoryType.TRUCK] },
    EXPORT_BREAK_BULK: { label: "Break Bulk", cycle: CycleType.EXPORT, vehicleType: [InventoryType.TRAILER] },
    EXPORT_CONTAINER_PICKUP: { label: "Export Container Pickup", cycle: CycleType.EXPORT, vehicleType: [InventoryType.TRAILER] },

    EMPTY_CONTAINER_FROM_YARD: { label: "From Empty Yard", cycle: CycleType.EMPTY, vehicleType: [InventoryType.CONTAINER] },
    EMPTY_CONTAINER_HUB: { label: "Hub Stuffing", cycle: CycleType.EMPTY, vehicleType: [InventoryType.CONTAINER] },
    EMPTY_CONTAINER_PICKUP: { label: "Empty Container Pickup", cycle: CycleType.EMPTY, vehicleType: [InventoryType.TRAILER] },

    IN_BONDING: { label: "In Bonding", cycle: CycleType.BOND, vehicleType: [InventoryType.TRUCK, InventoryType.CONTAINER] },
    EX_BONDING: { label: "Ex Bonding", cycle: CycleType.BOND, vehicleType: [InventoryType.TRUCK, InventoryType.TRAILER, InventoryType.CONTAINER] },
    OTHERS: { label: "Others", cycle: null, vehicleType: [InventoryType.TRUCK, InventoryType.TRAILER, InventoryType.CONTAINER] }
} as const;

export type GateInType = typeof GateInTypes[keyof typeof GateInTypes];


export function getGateInOptions(vehicleType: InventoryType) {
    return Object.entries(GateInTypes)
    .filter(([_, value]) => value.vehicleType.includes(vehicleType))
    .map(([key, value]) => ({
      key,
      label: value.label,
      cycle: value.cycle,
    }));
}