import { Component, ComponentRef, ViewContainerRef } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { DynamicFormService } from "../../../../services/dynamic-form.service";
import ChargeGroupConfig from "../../config/ChargeGroupFormConfig.json";
import { DynamicForm } from "../../../../models/dynamic-form";
import { DynamicFormComponent } from "../../../dynamic-form/dynamic-form.component";
import { FinanceService } from "../../../../services/finance.service";
import { LoadingService } from "../../../../services/loading.service";
import { ToasterService } from "../../../../services/toaster.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ModalComponent } from "../../../modal/modal.component";
import { AddAnotherDialogComponent } from "../../../add-another-dialog/add-another-dialog.component";
import { Subject, takeUntil } from "rxjs";
import { Charge } from "../../../../models/charge.interface";
import { ChargeGroup } from "../../../../models/charge-group";

@Component({
  selector: "app-manage-charge-group",
  standalone: true,
  imports: [
    DynamicFormComponent
  ],
  templateUrl: "./manage-charge-group.component.html",
  styleUrl: "./manage-charge-group.component.scss"
})
export class ManageChargeGroupComponent {
  form!: FormGroup;
  formConfig!: DynamicForm;
  editingEnabled: boolean = false;
  readOnlyMode: boolean = false;
  chargeGroupId: string = ""
  private destroy$ = new Subject<void>();

  constructor(private dynamicFormService: DynamicFormService,
              private fb: FormBuilder,
              private tariffService: FinanceService,
              private loadingService: LoadingService,
              private toasterService: ToasterService,
              private router: Router,
              private route: ActivatedRoute,
              private viewContainerRef: ViewContainerRef) {
    this.form = fb.group({});
  }

  ngOnInit() {
    this.formConfig = ChargeGroupConfig;
    this.dynamicFormService.addControlsToForm(this.form, ChargeGroupConfig);

    this.chargeGroupId = this.route.snapshot.paramMap.get("chargeGroupId") || "";
    this.setupComponentMode();
    if(this.chargeGroupId){
        this.loadChargeGroupData();
    }
  }

  save() {
    this.form.markAllAsTouched();
    if (!this.form.valid) {
      return;
    }

    this.loadingService.show();

    const formValue = this.form.value;
    const action$ = this.editingEnabled
      ? this.tariffService.updateChargeGroup(formValue, this.chargeGroupId)
      : this.tariffService.createChargeGroup(formValue);


    action$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (response: any) => {
        this.loadingService.hide();
        this.toasterService.success("Charge Group updated successfully");
        this.cancel()
        // this.showAddChargeDialog();
      },
      error: (error) => {
        this.loadingService.hide();
        this.toasterService.error(error.error.errorDesc || "An error occurred");
      }
    });


  }

  cancel() {
    this.router.navigateByUrl("/manage-finance/charge-group");
  }

  private showAddChargeDialog() {
    const modalRef = this.viewContainerRef.createComponent(ModalComponent);
    const addAnotherDialogComponentRef: ComponentRef<AddAnotherDialogComponent> = modalRef.instance.loadComponent(
      AddAnotherDialogComponent,
      {
        featureIconPath: "assets/icons/tick_add_another_dialog.svg",
        title: `Charge Group ${this.editingEnabled?'updated':'created'} Successfully.`,
        titleDescription: `The charge group has been successfully ${this.editingEnabled?'updated':'created'} . Click 'Add Charge' to include charges.`,
        primaryBtnText: "Add Charges",
        secondaryBtnUrl: "/manage-finance/charge-group",
      }
    );
    this.handleModalPrimaryButtonClick(addAnotherDialogComponentRef);

    modalRef.instance.close.subscribe(()=>modalRef.destroy())
  }

  private handleModalPrimaryButtonClick(addAnotherDialogComponentRef: ComponentRef<AddAnotherDialogComponent>) {
    addAnotherDialogComponentRef.instance.primaryBtnClickEmitter.subscribe(() => {

    });
  }

  private setupComponentMode(): void {
    if (this.chargeGroupId) {
      const path = this.route.snapshot.url[0]?.path || "";
      this.editingEnabled = path === "edit";
      this.readOnlyMode = path === "view";
    } else {
      this.editingEnabled = false;
      this.readOnlyMode = false;
    }
  }

  private loadChargeGroupData(): void {
    this.loadingService.show();
    this.tariffService.getChargeGroupById(this.chargeGroupId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: any) => {
          const chargeData: ChargeGroup = res.data;
          this.patchFormWithChargeGroupData(chargeData);
          this.loadingService.hide();
        },
        error: (err) => {
          console.error("Error loading charge data:", err);
          this.toasterService.error("Failed to load charge data");
          this.loadingService.hide();
        }
      });
  }

  private patchFormWithChargeGroupData(chargeGroupData: ChargeGroup) {
    this.form.patchValue({
      ...chargeGroupData,
      operationalProcess: chargeGroupData?.operationalProcess?.id || "",
      active: chargeGroupData.active !== undefined ? chargeGroupData.active : true
    });
    if (!this.editingEnabled) {
      this.form.disable();
    }
  }
}
