<div class="tos-table-template">
  @if (showTableStats) {
    <div class="table-stats">
      <div class="left">
        <div class="total">Total {{ tableMeta.name }}: {{ stats.total }}</div>
        <div class="active">Active: {{ stats.active }}</div>
        <div class="inactive">Inactive: {{ stats.inactive }}</div>
      </div>
    </div>
  }

  @if (dataListResponse.records.length === 0) {
<!--      <app-no-data-found [msg]="'No Records Found.'"></app-no-data-found>-->
        <ngx-skeleton-loader count="30" appearance="line"></ngx-skeleton-loader>
  } @else {
    <div class="table-container">
      <table>
        <thead>
        <tr>
          <th class="cb_th select-all-cb">
            <input type="checkbox" id="selected_all" [checked]="isAllRecordsOfCurrentPageSelected()"
                   (change)="toggleAllRecordsOfCurrentPageSelections($event.target.checked)" />
          </th>
          @for (column of tableMeta.columns; track column.fieldName) {
            <th class="{{column.fieldName}}_th sortable" mwlResizable
                (resizing)="onResizeEnd($event, column.fieldName)">
              <div class="icon_cover" (click)="sortData(column.fieldName)">
                {{ column.displayName }}
                @if (orderByListContainsField(column.fieldName)) {
                  @if (this.dataListRequest.orderBy[column.fieldName] === "desc") {
                    <app-svg-icon
                      [path]="'assets/icons/sort_icon.svg'"
                      class="sort_icon"
                    ></app-svg-icon>
                  } @else {
                    <app-svg-icon
                      [path]="'assets/icons/up_arrow.svg'"
                      class="sort_icon"
                    ></app-svg-icon>
                  }
                }
                <div class="resize-handle-right" mwlResizeHandle [resizeEdges]="{ right: true }"></div>
              </div>
            </th>
          }
          <th class="table_menu_th" *ngIf="showMenuOptions">
            <!--            <div class="icon_cover plus_icon">
                          <app-svg-icon
                            [path]="'assets/icons/plus_icon.svg'"
                          ></app-svg-icon>
                        </div>-->
          </th>
        </tr>
        </thead>
        <tbody>
          @for (record of dataListResponse.records; track $index) {
            <tr class="row" [ngClass]="isSelected(record) ? 'selected_row' : ''">
              <td class="cb_td row-select-cb">
                <input type="checkbox" (change)="toggleSelection(record)" [checked]="isSelected(record)" />
              </td>
              @for (column of tableMeta.columns; track column.fieldName) {
                <td class="{{column.fieldName}}_td" title="{{record[column.fieldName]}}">
                  <!--                  {{ record[column.fieldName] }}-->
                  <app-tos-table-td
                    [record]="record[column.fieldName]"
                    [column]="column"
                    [styleConfig]="styleConfig"
                  ></app-tos-table-td>
                </td>
              }

              <td class="table_menu_td" *ngIf="showMenuOptions" (click)="filterTableMenu(record)">
                <div class="menu_component">
                  <app-table-menu
                    [options]="filterTableMenuOptions"
                    [isOpen]="openMenuIndex === $index"
                    (toggle)="handleMenuToggle($index)"
                    (optionSelected)="handleOptionSelected(record, $event)"
                    [tableRowData]="record"
                  >
                  </app-table-menu>
                </div>
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>
    <div class="pagination">
      <div class="button_wrapper_left">
        <button (click)="handlePreviousBtnClick()" [disabled]="!(dataListRequest.page > 0)"><img
          src="assets/icons/left-arrow.svg" /> Previous
        </button>
      </div>
      <ng-container *ngIf="totalPages" class="page_numbers">
        <div
          *ngFor="let page of getDisplayedPages()"
          (click)="page !== '...' ? goToPage(page) : null"
          [class.page_number]="page !== '...'"
          [class.ellipsis]="page === '...'"
          [class.active]="dataListRequest.page === page - 1"
        >
          {{ page }}
        </div>
      </ng-container>
      <div class="button_wrapper_right">
        <div class="rows_per_page">
          Rows per page
          <select class="select_dropdown" (change)="handleRowsPerPage($event)">
            <option value="15">15</option>
            <!--          <option value="25">25</option>-->
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
        <button (click)="handleNextButtonClick()"
                [disabled]="!(this.dataListRequest.page >= 0 && this.dataListRequest.page < this.totalPages - 1)">Next
          <img src="assets/icons/right-arrow.svg"></button>
      </div>
    </div>
  }
</div>
