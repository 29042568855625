{
  "name": "User",
  "dbName": "users",
  "columns": [
    {
      "fieldName": "name",
      "dbName": "name",
      "displayName": "Name",
      "dataType": "STRING"
    },
    {
      "fieldName": "email",
      "dbName": "email",
      "displayName": "Email",
      "dataType": "STRING"
    },
    {
      "fieldName": "username",
      "dbName": "username",
      "displayName": "Username",
      "dataType": "STRING"
    },
    {
      "fieldName": "mobile",
      "dbName": "mobile",
      "displayName": "Mobile",
      "dataType": "STRING"
    },
    {
      "fieldName": "active",
      "dbName": "active",
      "displayName": "Status",
      "dataType": "BOOLEAN"
    },
    {
      "fieldName": "isLocked",
      "dbName": "locked",
      "displayName": "Locked",
      "dataType": "BOOLEAN"
    },
    {
      "fieldName": "isSuperAdmin",
      "dbName": "super_admin",
      "displayName": "Super Admin",
      "dataType": "BOOLEAN"
    },
    {
      "fieldName": "roles",
      "dbName": "roles",
      "displayName": "Roles",
      "dataType": "ARRAY"
    },
    {
      "fieldName": "modifiedBy",
      "dbName": "modified_by",
      "displayName": "Modified By",
      "dataType": "STRING",
      "hasLookup": true,
      "lookupDetail": {
        "lookupObjectName": "users",
        "lookupFieldName": "id",
        "lookupDbName": "id",
        "defaultResolutionField": "name"
      }
    },
    {
      "fieldName": "modifiedDate",
      "dbName": "modified_date",
      "displayName": "Modified Date",
      "dataType": "DATETIME"
    }

  ]
}
