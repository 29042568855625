import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { TableListRequest } from "../models/table-list-request.interface";
import { Charge } from "../models/charge.interface";
import { ChargeGroup } from "../models/charge-group";
import { StatsResponse } from "../models/stats-response.interface";
import { APIResponse } from "../models/standard-response.interface";
import { TariffRuleSet } from "../models/tariff-rule-set";

const chargesEndPoint = "v1/charges";
const chargeGroupsEndPoint = "v1/charge-groups";
const determinantsEndPoint = "v1/determinants";
const tariffsEndPoint = "v1/tariffs";

@Injectable({
  providedIn: 'root'
})
export class FinanceService {
  private baseUrl: string = environment.apiBaseUrl;

  constructor(private http: HttpClient) {
  }

  getChargesStats(): Observable<any> {
    return this.http.get(`${this.baseUrl}/${chargesEndPoint}/stats`);
  }

  getChargesList(chargeListRequest: TableListRequest): Observable<any> {
    console.log(chargeListRequest);
    return this.http.post(
      `${this.baseUrl}/${chargesEndPoint}/list`,
      chargeListRequest
    );
  }

  getChargesById(chargeId: string): Observable<any> {
    return this.http.get(
      `${this.baseUrl}/${chargesEndPoint}/${chargeId}`
    );
  }

  createCharge(charge: Charge) {
    console.log("creating charge" + charge.name)
    return this.http.post(
      `${this.baseUrl}/${chargesEndPoint}`,
      charge
    );
  }

  updateCharge(charge: Charge, chargeId: string) {
    console.log("updating charge" + charge.name)
    return this.http.put(
      `${this.baseUrl}/${chargesEndPoint}/${chargeId}`,
      charge
    );
  }


  setChargeActiveStatus(shouldSetActive: boolean, idList: string[]) {
    return this.http.patch(
      `${this.baseUrl}/${chargesEndPoint}/status?status=${shouldSetActive}`,
      idList
    );
  }

  getDeterminants(): Observable<any> {
    return this.http.get(`${this.baseUrl}/${determinantsEndPoint}`);
  }

  createChargeGroup(chargeGroup: ChargeGroup) {
    return this.http.post(
      `${this.baseUrl}/${chargeGroupsEndPoint}`,
      chargeGroup
    );
  }

  getChargeGroupStats():Observable<any> {
    return this.http.get(`${this.baseUrl}/${chargeGroupsEndPoint}/stats`);
  }

  getChargeGroupList(tableListRequest: TableListRequest) {
    return this.http.post(
      `${this.baseUrl}/${chargeGroupsEndPoint}/list`,
      tableListRequest
    );
  }

  setChargeGroupActiveStatus(flag: boolean, selectedItems: string[]) {
    return this.http.patch(
      `${this.baseUrl}/${chargeGroupsEndPoint}/status?status=${flag}`,
      selectedItems
    );
  }

  getChargeGroupById(chargeGroupId: string) {
    return this.http.get(
      `${this.baseUrl}/${chargeGroupsEndPoint}/${chargeGroupId}`
    );
  }

  updateChargeGroup(formValue: ChargeGroup, chargeGroupId: string) {
    return this.http.put(
      `${this.baseUrl}/${chargeGroupsEndPoint}/${chargeGroupId}`,
      formValue
    );
  }

  addChargesToChargeGroup(chargeIds: string[],chargeGroupId: string) {
    return this.http.put(
      `${this.baseUrl}/${chargeGroupsEndPoint}/${chargeGroupId}/mappings`,
      chargeIds
    );
  }

  addChargeGroupToCharge(selectedChargeGroupItems: { chargeGroupsAdded: string[]; chargeGroupsRemoved: string[] },chargeId: string) {
    return this.http.patch(
      `${this.baseUrl}/${chargesEndPoint}/${chargeId}/charge-groups`,
      selectedChargeGroupItems
    )
  }

  listTariffs(tableListRequest: TableListRequest) {
    return this.http.post(
      `${this.baseUrl}/${tariffsEndPoint}/list`,
      tableListRequest
    );
  }

  getDeterminantDetails(selectedDeterminant?: string): Observable<APIResponse> {
    return this.http.get<APIResponse>(`${this.baseUrl}/${determinantsEndPoint}/details`);
  }

  getDeterminantValues(determinantName: string, tableListRequest: TableListRequest) {
    return this.http.post(
      `${this.baseUrl}/${determinantsEndPoint}/values/${determinantName}`,
      tableListRequest
    );
  }

  saveTariff(rulesSets: any) {
    return this.http.post(
      `${this.baseUrl}/${tariffsEndPoint}`,
      rulesSets
    );
  }

  getTariffDetails(tariffId: string) {
    return this.http.get<APIResponse>(`${this.baseUrl}/${tariffsEndPoint}/${tariffId}`);
  }

  updateTariff(tariffId: string, saveTariffRequest: any) {
    return this.http.put(
      `${this.baseUrl}/${tariffsEndPoint}/${tariffId}`,
      saveTariffRequest
    );
  }

  deleteTariff(tariffId: string) {
    return this.http.delete(`${this.baseUrl}/${tariffsEndPoint}/${tariffId}`)
  }
}
