{
    "filterConfig": {
      "filterFields": [ 
          {
              "name": "arrivalContainerIds.containerNo",
              "displayLabel": "Container No",
              "dataType": "STRING",
              "supportedOperators": ["EQ_CIS", "NE_CIS"]
          },
          {
            "name": "igmItemNo",
            "displayLabel": "IGM Item No",
            "dataType": "STRING",
            "supportedOperators": ["EQ_CIS", "NE_CIS"]
          },
          {
              "name": "chaId",
              "displayLabel": "CHA",
              "dataType": "STRING",
              "hasLookup": true,
              "searchConfig": {
                  "apiUrl": "v1/customers/list",
                  "selectFields": ["id", "name", "code"],
                  "whereFields": ["name", "code"],
                  "limit": 10,
                  "orderBy": { "name": "asc" },
                  "bindLabel": "name",
                  "bindValue": "id",
                  "displayColumns": [
                    { "key": "name", "header": "Name" }
                  ]
              }
          },
          {
            "name": "blNo",
            "displayLabel": "MBL No",
            "dataType": "STRING",
            "supportedOperators": ["EQ_CIS", "NE_CIS"]
          },
          {
            "name": "hblNo",
            "displayLabel": "HBL No",
            "dataType": "STRING",
            "supportedOperators": ["EQ_CIS", "NE_CIS"]
          }
      ]
    },
    "tableConfig": {
      "name": "hold",
      "dbName": "hold",
      "uniqueColumns": ["cargoId", "containerId"],
      "columns": [
        {
          "fieldName": "containerNo",
          "displayName": "Container No",
          "dataType": "STRING"
        },
        {
          "fieldName": "igmItemNo",
          "displayName": "IGM Item No",
          "dataType": "STRING"
        },
        {
            "fieldName": "vesselName",
            "displayName": "Vessel Name",
            "dataType": "STRING"
        },
        {
          "fieldName": "accountHolder",
          "displayName": "Account Holder",
          "dataType": "STRING"
        },
        {
          "fieldName": "cha",
          "displayName": "CHA",
          "dataType": "STRING"
        },
        {
            "fieldName": "importType",
            "displayName": "ImportType",
            "dataType": "STRING"
          },
        {
            "fieldName": "blNo",
            "displayName": "MBL No",
            "dataType": "STRING"
        },
        {
            "fieldName": "hblNo",
            "displayName": "HBL No",
            "dataType": "STRING"
        }
      ]
    }
  }