<div class="sidebar_collapsed">

  <div class="navigation">
    <div class="nav_items">
      <div class="nav" (click)="toggleSearch()">
        <div class="application-nav-menu-button"
        >
          <img class="menu_icon" alt="" src="assets/icons/search_icon_sb.svg">
        </div>
        <div class="menu_text">Search</div>
      </div>
      @for (menu of menuItems; track menu.id) {
        <div class="nav" (click)="handleClickOnNavItem(menu)">
          <div class="application-nav-menu-button" [ngClass]="{
          'active': isParentActive(menu.id),
          'selecting': isClicked(menu.id)
          }"
          >
            <img class="menu_icon" alt="" src="{{menu.icon}}">
          </div>
          <div class="menu_text">{{ menu.name }}</div>
        </div>
      }
    </div>
  </div>
  <div class="footer" (mouseleave)="closeOptions()">
    <!--    <img class="avatar-icon" alt="" src="assets/icons/avatar.svg" (click)="showSideNavOptions()">-->
    <div class="avatar_icons" (click)="showSideNavOptions()"
         [ngClass]="{
      'avatar_icons_active': showOptions
    }"
    >
      <img src="assets/icons/admin_menu_icon.svg" />
    </div>

    @if (showOptions) {
      <app-sidebar-options></app-sidebar-options>
    }
  </div>

</div>
