<!--<div class="search_bar_action_btns">
  <div class="container">
    <div class="search_bar">
      <div class="search_container">
        <input
          type="text"
          name="search_table"
          id="search_table"
          [(ngModel)]="searchTerm"
          placeholder="Search by charge group name"
          (focus)="focusSearch = true"
          (focusout)="focusSearch = false"
          (keyup)="handleTableSearch($event)"
        >
        <img src="assets/icons/search_icon.svg" class="search_icon">
      </div>
    </div>
  </div>
</div>
<div class="tos-table-template">

  <div class="table-container">
    <ng-container *ngIf="!tableLoaded">
      <app-no-data
        [msg]="
                'No charge group are available in the system. Please click on Add New button to create one.'
              "
      ></app-no-data>
    </ng-container>
    <ng-container
      *ngIf="
              tableLoaded &&
              tableListData.records?.length > 0
            "
    >
      <table>
        <thead>
        <tr>
          <th class="cb_th select-all-cb">
            <input
              type="checkbox"
              id="select_all_users"
              [checked]="isAllRecordsOfCurrentPageSelected()"
              (change)="
                        toggleAllRecordsOfCurrentPageSelections(
                          $event.target.checked
                        )
                      "
            />
          </th>
          &lt;!&ndash;            <th class="sl_no_th">Sl No.</th>&ndash;&gt;
          <th
            class="name_th sortable"
            [ngClass]="{ sorted: sortColumn === 'name' }"
            (click)="sortData('name')"
          >
            <div class="icon_cover">
              Name
              <app-svg-icon
                [path]="'assets/icons/sort_icon.svg'"
              ></app-svg-icon>
            </div>
          </th>
          <th class="description_th">Description</th>
          <th class="invoice_header_title">Invoice Header Title</th>
          <th
            class="status_th sortable"
            [ngClass]="{ sorted: sortColumn === 'active' }"
            (click)="sortData('active')"
          >
            <div class="icon_cover">
              Status
              <app-svg-icon
                [path]="'assets/icons/sort_icon.svg'"
              ></app-svg-icon>
            </div>
          </th>

          <th class="operational_process_th">Operational Process</th>
          &lt;!&ndash;<th class="roles_th">Determinants</th>&ndash;&gt;
          <th
            class="created_date_th sortable"
            [ngClass]="{ sorted: sortColumn === 'createdAt' }"
            (click)="sortData('createdAt')"
          >
            <div class="icon_cover">
              Created date
              <app-svg-icon
                [path]="'assets/icons/sort_icon.svg'"
              ></app-svg-icon>
            </div>
          </th>

          <th
            class="modified_date_th sortable"
            [ngClass]="{ sorted: sortColumn === 'modifiedAt' }"
            (click)="sortData('modifiedAt')"
          >
            <div class="icon_cover">
              Modified date
              <app-svg-icon
                [path]="'assets/icons/sort_icon.svg'"
              ></app-svg-icon>
            </div>
          </th>
          <th
            class="modifiedBy_th sortable"
            [ngClass]="{ sorted: sortColumn === 'modifiedBy' }"
            (click)="sortData('modifiedBy')"
          >
            <div class="icon_cover">
              Modified By
              <app-svg-icon
                [path]="'assets/icons/sort_icon.svg'"
              ></app-svg-icon>
            </div>
          </th>

&lt;!&ndash;          <th class="table_menu_th">
            &lt;!&ndash; <div class="icon_cover plus_icon">
                  <app-svg-icon
                    [path]="'assets/icons/plus_icon.svg'"
                  ></app-svg-icon>
                </div> &ndash;&gt;
          </th>&ndash;&gt;
        </tr>
        </thead>
        <tbody>
        <tr
          *ngFor="let record of tableListData.records; let i = index"
          [ngClass]="isSelected(record) ? 'selected_row' : ''"
        >
          <td class="cb_td row-select-cb">
            <input
              type="checkbox"
              [id]="'user_sl_no_' + i + 1"
              [checked]="isSelected(record)"
              (change)="toggleSelection(record)"
            />
          </td>
          <td class="name_td" [title]="record.name">
                    <span
                      [innerHTML]="record.name | highlight : searchTerm"
                    ></span>
          </td>
          <td class="description_td" [title]="record.description">{{ record.description }}</td>
          <td class="invoice_header_title_td" [title]="record.invoiceHeaderTitle">{{ record.invoiceHeaderTitle }}</td>
          <td class="status_td">
            <div
              class="icon_cover"
              [ngClass]="record.active ? 'active' : 'inactive'"
            >
              <div class="badge_status">
                <app-svg-icon
                  [path]="'assets/icons/circle_icon.svg'"
                ></app-svg-icon>
                {{ record.active ? "Active" : "Inactive" }}
              </div>
            </div>
          </td>
          <td class="operationalService_td" [title]="record.operationalService">{{ record.operationalProcess }}</td>
          <td class="created_date_td">
            {{ record.createdAt | date : "dd/MM/yy hh:mm a" }}
          </td>
          <td class="modified_date_td">
            {{ record.modifiedAt | date : "dd/MM/yy hh:mm a" }}
          </td>
          &lt;!&ndash; <td class="createdBy_td">
              {{ record.createdBy || "N/A" }}
            </td> &ndash;&gt;
          <td class="modifiedBy_td">
            {{ record.modifiedBy || "N/A" }}
          </td>
&lt;!&ndash;          <td class="table_menu_td">
            <div class="menu_component">
              <app-table-menu
                [options]="menuOptions"
                [isOpen]="openMenuIndex === i"
                (toggle)="handleMenuToggle(i)"
                (optionSelected)="handleOptionSelected(record, $event)"
                [tableRowData]="record"
              >
              </app-table-menu>
            </div>
          </td>&ndash;&gt;
        </tr>
        </tbody>
      </table>
    </ng-container>
    <ng-container
      *ngIf="
              !tableLoaded
            "
    >
      <app-no-data-found [msg]="'No results found !'"></app-no-data-found>
    </ng-container>
  </div>
  <div class="pagination" [hidden]="tableListData.totalCount === 0">


    <div class="button_wrapper_left">
      <button  (click)="previousPage()"><img src="assets/icons/left-arrow.svg"> Previous</button>
    </div>
    <ng-container *ngIf="totalTablePages" class="page_numbers">
      <div
        *ngFor="let page of getDisplayedPages()"
        (click)="page !== '...' ? goToPage(page) : null"
        [class.page_number]="page !== '...'"
        [class.ellipsis]="page === '...'"
        [class.active]="currentTablePage === page"
      >
        {{ page }}
      </div>
    </ng-container>

    <div class="button_wrapper_right">
      <div class="rows_per_page">
        Rows per page
        <select (change)="updateRowsPerPage($event.target.value)" class="select_dropdown">
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </div>
      <button (click)="nextPage()">Next <img src="assets/icons/right-arrow.svg"></button>
    </div>
  </div>
</div>-->


<div class="top_action_bar">
  <div class="search-container">
    <app-autocomplete-dropdown
      id="search-bar"
      class="autocomplete-dropdown"
      [placeholder]="'Search And Add Charges'"
      [apiUrl]="'v1/charge-groups/list'"
      [selectFields]=tableListRequest.selectFields
      [whereFields]="['name']"
      [limit]="10"
      [orderBy]="{'name': 'asc'}"
      [bindLabel]="'name'"
      [bindValue]="'id'"
      [displayColumns]="[
    {key: 'name', header: 'Charge Group Name'},
    {key: 'invoiceHeaderTitle', header: 'Invoice Header Title'},
    ]"
      [additionalFilters]="[
                {
                  'name': 'active',
                  'alias': 'active',
                  'operator': 'EQ',
                  'value': ['true']
                },
                {
                'name': 'id',
                'alias':'id_not_in',
                'operator':'NOT_IN',
                'value': alreadyAddedChargeGroups.size > 0 ?  Array.from(alreadyAddedChargeGroups) : ['']
                }
              ]"
      (suggestionSelected)="handleSuggestionSelected($event)"
      [(ngModel)]="searchAndAddText"
    >
    </app-autocomplete-dropdown>
    <button type="button"
            class="add-button tertiary_button"
            [ngClass]="{ disabled: disableAddBtn }"
            (click)="addNewChargesGroupsToCharge()"
            [disabled]="disableAddBtn">
      <app-svg-icon class="image-icon" [path]="'assets/icons/add_plus_icon.svg'"></app-svg-icon>
      <span>Add</span>
    </button>
  </div>

  @if (selectedItems.length > 0) {
    <div class="action_btns">
      <button class="secondary_button" (click)="handleBulkDelete()">Delete</button>
    </div>
  }
</div>

<app-static-table
  [(data)]="tableListData.records"
  [tableMeta]="tableMeta"
  [showCheckBox]="true"
  (dataDeleted)="handleDataDeletion($event)"
  [(selectedItems)]="selectedItems"
>
</app-static-table>
