<div class="container">
  <div class="header header_with_back_button">
    <app-back-button></app-back-button>
    <span>
        @if (readOnlyMode) { View } @else if(masterEditingEnabled) { Edit } @else
        {Create New} Vessel Port Train Detail
      </span>
  </div>

  <div class="container-content">
    <form [formGroup]="masterForm">
      <div class="section">
        <div class="head">Basic Information</div>
        <div class="fields">
          <div class="grid-container">
            <div class="form-group">
              <label for="vesselName" class="required_field_label" #formField>Vessel Name</label>
              <input
                id="vesselName"
                name="vesselName"
                formControlName="vesselName"
                type="text"
                placeholder="Enter Vessel Name"
                required
                autocomplete="vesselName"
                [ngClass]="{
      form_error:
        masterForm.get('vesselName')?.invalid &&
        masterForm.get('vesselName')?.touched &&
        masterForm.get('vesselName')?.errors
    }"
              />
              <div
                class="form_validation_error"
                *ngIf="
      masterForm.get('vesselName')?.invalid &&
      masterForm.get('vesselName')?.touched
    "
              >
                <small *ngIf="masterForm.get('vesselName')?.errors?.['required']">Vessel Name is required</small>
              </div>
            </div>
            <div class="form-group">
              <label for="terminal" class="required_field_label" #code>Terminal</label>
              <input
                id="terminal"
                name="terminal"
                formControlName="terminal"
                type="text"
                placeholder="Enter Terminal"
                required
                autocomplete="terminal"
                [ngClass]="{
      form_error:
        masterForm.get('terminal')?.invalid &&
        masterForm.get('terminal')?.touched &&
        masterForm.get('terminal')?.errors
    }"
              />
              <div
                class="form_validation_error"
                *ngIf="
      masterForm.get('terminal')?.invalid &&
      masterForm.get('terminal')?.touched
    "
              >
                <small *ngIf="masterForm.get('terminal')?.errors?.['required']">Terminal is required</small>
              </div>
            </div>
            <div class="form-group">
              <label for="port" class="required_field_label" #code>Port</label>
              <input
                id="port"
                name="port"
                formControlName="port"
                type="text"
                placeholder="Enter Port"
                required
                autocomplete="port"
                [ngClass]="{
      form_error:
        masterForm.get('port')?.invalid &&
        masterForm.get('port')?.touched &&
        masterForm.get('port')?.errors
    }"
              />
              <div
                class="form_validation_error"
                *ngIf="
      masterForm.get('port')?.invalid &&
      masterForm.get('port')?.touched
    "
              >
                <small *ngIf="masterForm.get('port')?.errors?.['required']">Port is required</small>
              </div>
            </div>
            <div class="form-group">
              <label for="berthDate" class="required_field_label">Berth Date</label>
              <input
                id="berthDate"
                name="berthDate"
                formControlName="berthDate"
                placeholder="Berth Date"
                type="date"
                required
                autocomplete="berthDate"
                [ngClass]="{
      form_error:
        masterForm.get('berthDate')?.invalid &&
        masterForm.get('berthDate')?.touched &&
        masterForm.get('berthDate')?.errors
    }"
              />
              <div class="form_validation_error" *ngIf="masterForm.get('berthDate')?.invalid && masterForm.get('berthDate')?.touched">
                <small *ngIf="masterForm.get('berthDate')?.errors?.['required']">Berth Date is required</small>
              </div>
            </div>
            <div class="form-group">
              <label for="cutOffDate">Cut Off Date</label>
              <input
                id="cutOffDate"
                name="cutOffDate"
                formControlName="cutOffDate"
                placeholder="Cut Off Date"
                type="date"
                autocomplete="cutOffDate"
              />
            </div>
            <div class="form-group">
              <label for="voyageNo" class="required_field_label" #code>Voyage No</label>
              <input
                id="voyageNo"
                name="voyageNo"
                formControlName="voyageNo"
                type="text"
                placeholder="Enter Voyage Number"
                required
                autocomplete="voyageNo"
                [ngClass]="{
      form_error:
        masterForm.get('voyageNo')?.invalid &&
        masterForm.get('voyageNo')?.touched &&
        masterForm.get('voyageNo')?.errors
    }"
              />
              <div
                class="form_validation_error"
                *ngIf="
      masterForm.get('voyageNo')?.invalid &&
      masterForm.get('voyageNo')?.touched
    "
              >
                <small *ngIf="masterForm.get('voyageNo')?.errors?.['required']">Voyage Number is required</small>
              </div>
            </div>
            <div class="form-group">
              <label for="vesselServiceId" class="required_field_label" #code>Vessel Service ID</label>
              <input
                id="vesselServiceId"
                name="vesselServiceId"
                formControlName="vesselServiceId"
                type="text"
                placeholder="Enter Vessel Service Id"
                required
                autocomplete="vesselServiceId"
                [ngClass]="{
      form_error:
        masterForm.get('vesselServiceId')?.invalid &&
        masterForm.get('vesselServiceId')?.touched &&
        masterForm.get('vesselServiceId')?.errors
    }"
              />
              <div
                class="form_validation_error"
                *ngIf="
      masterForm.get('vesselServiceId')?.invalid &&
      masterForm.get('vesselServiceId')?.touched
    "
              >
                <small *ngIf="masterForm.get('vesselServiceId')?.errors?.['required']">Vessel Service Id is required</small>
              </div>
            </div>
            <div class="form-group">
              <label for="viaNo" class="required_field_label" #code>VIA Number</label>
              <input
                id="viaNo"
                name="viaNo"
                formControlName="viaNo"
                type="text"
                placeholder="Enter VIA Number"
                required
                autocomplete="port"
                [ngClass]="{
      form_error:
        masterForm.get('viaNo')?.invalid &&
        masterForm.get('viaNo')?.touched &&
        masterForm.get('viaNo')?.errors
    }"
              />
              <div
                class="form_validation_error"
                *ngIf="
      masterForm.get('viaNo')?.invalid &&
      masterForm.get('viaNo')?.touched
    "
              >
                <small *ngIf="masterForm.get('viaNo')?.errors?.['required']">VIA Number is required</small>
              </div>
            </div>
            <div class="form-group">
              <label for="igmNo" #code>IGM Number</label>
              <input
                id="igmNo"
                name="igmNo"
                formControlName="igmNo"
                type="text"
                placeholder="Enter IGM Number"
                autocomplete="igmNo"
              />
            </div>
            <div class="form-group">
              <label for="igmDate">IGM Date</label>
              <input
                id="igmDate"
                name="igmDate"
                formControlName="igmDate"
                placeholder="IGM Date"
                type="date"
                autocomplete="igmDate"
              />
            </div>
            <div class="form-group">
              <label for="rotationNo" #code>Rotation Number</label>
              <input
                id="rotationNo"
                name="rotationNo"
                formControlName="rotationNo"
                type="text"
                placeholder="Rotation Number"
                autocomplete="rotationNo"
              />
            </div>
            <div class="form-group">
              <label for="eta">ETA</label>
              <input
                id="eta"
                name="eta"
                formControlName="eta"
                placeholder="Enter ETA"
                type="date"
                autocomplete="eta"
              />
            </div>
            <div class="form-group">
              <label for="etd">ETD</label>
              <input
                id="etd"
                name="etd"
                formControlName="etd"
                placeholder="Enter ETD"
                type="date"
                autocomplete="etd"
              />
            </div>
            <div class="form-group">
              <label for="parentViaNo" #code>Parent VIA Number</label>
              <input
                id="parentViaNo"
                name="parentViaNo"
                formControlName="parentViaNo"
                type="text"
                placeholder="Enter Parent VIA Number"
                autocomplete="parentViaNo"
              />
            </div>
            <div class="form-group">
              <label for="holdVessel">Hold Vessel</label>
              <select id="holdVessel" formControlName="holdVessel">
                <option [value]="true">Yes</option>
                <option [value]="false">No</option>
              </select>
            </div>
          </div>
        </div>
      </div>




    </form>
  </div>
  <div class="footer_actions">
    <ng-container *ngIf="readOnlyMode">
      <button type="button" class="secondary_button" (click)="goBack()">
        Back
      </button>
    </ng-container>
    <ng-container *ngIf="!readOnlyMode">
      <button type="button" class="secondary_button" (click)="goBack()">
        Cancel
      </button>
      <button
        type="button"
        class="primary_button"
        (click)="submitMasterForm()"
      >
        @if(masterEditingEnabled){ Update }@else { Save }
      </button>
    </ng-container>
  </div>

</div>
