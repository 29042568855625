import { Component, ComponentRef, EventEmitter, Input, Output, Type, ViewChild, ViewContainerRef } from "@angular/core";
import { SvgIconComponent } from "../svg-icon/svg-icon.component";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { NgIf } from "@angular/common";

@Component({
  selector: 'app-right-panel',
  standalone: true,
  imports: [
    SvgIconComponent,
    NgIf
  ],
  templateUrl: './right-panel.component.html',
  styleUrl: './right-panel.component.scss',
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translateX(0)',
        opacity: 1
      })),
      state('out', style({
        transform: 'translateX(100%)',
        opacity: 0
      })),
      transition('in => out', [
        animate('0.3s cubic-bezier(0.55, 0, 0.55, 0.2)')
      ]),
      transition('out => in', [
        animate('0.3s cubic-bezier(0.22, 1, 0.36, 1.2)')
      ])
    ])

  ]
})
export class RightPanelComponent {
  @Input() title: string | undefined;
  @Input() showActionBtns = false;
  @Input() primaryActionBtnText = 'Save';

  @Output() close = new EventEmitter<void>();
  @Output() refreshParent = new EventEmitter<void>();
  @Output() primaryActionBtnEmitter = new EventEmitter<any>();

  @ViewChild('rightPanelContent', { read: ViewContainerRef, static: true })

  rightPanelContent!: ViewContainerRef;
  panelState: 'in' | 'out' = 'in';

  onClose(params?: any) {
    this.panelState = 'out';
    setTimeout(() => {
      this.close.emit(params);
    }, 300);
  }

  loadComponent(component: Type<any>, data?: Partial<any>): ComponentRef<any> {
    this.rightPanelContent.clear(); // Clear any previously loaded content
    const componentRef = this.rightPanelContent.createComponent(component);

    // Pass the closeModal function to the dynamically loaded component
    if (componentRef.instance) {
      (componentRef.instance as any).closeRightPanel = this.onClose.bind(this);

      if (data) {
        Object.assign(componentRef.instance, data); // Pass the data to the component
      }
    }

    return componentRef;
  }

  handlePrimaryBtnClick() {
    this.primaryActionBtnEmitter.emit()
  }
}
