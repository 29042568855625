<div class="otp-container">
  <form [formGroup]="otpForm">
    <div class="input_container">
      <input
        #otpInput
        *ngFor="let digit of otpForm.controls | keyvalue; let i = index"
        [formControlName]="digit.key"
        maxlength="1"
        type="tel"
        autocomplete="new-text"
        (keyup)="onKeyup($event, i)"
        [id]="'otp_digit_' + (i + 1)"
        autocomplete="off"
      />
    </div>
  </form>
</div>
