<header class="page_header">
  <div class="header_content">
    <div class="text_and_supporting_text">
      <div class="page_title">User Management</div>
      <!-- <div class="page_desc">View and manage the list of Users</div> -->
    </div>
    <div class="action_btn">
      <button class="add_new_btn" (click)="addNewRecord()">
        <img src="assets/icons/plus_icon.svg" height="20" width="20">Add New
      </button>
    </div>
  </div>
</header>

<div class="search_bar_action_btns">
  <div class="container">
    <div class="search_bar">
      <div class="search_container">
        <input
          type="text"
          name="search_table"
          id="search_table"
          [(ngModel)]="searchTerm"
          placeholder="Search by name or email"
          (focus)="focusSearch = true"
          (focusout)="focusSearch = false"
          (keyup)="handleTableSearch($event)"
        >
        <img src="assets/icons/search_icon.svg" class="search_icon">
      </div>
    </div>

    <div class="filter_btn">
      <button (click)="filter()">
        <img src="assets/icons/filter.svg">
        Filters
      </button>
    </div>

    <div class="bulk_actions">
      @if(selectedItems.length > 0 || allTableRecordsSelected){
        <div class="selection_count">
          <div class="count">
            {{
              allTableRecordsSelected
                ? tableStats.total
                : selectedItems.length
            }}
            selected
          </div>
        </div>
        <div class="actions">
          <button class="tick" (click)="bulkActionConfirmation('setActive')">Make Users Active</button>
          <button class="close" (click)="bulkActionConfirmation('setInActive')">Make Users Inactive</button>
        </div>
      }
    </div>
  </div>
</div>

<app-tos-table
  [stats]="tableStats"
  [showTableStats]="true"
  [tableMeta]="tableMeta"
  [styleConfig]="tableStyle"
  [tableMenuOptions]="menuOptions"
  [(dataListRequest)]="tableListRequest"
  [dataListResponse]="tableListData"
  [(selectedItems)]="selectedItems"
  (tosTableAction)="getTableList()"
  (tableMenuOptionSelected)="handleOptionSelected($event)"
></app-tos-table>

