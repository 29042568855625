import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NgClass, NgIf } from "@angular/common";

@Component({
  selector: 'app-sidebar-header',
  standalone: true,
  imports: [
    NgClass,
    NgIf
  ],
  templateUrl: './sidebar-header.component.html',
  styleUrl: './sidebar-header.component.scss'
})
export class SidebarHeaderComponent {
  @Input() isExpanded: boolean = false;
  @Input() isPinned: boolean = false;
  @Input() isSearching: boolean = false

  @Output() isPinnedEmitter: EventEmitter<boolean> =  new EventEmitter<boolean>();
  @Output() searchOffEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  togglePin() {
    this.isPinned = !this.isPinned;
    console.log(this.isPinned);
    this.isPinnedEmitter.emit(this.isPinned)
  }

  turnOffSearching() {
    this.isSearching = false;
    this.searchOffEmitter.emit(this.isSearching)
  }
}
