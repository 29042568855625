import {
  Component, ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  EventEmitter,
  Input,
  Output, QueryList,
  ViewChild, ViewChildren,
  ViewContainerRef
} from "@angular/core";
import { NgClass, NgForOf, NgIf } from "@angular/common";
import { Rule, RuleValue, TariffRuleSet } from "../../../models/tariff-rule-set";
import { SvgIconComponent } from "../../svg-icon/svg-icon.component";
import { FormsModule } from "@angular/forms";
import { animate, keyframes, state, style, transition, trigger } from "@angular/animations";
import { NoDataFoundComponent } from "../../no-data-found/no-data-found.component";
import { TariffTableRulesComponent } from "./tariff-table-rules/tariff-table-rules.component";
import { Determinant } from "../../../models/charge.interface";
import { MatChip } from "@angular/material/chips";
import { RightPanelComponent } from "../../right-panel/right-panel.component";
import { FinanceService } from "../../../services/finance.service";
import { ActivatedRoute } from "@angular/router";
import { TooltipDirective } from "../../../directives/tooltip.directive";
import { DeterminantDetails } from "../../../models/determinant-details";
import { selectAll } from "../../../auth/auth.reducer";
import { ModalComponent } from "../../modal/modal.component";
import { DeleteConfirmDialogComponent } from "./delete-confirm-dialog/delete-confirm-dialog.component";
import { ConfirmDialogComponent } from "../../confirm-dialog/confirm-dialog.component";
import { ToasterService } from "../../../services/toaster.service";
import { TariffTableConfigs } from "../config/TariffTableConfigs";

@Component({
  selector: "app-tariff-table",
  standalone: true,
  imports: [
    NgForOf,
    NgIf,
    SvgIconComponent,
    FormsModule,
    NoDataFoundComponent,
    MatChip,
    TooltipDirective,
    NgClass
  ],
  templateUrl: "tariff-table.html",
  styleUrl: "tariff-table.scss",
  animations: [
    trigger("tabAnimation", [
      transition(":enter", [
        style({ opacity: 0, transform: "scale(0.8) translateY(-10px)" }),
        animate("200ms ease-out", style({ opacity: 1, transform: "scale(1) translateY(0)" }))
      ]),
      transition(":leave", [
        animate("150ms ease-in", style({ opacity: 0, transform: "scale(0.8) translateY(-10px)" }))
      ])
    ]),
    trigger("blinkAnimation", [
      transition(":enter", [
        animate("1s", keyframes([
          style({ backgroundColor: "#F5FBFF", offset: 0 }),
          style({ backgroundColor: "#ffffff", offset: 1 })
        ]))
      ])
    ])
  ]
})
export class TariffTable {
  addButtonVisible: boolean = false;
  protected readonly ruleSetsInit: TariffRuleSet = {
    "description": "Rule Set",
    "rows": [
      {
        "rules": []
      }
    ],
    "cols": [
      {
        "rules": []
      }
    ],
    "values": [[
      {
        "minTariffExpression": "",
        "tariffExpression": ""
      }
    ]]
  };
  selectedRuleSetIndex: number = 0;
  descriptionEditable: boolean = false;
  private deleteConfirmationComponentRef: ComponentRef<DeleteConfirmDialogComponent> | null = null;
  protected highlightHeaders: { colIndex: number; rowIndex: number } = { colIndex: -1, rowIndex: -1 };

  @Input() ruleSets: TariffRuleSet[] = [];
  @Input() determinants: DeterminantDetails[] = [];

  @ViewChild("descriptionTextArea") descriptionTextArea!: ElementRef;
  @ViewChildren("ColumnHeaderLetter", { read: ElementRef }) columnHeaderLetters!: QueryList<ElementRef>;

  @Output() ruleSetsChange = new EventEmitter<any>();


  constructor(private viewContainerRef: ViewContainerRef,
              private financeService: FinanceService,
              private route: ActivatedRoute,
              private toasterService: ToasterService) {
  }

  ngOnInit() {
    const path = this.route.snapshot.url[0].path;
    if (path === "add-new") this.addNewRuleSet();
  }

  addColumns(ruleSet: TariffRuleSet, event: Event) {
    if (ruleSet.cols.length >= TariffTableConfigs.maxColumns) {
      return;
    }

    if (ruleSet.cols[ruleSet.cols.length - 1]?.rules?.length === 0) {
      this.toasterService.error("Add rules to the existing column before proceeding.");
      return;
    }

    // add a new column with empty rules
    ruleSet.cols.push({ rules: [] });

    // add a new tariff cell to each existing row
    ruleSet.values.forEach((row: any[]) => {
      row.push({
        minTariffExpression: "",
        tariffExpression: ""
      });
    });

    //scroll to the end of columns when new columns are added
    this.scrollToRight(event);

    console.log(this.ruleSets);
  }

  deleteColumns(ruleSet: TariffRuleSet, colIndex: number) {
    if (colIndex < -1) return;

    const componentRef = this.showConfirmationModal("Column", colIndex);

    componentRef.confirmDialogComponentComponentRef.instance.confirm.subscribe(() => {
      ruleSet.cols.splice(colIndex, 1);
      ruleSet.values.forEach(tariff => tariff.splice(colIndex, 1));
      componentRef.modalComponentRef.destroy();
    });
  }

  addRows(ruleSet: TariffRuleSet, event: Event) {
    if (ruleSet.rows.length >= TariffTableConfigs.maxRows) {
      return;
    }

    if (ruleSet.rows[ruleSet.rows.length - 1]?.rules?.length === 0) {
      this.toasterService.error("Add rules to the existing row before proceeding.");
      return;
    }

    // add a new row with empty rules
    ruleSet.rows.push({ rules: [] });

    // create a new tariff row with cells equal to the number of columns
    const newRow = new Array(ruleSet.cols.length)
      .fill({})
      .map(() => ({
        minTariffExpression: "",
        tariffExpression: ""
      }));
    ruleSet.values.push(newRow);

    this.scrollToBottom(event);
  }

  deleteRows(ruleSet: any, rowIndex: number, event: Event) {
    if (rowIndex < -1) return;

    const componentRef = this.showConfirmationModal("Row", rowIndex);

    componentRef.confirmDialogComponentComponentRef.instance.confirm.subscribe(() => {
      ruleSet.rows.splice(rowIndex, 1);
      ruleSet.values.splice(rowIndex, 1);
      componentRef.modalComponentRef.destroy();
    });
  }

  addConditionsForSelectedRowOrColumn(ruleSetIndex: number, headerIndex: number, headerType: string) {
    const panelComponentComponentRef = this.setUpRightPanel();

    const determinantRulesComponentRef = panelComponentComponentRef.instance.loadComponent(TariffTableRulesComponent);
    determinantRulesComponentRef.setInput("allDeterminants", this.determinants);
    determinantRulesComponentRef.setInput("ruleSets", this.ruleSets);

    // if (this.ruleSets[ruleSetIndex]?.cols[headerIndex]?.rules?.length !== 0 || this.ruleSets[ruleSetIndex]?.rows[headerIndex]?.rules?.length !== 0)
      determinantRulesComponentRef.instance.patchRulesValue(ruleSetIndex, headerIndex, headerType);

    //check for apply all button of the right panel and then call the applyRules method of determinantRulesComponent
    panelComponentComponentRef.instance.primaryActionBtnEmitter.subscribe(() => {
      determinantRulesComponentRef.instance.applyRules(ruleSetIndex, headerIndex, headerType);
      if (determinantRulesComponentRef.instance.filterForm.valid) panelComponentComponentRef.destroy();
    });

    determinantRulesComponentRef.instance.applyAllButtonClicked.subscribe((ruleSets: any) => {
      this.ruleSets = ruleSets;
      this.ruleSetsChange.emit(this.ruleSets);
    });
  }

  private setUpRightPanel() {
    const modalRef = this.viewContainerRef.createComponent(RightPanelComponent);
    modalRef.instance.title = "Add Rule";
    modalRef.instance.showActionBtns = true;
    modalRef.instance.primaryActionBtnText = "Apply";
    modalRef.instance.close.subscribe(() => {
      modalRef.destroy();
    });
    return modalRef;
  }

  private scrollToBottom(event: Event) {
    setTimeout(() => {
      const button = event.target as HTMLElement;
      const rulesMatrix = button.closest(".rules-matrix");
      if (rulesMatrix) {
        const tableContainer = rulesMatrix.querySelector(".table-container") as HTMLElement;
        if (tableContainer) {
          tableContainer.scrollTop = tableContainer.scrollHeight;
        }
      }
    }, 10);
  }

  private scrollToRight(event: Event) {
    setTimeout(() => {
      const button = event.target as HTMLElement;
      const rulesMatrix = button.closest(".rules-matrix");
      if (rulesMatrix) {
        const tableContainer = rulesMatrix.querySelector(".table-container") as HTMLElement;
        if (tableContainer) {
          tableContainer.scrollLeft = tableContainer.scrollWidth;
        }
      }
    }, 10);
  }

  getValuesToPrint(rule: Rule) {
    return rule.values.slice(0, 3).map(value => value.text).join(", ");
  }

  addNewRuleSet() {
    if(this.ruleSets.length >= TariffTableConfigs.maxRules) return
    this.ruleSetsInit.description = `Rule Set ${this.ruleSets.length + 1}`;
    this.ruleSets.push(JSON.parse(JSON.stringify(this.ruleSetsInit)));
    this.selectedRuleSetIndex = this.ruleSets.length - 1;
  }

  deleteRuleSet(index: number, event: MouseEvent) {
    const showConfirmationModal = this.showConfirmationModal("Rule Set", index);
    showConfirmationModal.confirmDialogComponentComponentRef.instance.confirm.subscribe(() => {
      event.stopPropagation();
      this.ruleSets.splice(index, 1);

      if (this.selectedRuleSetIndex >= index) {
        this.selectedRuleSetIndex = Math.max(0, this.selectedRuleSetIndex - 1);
      }
      showConfirmationModal.modalComponentRef.destroy()
    })
  }

  getDisplayTextForDeterminant(determinantName: string | undefined) {
    return this.determinants.find(determinant => determinantName === determinant.name)?.displayName || determinantName;
  }

  getDisplayTextForOperator(determinantName: string | undefined, operatorName: string | undefined) {
    const operatorValue = this.determinants.find(determinant => determinantName === determinant.name)?.valueTypeResponse?.supportedOperators.find(operator => operatorName === operator.name)?.operator || operatorName;
    if (operatorValue === "==" || operatorValue === "text") return "=";
    return operatorValue;
  }

  highlightColumnsToBeDeleted(ruleSet: TariffRuleSet, colIndex: number) {
    const columns = document.querySelectorAll(`.tariff-cell:nth-child(${colIndex + 2})`);
    columns.forEach(column => {
      column.classList.add("highlight-delete");
    });

    const removeHighlight = () => {
      columns.forEach(column => {
        column.classList.remove("highlight-delete");
      });
    };

    document.querySelector(".delete_btn")?.addEventListener("mouseleave", removeHighlight, { once: true });
  }

  showDescriptionTextArea() {
    this.descriptionEditable = true;
    setTimeout(() => {
      this.descriptionTextArea.nativeElement.focus();
    }, 1);
  }

  hideDescriptionArea(ruleSet: TariffRuleSet) {
    const descriptionValueEntered = this.descriptionTextArea.nativeElement.value;

    if (descriptionValueEntered !== "")
      ruleSet.description = descriptionValueEntered;
    this.descriptionEditable = false;
  }

  showAddRowColBtns() {
    this.addButtonVisible = true;
  }

  hideAddRowColBtns() {
    this.addButtonVisible = false;
  }

  getColumnLetter(index: number): string {
    let letter = "";
    while (index >= 0) {
      letter = String.fromCharCode((index % 26) + 65) + letter;
      index = Math.floor(index / 26) - 1;
    }
    return letter;
  }

  highLightRowAndCol(colIndex: number, rowIndex: number) {
    this.highlightHeaders = { colIndex, rowIndex };
  }

  selectAllCols(colIndex: number) {
    const columns = document.querySelectorAll(`.matrix-table tbody tr td:nth-child(${colIndex + 2})`);
    columns.forEach(column => {
      column.classList.add("highlighted");
    });
  }

  unselectAllCols = (colIndex: number) => {
    const columns = document.querySelectorAll(`.matrix-table tbody tr td:nth-child(${colIndex + 2})`);
    columns.forEach(column => {
      column.classList.remove("highlighted");
    });
  };

  selectAllRows(rowIndex: number) {
    const rows = document.querySelectorAll(`.matrix-table tbody tr:nth-child(${rowIndex + 1}) td`);

    rows.forEach(row => {
      row.classList.add("highlighted");
    });
  }

  unselectAllRows(rowIndex: number) {
    const rows = document.querySelectorAll(`.matrix-table tbody tr:nth-child(${rowIndex + 1}) td`);

    rows.forEach(row => {
      row.classList.remove("highlighted");
    });
  }

  private showConfirmationModal(headerType: string, index: number) {
    const modalComponentRef = this.viewContainerRef.createComponent(ModalComponent);
    const confirmDialogComponentComponentRef = modalComponentRef.instance.loadComponent(ConfirmDialogComponent);

    modalComponentRef.instance.title = `Delete ${headerType}`;

    const letter = (headerType.toLowerCase() === "column") ? this.getColumnLetter(index) : index + 1;
    confirmDialogComponentComponentRef.instance.message = `Are you sure you want to Delete the ${headerType} "${letter}”?`;

    confirmDialogComponentComponentRef.instance.cancel.subscribe(() => {
      modalComponentRef.destroy();
    });
    modalComponentRef.instance.close.subscribe(() => {
      modalComponentRef.destroy();
    });

    return { modalComponentRef, confirmDialogComponentComponentRef };
  }


  //TODO: Implement these methods to show pop-up in the table
  /*  private showDeleteConfirmation(colIndex: number) {
      if(this.deleteConfirmationComponentRef) {
        this.removeDeleteConfirmationBox()
        return
      }

      this.deleteConfirmationComponentRef = this.viewContainerRef.createComponent(DeleteConfirmDialogComponent)


      const columnElement = document.querySelector(`.matrix-table thead th:nth-child(${colIndex + 2})`);
      if (columnElement) {
        const rect = this.columnHeaderLetters.get(colIndex)?.nativeElement.getBoundingClientRect();
        const componentElement = this.deleteConfirmationComponentRef.location.nativeElement;
        componentElement.style.position = 'absolute';
        componentElement.style.top = `${rect.bottom + window.scrollY}px`;
        componentElement.style.left = `${rect.left + window.scrollX + 180}px`;
      }
    }

    private removeDeleteConfirmationBox() {
      if (this.deleteConfirmationComponentRef) {
        this.deleteConfirmationComponentRef.destroy();
        this.deleteConfirmationComponentRef = null;
      }
    }*/
}
