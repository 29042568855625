import {
  ApplicationConfig,
  provideZoneChangeDetection,
  isDevMode,
} from '@angular/core';
import { routes } from './app.routes';
import { provideRouter } from '@angular/router';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideStore } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { RedirectInterceptor } from './interceptors/redirect.interceptor';
import { withCredentialsInterceptor } from './interceptors/withCredentails.interceptor';
import { authInterceptor } from './interceptors/http.interceptor';
import { progressLoaderInterceptor } from './interceptors/progress-loader.interceptor';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideAnimationsAsync(),
    provideStore(),
    provideEffects(),
    provideStoreDevtools({ maxAge: 25, logOnly: !isDevMode() }),
    provideHttpClient(
      withInterceptors([
        authInterceptor,
        withCredentialsInterceptor,
        RedirectInterceptor,
        progressLoaderInterceptor,
      ])
    ),
  ],
};
