import { Component, Input } from '@angular/core';
import { SharedModule } from '../../../../modules/shared/shared.module';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HoldReason, HoldSource, HoldType } from '../../../../constants/operations-contsants';
import { MultiSelectComponent } from "../../../select/multi-select.component";
import { Where } from '../../../../models/user-list-request.interface';
import { OperationService } from '../../../../services/operations.service';
import { ToasterService } from '../../../../services/toaster.service';
import { LoadingService } from '../../../../services/loading.service';

@Component({
  selector: 'app-release-hold-form',
  standalone: true,
  imports: [SharedModule, MultiSelectComponent],
  templateUrl: './release-hold-dialog.component.html',
  styleUrl: './release-hold-dialog.component.scss'
})
export class ReleaseHoldDialogComponent {
  releaseForm!: FormGroup;
  @Input() selectedFilter!: Where;
  @Input() selectedItems!: any[];
  @Input() processCycle!: String;
  @Input() holdSource!: HoldSource;
  @Input() holdDocumentNo!: string;
  @Input() holdSubCategory!: string

  isHoldDocumentReadOnly: boolean = false;

  holdSourceOptions = Object.values(HoldSource);
  holdTypeOptions = Object.values(HoldType);
  holdReasonOptions = Object.values(HoldReason);


  constructor(private fb: FormBuilder,
              private api: OperationService,
              private toasterService: ToasterService,
              private loadingService: LoadingService
  ) {
    this.initForm();
  }

  ngOnInit() {
    this.loadDataToForm();
  }

  initForm() {
      this.releaseForm = this.fb.group({
        holdIssuedBy: [null, Validators.required],
        holdCategory: [{ value: null, disabled: true }, Validators.required],
        holdSubCategory: [null, Validators.required],
        holdDocumentNo: ['', Validators.required],
        releaseDocumentNo: ['', Validators.required],
        releaseDate: [null, Validators.required]
      });
  }

  private loadDataToForm() {
    if (this.holdSource) {
      this.releaseForm.get('holdIssuedBy')?.setValue(this.holdSource.key);
      this.onHoldSourceChange(this.holdSource);
      this.releaseForm.get('holdIssuedBy')?.disable();
    }
    if (this.holdDocumentNo) {
      this.isHoldDocumentReadOnly = true;
      this.releaseForm.get('holdDocumentNo')?.setValue(this.holdDocumentNo);
    }
    if (this.holdSubCategory) {
      this.releaseForm.get('holdSubCategory')?.setValue(this.holdSubCategory);
      this.releaseForm.get('holdSubCategory')?.disable();
    }
  }

  closeModal!: (params?: any) => void;

  close(params?: any) {
    if (this.closeModal) {
      this.closeModal(params);
    }
  }

  isCustomsHold(): boolean {
    return this.releaseForm.get('holdCategory')?.value === 'CUSTOMS';
  }

  isInternalHold(): boolean {
    return this.releaseForm.get('holdCategory')?.value === 'INTERNAL';
  }

  onHoldSourceChange(selectedHoldSource: HoldSource) {
    this.holdSource = selectedHoldSource;
    this.releaseForm.get('holdCategory')?.setValue(selectedHoldSource.holdType.key);
    if (selectedHoldSource.holdType.key === 'CUSTOMS') {
      this.releaseForm.get("holdDocumentNo")?.enable();
      this.releaseForm.get("releaseDocumentNo")?.enable();
      this.releaseForm.get('holdSubCategory')?.disable();
      // this.releaseForm.get("holdDocumentNo")?.addValidators(Validators.required);
      // this.releaseForm.get("releaseDocumentNo")?.addValidators(Validators.required);

    } else {
      this.releaseForm.get("holdDocumentNo")?.disable();
      this.releaseForm.get("releaseDocumentNo")?.disable();
      this.releaseForm.get('holdSubCategory')?.enable();
      // this.releaseForm.get("holdDocumentNo")?.clearValidators();
      // this.releaseForm.get("releaseDocumentNo")?.clearValidators();
    }
  }

  closeDialog() {

  }

  releaseHold() {
    console.log("Inside releaseHold, ", this.releaseForm.get('holdIssuedBy')?.value);
    this.releaseForm.markAllAsTouched();
    if (this.releaseForm.invalid) {
      return;
    }

    if (!this.selectedFilter && (!this.selectedItems?.length || this.selectedItems?.length < 1)) {
      this.toasterService.error("Please select the items for hold");
      this.closeModal();
      return;
    }
  
    const holdReleaseRequest = this.createHoldReleaseRequest();
    console.log("holdRequest: ", holdReleaseRequest);
      this.api.submitHoldReleaseRequest(holdReleaseRequest).subscribe({
        next: (res: any) => {
          this.toasterService.success("Successfully processed hold request");
          this.closeModal({
            refresh: true,
          });
        },
        error: (err) => {
          console.error(err);
          this.toasterService.error(err.error.errorDesc);
        }
      });
  }
  
  createHoldReleaseRequest() {
    let holdItemIdList = null;
    let releaseFilter = null;
    if (this.selectedFilter) {
      releaseFilter = this.selectedFilter;
    } else {
      holdItemIdList = this.selectedItems.map((item: any) => item.id);
    }
    const holdRequest = {
      ...this.releaseForm.value,
      processCycle: this.processCycle,
      holdCategory: this.holdSource.holdType.key,
      holdSubCategory: this.releaseForm.get('holdSubCategory')?.value,
      holdIssuedBy: this.releaseForm.get('holdIssuedBy')?.value,
      releaseDate: new Date(this.releaseForm.get('releaseDate')?.value).getTime(),
      holdItemIdList,
      releaseFilter
    }
    return holdRequest;
  }
}
