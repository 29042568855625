<div class="page_container">
  <header class="header">
    <div class="close_btn" (click)="goBack()">
      <img src="assets/icons/back_button.svg" alt="back icon" />
    </div>
    <div class="page_title">Tariff</div>
    <button class="tertiary_button add_new_btn" (click)="addNewTariff()">Add New</button>
  </header>
<!--  <app-stats-horizontal
    [stats]="chargeStats"
  ></app-stats-horizontal>-->
  <app-tos-table
    [showTableStats]="false"
    [tableMeta]="tableMeta"
    [tableMenuOptions]="menuOptions"
    [(dataListRequest)]="tableListRequest"
    [dataListResponse]="tableListData"
    [(selectedItems)]="selectedItems"
    (tosTableAction)="getTableList()"
    (tableMenuOptionSelected)="handleOptionSelected($event)"
  ></app-tos-table>
</div>
