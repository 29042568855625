import { Component, EventEmitter, Output } from "@angular/core";
import { SidebarNewCollapsedComponent } from "./sidebar-new-collapsed/sidebar-new-collapsed.component";
import { SidebarExpandedComponent } from "../sidebar/sidebar-expanded/sidebar-expanded.component";
import { SidebarNewExpandedComponent } from "./sidebar-new-expanded/sidebar-new-expanded.component";
import { SidebarHeaderComponent } from "./sidebar-header/sidebar-header.component";
import { StorageKeys } from "../../constants/app-constants";
import { debounceTime, filter } from "rxjs/operators";
import { NavigationEnd, Router } from "@angular/router";
import { LocalStorageService } from "../../services/local-storage.service";
import { MenuService } from "../../services/menu.service";
import { User } from "../../models/user.interface";
import { Menu } from "../../models/sidebar-menu.interface";
import { SidebarNewSearchOverlayComponent } from "./sidebar-new-search-overlay/sidebar-new-search-overlay.component";
import { SidebarOptionsComponent } from "./sidebar-options/sidebar-options.component";
import { NgClass } from "@angular/common";

@Component({
  selector: "app-sidebar-new",
  standalone: true,
  imports: [
    SidebarNewCollapsedComponent,
    SidebarExpandedComponent,
    SidebarNewExpandedComponent,
    SidebarHeaderComponent,
    SidebarNewSearchOverlayComponent,
    SidebarOptionsComponent,
    NgClass
  ],
  templateUrl: "./sidebar-new.component.html",
  styleUrl: "./sidebar-new.component.scss"
})
export class SidebarNewComponent {
  @Output() toggle = new EventEmitter<{ expanded: boolean; pinned: boolean, searching: boolean }>();

  isExpanded: boolean = false;
  isPinned: boolean = false;
  isSearching: boolean = false;
  userProfile!: User;
  selectedMenu!: Menu;
  selectingMenu!: Menu
  activeChild!: Menu;
  menuItems: Menu[] = [];

  constructor(private localStorageService: LocalStorageService,
              private menuService: MenuService,
              private router: Router) {
  }

  ngOnInit() {
    this.loadSidebarState();
    this.menuService.getMenuItems().subscribe(items => {
      this.menuItems = items;
      this.highlightActiveMenu();
    });
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        debounceTime(100)
      ) // Debounce to smooth the transitions)
      .subscribe(() => {
        this.highlightActiveMenu();
      });

    // Initial highlight based on the current route
    this.highlightActiveMenu();
  }

  loadSidebarState() {
    const isSidebarPinned = this.localStorageService.getItem("isSidebarPinned");
    if (isSidebarPinned) {
      this.isPinned = true;
    }
    const isSidebarExpanded =
      this.localStorageService.getItem("isSidebarExpanded");
    if (isSidebarExpanded) {
      this.isExpanded = true;
    }
    this.emitToggle();
  }

  emitToggle() {
    this.toggle.emit({ expanded: this.isExpanded, pinned: this.isPinned, searching: this.isSearching });
  }

  expandSideBar() {
    if (!this.isPinned)
      this.isExpanded = true;
    this.emitToggle();
  }

  collapseSideBar() {
    if (!this.isPinned) {
      this.isExpanded = false;
      this.isSearching = false;
      this.selectingMenu = this.selectedMenu
    }
    this.emitToggle();
  }

  togglePin(event: boolean) {
    this.isPinned = event;
    this.isExpanded = this.isPinned;
    this.selectingMenu = this.selectedMenu
    this.emitToggle();
    this.saveSidebarState();
  }

  saveSidebarState() {
    this.localStorageService.setItem("isSidebarPinned", this.isPinned);
    this.localStorageService.setItem("isSidebarExpanded", this.isExpanded);
  }

  handleMenuChildResources(menu: Menu) {
    this.isExpanded = true
    this.emitToggle()
    this.selectingMenu = menu;
  }

  highlightActiveMenu() {
    const currentUrl = this.router.url;

    for (const menu of this.menuItems) {
      const matchingChild = menu.childResources?.find(child => currentUrl.includes(<string>child.resourceProperties?.url));
      if (!matchingChild)
        continue;
      this.selectedMenu = menu;
      this.activeChild = matchingChild;
      return;
    }
  }

  handleSearchingEvent(value: boolean) {
    this.isSearching = value;
    this.emitToggle()
  }

  turnOffSearching() {
    this.isSearching = false;

  }
}
