import {
  Component,
  ElementRef,
  EventEmitter,
  input,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from "@angular/core";
import { DatePipe, NgClass, NgForOf, NgIf } from "@angular/common";
import { HighlightPipe } from "../../pipes/highlight.pipe";
import { NoDataComponent } from "../no-data/no-data.component";
import { NoDataFoundComponent } from "../no-data-found/no-data-found.component";
import { ResizableModule } from "angular-resizable-element";
import { SvgIconComponent } from "../svg-icon/svg-icon.component";
import { TableMenuComponent } from "../table-menu/table-menu.component";
import { SelectComponent } from "../select/select.component";
import { AutocompleteDropdownComponent } from "../autocomplete-dropdown/autocomplete-dropdown.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { EditableTableHeader } from "../../models/editable-table-header.interface";
import { User } from "../../models/user.interface";
import { select } from "@ngrx/store";
import {
  EditableTableDynamicInputComponent
} from "./editable-table-dynamic-input/editable-table-dynamic-input.component";
import { DynamicInputComponent } from "../dynamic-form/dynamic-input/dynamic-input.component";
import { Subject } from "rxjs";

@Component({
  selector: "app-editable-table",
  standalone: true,
  imports: [
    DatePipe,
    HighlightPipe,
    NgForOf,
    NgIf,
    NoDataComponent,
    NoDataFoundComponent,
    ResizableModule,
    SvgIconComponent,
    TableMenuComponent,
    SelectComponent,
    NgClass,
    AutocompleteDropdownComponent,
    FormsModule,
    ReactiveFormsModule,
    DynamicInputComponent,
    EditableTableDynamicInputComponent
  ],
  templateUrl: "./editable-table.component.html",
  styleUrls: ["./editable-table.component.scss"]
})
export class EditableTableComponent {
  selectedRows: any[] = [];
  selectedCols: any[] = [];
  columnValues: { [key: string]: any } = {};
  allTableRecordsSelected: boolean = false;
  suggestions: any[] = [];
  showSuggestions: boolean = false;
  searchTerm: string = "";
  loading = false;
  searchedAndSelectedRecord: any;

  @Input() tableHeaders: EditableTableHeader[] = [];
  @Input() tableData: any[] = [];
  @Input() title: string = "";
  @Input() subtitle: string = "";
  @Input() searchBarConfig: {
    displayColumns?: { key: string; header: string }[],
    searchPlaceholder?: string,
    apiMethod?: any,
    selectFields?: string[],
  } = {};
  @Input() uniqueId: string = "id";
  @Input() addButtonText!: string;
  @Input() disable: boolean = false;
  @Input() noDataFoundErrorMsg: string = "No data found";
  @Input() showSearchBarWithSuggestions: boolean = false;
  @Input() selectedRowsInput: any[] = [];

  @Output() addNewRecord = new EventEmitter<any>();
  @Output() dataDeleted = new EventEmitter<any>();
  @Output() selectedRowsSubject = new EventEmitter<any>();
  @Output() dataUpdated = new EventEmitter<any>();

  @ViewChild("searchBarInput") searchBarInput: ElementRef | undefined;
  @ViewChildren("headerInput") headerInputs: QueryList<EditableTableDynamicInputComponent> | undefined;
  @ViewChildren("tableDataInput") tableDataInputs: QueryList<EditableTableDynamicInputComponent> | undefined;

  ngAfterViewInit(){
    this.selectedRowsInput.forEach((row) => {
      if (!this.isSelected(row)) {
        this.selectedRows.push(row);
      }
    })
  }


  updateValue(event: any, row: number, header: EditableTableHeader) {
    const value = event.target ? (event.target as HTMLInputElement).value : event[header.fieldInfo?.lookupDetails?.bindValue || "id"];

    if (this.tableData[row][header.label] === value)
      return;

    this.tableData[row][header.label] = value;
    this.dataUpdated.emit({
      key: header.label,
      rowInfo: this.tableData[row],
      value: this.tableData,
      inputElement: event.target as HTMLInputElement });
  }

  updateColumnValue(header: EditableTableHeader, event: any) {
    const value = event.target ? (event.target as HTMLInputElement).value : event;
    this.columnValues[header.label] = { header, value };
  }

  applyAllAllowed(): boolean {
    return this.selectedRows.length > 0 && this.selectedCols.length > 0;
  }

  deleteAllowed(): boolean {
    return this.selectedRows.length > 0;
  }

  toggleAllRecordsOfCurrentPageSelections(isChecked: boolean) {
    if (isChecked) {
      this.tableData?.forEach((item) => {
        if (!this.isSelected(item)) {
          this.selectedRows.push(item);
        }
      });
    } else {
      this.tableData?.forEach((item) => {
        this.selectedRows = this.selectedRows.filter((i) => i[this.uniqueId] !== item[this.uniqueId]);
      });
    }
  }

  isSelected(item: any): boolean {
    if (this.allTableRecordsSelected) {
      return true;
    } else {
      const recordIdx = this.selectedRows.findIndex((x) => x[this.uniqueId] === item[this.uniqueId]);
      if (recordIdx !== -1) {
        return true;
      } else {
        return false;
      }
    }
  }

  toggleSelection(item: any) {
    if (!this.isSelected(item)) {
      this.selectedRows.push(item);
    } else {
      this.selectedRows = this.selectedRows.filter((i) => i[this.uniqueId] !== item[this.uniqueId]);
    }
    this.selectedRowsSubject.emit(this.selectedRows)
  }

  isAllRecordsOfCurrentPageSelected(): boolean {
    return (
      this.allTableRecordsSelected ||
      (this.tableData?.length > 0 &&
        this.tableData?.every((item: any) =>
          this.isSelected(item)
        ))
    );
  }

  isSelectedColumn(selectedColumnLabel: string): boolean {
    return this.selectedCols.includes(selectedColumnLabel);
  }


  selectColumns(selectedColumnLabel: string) {
    if (!this.isSelectedColumn(selectedColumnLabel)) {
      this.selectedCols.push(selectedColumnLabel);
    } else {
      this.selectedCols = this.selectedCols.filter((i) => i !== selectedColumnLabel);
    }

    console.log(this.selectedCols);
  }

  applyEnteredValueForSelectedRows() {
    this.selectedRows.forEach((row) => {
      this.selectedCols.forEach((col) => {
        if (this.columnValues[col]?.value === undefined)
          return;

        //apply lookup value
        if (this.columnValues[col].header?.hasLookup) {
          this.applyLookupValues(col, row);
          return;
        }

        row[col] = this.columnValues[col].value;
      });
    });

    this.cancelSelection();
  }

  private applyLookupValues(col: string | number, row: { [x: string]: any; }) {
    this.tableDataInputs?.forEach(input => {
      if (input.autocompleteDropdown && this.isSelected(input.row)) {
        input.autocompleteDropdown.select(this.columnValues[col].value);
      }
    });
    row[col] = this.columnValues[col].value[this.columnValues[col].header?.fieldInfo?.lookupDetails?.bindValue];
  }

  cancelSelection() {
    this.selectedCols = [];
    this.selectedRows = [];

    this?.headerInputs?.forEach(input => {
      if (input.autocompleteDropdown) {
        input.autocompleteDropdown.searchTerm = "";
        return;
      }
      input.value = "";
    });
  }

  callApiToFetchSuggestions(value: string, selectFields: string) {
    this.loading = true;
    this.searchBarConfig.apiMethod(value, selectFields).subscribe({
      next: (res: any) => {
        const fetchedSuggestions = res.data || [];
        this.suggestions = fetchedSuggestions.filter((suggestion: any) =>
          !this.tableData.some(item => item[this.uniqueId] === suggestion[this.uniqueId])
        );
        this.loading = false;
        this.showSuggestions = true;
      },
      error: (err: any) => {
        console.error("Error fetching suggestions:", err);
        this.loading = false;
      }
    });
  }


  toggleSuggestionBox(event: Event) {
    const target = event.target as HTMLInputElement;
    const value = target.value;

    if (value.length > 3) {
      this.searchTerm = value;
      this.callApiToFetchSuggestions(value, this.searchBarConfig?.selectFields?.join(",") || "");
    } else {
      this.showSuggestions = false;
    }
  }

  select(record: any) {
    this.searchedAndSelectedRecord = record;
    this.showSuggestions = false;
    if (this.searchBarInput) {
      this.searchBarInput.nativeElement.value = record.containerNo;
    }
  }

  addRecord() {
    if (!this.searchedAndSelectedRecord)
      return;

    this.addNewRecord.emit(this.searchedAndSelectedRecord);
    this.searchedAndSelectedRecord = null;
    if (this.searchBarInput) {
      this.searchBarInput.nativeElement.value = "";
    }
  }

  deleteTableData() {
    this.dataDeleted.emit(this.selectedRows);
    this.cancelSelection();
  }
}
